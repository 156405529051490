import {API_PATH, TOAST_MESSAGES} from '@app/common/constants';
import {routeStateActions} from '@app/router/redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import NetworkService from '@app/services/network/NetworkService';
import {
  Box,
  Divider,
  TableCell,
  TableRow,
  IconButton,
  CircularProgress,
  InputLabel,
  TextField,
  Grid,
  Button,
  Checkbox,
} from '@mui/material';
import {useEffect, useMemo, useState, useCallback, Fragment} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {
  actions,
  directoryActions,
  directorySelector,
  directoryNavActionHandlerCreator,
} from '../../redux';
import EditIcon from '@app/assets/svg/edit-icon';
import Popup from '../../components/Popup';
import {socialMediaCategorySelector} from './redux/selectors';

import {socialMediaCategoryListCreator} from './redux/slice';
import {DirectoryTabProps} from './types';
import TableList from '../../components/TableList';
import {
  directoryPageLists,
  directoryUpdateCurrentPageCreator,
} from '../../redux/slice';
import {ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
import {
  searchObj,
  tabTitleObj,
} from '../../components/CommonFunctions/CommonFun';

const columns = ['Name', '', ''];

const initialAddTabData = {
  id: 0,
  name: '',
  isActive: 1,
};

const SocialMediaCategory = () => {
  const [updatingError, setUpdatingError] = useState<null | string>(null);
  const [isUpdatingTab, setIsUpdatingTab] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [updatedInitialAddTabData, setUpdatedInitialAddTabData] =
    useState(initialAddTabData);

  const [addSocialMediaCategoryData, setSocialMediaCategoryData] =
    useState<any>(updatedInitialAddTabData);

  const hoSocialMediaCategoryList: any = useSelector(
    socialMediaCategorySelector.getsocialmediacategorylist(),
    shallowEqual,
  );

  const dispatch = useDispatch();

  const isActive = useSelector(directorySelector.getStatus, shallowEqual);
  const search = useSelector(directorySelector.getNavSearch(), shallowEqual);

  const modal = useSelector(
    directorySelector.getDirectoryModal(),
    shallowEqual,
  );

  const showAddTabModal = useMemo(
    () => modal?.show && modal?.type === actions.addTab,
    [modal],
  );

  const loading: any = useSelector(
    directorySelector.getLoading(),
    shallowEqual,
  );

  const isTabsFetching = useMemo(
    () =>
      loading === API_PATH.directoryConfiguration.socialmediacategoryList &&
      (!hoSocialMediaCategoryList || hoSocialMediaCategoryList.length === 0),
    [loading, hoSocialMediaCategoryList],
  );

  const isUpdatingData = useMemo(
    () => loading === API_PATH.directoryConfiguration.socialmediacategoryList,
    [loading],
  );

  const isEmpty = useMemo(
    () =>
      !isTabsFetching &&
      (!hoSocialMediaCategoryList || hoSocialMediaCategoryList.length === 0),
    [isTabsFetching, hoSocialMediaCategoryList],
  );

  const cancelAddTabModal = useCallback(() => {
    setIsEditable(false);
    dispatch(directoryNavActionHandlerCreator({show: false, type: null}));
    setUpdatedInitialAddTabData(initialAddTabData);
    setSocialMediaCategoryData(updatedInitialAddTabData);
    setUpdatingError(null);
  }, [initialAddTabData, updatedInitialAddTabData]);

  const onLoadMoreHandler = useCallback(
    (index: number) => {
      dispatch(
        socialMediaCategoryListCreator({
          index,
          isActive: isActive === 1,
          query: search?.query,
        }),
      );
    },
    [search, isActive],
  );

  const onFormUpdateHandler = (data: any) => {
    if (updatingError) {
      setUpdatingError(null);
    }
    setSocialMediaCategoryData({
      ...addSocialMediaCategoryData,
      ...data,
    });
  };
  const onAddOrUpdateTabHandler = async (e: any) => {
    e.preventDefault();
    if (addSocialMediaCategoryData.name?.toString().trim()) {
      setIsUpdatingTab(true);

      const updatedInitialData = {...addSocialMediaCategoryData};
      try {
        const response = await NetworkService.post(
          API_PATH.directoryConfiguration.socialMediaCategorySave,
          updatedInitialData,
          {},
        );
        setIsUpdatingTab(false);
        if (response.status !== 200) {
          ToasterService.showToaster(
            isEditable
              ? TOAST_MESSAGES.UNSUCCESSFULL_UPDATE
              : TOAST_MESSAGES.UNSUCCESSFULL_ADD,
            ToastType.ERROR,
          );
          setUpdatingError(response.data.description);
        } else {
          ToasterService.showToaster(
            isEditable
              ? TOAST_MESSAGES.SUCCESSFULLY_UPDATED
              : TOAST_MESSAGES.SUCCESSFULLY_ADD,
            ToastType.SUCCESS,
          );
          dispatch(
            socialMediaCategoryListCreator({
              index: 1,
              isActive: isActive === 1,
              query: search?.query,
            }),
          );
          cancelAddTabModal();
        }
      } catch (error) {
        setIsUpdatingTab(false);
      }
    } else {
      setUpdatingError('Please fill all required fields!!');
    }
  };
  useEffect(() => {
    let commonSearch: any = searchObj(isActive, search);
    dispatch(socialMediaCategoryListCreator(commonSearch));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, search]);

  useEffect(() => {
    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.directoryConfiguration,
      ),
    );
    let tabTitleData = tabTitleObj('Social Media Category', actions);
    dispatch(directoryActions.updateNavData(tabTitleData));

    dispatch(
      directoryUpdateCurrentPageCreator(directoryPageLists.socialMediaCategory),
    );

    return () => {
      dispatch(routeStateActions.setNavbarComponentName(null));
    };
  }, []);

  useEffect(() => {
    setSocialMediaCategoryData(updatedInitialAddTabData);
  }, [updatedInitialAddTabData]);

  const tabEditHandler = useCallback(event => {
    const tab: DirectoryTabProps = JSON.parse(
      event.currentTarget.dataset.current,
    );
    setIsEditable(true);
    setUpdatingError(null);
    setUpdatedInitialAddTabData({
      id: tab.id,
      name: tab.name,
      isActive: tab.isActive,
    });
    dispatch(
      directoryNavActionHandlerCreator({show: true, type: actions.addTab}),
    );
  }, []);

  const onChangeSocialMediaCategoryNameHandler = useCallback(
    event => onFormUpdateHandler({name: event.target.value}),
    [onFormUpdateHandler],
  );

  const onChangeIsActiveSocialMediaCategoryHandler = useCallback(
    (event: any) =>
      onFormUpdateHandler({
        isActive: event.target.checked ? 1 : 0,
      }),
    [onFormUpdateHandler],
  );

  const onResetHandler = useCallback(() => {
    setSocialMediaCategoryData(updatedInitialAddTabData);
  }, [updatedInitialAddTabData]);

  if (isTabsFetching) {
    return (
      <Box justifyContent={'center'} display="flex" sx={{mt: 2}}>
        <CircularProgress sx={{color: 'primary.main'}} disableShrink />
      </Box>
    );
  }

  return (
    <>
      <TableList
        columns={columns}
        isEmpty={isEmpty}
        onLoadMore={onLoadMoreHandler}
        isPaging={true}
        isLoading={isUpdatingData}
      >
        {hoSocialMediaCategoryList && hoSocialMediaCategoryList.length > 0
          ? hoSocialMediaCategoryList?.map(
              (tab: DirectoryTabProps, index: number) => (
                <Fragment key={tab.id.toString()}>
                  <TableRow>
                    <TableCell>{tab.name}</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      <IconButton
                        sx={{p: 0}}
                        data-current={JSON.stringify(tab)}
                        onClick={tabEditHandler}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <Divider
                    component={TableRow}
                    variant="fullWidth"
                    sx={{height: 5, border: 0}}
                  />
                </Fragment>
              ),
            )
          : null}
      </TableList>
      <Popup
        open={!!showAddTabModal}
        cancelPopup={cancelAddTabModal}
        isLoading={isUpdatingTab}
        onClose={cancelAddTabModal}
        error={updatingError}
        title={
          isEditable
            ? 'Edit Social Media Category'
            : 'Add Social Media Category'
        }
      >
        <form
          onSubmit={onAddOrUpdateTabHandler}
          style={{maxHeight: 500, overflow: 'auto'}}
        >
          <Box sx={{mb: 3}}>
            <InputLabel variant="standard" required>
              Name
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Enter Text"
              required
              value={addSocialMediaCategoryData.name}
              type="text"
              onChange={onChangeSocialMediaCategoryNameHandler}
              inputProps={{
                maxlength: 30,
                minlength: 2,
              }}
            />
          </Box>

          <Box sx={{my: 3}}>
            <InputLabel variant="standard">Is active</InputLabel>
          </Box>

          <Box sx={{mb: 3}}>
            <Checkbox
              checked={addSocialMediaCategoryData.isActive === 1}
              onChange={onChangeIsActiveSocialMediaCategoryHandler}
            />
          </Box>
          <Grid
            container
            justifyContent={'center'}
            sx={{mb: 2}}
            alignItems={'center'}
          >
            <Grid item mr={2}>
              <Button variant="outlined" onClick={onResetHandler}>
                Reset
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" type="submit">
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Popup>
    </>
  );
};

export default SocialMediaCategory;
