export enum REQUEST_FOR_ENUM {
  saleTransfer = 'Sale Transfer',
  incentiveDistribution = 'Incentive Distribution',
}

export enum MonthType {
  month1 = 'month1',
  month2 = 'month2',
  month3 = 'month3',
  total = 'total',
  incentive = 'incentiveAmount',
}

export interface DropDownValue {
  label: string;
  value: number;
}

export interface ModalExistingDataType {
  id: number;
  unlockTillDate: Date | null;
  reason: string;
  state: DropDownValue;
  division: DropDownValue;
  request: DropDownValue;
  isActive: boolean;
}

export interface ModalExistingDataTypeForSalesTransferConfig {
  id: number;
  state: DropDownValue;
  division: DropDownValue;
  quarter: DropDownValue;
  configuration: DropDownValue;
  isActive: boolean;
}
export interface SegmentList {
  id: number;
  incentiveAmount: number;
  incentivePercent: number;
  isActive: boolean;
  isIncentiveAmount: boolean;
  month1: number;
  month2: number;
  month3: number;
  name: string;
  remarks: string;
  subSegment: Omit<SegmentList, 'subSegment'>[];
  subProduct: Omit<SegmentList, 'subProduct'>[];
  total?: number;
}

export interface SegmentProductList {
  id: number;
  segmentId: number;
  productId: number;
  productName: string;
  pack: any;
  productCode: any;
  isActive: boolean;
  month1: number;
  month2: number;
  month3: number;
  totalQuantity: number;
  remarks: any;
}

export interface SalesTransferData {
  fromHQId: number;
  isLocked: boolean;
  quarterId: number;
  segmentList: SegmentList[];
  showDisclaimer: boolean;
  staffPositionId: number;
  toHQId: number;
}
export interface CutOffDate {
  id: number;
  isActive: boolean;
}

/*** sales distribution search api tyoe here */
export interface SegmentData {
  segmentId: number;
  segmentName: string;
  incentiveAmount: number;
}

export interface MonthData {
  monthId: number;
  monthName: string;
  segmentData: SegmentData[];
}

export interface AccountData {
  accountType: string;
  monthData: MonthData[];
  averageData: SegmentData[];
  previousQuarterData: SegmentData[];
  remarks: string | null;
}
