import {API_PATH, HTTP_OK} from '@app/common/constants';
import {NetworkService, ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
import {takeLatest, call, put, select} from 'redux-saga/effects';
import {approveExpenseStateSelector} from './selectors';
import {
  approveExpenseStateActions,
  fetchStateDataTypeName,
  fetchDivisionDataTypeName,
  fetchManagerDataTypeName,
  fetchStaffDataTypeName,
  fetchExpenseApprovalsDataTypeName,
  fetchStatusDataTypeName,
  fetchMiscExpenseApprovalsDataTypeName,
  fetchDailyExpenseApprovalsDataTypeName,
  fetchExpenseApproveStatusTypeName,
  fetchDailyExpenseApprovalViewBillListTypeName,
  uploadMiscExpenseDataTypeName,
  fetchMiscExpenseApprovalViewBillListTypeName,
  fetchExpenseRejectStatusTypeName,
  fetchDailyExpenseDcrReportDataTypeName,
  fetchDailyExpenseDcrMapUrlDataTypeName,
  fetchMiscExpenseFileDeleteDataTypeName,
  fetchDailyExpensePopupReadOnlyDataTypeName,
  fetchDailyExpensePopupTADADataTypeName,
  fetchDailyExpensePopupTADASaveDataTypeName,
  miscSaveCreatorTypeName,
  deductDaAmountCreatorTypeName,
  approveExpenseStatusCreatorTypeName,
} from './slice';

// State Dropdown watcher
export function* fetchStateDataWatcher() {
  yield takeLatest(fetchStateDataTypeName, fetchStateDataWorker);
}

// State Dropdown worker
export function* fetchStateDataWorker(): any {
  const url = `${API_PATH.moduleSpecificUtilities.approveExpense.dropdowns.allStates}`;
  try {
    yield put(approveExpenseStateActions.setStateLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      yield put(approveExpenseStateActions.setStateData(response?.data));
    } else {
      yield put(approveExpenseStateActions.setStateError());
    }
  } catch (error) {
    yield put(approveExpenseStateActions.setStateError());
  }
}

// Division Dropdown watcher
export function* fetchDivisionDataWatcher() {
  yield takeLatest(fetchDivisionDataTypeName, fetchDivisionDataWorker);
}

// Division Dropdown worker
export function* fetchDivisionDataWorker(): any {
  const url = `${API_PATH.moduleSpecificUtilities.approveExpense.dropdowns.divisions}`;
  try {
    yield put(approveExpenseStateActions.setDivisionLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      yield put(approveExpenseStateActions.setDivisionData(response?.data));
    } else {
      yield put(approveExpenseStateActions.setDivisionError());
    }
  } catch (error) {
    yield put(approveExpenseStateActions.setDivisionError());
  }
}

// Status Dropdown watcher
export function* fetchStatusDataWatcher() {
  yield takeLatest(fetchStatusDataTypeName, fetchStatusDataWorker);
}

// Status Dropdown worker
export function* fetchStatusDataWorker(): any {
  const categoryType = 'ExpenseAdminStatus';
  const url = `${API_PATH.moduleSpecificUtilities.approveExpense.dropdowns.category}/${categoryType}`;
  try {
    yield put(approveExpenseStateActions.setStatusLoading());
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === HTTP_OK) {
      yield put(approveExpenseStateActions.setStatusData(response?.data));
    } else {
      yield put(approveExpenseStateActions.setStatusError());
    }
  } catch (error) {
    yield put(approveExpenseStateActions.setStatusError());
  }
}

// Manager Dropdown watcher
export function* fetchManagerDataWatcher() {
  yield takeLatest(fetchManagerDataTypeName, fetchManagerDataWorker);
}

// Manager Dropdown worker
export function* fetchManagerDataWorker(action: any): any {
  const url = `${API_PATH.moduleSpecificUtilities.approveExpense.managers}`;
  try {
    const {searchData, divisionId} = action?.payload;
    if (searchData?.length > 0 && divisionId) {
      yield put(approveExpenseStateActions.setManagerLoading());
      const response = yield call(NetworkService.post, url, {
        searchData,
        divisionId,
      });
      if (response.status === HTTP_OK) {
        yield put(approveExpenseStateActions.setManagerData(response?.data));
      } else {
        yield put(approveExpenseStateActions.setManagerError());
      }
    }
  } catch (error) {
    yield put(approveExpenseStateActions.setManagerError());
  }
}

// Staff Dropdown watcher
export function* fetchStaffDataWatcher() {
  yield takeLatest(fetchStaffDataTypeName, fetchStaffDataWorker);
}

// Staff Dropdown worker
export function* fetchStaffDataWorker(action: any): any {
  const url = `${API_PATH.moduleSpecificUtilities.approveExpense.staffs}`;
  try {
    const {searchData, divisionId} = action?.payload;
    if (searchData?.length > 0 && divisionId) {
      yield put(approveExpenseStateActions.setStaffLoading());
      const response = yield call(NetworkService.post, url, {
        searchData,
        divisionId,
      });
      if (response.status === HTTP_OK) {
        yield put(approveExpenseStateActions.setStaffData(response?.data));
      } else {
        yield put(approveExpenseStateActions.setStaffError());
      }
    }
  } catch (error) {
    yield put(approveExpenseStateActions.setStaffError());
  }
}

// expnese appovals data
export function* fetchExpenseApprovalsWatcher() {
  yield takeLatest(
    fetchExpenseApprovalsDataTypeName,
    fetchExpenseApprovalsWorker,
  );
}

export function* fetchExpenseApprovalsWorker(action: any): any {
  const url = `${API_PATH.moduleSpecificUtilities.approveExpense.expenseApprovals}`;
  try {
    const {
      divisionId,
      month,
      year,
      statusId,
      stateId,
      managerStaffPositionId,
      staffPositionId,
      managerUserId,
      staffUserId,
    } = action?.payload;

    yield put(approveExpenseStateActions.setExpenseApprovalsLoading());
    const response = yield call(NetworkService.post, url, {
      divisionId,
      month,
      year,
      statusId,
      stateId,
      managerStaffPositionId,
      staffPositionId,
      managerUserId,
      staffUserId,
    });
    if (response.status === HTTP_OK) {
      yield put(
        approveExpenseStateActions.setExpenseApprovalsData(response?.data),
      );
    } else {
      yield put(approveExpenseStateActions.setExpenseApprovalsError());
    }
  } catch (error) {
    yield put(approveExpenseStateActions.setExpenseApprovalsError());
  }
}

// daily expnese appovals data
export function* fetchDailyExpenseApprovalsWatcher() {
  yield takeLatest(
    fetchDailyExpenseApprovalsDataTypeName,
    fetchDailyExpenseApprovalsWorker,
  );
}

export function* fetchDailyExpenseApprovalsWorker(action: any): any {
  const url = `${API_PATH.moduleSpecificUtilities.approveExpense.dailyExpenseApprovals}`;
  try {
    const {month, year, staffPositionId, userId} = action?.payload;

    yield put(approveExpenseStateActions.setDailyExpenseApprovalsLoading());
    const response = yield call(NetworkService.post, url, {
      month,
      year,
      staffPositionId,
      userId,
    });
    if (response.status === HTTP_OK) {
      let sample = response?.data;
      for (let i = 0; i < sample?.length; i++) {
        sample[i].id = i + 1;
      }
      yield put(
        approveExpenseStateActions.setDailyExpenseApprovalsData(sample),
      );
    } else {
      yield put(approveExpenseStateActions.setDailyExpenseApprovalsError());
    }
  } catch (error) {
    yield put(approveExpenseStateActions.setDailyExpenseApprovalsError());
  }
}

// misc expnese appovals data
export function* fetchMiscExpenseApprovalsWatcher() {
  yield takeLatest(
    fetchMiscExpenseApprovalsDataTypeName,
    fetchMiscExpenseApprovalsWorker,
  );
}

export function* fetchMiscExpenseApprovalsWorker(action: any): any {
  const url = `${API_PATH.moduleSpecificUtilities.approveExpense.miscExpenseApprovals}`;
  try {
    const {month, year, staffPositionId, userId} = action?.payload;

    yield put(approveExpenseStateActions.setMiscExpenseApprovalsLoading());
    const response = yield call(NetworkService.post, url, {
      month,
      year,
      staffPositionId,
      userId,
    });
    if (response.status === HTTP_OK) {
      yield put(
        approveExpenseStateActions.setMiscExpenseApprovalsData(response?.data),
      );
    } else {
      yield put(approveExpenseStateActions.setMiscExpenseApprovalsError());
    }
  } catch (error) {
    yield put(approveExpenseStateActions.setMiscExpenseApprovalsError());
  }
}

// approve expense modal confirm button
export function* fetchConfirmExpenseApprovalsWatcher() {
  yield takeLatest(
    fetchExpenseApproveStatusTypeName,
    fetchConfirmExpenseApprovalsWorker,
  );
}

export function* fetchConfirmExpenseApprovalsWorker(action: any): any {
  const url = `${API_PATH.moduleSpecificUtilities.approveExpense.confirmExpenseApprovals}`;
  const filteredData = yield select(
    approveExpenseStateSelector.getFilterData(),
  );
  const SelectedExpenseApprovalsUser = yield select(
    approveExpenseStateSelector.getSelectedExpenseApprovalsUser(),
  );
  try {
    yield put(approveExpenseStateActions.setConfirmExpenseApprovalsLoading());
    const response = yield call(NetworkService.post, url, action?.payload);
    if (response.status === HTTP_OK && response?.data) {
      yield put(approveExpenseStateActions.setConfirmExpenseApprovalsSuccess());
      yield put(approveExpenseStateActions.setOpenApproveExpenseModal(false));
      ToasterService.showToaster(
        `${response.data.message}`,
        response.data.isCreated === true ? ToastType.SUCCESS : ToastType.ERROR,
      );
      const request = {
        month: filteredData?.month,
        year: filteredData?.year,
        staffPositionId: SelectedExpenseApprovalsUser?.staffPositionId,
        userId: SelectedExpenseApprovalsUser?.staffUserId,
      };

      yield put({type: approveExpenseStatusCreatorTypeName, payload: request});
    } else {
      ToasterService.showToaster(
        `Error ${response?.data?.errorCode} ${response?.data?.description}`,
        ToastType.ERROR,
      );
      yield put(approveExpenseStateActions.setConfirmExpenseApprovalsError());
    }
  } catch (error) {
    yield put(approveExpenseStateActions.setConfirmExpenseApprovalsError());
  }
}

// reject expense modal yes button
export function* fetchRejectExpenseApprovalsWatcher() {
  yield takeLatest(
    fetchExpenseRejectStatusTypeName,
    fetchRejectExpenseApprovalsWorker,
  );
}

export function* fetchRejectExpenseApprovalsWorker(action: any): any {
  const filteredData = yield select(
    approveExpenseStateSelector.getFilterData(),
  );
  const SelectedExpenseApprovalsUser = yield select(
    approveExpenseStateSelector.getSelectedExpenseApprovalsUser(),
  );
  const url = `${API_PATH.moduleSpecificUtilities.approveExpense.rejectExpenseApprovals}`;
  try {
    const {expenseHistoryId, adminComments} = action?.payload;
    yield put(approveExpenseStateActions.setRejectExpenseApprovalsLoading());
    const response = yield call(NetworkService.post, url, {
      expenseHistoryId,
      adminComments,
    });
    if (response.status === HTTP_OK && response?.data) {
      yield put(approveExpenseStateActions.setRejectExpenseApprovalsSuccess());
      ToasterService.showToaster(
        `${response.data.message}`,
        response.data.isCreated === true ? ToastType.SUCCESS : ToastType.ERROR,
      );
      const request = {
        month: filteredData?.month,
        year: filteredData?.year,
        staffPositionId: SelectedExpenseApprovalsUser?.staffPositionId,
        userId: SelectedExpenseApprovalsUser?.staffUserId,
      };

      yield put({type: approveExpenseStatusCreatorTypeName, payload: request});
      // ToasterService.showToaster(`Successfully Rejected`, ToastType.SUCCESS);
    } else {
      ToasterService.showToaster(
        `Error ${response?.data?.errorCode} ${response?.data?.description}`,
        ToastType.ERROR,
      );
      yield put(approveExpenseStateActions.setRejectExpenseApprovalsError());
    }
    yield put(approveExpenseStateActions.setOpenRejectExpenseModal(false));
  } catch (error) {
    yield put(approveExpenseStateActions.setRejectExpenseApprovalsError());
  }
}

// Daily expense view bill watcher
export function* fetchDailyExpenseApprovalsViewBillWatcher() {
  yield takeLatest(
    fetchDailyExpenseApprovalViewBillListTypeName,
    fetchDailyExpenseApprovalsViewBillWorker,
  );
}
// Daily expense view bill worker
export function* fetchDailyExpenseApprovalsViewBillWorker(action: any): any {
  const url = `${
    API_PATH.moduleSpecificUtilities.approveExpense.dailyExpenseViewBill
      .viewBillList
  }${action.payload}${
    API_PATH.moduleSpecificUtilities.approveExpense.dailyExpenseViewBill
      .isMiscellaneousExpense
  }${false}
  `;
  try {
    yield put(approveExpenseStateActions.setexpenseApprovalsViewBillLoading());
    const response = yield call(NetworkService.get, url, {}, {});

    if (response.status === HTTP_OK) {
      yield put(
        approveExpenseStateActions.setexpenseApprovalsViewBillData(
          response?.data,
        ),
      );
    } else {
      yield put(approveExpenseStateActions.setexpenseApprovalsViewBillError());
    }
  } catch (error) {
    yield put(approveExpenseStateActions.setexpenseApprovalsViewBillError());
  }
}

/**
 * Employee Details Watcher
 */
export function* uploadMiscExpenseDataWatcher() {
  yield takeLatest(
    uploadMiscExpenseDataTypeName,
    uploadMiscExpenseDataWatcherWorker,
  );
}
/**
 * Employee Details Worker
 */
export function* uploadMiscExpenseDataWatcherWorker(action: any): any {
  try {
    const url = `${API_PATH.moduleSpecificUtilities.approveExpense.miscUploadFile}`;
    const request = yield select(
      approveExpenseStateSelector.getMiscExpenseApprovalsRequest(),
    );

    let responsedata = yield call(
      NetworkService.postUploadFile,
      url,
      action.payload,
    );

    if (responsedata?.data?.isFileUploaded) {
      ToasterService.showToaster(
        'File Uploaded Successfully',
        ToastType.SUCCESS,
      );
    }
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while upload the file!',
      ToastType.ERROR,
    );
  }
}
// Misc expense view bill watcher
export function* fetchMiscExpenseApprovalsViewBillWatcher() {
  yield takeLatest(
    fetchMiscExpenseApprovalViewBillListTypeName,
    fetchMiscExpenseApprovalsViewBillWorker,
  );
}
// Misc expense view bill worker
export function* fetchMiscExpenseApprovalsViewBillWorker(action: any): any {
  const url = `${
    API_PATH.moduleSpecificUtilities.approveExpense.dailyExpenseViewBill
      .viewBillList
  }${action.payload}${
    API_PATH.moduleSpecificUtilities.approveExpense.dailyExpenseViewBill
      .isMiscellaneousExpense
  }${true}
  `;
  try {
    yield put(
      approveExpenseStateActions.setMiscexpenseApprovalsViewBillLoading(),
    );
    const response = yield call(NetworkService.get, url, {}, {});

    if (response.status === HTTP_OK) {
      yield put(
        approveExpenseStateActions.setMiscexpenseApprovalsViewBillData(
          response?.data,
        ),
      );
    } else {
      yield put(
        approveExpenseStateActions.setMiscexpenseApprovalsViewBillError(),
      );
    }
  } catch (error) {
    yield put(
      approveExpenseStateActions.setMiscexpenseApprovalsViewBillError(),
    );
  }
}
// Daily expense dcr report watcher
export function* fetchDailyExpenseApprovalsDcrReportWatcher() {
  yield takeLatest(
    fetchDailyExpenseDcrReportDataTypeName,
    fetchDailyExpenseApprovalsDcrReportWorker,
  );
}
// Daily expense dcr report worker
export function* fetchDailyExpenseApprovalsDcrReportWorker(action: any): any {
  const url = `${API_PATH.moduleSpecificUtilities.approveExpense.dailyExpenseDcrReport}`;
  try {
    yield put(approveExpenseStateActions.setDailyExpenseDcrReportLoading());
    const response = yield call(NetworkService.post, url, action.payload);

    if (response.status === HTTP_OK) {
      yield put(
        approveExpenseStateActions.setDailyExpenseDcrReportData(response?.data),
      );
    } else {
      yield put(approveExpenseStateActions.setDailyExpenseDcrReportError());
    }
  } catch (error) {
    yield put(approveExpenseStateActions.setDailyExpenseDcrReportError());
  }
}
/**
 * Employee Details Watcher
 */
export function* fetchDailyExpenseDcrMapUrlDataWatcher() {
  yield takeLatest(
    fetchDailyExpenseDcrMapUrlDataTypeName,
    fetchDailyExpenseDcrMapUrlDataWatcherWorker,
  );
}
/**
 * Employee Details Worker
 */
export function* fetchDailyExpenseDcrMapUrlDataWatcherWorker(action: any): any {
  try {
    const url = `${API_PATH.moduleSpecificUtilities.approveExpense.dailyExpenseDcrMapurl}${action.payload}`;
    let responsedata = yield call(NetworkService.get, url, {}, {});
    yield put(
      approveExpenseStateActions.setDailyExpenseDcrMapUrl(responsedata?.data),
    );
    window.open(
      responsedata?.data,
      '',
      'width=1000,height=400,screenX=200,screenY=200',
    );
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while upload the file!',
      ToastType.ERROR,
    );
  }
}

/**
 * Employee Details Watcher
 */
export function* fetchMiscExpenseFileDeleteDataWatcher() {
  yield takeLatest(
    fetchMiscExpenseFileDeleteDataTypeName,
    fetchMiscExpenseFileDeleteDataWatcherWorker,
  );
}
/**
 * Employee Details Worker
 */
export function* fetchMiscExpenseFileDeleteDataWatcherWorker(action: any): any {
  try {
    const url = `${API_PATH.moduleSpecificUtilities.approveExpense.deleteMiscfile}`;
    let responsedata = yield call(NetworkService.post, url, action.payload, {});

    if (responsedata?.data) {
      ToasterService.showToaster(
        'File Deleted Successfully',
        ToastType.SUCCESS,
      );
      yield put(approveExpenseStateActions.setViewBillPopupVisible(false));
    }
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while delete the file!',
      ToastType.ERROR,
    );
  }
}
export function* fetchDailyExpensePopupReadOnlyDataWatcher() {
  yield takeLatest(
    fetchDailyExpensePopupReadOnlyDataTypeName,
    fetchDailyExpensePopupReadOnlyDataWatcherWorker,
  );
}
/**
 * Employee Details Worker
 */
export function* fetchDailyExpensePopupReadOnlyDataWatcherWorker(
  action: any,
): any {
  try {
    const url = `${API_PATH.moduleSpecificUtilities.approveExpense.popupReadOnlyData}${action.payload}`;
    let responsedata = yield call(NetworkService.get, url, {}, {});
    yield put(
      approveExpenseStateActions.setPopupReadOnlyData(responsedata.data),
    );
  } catch (error) {
    ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
  }
}

export function* fetchDailyExpensePopupTADADataWatcher() {
  yield takeLatest(
    fetchDailyExpensePopupTADADataTypeName,
    fetchDailyExpensePopupTADADataWatcherWorker,
  );
}
/**
 * Employee Details Worker
 */
export function* fetchDailyExpensePopupTADADataWatcherWorker(action: any): any {
  try {
    const url = `${API_PATH.moduleSpecificUtilities.approveExpense.popuptadagetdata}${action.payload}`;
    let responsedata = yield call(NetworkService.post, url, {}, {});
    yield put(approveExpenseStateActions.setPopuptadaData(responsedata.data));
  } catch (error) {
    ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
  }
}

export function* fetchDailyExpensePopupTADASaveDataWatcher() {
  yield takeLatest(
    fetchDailyExpensePopupTADASaveDataTypeName,
    fetchDailyExpensePopupTADASaveDataWatcherWorker,
  );
}
/**
 * Employee Details Worker
 */
export function* fetchDailyExpensePopupTADASaveDataWatcherWorker(
  action: any,
): any {
  try {
    const filteredData = yield select(
      approveExpenseStateSelector.getFilterData(),
    );
    const SelectedExpenseApprovalsUser = yield select(
      approveExpenseStateSelector.getSelectedExpenseApprovalsUser(),
    );
    const requestBody = {
      month: filteredData?.month,
      year: filteredData?.year,
      staffPositionId: SelectedExpenseApprovalsUser?.staffPositionId,
      userId: SelectedExpenseApprovalsUser?.staffUserId,
    };
    const url = `${API_PATH.moduleSpecificUtilities.approveExpense.popuptadasavedata}`;
    let responsedata = yield call(NetworkService.post, url, action.payload, {});
    yield put({
      type: fetchDailyExpenseApprovalsDataTypeName,
      payload: requestBody,
    });
    yield put(approveExpenseStateActions.setTaDaPopupVisible(false));
    ToasterService.showToaster(
      responsedata.data.message,
      responsedata.data.isCreated ? ToastType.SUCCESS : ToastType.ERROR,
    );
  } catch (error) {
    ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
  }
}
export function* fetchMiscSaveWatcher() {
  yield takeLatest(miscSaveCreatorTypeName, fetchMiscSaveWatcherWorker);
}
/**
 * Employee Details Worker
 */
export function* fetchMiscSaveWatcherWorker(action: any): any {
  try {
    const filteredData = yield select(
      approveExpenseStateSelector.getFilterData(),
    );
    const SelectedExpenseApprovalsUser = yield select(
      approveExpenseStateSelector.getSelectedExpenseApprovalsUser(),
    );
    yield put(approveExpenseStateActions.setMiscEditedRowColorIndex([]));
    const requestBody = {
      month: filteredData?.month,
      year: filteredData?.year,
      staffPositionId: SelectedExpenseApprovalsUser?.staffPositionId,
      userId: SelectedExpenseApprovalsUser?.staffUserId,
    };
    const url = `${API_PATH.moduleSpecificUtilities.approveExpense.saveMisc}`;
    let responsedata = yield call(NetworkService.post, url, action.payload, {});
    yield put({
      type: fetchMiscExpenseApprovalsDataTypeName,
      payload: requestBody,
    });
    ToasterService.showToaster(
      responsedata.data.message,
      responsedata.data.isCreated ? ToastType.SUCCESS : ToastType.ERROR,
    );
  } catch (error) {
    ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
  }
}

export function* deductDaAmountWatcher() {
  yield takeLatest(deductDaAmountCreatorTypeName, deductDaAmountWatcherWorker);
}
/**
 * Employee Details Worker
 */
export function* deductDaAmountWatcherWorker(action: any): any {
  try {
    const filteredData = yield select(
      approveExpenseStateSelector.getFilterData(),
    );
    const SelectedExpenseApprovalsUser = yield select(
      approveExpenseStateSelector.getSelectedExpenseApprovalsUser(),
    );
    yield put(approveExpenseStateActions.setMiscEditedRowColorIndex([]));
    const requestBody = {
      expenseMonth: filteredData?.month,
      expenseYear: filteredData?.year,
      staffPositionId: SelectedExpenseApprovalsUser?.staffPositionId,
      staffUserId: SelectedExpenseApprovalsUser?.staffUserId,
      amount: action.payload,
    };
    const daily = {
      month: filteredData?.month,
      year: filteredData?.year,
      staffPositionId: SelectedExpenseApprovalsUser?.staffPositionId,
      userId: SelectedExpenseApprovalsUser?.staffUserId,
    };
    const url = `${API_PATH.moduleSpecificUtilities.approveExpense.deductDaAmount}`;
    let responsedata = yield call(NetworkService.post, url, requestBody, {});
    yield put({type: fetchDailyExpenseApprovalsDataTypeName, payload: daily});
    ToasterService.showToaster(
      responsedata.data.message,
      responsedata.data.isSuccess ? ToastType.SUCCESS : ToastType.ERROR,
    );
  } catch (error) {
    ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
  }
}
export function* approveExpenseStatusWatcher() {
  yield takeLatest(
    approveExpenseStatusCreatorTypeName,
    approveExpenseStatusWatcherWorker,
  );
}
/**
 * Employee Details Worker
 */
export function* approveExpenseStatusWatcherWorker(action: any): any {
  try {
    const url = `${API_PATH.moduleSpecificUtilities.approveExpense.approveExpenseSataus}${action.payload.staffPositionId}/${action.payload.userId}/${action.payload.month}/${action.payload.year}`;
    let responsedata = yield call(NetworkService.get, url, {}, {});
    yield put(
      approveExpenseStateActions.setApproveExpensestatus(responsedata.data),
    );
  } catch (error) {
    ToasterService.showToaster('Something went wrong!', ToastType.ERROR);
  }
}
