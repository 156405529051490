import {API_PATH} from '@app/common/constants';
import {directoryActions} from '@app/screens/directory-configuration/redux';
import {ToasterService} from '@app/services';
import NetworkService from '@app/services/network/NetworkService';
import {ToastType} from '@app/services/toaster';
import {call, put, takeLatest} from 'redux-saga/effects';
import {socialMediaCategoryTypeName, socialMediaCategoryActions} from './slice';
import {directoryPageLists} from '@app/screens/directory-configuration/redux/slice';
/**
 * Fetch Social Media Category List watcher
 */

export function* fetchSocialMediaCategoryListWatcher() {
  yield takeLatest(
    socialMediaCategoryTypeName,
    fetchSocialMediaCategoryListWorker,
  );
}

/**
 * Fetch Social Media Category List Worker
 */

function* fetchSocialMediaCategoryListWorker(action: any): any {
  const {index, isActive, query} = action.payload;

  yield put(
    directoryActions.updateLoading(
      API_PATH.directoryConfiguration.socialmediacategoryList,
    ),
  );
  try {
    const response = yield call(
      NetworkService.get,
      API_PATH.directoryConfiguration.socialmediacategoryList,
      {pageIndex: index, pageSize: 15, isActive, name: query || ''},
      {},
    );
    yield put(directoryActions.updateLoading(null));
    if (index === 1) {
      yield put(
        directoryActions.updateTotalRecords({
          listing: directoryPageLists.socialMediaCategory,
          count: response?.data[0]?.totalRecords || 0,
          pageIndex: index,
        }),
      );
      yield put(
        socialMediaCategoryActions.fetchSocialMediaCategoryList(response?.data),
      );
    } else {
      yield put(
        directoryActions.updateTotalRecords({
          listing: directoryPageLists.socialMediaCategory,
          pageIndex: index,
        }),
      );
      yield put(
        socialMediaCategoryActions.fetchSocialMediaCategoryNewList(
          response?.data,
        ),
      );
    }
  } catch (error) {
    yield put(directoryActions.updateLoading(null));
    ToasterService.showToaster(
      'Something went wrong while fetching admin data!',
      ToastType.ERROR,
    );
  }
}
