import {createAction, createSlice} from '@reduxjs/toolkit';

export const expenseInitialState = {
  parameter: [],
  download: '',
  uploadResponse: [],
};
export const fetchExpenseParameterCreator = createAction(
  'EXPENSE_HANDLER/FETCH_PARAMETER',
);
export const fetchExpenseParameterCreatorTypeName =
  fetchExpenseParameterCreator().type;

export const fetchDownloadCreator = createAction<any>(
  'EXPENSE_HANDLER/FETCH_DOWNLOAD',
);
export const fetchDownloadCreatorTypeName = fetchDownloadCreator(null).type;

export const fileUploadCreator = createAction<any>(
  'EXPENSE_HANDLER/FILEUPLOAD',
);
export const fileUploadCreatorTypeName = fileUploadCreator(null).type;

const expenseStateHandler = createSlice({
  name: 'EXPENSE_HANDLER',
  initialState: expenseInitialState,
  reducers: {
    setParameter: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        parameter: payload,
      };
    },
    setDownload: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        download: payload,
      };
    },
    setUploadResponse: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        uploadResponse: payload,
      };
    },
  },
});
export const expenseStateActions = expenseStateHandler.actions;
export const expenseStateReducer = expenseStateHandler.reducer;
