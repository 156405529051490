import {routeStateActions} from '@app/router/redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import {Container} from '@mui/material';
import {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {
  expenseDAMasterStateSelector,
  getexpenseDAMasterStateSelector,
} from './redux/selectors';
import {AddExpenseDAMaster} from '../../components/ExpenseDAMasterPopup/create-expense-DA-master/addExpenseDAMaster';
import {filterStateActions} from '@app/components/right-sidebar/redux';
import {expenseDAMasterStateActions} from './redux';
import {ExpenseDAGrid} from '../../components/ExpenseDAMasterPopup/ExpenseDAMasterGrid/expenseDAGrid';
import {
  fetchDesignationsDropdownCreator,
  fetchHQDropdownCreator,
  fetchHQTypeDropdownCreator,
} from './redux/slice';
import {fetchDivisionDropdownCreator} from '@app/screens/access-management/pages/user-management/redux/slice';
import {expenseTAMasterStateActions} from '../expense-ta-master/redux';
import {useStyles} from '@app/screens/Styles/style';
import {Filter} from '@app/components/widgets/filters';

export const ExpenseDAMasterConetent = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const addExpenseDAMasterPopupVisible = useSelector(
    getexpenseDAMasterStateSelector('addExpenseDAMasterPopupVisible'),
  );
  const expenseDAMasterGrid = useSelector(
    expenseDAMasterStateSelector.getExpenseDAMasterGrid(),
  );
  const resetNavbarVersionDAMaster = useSelector(
    expenseDAMasterStateSelector.getResetNavbarVersion(),
    shallowEqual,
  );
  useEffect(() => {
    dispatch(expenseTAMasterStateActions.setIsExpenseTaMaster(false));
    dispatch(expenseDAMasterStateActions.setIsExpenseDaMaster(true));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.MasterDataManagement,
      ),
    );
    dispatch(
      routeStateActions.setNavbarComponentHeading(
        <div className={classes.navbarComponentHeading}>Expense DA Master</div>,
      ),
    );
    dispatch(filterStateActions.setFilterState({}));
    dispatch(
      filterStateActions.setFilterOptions({
        isActive: true,
        searchText: '',
        pageNumber: 0,
        pageLimit: 100,
        divisionIds: [],
        designationIds: [],
        hqIds: [],
      }),
    );
    dispatch(filterStateActions.setFilterAppliedDAMaster(false));
    dispatch(fetchHQDropdownCreator());
    dispatch(fetchDivisionDropdownCreator());
    dispatch(fetchHQTypeDropdownCreator());
    dispatch(fetchDesignationsDropdownCreator());
    return () => {
      dispatch(routeStateActions.setNavbarComponentName(null));
      dispatch(filterStateActions.setFilterState({}));
      dispatch(
        filterStateActions.setFilterOptions({
          isActive: true,
          searchText: '',
          pageNumber: 0,
          pageLimit: 100,
          divisionIds: [],
          designationIds: [],
          hqIds: [],
        }),
      );
      dispatch(expenseDAMasterStateActions.setIsExpenseDaMaster(false));
      dispatch(expenseDAMasterStateActions.setSearchText(''));
      dispatch(expenseDAMasterStateActions.setDesignationArr([]));
      dispatch(expenseDAMasterStateActions.setExpenseDAMasterGrid([]));
      dispatch(expenseDAMasterStateActions.setHqByDivisionDesignation([]));
      dispatch(expenseDAMasterStateActions.setPageNo(0));
      dispatch(expenseDAMasterStateActions.setRowPerPage(100));
      dispatch(filterStateActions.setFilterAppliedDAMaster(false));
      dispatch(
        expenseDAMasterStateActions.setResetNavbarVersion(
          resetNavbarVersionDAMaster + 1,
        ),
      );
    };
  }, [classes.navbarComponentHeading, dispatch]);
  return (
    <div>
      <Container maxWidth={false}>
        <Filter />

        <ExpenseDAGrid DAgridData={expenseDAMasterGrid ?? []} />

        {addExpenseDAMasterPopupVisible && <AddExpenseDAMaster />}
      </Container>
    </div>
  );
};
