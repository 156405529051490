import {API_PATH} from '@app/common/constants';
import NetworkService from '@app/services/network/NetworkService';
import {call, takeLatest, put} from '@redux-saga/core/effects';
import {
  intransistChallanActions,
  fetchIntransistChallanName,
  fetchAutoSuggestion,
  fetchFilteredData,
  postOpenCloseChallanName,
  fetchIntransistChallanCreator,
  enableDisableIntransitChallansName,
  fetchVoucherNumberData,
  fetchRecieveChallans,
} from './slice';

export function* fetchInventoryIntransistChallansWatcher() {
  yield takeLatest(fetchIntransistChallanName, fetchIntransistChallansWorker);
}
export function* fetchAutoSuggestionWatcher() {
  yield takeLatest(fetchAutoSuggestion, fetchAutoSuggestionsWorker);
}
export function* fetchInTransitFilteredDataWatcher() {
  yield takeLatest(fetchFilteredData, fetchFilteredDataWorker);
}
export function* postIntransitChallanOpenCloseWatcher() {
  yield takeLatest(
    postOpenCloseChallanName,
    postIntransitChallanOpenCloseWorker,
  );
}
export function* enableDisableIntransitChallansWatcher() {
  yield takeLatest(
    enableDisableIntransitChallansName,
    enableDisableIntransitChallansWorker,
  );
}

export function* fetchVoucherNumberDataWatcher() {
  yield takeLatest(fetchVoucherNumberData, fetchVoucherNumberDataWorker);
}

export function* fetchRecievedChallansDataWatcher() {
  yield takeLatest(fetchRecieveChallans, fetchRecievedDataWorker);
}

export function* fetchIntransistChallansWorker(action: any): any {
  try {
    yield put(intransistChallanActions.setIntransistChallanInit());
    yield put(intransistChallanActions.setIntransistChallansSuccess([]));
  } catch (e) {
    yield put(intransistChallanActions.setIntransistChallanError(e));
  }
}
export function* fetchAutoSuggestionsWorker(action: any): any {
  try {
    if (action && action.payload) {
      let chllan = false;
      if (action.payload.isOpenChallans) {
        chllan = action.payload.isOpenChallans;
      }
      const url = `${API_PATH.inventoryModule.autoSuggestionData}/${chllan}`;
      const autoSuggestionData = yield call(
        NetworkService.post,
        url,
        {...action.payload},
        null,
      );
      if (autoSuggestionData?.status === 200) {
        yield put(
          intransistChallanActions.setAutoSuggestionDataSuccess(
            autoSuggestionData?.data,
          ),
        );
      }
    }
  } catch (e) {
    yield put(intransistChallanActions.setIntransistChallanError(e));
  }
}
export function* fetchFilteredDataWorker(action: any): any {
  try {
    const {pageNumber, pageSize} = action.payload;
    yield put(intransistChallanActions.setInTransitFilteredData());
    if (
      action &&
      action.payload &&
      Object.keys(action.payload).length > 0 &&
      typeof action.payload.isOpenChallans !== 'undefined'
    ) {
      const url = `${API_PATH.inventoryModule.filteredData}/${action.payload.isOpenChallans}?pageNumber=${pageNumber}&offsetValue=${pageSize}`;
      const filteredData = yield call(
        NetworkService.post,
        url,
        {...action.payload},
        null,
      );
      if (filteredData?.status === 200) {
        yield put(
          intransistChallanActions.setTotalIntransitChallans(
            filteredData.data.totalRecords,
          ),
        );
        yield put(
          intransistChallanActions.setFilteredDataSuccess(
            filteredData.data.adminIntransitChallanMasterDtoLst,
          ),
        );
      }
    }
  } catch (e) {
    yield put(intransistChallanActions.setIntransistChallanError(e));
  }
}
export function* postIntransitChallanOpenCloseWorker(action: any): any {
  try {
    if (action && action.payload) {
      const {closeOpen, challToBeOpenOrClose} = action.payload;
      const url = `${API_PATH.inventoryModule.postIntransitChallanOpenClose}/${closeOpen}`;
      const response = yield call(
        NetworkService.post,
        url,
        challToBeOpenOrClose,
        null,
      );
      if (response?.data) {
        yield put(fetchIntransistChallanCreator({isOpenClose: !closeOpen}));
      } else {
        yield put(intransistChallanActions.setIntransistChallanError(''));
      }
    }
  } catch (e) {
    yield put(intransistChallanActions.setIntransistChallanError(e));
  }
}
export function* enableDisableIntransitChallansWorker(action: any): any {
  try {
    yield put(intransistChallanActions.setIntransistChallanInit());
    if (action && action.payload) {
      const {isToggled, challToBeToggled, isOpenChallans} = action.payload;
      const url = `${API_PATH.inventoryModule.enableDisableIntransitChallans}/${isToggled}`;
      const response = yield call(
        NetworkService.post,
        url,
        challToBeToggled,
        null,
      );
      if (response?.data) {
        yield put(fetchIntransistChallanCreator({isOpenClose: isOpenChallans}));
      } else {
        yield put(intransistChallanActions.setIntransistChallanError(''));
      }
    }
  } catch (e) {
    yield put(intransistChallanActions.setIntransistChallanError(e));
  }
}

export function* fetchVoucherNumberDataWorker(action: any): any {
  try {
    yield put(intransistChallanActions.setIntransistChallanInit());
    if (action && action.payload) {
      const {
        payload: {voucherNumber},
      } = action;
      const url = `${API_PATH.inventoryModule.voucherNumberData}/${voucherNumber}`;
      const response = yield call(NetworkService.get, url, {}, {});
      if (response?.data) {
        yield put(
          intransistChallanActions.setVoucherNumberData({voucherNumber}),
        );
      } else {
        yield put(intransistChallanActions.setIntransistChallanError(''));
      }
    }
  } catch (e) {
    yield put(intransistChallanActions.setIntransistChallanError(e));
  }
}

export function* fetchRecievedDataWorker(action: any): any {
  try {
    if (action && action.payload) {
      const url = `${API_PATH.inventoryModule.receivedChallans}`;
      const recievedData = yield call(
        NetworkService.post,
        url,
        action.payload,
        null,
      );
      if (recievedData?.status === 200) {
        yield put(
          intransistChallanActions.setRecievedChallansData(recievedData?.data),
        );
      }
    }
  } catch (e) {
    yield put(intransistChallanActions.setIntransistChallanError(e));
  }
}
