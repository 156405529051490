import {API_PATH, TOAST_MESSAGES} from '@app/common/constants';
import {routeStateActions} from '@app/router/redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import NetworkService from '@app/services/network/NetworkService';
import {
  Box,
  Divider,
  TableCell,
  TableRow,
  IconButton,
  CircularProgress,
  InputLabel,
  TextField,
  Grid,
  Button,
  Checkbox,
} from '@mui/material';
import {useEffect, useMemo, useState, useCallback} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {
  actions,
  directoryActions,
  directorySelector,
  directoryNavActionHandlerCreator,
} from '../../redux';
import EditIcon from '@app/assets/svg/edit-icon';
import Popup from '../../components/Popup';
import {
  departmentManagementSelector,
  departmentManagementListCreator,
} from './redux';
import {DirectoryTabProps} from './types';
import TableList from '../../components/TableList';
import {
  directoryPageLists,
  directoryUpdateCurrentPageCreator,
} from '../../redux/slice';
import {ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
import {
  searchObj,
  tabTitleObj,
} from '../../components/CommonFunctions/CommonFun';
const columns = ['Department Name', '', ''];

const initialAddTabData = {
  id: 0,
  name: '',
  isActive: 1,
};

const DepartmentManagement = () => {
  const [updatingError, setUpdatingError] = useState<null | string>(null);
  const [isUpdatingTab, setIsUpdatingTab] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [updatedInitialAddTabData, setUpdatedInitialAddTabData] =
    useState(initialAddTabData);

  const [addDepartmentManagementData, setDepartmentManagementData] =
    useState<any>(updatedInitialAddTabData);

  const hoDepartmentList: any = useSelector(
    departmentManagementSelector.getdepartmentlist(),
    shallowEqual,
  );
  const isActive = useSelector(directorySelector.getStatus, shallowEqual);
  const search = useSelector(directorySelector.getNavSearch(), shallowEqual);
  const dispatch = useDispatch();

  const modal = useSelector(
    directorySelector.getDirectoryModal(),
    shallowEqual,
  );
  const showAddTabModal = useMemo(
    () => modal?.show && modal?.type === actions.addTab,
    [modal],
  );
  const loading: any = useSelector(
    directorySelector.getLoading(),
    shallowEqual,
  );
  const isTabsFetching = useMemo(
    () =>
      loading === API_PATH.directoryConfiguration.departmentList &&
      (!hoDepartmentList || hoDepartmentList.length === 0),
    [loading, hoDepartmentList],
  );
  const isUpdatingData = useMemo(
    () => loading === API_PATH.directoryConfiguration.departmentList,
    [loading],
  );
  const isEmpty = useMemo(
    () =>
      !isTabsFetching && (!hoDepartmentList || hoDepartmentList.length === 0),
    [isTabsFetching, hoDepartmentList],
  );

  const cancelAddTabModal = useCallback(() => {
    setIsEditable(false);
    dispatch(directoryNavActionHandlerCreator({show: false, type: null}));
    setUpdatedInitialAddTabData(initialAddTabData);
    setDepartmentManagementData(updatedInitialAddTabData);
    setUpdatingError(null);
  }, []);

  const onAddOrUpdateTabHandler = async (e: any) => {
    e.preventDefault();
    if (addDepartmentManagementData.name?.toString().trim()) {
      setIsUpdatingTab(true);
      const updatedInitialData = {...addDepartmentManagementData};
      try {
        const response = await NetworkService.post(
          API_PATH.directoryConfiguration.departmentSave,
          updatedInitialData,
          {},
        );
        setIsUpdatingTab(false);
        if (response.status !== 200) {
          ToasterService.showToaster(
            isEditable
              ? TOAST_MESSAGES.UNSUCCESSFULL_UPDATE
              : TOAST_MESSAGES.UNSUCCESSFULL_ADD,
            ToastType.ERROR,
          );
          setUpdatingError(response.data.description);
        } else {
          ToasterService.showToaster(
            isEditable
              ? TOAST_MESSAGES.SUCCESSFULLY_UPDATED
              : TOAST_MESSAGES.SUCCESSFULLY_ADD,
            ToastType.SUCCESS,
          );
          dispatch(
            departmentManagementListCreator({
              index: 1,
              isActive: isActive === 1,
              query: search?.query,
            }),
          );
          cancelAddTabModal();
        }
      } catch (error) {
        setIsUpdatingTab(false);
      }
    } else {
      setUpdatingError('Please fill all required fields!!');
    }
  };

  const onFormUpdateHandler = (data: any) => {
    if (updatingError) {
      setUpdatingError(null);
    }
    setDepartmentManagementData({
      ...addDepartmentManagementData,
      ...data,
    });
  };

  const onLoadMoreHandler = useCallback((index: number) => {
    dispatch(
      departmentManagementListCreator({
        index,
        isActive: isActive === 1,
        query: search?.query,
      }),
    );
  }, []);

  useEffect(() => {
    let commonSearch: any = searchObj(isActive, search);
    dispatch(departmentManagementListCreator(commonSearch));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, search]);

  useEffect(() => {
    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.directoryConfiguration,
      ),
    );
    let tabTitleData = tabTitleObj('Department Management', actions);
    dispatch(directoryActions.updateNavData(tabTitleData));
    dispatch(
      directoryUpdateCurrentPageCreator(
        directoryPageLists.departmentManagement,
      ),
    );
    return () => {
      dispatch(routeStateActions.setNavbarComponentName(null));
    };
  }, []);

  useEffect(() => {
    setDepartmentManagementData(updatedInitialAddTabData);
  }, [updatedInitialAddTabData]);

  const onChangeIsAddText = useCallback(
    (event: any) => onFormUpdateHandler({name: event.target.value}),
    [onFormUpdateHandler],
  );

  const onChangeAddDepartment = useCallback(
    (event: any) =>
      onFormUpdateHandler({isActive: event.target.checked ? 1 : 0}),
    [onFormUpdateHandler],
  );

  const getDepartmentManagementData = useCallback(() => {
    setDepartmentManagementData(updatedInitialAddTabData);
  }, [updatedInitialAddTabData]);

  const tabEditHandler = useCallback((event: any) => {
    const tab = JSON.parse(event.currentTarget.dataset.current);
    setIsEditable(true);
    setUpdatingError(null);
    setUpdatedInitialAddTabData({
      id: tab.id,
      name: tab.name,
      isActive: tab.isActive,
    });
    dispatch(
      directoryNavActionHandlerCreator({show: true, type: actions.addTab}),
    );
  }, []);

  if (isTabsFetching) {
    return (
      <Box justifyContent={'center'} display="flex" sx={{mt: 2}}>
        <CircularProgress sx={{color: 'primary.main'}} disableShrink />
      </Box>
    );
  }

  return (
    <>
      <TableList
        columns={columns}
        isEmpty={isEmpty}
        onLoadMore={onLoadMoreHandler}
        isPaging={true}
        isLoading={isUpdatingData}
      >
        {hoDepartmentList && hoDepartmentList.length > 0
          ? hoDepartmentList?.map((tab: DirectoryTabProps, index: number) => (
              <>
                <TableRow key={tab.id}>
                  <TableCell>{tab.name ? tab.name : '-'}</TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right">
                    <IconButton
                      sx={{p: 0}}
                      data-current={JSON.stringify(tab)}
                      onClick={tabEditHandler}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <Divider
                  component={TableRow}
                  variant="fullWidth"
                  sx={{height: 5, border: 0}}
                />
              </>
            ))
          : null}
      </TableList>
      <Popup
        open={!!showAddTabModal}
        cancelPopup={cancelAddTabModal}
        isLoading={isUpdatingTab}
        onClose={cancelAddTabModal}
        error={updatingError}
        title={isEditable ? 'Edit Department' : 'Add Department'}
      >
        <form
          onSubmit={onAddOrUpdateTabHandler}
          style={{maxHeight: 500, overflow: 'auto'}}
        >
          <Box sx={{mb: 3}}>
            <InputLabel variant="standard" required>
              Department Name
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Enter Text"
              required={true}
              value={addDepartmentManagementData.name}
              onChange={onChangeIsAddText}
              inputProps={{
                maxlength: 30,
                minlength: 2,
              }}
            />
          </Box>
          <Box sx={{my: 3}}>
            <InputLabel variant="standard">Is active</InputLabel>
          </Box>

          <Box sx={{mb: 3}}>
            <Checkbox
              checked={addDepartmentManagementData.isActive === 1}
              onChange={onChangeAddDepartment}
            />
          </Box>
          <Grid
            container
            justifyContent={'center'}
            sx={{mb: 2}}
            alignItems={'center'}
            position={'-webkit-sticky'}
          >
            <Grid item mr={2}>
              <Button variant="outlined" onClick={getDepartmentManagementData}>
                Reset
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" type="submit">
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Popup>
    </>
  );
};

export default DepartmentManagement;
