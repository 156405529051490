import {createSelector} from '@reduxjs/toolkit';

export const filterSelector = {
  getFilterState: () => {
    return createSelector(
      [(state: any) => state?.filterState?.filters],
      filters => filters,
    );
  },

  getDefaultFilterResponse: () => {
    return createSelector(
      [(state: any) => state?.filterState?.setDefaultFilterResponse],
      data => data,
    );
  },
  getApplyDisableStatus: () => {
    return createSelector(
      [(state: any) => state?.filterState?.applyDisableStatus],
      data => data,
    );
  },
  getFilterAppliedDAMaster: () => {
    return createSelector(
      [(state: any) => state.filterState.filterAppliedDAMaster],
      applied => applied,
    );
  },
  getFilterAppliedState: () => {
    return createSelector(
      [(state: any) => state.filterState.filterApplied],
      applied => applied,
    );
  },
  getFilterOptions: () => {
    return createSelector(
      [(state: any) => state.filterState.filterOptions],
      filterOptions => filterOptions,
    );
  },
  getFilterBtn: () => {
    return createSelector(
      [(state: any) => state.filterState.filterBtn],
      filterBtn => filterBtn,
    );
  },
};
