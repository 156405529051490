import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import {Item, useStyles} from '@app/screens/Styles/style';
import {
  fetchBillDownloadCreator,
  fetchCategoryCreator,
  fetchDesignationByDivisionCreator,
  fetchDivisionCreator,
  fetchEmployeebydivisionCreator,
  fetchEmployeebydivisionforDropdownCreator,
  fetchHqByDivisionsandDesignationsCreator,
  fetchPoolByDivisionsandDesignationsCreator,
  fetchStatusCreator,
  fetchViewHistoryCreator,
  reviewSaveCreator,
} from './redux/slice';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useCallback, useEffect, useState} from 'react';

import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {ViewHistoryGrid} from './view-history';
import any from 'react/jsx-runtime';
import {appSelector} from '@app/store/selectors';
import {billLibraryStateActions} from './redux';
import {billLibraryStateSelector} from './redux/selectors';
import {muiltipleSelect} from '@app/components/widgets/placeholder/multiselect-placeholder';
export const BillLibraryContent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [timePeriod, setTimePeriod] = useState('date');
  const [monthYear, setMonthYear] = useState<Date | null>(null);
  const [toDate, setTodate] = useState<Date | null>(null);
  const [fromDate, setFromdate] = useState<Date | null>(null);
  const [category, setCategory] = useState<any>([]);
  const [status, setStatus] = useState<any>([]);
  const [division, setDivision] = useState<any[]>([]);
  const [reviewButton, setReviewButton] = useState(true);
  const [designation, setDesgination] = useState<any[]>([]);
  const [employee, setEmployee] = useState<any[]>([]);
  const [hq, setHQ] = useState<any[]>([]);
  const [pool, setPool] = useState<any[]>([]);
  const [checkedBox, setCheckedBox] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<any[]>([]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const divisionArray = useSelector(billLibraryStateSelector.getDivision());
  const userInfo = useSelector(appSelector.getUserInfo(), shallowEqual);
  const poolArray = useSelector(billLibraryStateSelector.getPool());

  const hqArray = useSelector(billLibraryStateSelector.getHQ());
  const categoryArr = useSelector(billLibraryStateSelector.getCategory());
  const statusArr = useSelector(billLibraryStateSelector.getStatus());

  const designationArray = useSelector(
    billLibraryStateSelector.getDesignation(),
  );
  const empolyeeArray = useSelector(billLibraryStateSelector.getEmployee());
  const empolyeeData = useSelector(
    billLibraryStateSelector.getEmployeeDropDown(),
  );
  const contentGrid = useSelector(
    billLibraryStateSelector.getContentGridView(),
  );
  const viewHistoryArray = useSelector(
    billLibraryStateSelector.getViewHistory(),
  );
  const getEmployee = useCallback(() => {
    let ids = {
      divisionIds: muiltipleSelect(setvalues(division, 'value')),
      designationIds: muiltipleSelect(setvalues(designation, 'designationId')),
      hqIds: muiltipleSelect(setvalues(hq, 'headQuaterId')),
      poolIds: muiltipleSelect(setvalues(pool, 'poolId')),
    };
    dispatch(fetchEmployeebydivisionCreator(ids));
    dispatch(reviewSaveCreator(requestsForsave()));
    dispatch(billLibraryStateActions.setContentGridView(true));
    dispatch(billLibraryStateActions.setDownloadRequest(requests()));
  }, [
    division,
    designation,
    hq,
    pool,
    status,
    category,
    toDate,
    fromDate,
    monthYear,
    checkedBox,
  ]);
  const requests = () => {
    return {
      employeeList: selectedEmployee,
      fromDate: fromDate ?? null,
      toDate: toDate ?? null,
      year: monthYear ?? 0,
      month: monthYear ?? 0,
      expenseCategory: setvalues(category, 'displayName'),
      hq: muiltipleSelect(setvalues(hq, 'headQuaterId')),
      pool: muiltipleSelect(setvalues(pool, 'poolId')),
      division: muiltipleSelect(setvalues(division, 'value')),
      designation: muiltipleSelect(setvalues(designation, 'designationId')),
      status: muiltipleSelect(setvalues(status, 'displayName')),
      adminStaffpositionId: userInfo?.staffPositionId,
      policyViolated: true,
    };
  };

  const requestsForsave = () => {
    return {
      employeeList: employee?.length > 0 ? selectedEmployee : [],
      fromDate: fromDate ?? null,
      toDate: toDate ?? null,
      year: monthYear ?? 0,
      month: monthYear ?? 0,
      expenseCategory: setvalues(category, 'displayName'),
      division: muiltipleSelect(setvalues(division, 'value')),
      designation: muiltipleSelect(setvalues(designation, 'designationId')),
      hq: muiltipleSelect(setvalues(hq, 'headQuaterId')),
      pool: muiltipleSelect(setvalues(pool, 'poolId')),
      status: muiltipleSelect(setvalues(status, 'displayName')),
      adminStaffpositionId: userInfo?.staffPositionId,
      requestDateTime: new Date(),
      returnAsBase64String: true,
      policyViolated: true,
    };
  };
  const downloadBills = () => {
    dispatch(fetchBillDownloadCreator(requests()));
  };
  const viewHistory = useCallback(() => {
    dispatch(fetchViewHistoryCreator(userInfo?.staffPositionId));
    dispatch(billLibraryStateActions.setContentGridView(false));
  }, []);
  const intId = (arrayValue: string) => {
    return arrayValue?.split('|').map((value1: any) => {
      return parseInt(value1);
    });
  };
  const d = new Date();
  const dateChange = useCallback((dateval: any) => {
    return dateval ? new Date(dateval) : null;
  }, []);
  const setValue = useCallback((val: any) => {
    return val !== null ? intId(val) : [];
  }, []);
  const dateYear = useCallback(() => {
    return viewHistoryArray?.year
      ? new Date(d.setFullYear(viewHistoryArray?.year))
      : null;
  }, []);
  const dateMonth = useCallback(() => {
    return viewHistoryArray?.month
      ? new Date(d.setMonth(viewHistoryArray?.month - 1))
      : null;
  }, []);
  const setEmp = useCallback(emp => {
    return emp !== null && emp !== '' ? emp?.split('|') : [];
  }, []);

  const getvalues = (value: any, arr: any, id: any) =>
    arr?.filter((val: any) =>
      value?.find((item2: any) => val?.['' + id + ''] === item2),
    );
  const setvalues = (value: any, id: any) =>
    value?.map((val: any) => val?.['' + id + '']);
  const handleClick = useCallback(
    (event: {target: {checked: boolean | ((prevState: boolean) => boolean)}}) =>
      setCheckedBox(event?.target?.checked),
    [checkedBox],
  );

  const handleChangeTimePeriod = useCallback(
    (event: any) => {
      if (event.target.value == 'date') {
        setMonthYear(null);
      } else if (event.target.value == 'month') {
        setFromdate(null);
        setTodate(null);
      }
      setTimePeriod(event.target.value);
    },
    [monthYear, timePeriod, fromDate, toDate],
  );
  let mandatoryField = (
    <Typography className={classes.mandatoryField}>*</Typography>
  );
  const handleDivision = useCallback(
    function (_event: any, value: any) {
      setDivision(value);
    },
    [division],
  );
  const handleCategory = useCallback(
    function (_event: any, value: any) {
      setCategory(value);
    },
    [category],
  );
  const handleStatus = useCallback(
    function (_event: any, value: any) {
      setStatus(value);
    },
    [status],
  );
  const handleDesignation = useCallback(
    function (_event: any, value: any) {
      setDesgination(value);
    },
    [designation],
  );
  const handleEmployee = useCallback(
    function (_event: any, value: any) {
      setEmployee(value);
      setHQ([]);
      setPool([]);
    },
    [employee],
  );

  const handleHQ = useCallback(
    function (_event: any, value: any) {
      setHQ(value);
    },
    [hq],
  );
  const handlePool = useCallback(
    function (_event: any, value: any) {
      setPool(value);
    },
    [pool],
  );
  const preventDefaultHandleBill = useCallback(e => e.preventDefault(), []);
  const renderInputBill = useCallback(function (params: any) {
    return (
      <TextField
        {...params}
        helperText={null}
        onKeyDown={preventDefaultHandleBill}
        size="small"
      />
    );
  }, []);

  const openDivision = useCallback(() => setOpen(true), []);
  const closeDivision = useCallback(() => setOpen(false), []);
  const triggers = () => {
    getEmployee();
    viewHistory();
    requests();
    handleClick({target: {checked: true}});
    handleChangeTimePeriod({target: {value: 'month'}});
  };

  const renderInputdivision = useCallback(function (params1: any) {
    return (
      <TextField {...params1} size="small" placeholder="Search Division" />
    );
  }, []);

  const renderInputdesignation = useCallback(function (params2: any) {
    return (
      <TextField {...params2} size="small" placeholder="Search Designation" />
    );
  }, []);

  const renderInputhq = useCallback(function (params2: any) {
    return <TextField {...params2} size="small" placeholder="Search HQ" />;
  }, []);

  const renderInputemployee = useCallback(function (params2: any) {
    return (
      <TextField {...params2} size="small" placeholder="Search Employee" />
    );
  }, []);
  const renderInputpool = useCallback(function (params2: any) {
    return <TextField {...params2} size="small" placeholder="Search Pool" />;
  }, []);
  const renderInputcategory = useCallback(function (params2: any) {
    return (
      <TextField {...params2} size="small" placeholder="Search Category" />
    );
  }, []);
  const renderInputstatus = useCallback(function (params2: any) {
    return <TextField {...params2} size="small" placeholder="Search Status" />;
  }, []);
  console.log('lenghttt');
  useEffect(() => {
    if (
      division?.length !== 0 &&
      status?.length !== 0 &&
      category?.length !== 0 &&
      (designation?.length !== 0 || employee?.length !== 0) &&
      ((employee?.length !== 0 && hq?.length == 0 && pool?.length == 0) ||
        (employee?.length == 0 && hq?.length !== 0) ||
        pool?.length !== 0) &&
      checkedBox !== false &&
      ((timePeriod == 'date' && fromDate && toDate) ||
        (timePeriod == 'month' && monthYear))
    )
      setReviewButton(false);
    else {
      setReviewButton(true);
    }
  }, [
    division,
    status,
    category,
    checkedBox,
    designation,
    employee,
    hq,
    pool,
    fromDate,
    toDate,
    monthYear,
    timePeriod,
  ]);
  useEffect(() => {
    if (
      fromDate == null &&
      viewHistoryArray?.fromDate !== null &&
      viewHistoryArray?.fromDate !== undefined
    ) {
      setFromdate(new Date(viewHistoryArray?.fromDate));
    }
    if (
      toDate == null &&
      viewHistoryArray?.toDate !== null &&
      viewHistoryArray?.toDate !== undefined
    ) {
      setTodate(new Date(viewHistoryArray?.toDate));
    }
    if (
      monthYear == null &&
      viewHistoryArray?.monthYear &&
      viewHistoryArray?.monthYear !== undefined
    ) {
      setMonthYear(viewHistoryArray?.year);
      setMonthYear(viewHistoryArray?.month);
    }
  }, [fromDate, toDate, monthYear]);

  useEffect(
    () =>
      setDesgination(
        getvalues(
          setValue(viewHistoryArray?.designation),
          designationArray,
          'designationId',
        ),
      ),
    [designationArray],
  );
  useEffect(() => {
    setHQ(getvalues(setValue(viewHistoryArray?.hq), hqArray, 'headQuaterId'));
  }, [hqArray]);

  useEffect(
    () =>
      setPool(getvalues(setValue(viewHistoryArray?.pool), poolArray, 'poolId')),
    [poolArray],
  );
  useEffect(() => {
    dispatch(fetchDivisionCreator());
    dispatch(fetchCategoryCreator());
    dispatch(fetchStatusCreator());
    return () => {
      dispatch(billLibraryStateActions.setEmployee([]));
      dispatch(billLibraryStateActions.setHQ([]));
      dispatch(billLibraryStateActions.setPool([]));
      dispatch(billLibraryStateActions.setDesignation([]));
      dispatch(billLibraryStateActions.setContentGridView(false));
      setFromdate(null);
      setTodate(null);
      setMonthYear(null);
      setMonthYear(null);
      setDivision([]);
      setDesgination([]);
      setPool([]);
      setStatus([]);
      setCategory([]);
      setEmployee([]);
      setCheckedBox(false);
    };
  }, [dispatch]);
  useEffect(() => {
    if (
      (division?.length > 0 && division !== undefined) ||
      (designation?.length > 0 && designation !== undefined)
    ) {
      let ids = {
        divisionIds: setvalues(division, 'value'),
        designationIds: setvalues(designation, 'designationId'),
      };
      let ids1 = {
        divisionIds: muiltipleSelect(setvalues(division, 'value')),
        designationIds: muiltipleSelect(
          setvalues(designation, 'designationId'),
        ),
      };
      dispatch(fetchHqByDivisionsandDesignationsCreator(ids));
      dispatch(fetchPoolByDivisionsandDesignationsCreator(ids1));
    }
  }, [designation]);
  useEffect(() => {
    if (division?.length > 0 && division !== undefined) {
      let ids = {
        divisionIds: muiltipleSelect(setvalues(division, 'value')),
      };
      let ids1 = {
        divisionIds: setvalues(division, 'value'),
        designationIds: [],
      };

      dispatch(fetchDesignationByDivisionCreator(ids.divisionIds));
      dispatch(fetchHqByDivisionsandDesignationsCreator(ids1));
      dispatch(fetchEmployeebydivisionforDropdownCreator(ids.divisionIds));
      dispatch(fetchPoolByDivisionsandDesignationsCreator(ids));
    }
  }, [division]);
  useEffect(() => {
    if (Object?.keys(viewHistoryArray ?? {})?.length > 0) {
      setFromdate(dateChange(viewHistoryArray?.fromDate));
      setTodate(dateChange(viewHistoryArray?.toDate));
      setMonthYear(dateYear());
      setMonthYear(dateMonth());
      setDivision(
        getvalues(setValue(viewHistoryArray?.division), divisionArray, 'value'),
      );
      setDesgination(
        getvalues(
          setValue(viewHistoryArray?.designation),
          designationArray,
          'designationId',
        ),
      );
      setHQ(getvalues(setValue(viewHistoryArray?.hq), hqArray, 'headQuaterId'));
      setPool(getvalues(setValue(viewHistoryArray?.pool), poolArray, 'poolId'));
      setEmployee(
        getvalues(
          setEmp(viewHistoryArray?.employee),
          empolyeeArray,
          'employeeId',
        ),
      );
      setStatus(
        getvalues(
          viewHistoryArray?.status?.split('|'),
          statusArr,
          'displayName',
        ),
      );
      setCategory(
        getvalues(
          viewHistoryArray?.category?.split('|'),
          categoryArr,
          'displayName',
        ),
      );
      setCheckedBox(viewHistoryArray?.isPolicyViolated);
      let time = viewHistoryArray?.year ? 'month' : 'date';
      setTimePeriod(time);
    }
  }, [viewHistoryArray]);
  useEffect(() => {
    setSelectedEmployee([]);
    if (employee?.length > 0) {
      setSelectedEmployee(employee);
    } else {
      setSelectedEmployee(empolyeeArray);
    }
  }, [empolyeeArray, employee]);
  return (
    <div className={classes.toolbar}>
      <button
        data-testid="triggeringButton"
        className={'hiddenButton'}
        onClick={triggers}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.gridStyle}>
          <Item className={classes.marginleft2_5}>
            <Typography>
              <label className={classes.labelSizing}>TimeLine</label>
            </Typography>
          </Item>
          <Item className={classes.marginleft3}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={timePeriod}
                onChange={handleChangeTimePeriod}
              >
                <FormControlLabel
                  className={classes.formlabel}
                  data-testid="date-radio-button"
                  value="date"
                  control={<Radio />}
                  label="Date"
                />
                <FormControlLabel
                  data-testid="month-radio-button"
                  className={classes.formlabel}
                  value="month"
                  control={<Radio />}
                  label="Month"
                />
              </RadioGroup>
            </FormControl>
          </Item>
        </Grid>
        <Grid item xs={6} className={classes.paddingleft40}>
          {timePeriod === 'date' && (
            <>
              <Item>
                <Typography>
                  <label className={classes.labelSizing}>From</label>
                </Typography>
              </Item>
              <Item className={classes.marTop}>
                <FormControl className={classes.sizeofdropdown}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      mask="__/__"
                      inputFormat="DD/MM"
                      maxDate={new Date()}
                      PopperProps={any}
                      views={['day', 'month']}
                      value={fromDate}
                      onChange={setFromdate}
                      renderInput={renderInputBill}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Item>
            </>
          )}
        </Grid>
        <Grid item xs={6} className={classes.paddingleft30}>
          {timePeriod === 'date' && (
            <>
              <Item>
                <Typography>
                  <label className={classes.labelSizing}>To</label>
                </Typography>
              </Item>
              <Item className={classes.marTop}>
                <FormControl className={classes.sizeofdropdown}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      mask="__/__"
                      disabled={fromDate === null}
                      inputFormat="DD/MM"
                      views={['day', 'month']}
                      PopperProps={any}
                      maxDate={new Date()}
                      minDate={new Date(fromDate ?? '')}
                      value={toDate}
                      onChange={setTodate}
                      className={classes.datepick}
                      renderInput={renderInputBill}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Item>
            </>
          )}
        </Grid>
        <Grid item xs={6} className={classes.lefttopbottom}>
          {timePeriod === 'month' && (
            <>
              <Item>
                <Typography>
                  <label className={classes.labelSizing}>Month</label>
                </Typography>
              </Item>
              <Item className={classes.marTop}>
                <FormControl className={classes.sizeofdropdown}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      inputFormat="MM/YYYY"
                      views={['month', 'year']}
                      value={monthYear}
                      onChange={setMonthYear}
                      PopperProps={any}
                      disableFuture={true}
                      renderInput={renderInputBill}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Item>
            </>
          )}
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={2} className={classes.Labelforbill}>
          <Item>
            <Typography>
              <label className={classes.labelSizing}>
                Division{mandatoryField}
              </label>
            </Typography>
          </Item>
          <Grid item xs={9}>
            <Item className={classes.marTop}>
              <FormControl className={classes.minmax}>
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo1"
                  options={divisionArray ?? []}
                  limitTags={1}
                  disableCloseOnSelect
                  size="small"
                  open={open}
                  value={division}
                  onClose={closeDivision}
                  onOpen={openDivision}
                  getOptionLabel={(option: any) => option?.label}
                  onChange={handleDivision}
                  renderOption={(props, option, {selected}) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                  renderInput={renderInputdivision}
                />
              </FormControl>
            </Item>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.paddingleft40}>
          <Grid item xs={6} md={6}>
            <Item>
              <Typography>
                <label className={classes.labelSizing}>
                  Designation{mandatoryField}
                </label>
              </Typography>
            </Item>
            <Grid item xs={9}>
              <Item className={classes.marTop}>
                <FormControl className={classes.minmax}>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo2"
                    options={designationArray ?? []}
                    limitTags={1}
                    disabled={employee?.length > 0}
                    disableCloseOnSelect
                    size="small"
                    value={designation}
                    getOptionLabel={(option: any) => option?.designationName}
                    onChange={handleDesignation}
                    renderOption={(props, option, {selected}) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{marginRight: 8}}
                          checked={selected}
                        />
                        {option.designationName}
                      </li>
                    )}
                    renderInput={renderInputdesignation}
                  />
                </FormControl>
                <Typography className={classes.or2Style}>OR</Typography>
              </Item>
            </Grid>
          </Grid>
          <Grid item xs={6} md={6}>
            <Item>
              <Typography>
                <label className={classes.labelSizing}>
                  Employee{mandatoryField}
                </label>
              </Typography>
            </Item>
            <Grid item xs={9}>
              <Item>
                <FormControl className={classes.minmax}>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo2"
                    options={empolyeeData ?? []}
                    limitTags={1}
                    disabled={designation?.length > 0}
                    disableCloseOnSelect
                    size="small"
                    value={employee}
                    getOptionLabel={(option: any) =>
                      `${option?.employeeName}-${option?.employeeId}`
                    }
                    onChange={handleEmployee}
                    renderOption={(props, option, {selected}) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{marginRight: 8}}
                          checked={selected}
                        />
                        {option.employeeName}
                      </li>
                    )}
                    renderInput={renderInputemployee}
                  />
                </FormControl>
              </Item>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.paddingleft40}>
          <Grid item xs={6} md={6}>
            <Item>
              <Typography>
                <label className={classes.labelSizing}>
                  HQ{mandatoryField}
                </label>
              </Typography>
            </Item>
            <Grid item xs={9}>
              <Item>
                <FormControl className={classes.minmaxtop}>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo3"
                    options={hqArray ?? []}
                    limitTags={1}
                    disabled={pool?.length > 0 || employee?.length > 0}
                    disableCloseOnSelect
                    size="small"
                    value={hq}
                    getOptionLabel={(option: any) => option?.headQuaterName}
                    onChange={handleHQ}
                    renderOption={(props, option, {selected}) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{marginRight: 8}}
                          checked={selected}
                        />
                        {option.headQuaterName}
                      </li>
                    )}
                    renderInput={renderInputhq}
                  />
                </FormControl>
                <Typography className={classes.orStyle}> OR</Typography>
              </Item>
            </Grid>
          </Grid>
          <Grid item xs={6} md={6}>
            <Item>
              <Typography>
                <label className={classes.labelSizing}>
                  Pool{mandatoryField}
                </label>
              </Typography>
            </Item>
            <Grid item xs={9}>
              <Item>
                <FormControl className={classes.minmaxtop}>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo3"
                    options={poolArray ?? []}
                    limitTags={1}
                    disabled={hq?.length > 0 || employee?.length > 0}
                    disableCloseOnSelect
                    size="small"
                    value={pool}
                    getOptionLabel={(option: any) => option?.poolName}
                    onChange={handlePool}
                    renderOption={(props, option, {selected}) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{marginRight: 8}}
                          checked={selected}
                        />
                        {option.poolName}
                      </li>
                    )}
                    renderInput={renderInputpool}
                  />
                </FormControl>
              </Item>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.category}>
          <Grid item xs={6} md={6}>
            <Item>
              <Typography>
                <label className={classes.labelSizing}>
                  Category{mandatoryField}
                </label>
              </Typography>
            </Item>
            <Grid item xs={9}>
              <Item>
                <FormControl className={classes.minmaxtop}>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo3"
                    options={categoryArr ?? []}
                    limitTags={1}
                    disableCloseOnSelect
                    size="small"
                    value={category}
                    getOptionLabel={(option: any) => option?.displayName}
                    onChange={handleCategory}
                    renderOption={(props, option, {selected}) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{marginRight: 8}}
                          checked={selected}
                        />
                        {option?.displayName}
                      </li>
                    )}
                    renderInput={renderInputcategory}
                  />
                </FormControl>
              </Item>
            </Grid>
          </Grid>
          <Grid item xs={6} md={6}>
            <Item>
              <Typography>
                <label className={classes.labelSizing}>
                  Status {mandatoryField}
                </label>
              </Typography>
            </Item>
            <Grid item xs={9}>
              <Item className={classes.marTop}>
                <FormControl className={classes.minmaxtop}>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo3"
                    options={statusArr ?? []}
                    limitTags={1}
                    disableCloseOnSelect
                    size="small"
                    value={status}
                    getOptionLabel={(option: any) => option?.displayName}
                    onChange={handleStatus}
                    renderOption={(props, option, {selected}) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{marginRight: 8}}
                          checked={selected}
                        />
                        {option?.displayName}
                      </li>
                    )}
                    renderInput={renderInputstatus}
                  />
                </FormControl>
              </Item>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2} className={classes.violation}>
          <Item>
            <Typography>
              <label className={classes.labelSizing}>
                Policy violation{mandatoryField}
              </label>
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={9}>
          <Item>
            <Checkbox
              data-testid="handleClick-checkbox"
              checked={checkedBox}
              onChange={handleClick}
              className={classes.checkbox}
            />
          </Item>
        </Grid>
        <Grid item xs={10} className={classes.flexend}>
          <Box>
            <Button
              data-testid="getEmployee-button"
              variant="contained"
              className={classes.Gobutton}
              onClick={getEmployee}
              style={{right: 120}}
              disabled={reviewButton}
            >
              Review
            </Button>
            <Button
              data-testid="viewHistory-button"
              variant="contained"
              className={classes.Gobutton}
              onClick={viewHistory}
            >
              View History
            </Button>
          </Box>
        </Grid>
      </Grid>

      {contentGrid &&
        selectedEmployee !== undefined &&
        selectedEmployee?.length > 0 && (
          <>
            <ViewHistoryGrid EmployeeData={selectedEmployee} />
            <div className={classes.flexSpaceBetween}>
              <Button
                data-testid="downloadBills-button"
                variant="contained"
                className={classes.Gobutton}
                onClick={downloadBills}
              >
                Download
              </Button>
            </div>
          </>
        )}
    </div>
  );
};
