import {useState, useEffect, Fragment} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import {
  BottomNavigation,
  Box,
  Button,
  Chip,
  Collapse,
  Icon,
  IconButton,
  Paper,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import {filterSelector, filterStateActions} from '../redux';
import {
  CheckBoxOutlineBlank,
  ExpandLess,
  ExpandMore,
  Refresh,
  LockReset,
  SettingsBackupRestore,
} from '@mui/icons-material';
import {useStyles} from './styles';
import {Label} from '@app/components/elements/Label';
import {
  APPROVALSTATUSKEY,
  DCRTYPEKEY,
  FILTERS_LABEL,
  HEADQUARTERSKEY,
  HQ_LIMIT,
  REGIONSKEY,
  ZONESKEY,
} from '@app/common/constants';
import {
  capitalize,
  createFilterObject,
  createFilterObjectFromPreferredFilters,
  isFilterEmpty,
  sortHQs,
  transformFilters,
} from '@app/helper/plan-and-meet';
import {
  fetchPlanAndMeetListCreator,
  planAndMeetSelector,
  planAndMeetStatusStateActions,
} from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux';
import FilterSearchDailog from '@app/screens/module-specific-utilities/components/filter-search-dialog';
import {fetchHqsCreator} from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux/slice';
import DefaultFilterActionDialog from '../default-filter-action-dialog';
import isEqual from 'lodash/isEqual';
import {routeSelector} from '@app/router/redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import {
  dcrUnlockRequestSelector,
  dcrUnlockRequestStateActions,
  fetchDCRHQsCreator,
  fetchDcrUnlockRequestListCreator,
} from '@app/screens/module-specific-utilities/pages/dcr-unlock-request/redux';
import {createDCRFilterObject} from '@app/helper/dcr-unlock-request';

const PlanAndMeetFilter = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [showFilterDailog, setShowFilterDailog] = useState<boolean>(false);
  const [disableDefaultFilters, setDisableDefaultFilters] =
    useState<boolean>(false);
  const [mockFilterData, setMockFilterData] = useState<any>([]);
  const [parentRegionId, setParentRegionId] = useState<any>([]);
  const [hqFilterLoading, setHQFilterLoading] = useState(false);
  const [showDefaultActionDailog, setShowDefaultActionDailog] =
    useState<boolean>(false);
  const [defaultActionType, setDefaultActionType] = useState<
    string | undefined
  >(undefined);
  const [filterComponents, setFilterComponent] = useState<any>(<></>);

  const gridOptionsPlanAndMeet = useSelector(
    planAndMeetSelector.getPlanAndMeetGridOption(),
  );
  const gridOptionsDCR = useSelector(
    dcrUnlockRequestSelector.getDcrUnlockRequestGridOption(),
  );
  const isApprovedDCR = useSelector(
    dcrUnlockRequestSelector.getDcrUnlockRequestApprovedSelected(),
  );

  const filters = useSelector(filterSelector.getFilterState());
  const applyButtonDisabled = useSelector(
    filterSelector.getApplyDisableStatus(),
  );
  const preferredFilters = useSelector(
    planAndMeetSelector.getPreferredFilters(),
  );
  const navbarComponentNameSelector = useSelector(
    routeSelector.getNavbarComponentName(),
  );
  const isDefaultFilterApplied =
    preferredFilters && Object.keys(preferredFilters)?.length > 0;

  const appliedFiltersPlanAndMeet = useSelector(
    planAndMeetSelector.getAppliedFilters(),
  );
  const appliedFiltersDCR = useSelector(
    dcrUnlockRequestSelector.getAppliedFilters(),
  );

  const getAppliedFilters = () => {
    switch (navbarComponentNameSelector) {
      case navbarComponentName.planAndMeet:
        return appliedFiltersPlanAndMeet;

      case navbarComponentName.dcrUnlockRequest:
        return appliedFiltersDCR;

      default:
        return {};
    }
  };

  const appliedFiltersDefault = getAppliedFilters();

  useEffect(() => {
    filters && Object.keys(filters)?.length > 0
      ? setFilterComponent(getFilterLayout(filters))
      : setFilterComponent(<></>);
    setHQFilterLoading(false);
  }, [filters, showFilterDailog]);
  // useEffect(() => {
  //   let disableDefaultFilter = isEqual(preferredFilters, appliedFiltersDefault);
  //   setDisableDefaultFilters(disableDefaultFilter);
  // }, [appliedFiltersDefault, preferredFilters]);

  useEffect(() => {
    return () => {
      dispatch(filterStateActions.setFilterState({}));
      dispatch(filterStateActions.setApplyDisableStatus(true));
    };
  }, []);

  const getFilterLayout = (filtersObj: any) => {
    let filterItems: any = [];
    Object.keys(filtersObj).forEach(key => {
      const showApprovalFilter = isApprovedDCR && key == APPROVALSTATUSKEY;
      if (key !== 'headquarters' && key !== 'regions' && !showApprovalFilter) {
        filterItems.push(
          <SecondaryListItems filter={filtersObj[key]} parentKeyName={key} />,
        );
      }
    });
    return <Box paddingBottom={'100px'}>{filterItems}</Box>;
  };

  const setFilters = (appliedFilters: any) => {
    switch (navbarComponentNameSelector) {
      case navbarComponentName.planAndMeet:
        dispatch(planAndMeetStatusStateActions.setFilters(appliedFilters));
        break;

      case navbarComponentName.dcrUnlockRequest:
        dispatch(dcrUnlockRequestStateActions.setFilters(appliedFilters));
        break;

      default:
        break;
    }
  };

  const clearAllFilter = () => {
    let filtersCopy = JSON.parse(JSON.stringify(filters ? filters : {}));
    filtersCopy &&
      Object.keys(filtersCopy).forEach(key => {
        if (filtersCopy && filtersCopy[key]) {
          filtersCopy[key].isChecked = false;
          if (filtersCopy[key] && filtersCopy[key].filterData) {
            Object.keys(filtersCopy[key].filterData).forEach(optionKey => {
              filtersCopy[key].filterData[optionKey]['isChecked'] = false;
            });
          }
        }
      });

    filtersCopy['zones']['filterData'] = filtersCopy['zones']?.filterData.map(
      (item: any) => {
        item?.regions?.forEach((region: any) => {
          region['isChecked'] = false;
          const sortedHQs = sortHQs(region?.headquarters);
          region['headquarters'] = sortedHQs?.map((hq: any) => ({
            ...hq,
            isChecked: false,
          }));
        });
        return item;
      },
    );

    dispatch(filterStateActions.setFilterState(filtersCopy));
    dispatch(filterStateActions.setApplyDisableStatus(false));
    setFilters({});
  };

  const onCLickUseDefaultFilter = () => {
    if (isDefaultFilterApplied) {
      const appliedFilters =
        createFilterObjectFromPreferredFilters(preferredFilters);
      dispatch(planAndMeetStatusStateActions.setFilters(appliedFilters));
      let filtersCopy = JSON.parse(JSON.stringify(filters ? filters : {}));
      const updatedFilters = transformFilters(filtersCopy, appliedFilters);
      dispatch(filterStateActions.setFilterState(updatedFilters));
      dispatch(filterStateActions.setApplyDisableStatus(false));
    }
  };

  const alterOptionFilterAppliedState = (
    state: any,
    parentKey: any,
    childKey: any,
  ) => {
    let filtersCopy = JSON.parse(JSON.stringify(filters ? filters : {}));

    if (filtersCopy[parentKey] && filtersCopy[parentKey].filterData) {
      filtersCopy[parentKey].filterData = filtersCopy[
        parentKey
      ].filterData?.map((optionKey: any) => {
        if (optionKey.id === childKey.id) {
          return {
            ...optionKey,
            isChecked: state,
          };
        }
        return optionKey;
      });

      const uncheckedChild = filtersCopy[parentKey]?.filterData?.find(
        (child: any) => !child?.isChecked,
      );
      filtersCopy[parentKey]['isChecked'] = getIsCheckedValue(uncheckedChild);
    }

    dispatch(filterStateActions.setFilterState(filtersCopy));
    dispatch(filterStateActions.setApplyDisableStatus(false));
  };

  const alterAllAppliedState = (state: any, key: any) => {
    let filtersCopy = JSON.parse(JSON.stringify(filters ? filters : {}));
    if (filtersCopy && filtersCopy[key]) {
      filtersCopy[key].isChecked = state;
      if (filtersCopy[key] && filtersCopy[key].filterData) {
        if (state) {
          Object.keys(filtersCopy[key].filterData).forEach(optionKey => {
            filtersCopy[key].filterData[optionKey]['isChecked'] = true;
          });
        } else {
          Object.keys(filtersCopy[key].filterData).forEach(optionKey => {
            filtersCopy[key].filterData[optionKey]['isChecked'] = false;
          });
        }
      }
      dispatch(filterStateActions.setFilterState(filtersCopy));
      dispatch(filterStateActions.setApplyDisableStatus(false));
    }
  };

  const alterZoneAllFilterAppliedState = (state: boolean) => {
    let filtersCopy = JSON.parse(JSON.stringify(filters ? filters : {}));

    filtersCopy['zones']['isChecked'] = state;

    filtersCopy['zones']['filterData'] = filtersCopy?.zones?.filterData?.map(
      (item: any) => {
        item['isChecked'] = state;
        item?.regions?.forEach((region: any) => {
          region['isChecked'] = state;
          region?.headquarters?.forEach((hq: any) => {
            hq['isChecked'] = state;
          });
        });
        return item;
      },
    );

    !state && updateAppliedHQByParentZone();

    dispatch(filterStateActions.setFilterState(filtersCopy));
    dispatch(filterStateActions.setApplyDisableStatus(false));
  };

  const alterZoneOptionFilterAppliedState = (state: boolean, childKey: any) => {
    let filtersCopy = JSON.parse(JSON.stringify(filters ? filters : {}));

    filtersCopy[ZONESKEY]['filterData'] = filtersCopy?.zones?.filterData?.map(
      (item: any) => {
        if (childKey.id === item.id) {
          item['isChecked'] = state;
          item?.regions?.forEach((regionItem: any) => {
            regionItem['isChecked'] = state;
            regionItem?.headquarters?.forEach((hq: any) => {
              hq['isChecked'] = state;
            });
          });
          !state && updateAppliedHQByZone(item);
        }
        return item;
      },
    );

    const uncheckedChild = filtersCopy[ZONESKEY]?.filterData?.find(
      (child: any) => !child?.isChecked,
    );
    filtersCopy[ZONESKEY]['isChecked'] = getIsCheckedValue(uncheckedChild);

    dispatch(filterStateActions.setFilterState(filtersCopy));
    dispatch(filterStateActions.setApplyDisableStatus(false));
  };

  const alterRegionOptionFilterAppliedState = (
    state: boolean,
    childKey: any,
    zone: any,
  ) => {
    let filtersCopy = JSON.parse(JSON.stringify(filters ? filters : {}));
    filtersCopy[ZONESKEY]['filterData'] = filtersCopy?.zones?.filterData?.map(
      (item: any) => {
        if (item.id === zone.id) {
          item?.regions?.forEach((region: any) => {
            if (childKey.id === region.id) {
              item['isChecked'] = false;
              region['isChecked'] = state;
              region?.headquarters?.forEach((hq: any) => {
                hq['isChecked'] = state;
              });
              !state && updateAppliedHQByRegion(region);
            }
          });

          const uncheckedChild = item?.regions?.find(
            (child: any) => !child?.isChecked,
          );
          item['isChecked'] = getIsCheckedValue(uncheckedChild);
        }
        return item;
      },
    );

    const uncheckedZoneChild = filtersCopy[ZONESKEY]?.filterData?.find(
      (child: any) => !child?.isChecked,
    );
    filtersCopy[ZONESKEY]['isChecked'] = getIsCheckedValue(uncheckedZoneChild);

    dispatch(filterStateActions.setFilterState(filtersCopy));
    dispatch(filterStateActions.setApplyDisableStatus(false));
  };

  const alterHQOptionFilterAppliedState = (
    state: boolean,
    childKey: any,
    regionId: number,
  ) => {
    let filtersCopy = JSON.parse(JSON.stringify(filters ? filters : {}));

    filtersCopy['zones']['filterData'] = filtersCopy?.zones?.filterData?.map(
      (item: any) => {
        item?.regions?.forEach((region: any) => {
          if (regionId === region.id) {
            region.isChecked = false;
            region?.headquarters?.forEach((hq: any) => {
              if (childKey.id === hq.id) {
                hq['isChecked'] = state;
              }
            });

            const uncheckedChild = region?.headquarters?.find(
              (child: any) => !child?.isChecked,
            );
            region['isChecked'] = getIsCheckedValue(uncheckedChild);
          }
        });
        const uncheckedRegionChild = item?.regions?.find(
          (child: any) => !child?.isChecked,
        );
        item['isChecked'] = getIsCheckedValue(uncheckedRegionChild);
        return item;
      },
    );

    const uncheckedZoneChild = filtersCopy[ZONESKEY]?.filterData?.find(
      (child: any) => !child?.isChecked,
    );
    filtersCopy[ZONESKEY]['isChecked'] = getIsCheckedValue(uncheckedZoneChild);

    !state && updateAppliedHQ(childKey);

    dispatch(filterStateActions.setFilterState(filtersCopy));
    dispatch(filterStateActions.setApplyDisableStatus(false));
  };

  const updateAppliedHQ = (hq: any) => {
    const appliedFilters = JSON.parse(
      JSON.stringify(appliedFiltersDefault ? appliedFiltersDefault : {}),
    );
    const hqIndex = appliedFilters?.[HEADQUARTERSKEY]?.findIndex(
      (item: any) => item.id === hq.id,
    );

    if (hqIndex !== -1) {
      appliedFilters?.[HEADQUARTERSKEY]?.splice(hqIndex, 1);

      setFilters(appliedFilters);
    }
  };

  const updateAppliedHQByRegion = (region: any) => {
    const appliedFilters = JSON.parse(
      JSON.stringify(appliedFiltersDefault ? appliedFiltersDefault : {}),
    );
    appliedFilters[HEADQUARTERSKEY] = appliedFilters?.[HEADQUARTERSKEY]?.filter(
      (item: any) => item.parentId !== region.id,
    );
    setFilters(appliedFilters);
  };

  const updateAppliedHQByZone = (zone: any) => {
    const appliedFilters = JSON.parse(
      JSON.stringify(appliedFiltersDefault ? appliedFiltersDefault : {}),
    );

    filters?.zones?.filterData?.forEach((item: any) => {
      if (zone.id === item.id) {
        item?.regions?.forEach((regionItem: any) => {
          appliedFilters[HEADQUARTERSKEY] = appliedFilters?.[
            HEADQUARTERSKEY
          ]?.filter((hq: any) => hq.parentId !== regionItem.id);
        });
      }
      return item;
    });

    setFilters(appliedFilters);
  };

  const updateAppliedHQByParentZone = () => {
    const appliedFilters = JSON.parse(
      JSON.stringify(appliedFiltersDefault ? appliedFiltersDefault : {}),
    );
    appliedFilters[HEADQUARTERSKEY] = null;
    setFilters(appliedFilters);
  };

  const getIsCheckedValue = (value: any) => {
    return value ? false : true;
  };

  const collapseZones = (key: any) => {
    let filtersCopy = JSON.parse(JSON.stringify(filters ? filters : {}));

    filtersCopy['zones']['filterData'] = filtersCopy?.zones?.filterData?.map(
      (zone: any) => {
        if (zone.id === key.id) {
          return {
            ...zone,
            collapsed: !key?.collapsed,
          };
        }
        return zone;
      },
    );

    dispatch(filterStateActions.setFilterState(filtersCopy));
  };

  const collapseRegions = (zoneKey: any, regionKey: any) => {
    if (hqFilterLoading) {
      return;
    }
    let filtersCopy = JSON.parse(JSON.stringify(filters ? filters : {}));

    filtersCopy['zones']['filterData'] = filtersCopy?.zones?.filterData?.map(
      (zone: any) => {
        if (zone.id === zoneKey.id) {
          zone['regions'] = zone?.regions?.map((region: any) => {
            if (region.id === regionKey.id) {
              return {
                ...region,
                collapsed: !regionKey?.collapsed,
              };
            }
            return region;
          });
        }
        return zone;
      },
    );

    dispatch(filterStateActions.setFilterState(filtersCopy));

    if (!regionKey?.headquarters?.length && !hqFilterLoading) {
      const payload = {
        regionId: regionKey?.id,
        zoneId: zoneKey?.id,
      };
      setHQFilterLoading(true);
      fetchHQS(payload);
    }
  };

  const fetchHQS = (payload: any) => {
    switch (navbarComponentNameSelector) {
      case navbarComponentName.planAndMeet:
        dispatch(fetchHqsCreator(payload));
        break;

      case navbarComponentName.dcrUnlockRequest:
        dispatch(fetchDCRHQsCreator(payload));
        break;

      default:
        break;
    }
  };

  const handleChildCheckbox = (parentKeyName: string, childKey: any) => {
    if (parentKeyName === ZONESKEY) {
      alterZoneOptionFilterAppliedState(!childKey?.isChecked, childKey);
    } else {
      alterOptionFilterAppliedState(
        !childKey?.isChecked,
        parentKeyName,
        childKey,
      );
    }
  };

  const handleParentCheckbox = (parentKeyName: string, filter: any) => {
    if (parentKeyName === ZONESKEY) {
      alterZoneAllFilterAppliedState(!filter?.isChecked);
    } else {
      alterAllAppliedState(!filter?.isChecked, parentKeyName);
    }
  };

  const checkIfIndeterminate = (
    list: any,
    filter: any,
    parentKeyName?: string,
  ) => {
    if (filter.isChecked) return false;
    let isIndeterminate = list?.find((item: any) => item.isChecked);

    if (parentKeyName === `parent_${ZONESKEY}`) {
      filters?.[ZONESKEY]?.filterData?.forEach((zone: any) => {
        const checked = checkIfZoneIndeterminate(zone);
        if (checked) isIndeterminate = checked;
      });
    }

    if (parentKeyName === ZONESKEY) {
      const checked = checkIfZoneIndeterminate(filter);
      if (checked) isIndeterminate = checked;
    }

    if (
      parentKeyName === `parent_${ZONESKEY}` ||
      parentKeyName === ZONESKEY ||
      parentKeyName === REGIONSKEY
    ) {
      isIndeterminate =
        isIndeterminate || checkIfHQSelected(parentKeyName, filter);
    }

    return isIndeterminate ? true : false;
  };

  const checkIfZoneIndeterminate = (zone: any) => {
    let isIndeterminate;
    zone?.regions?.forEach((region: any) => {
      if (region?.isChecked) isIndeterminate = true;

      const checkedHQs = region?.headquarters?.find((hq: any) => hq?.isChecked);
      if (checkedHQs) isIndeterminate = true;
    });
    return isIndeterminate;
  };

  const checkIfHQSelected = (parentKeyName: string, filter: any) => {
    let isIndeterminate;
    if (parentKeyName === `parent_${ZONESKEY}`) {
      if (appliedFiltersDefault?.[HEADQUARTERSKEY]?.length) {
        isIndeterminate = true;
      }
    }

    if (parentKeyName === ZONESKEY) {
      filter?.regions?.forEach((region: any) => {
        const appliedZone = appliedFiltersDefault?.[HEADQUARTERSKEY]?.find(
          (hq: any) => hq?.parentId === region?.id,
        );
        if (appliedZone) isIndeterminate = true;
      });
    }

    if (parentKeyName === REGIONSKEY) {
      const regionSelected = appliedFiltersDefault?.[HEADQUARTERSKEY]?.find(
        (hq: any) => hq.parentId === filter.id,
      );
      isIndeterminate = regionSelected ? true : false;
    }

    return isIndeterminate;
  };

  const applyFilters = () => {
    if (navbarComponentNameSelector === navbarComponentName.planAndMeet) {
      const appliedFilters = createFilterObject(filters, appliedFiltersDefault);
      if (isFilterEmpty(appliedFilters)) {
        dispatch(filterStateActions.setApplyDisableStatus(true));
      }
      dispatch(
        planAndMeetStatusStateActions.setPlanAndMeetGridOptions({
          ...gridOptionsPlanAndMeet,
          pageNo: 0,
        }),
      );
      dispatch(planAndMeetStatusStateActions.setFilters(appliedFilters));
      dispatch(filterStateActions.setApplyDisableStatus(true));
      dispatch(fetchPlanAndMeetListCreator());
    } else if (
      navbarComponentNameSelector === navbarComponentName.dcrUnlockRequest
    ) {
      dispatch(
        dcrUnlockRequestStateActions.setDcrUnlockRequestGridOptions({
          ...gridOptionsDCR,
          pageNo: 0,
        }),
      );
      const appliedFilters = createDCRFilterObject(
        filters,
        appliedFiltersDefault,
      );
      dispatch(dcrUnlockRequestStateActions.setFilters(appliedFilters));
      dispatch(filterStateActions.setApplyDisableStatus(true));
      dispatch(fetchDcrUnlockRequestListCreator());
    }
  };

  const onShowAllHQClick = (headQuarters: any, regionId: number) => {
    setMockFilterData(headQuarters);
    setParentRegionId(regionId);
  };
  useEffect(() => {
    if (mockFilterData?.length > 0) {
      setShowFilterDailog(true);
    }
  }, [mockFilterData]);
  const onFilterDialogClose = () => {
    setMockFilterData([]);
    setShowFilterDailog(false);
  };

  const setHQFilterFromSearchDailog = (hqFromSearchModal: any) => {
    let filtersCopy = JSON.parse(JSON.stringify(filters ? filters : {}));
    const regionId = hqFromSearchModal?.regionId;
    filtersCopy['zones']['filterData'] = filtersCopy?.zones?.filterData?.map(
      (item: any) => {
        item?.regions?.forEach((region: any) => {
          if (regionId === region.id) {
            region.headquarters = hqFromSearchModal?.sortedArray;
            const uncheckedChild = region?.headquarters?.find(
              (child: any) => !child?.isChecked,
            );
            region['isChecked'] = getIsCheckedValue(uncheckedChild);
          }
        });
        const uncheckedRegionChild = item?.regions?.find(
          (child: any) => !child?.isChecked,
        );
        item['isChecked'] = getIsCheckedValue(uncheckedRegionChild);
        return item;
      },
    );

    const uncheckedZoneChild = filtersCopy[ZONESKEY]?.filterData?.find(
      (child: any) => !child?.isChecked,
    );
    filtersCopy[ZONESKEY]['isChecked'] = getIsCheckedValue(uncheckedZoneChild);

    dispatch(filterStateActions.setFilterState(filtersCopy));
    dispatch(filterStateActions.setApplyDisableStatus(false));
  };
  const onApplyFilterClick = (data: any) => {
    setHQFilterFromSearchDailog(data);
    setMockFilterData([]);
    setShowFilterDailog(false);
  };
  const onDefaultActionButtonClick = (type: string) => {
    setDefaultActionType(type);
  };
  useEffect(() => {
    if (defaultActionType !== undefined) {
      setShowDefaultActionDailog(true);
    }
  }, [defaultActionType]);
  const onDefaultActionDialogClose = () => {
    setDefaultActionType(undefined);
    setShowDefaultActionDailog(false);
  };
  const Regions = ({zone, regions}: any) => (
    <Collapse in={!zone?.collapsed} timeout="auto" unmountOnExit>
      <List component="div" disablePadding className={classes.marginLeft15}>
        {regions?.map((childKey: any) => {
          return (
            <Fragment key={childKey?.id}>
              <ListItem
                disablePadding
                button
                onClick={() => collapseRegions(zone, childKey)}
                data-testid={`region_${childKey?.id}`}
              >
                <ListItemIcon>
                  <Checkbox
                    className={classes.checkbox}
                    checked={childKey?.isChecked}
                    indeterminate={checkIfIndeterminate(
                      childKey?.headquarters,
                      childKey,
                      REGIONSKEY,
                    )}
                    indeterminateIcon={<IndeterminateIcon />}
                    onClick={event => {
                      event.stopPropagation();
                      alterRegionOptionFilterAppliedState(
                        !childKey?.isChecked,
                        childKey,
                        zone,
                      );
                    }}
                    data-testid={`option_regions_${childKey?.id}`}
                  />
                </ListItemIcon>
                {listItemText(
                  `${capitalize(childKey?.value)} (${childKey?.count})`,
                )}
                <Box paddingRight={'10px'}>
                  {getExpandIcon(childKey?.collapsed)}
                </Box>
              </ListItem>
              <HeadQuarters
                region={childKey}
                headQuarters={childKey?.headquarters}
              />
            </Fragment>
          );
        })}
      </List>
    </Collapse>
  );

  const HeadQuarters = ({region, headQuarters}: any) => (
    <Collapse in={!region?.collapsed} timeout="auto" unmountOnExit>
      <List component="div" disablePadding className={classes.marginLeft15}>
        {(headQuarters || [])?.slice(0, HQ_LIMIT)?.map((childKey: any) => {
          return (
            <ListItem
              disablePadding
              button
              key={childKey?.id}
              onClick={() =>
                alterHQOptionFilterAppliedState(
                  !childKey?.isChecked,
                  childKey,
                  region?.id,
                )
              }
              data-testid={`hq_${region?.id}_${childKey?.id}`}
            >
              <ListItemIcon>
                <Checkbox
                  className={classes.checkbox}
                  checked={childKey?.isChecked}
                  data-testid={`option_headquarters_${childKey?.id}`}
                />
              </ListItemIcon>
              {listItemText(
                `${capitalize(childKey?.value)} (${childKey?.count})`,
              )}
            </ListItem>
          );
        })}
        {headQuarters?.length >= HQ_LIMIT && (
          <>
            <Box
              data-testid={`box-showallhq_${region?.id}`}
              onClick={() => onShowAllHQClick(headQuarters, region.id)}
            >
              <Label
                title="Show all results"
                hideToolTip
                classes={classes.showMoreLabel}
              />
            </Box>
          </>
        )}
      </List>
    </Collapse>
  );

  const getExpandIcon = (collapsed: boolean) => {
    if (collapsed) return <ExpandMore />;
    else return <ExpandLess />;
  };

  const listItemText = (text: string) => (
    <Label title={text} classes={classes.listItemText} />
  );

  const parentListItemText = (text: string) => (
    <Label title={text} classes={classes.parentListItemText} />
  );

  const SecondaryListItems = function ({
    filter,
    parentKeyName,
  }: {
    filter: any;
    parentKeyName: any;
  }) {
    return (
      <>
        {filter ? (
          <Box marginBottom={'15px'} marginLeft={'10px'}>
            <ListItem
              disablePadding
              button
              key={parentKeyName}
              onClick={() => {
                dispatch(
                  filterStateActions.setFilterCollapsedState({
                    key: parentKeyName,
                    value: !filter?.collapsed,
                  }),
                );
              }}
              data-testid={`list_item_${parentKeyName}`}
            >
              <ListItemIcon>
                <Checkbox
                  className={classes.checkbox}
                  checked={filter?.isChecked}
                  indeterminate={checkIfIndeterminate(
                    filter?.filterData,
                    filter,
                    `parent_${parentKeyName}`,
                  )}
                  indeterminateIcon={<IndeterminateIcon />}
                  onClick={event => {
                    event.stopPropagation();
                    handleParentCheckbox(parentKeyName, filter);
                  }}
                  data-testid={`parent_${parentKeyName}`}
                />
              </ListItemIcon>
              {parentListItemText(FILTERS_LABEL[parentKeyName as keyof object])}
              <Box paddingRight={'10px'}>
                {getExpandIcon(filter?.collapsed)}
              </Box>
            </ListItem>

            <Collapse in={!filter?.collapsed} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding
                className={classes.marginLeft15}
              >
                {filter?.filterData?.map((childKey: any) => {
                  return (
                    <>
                      <ListItem
                        disablePadding
                        button
                        key={childKey?.id}
                        onClick={() => {
                          if (parentKeyName === ZONESKEY) {
                            collapseZones(childKey);
                          }
                        }}
                        data-testid={`list_item_${childKey?.id}`}
                      >
                        <ListItemIcon>
                          <Checkbox
                            className={classes.checkbox}
                            checked={childKey?.isChecked}
                            indeterminate={
                              parentKeyName === ZONESKEY
                                ? checkIfIndeterminate(
                                    childKey?.[REGIONSKEY],
                                    childKey,
                                    parentKeyName,
                                  )
                                : false
                            }
                            indeterminateIcon={<IndeterminateIcon />}
                            onClick={event => {
                              event.stopPropagation();
                              handleChildCheckbox(parentKeyName, childKey);
                            }}
                            data-testid={`option_${parentKeyName}_${childKey?.id}`}
                          />
                        </ListItemIcon>
                        {listItemText(
                          parentKeyName === DCRTYPEKEY
                            ? `${childKey?.value} (${childKey?.count})`
                            : `${capitalize(childKey?.value)} (${
                                childKey?.count
                              })`,
                        )}
                        {parentKeyName === ZONESKEY && (
                          <Box paddingRight={'10px'}>
                            {getExpandIcon(childKey?.collapsed)}
                          </Box>
                        )}
                      </ListItem>
                      {parentKeyName === ZONESKEY && (
                        <Regions zone={childKey} regions={childKey?.regions} />
                      )}
                    </>
                  );
                })}
              </List>
            </Collapse>
          </Box>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <>
      <Box
        margin={'20px'}
        marginTop={'10px'}
        borderRadius={'20px'}
        className={`${classes.backgroundWhite} hide-scrollbar`}
        height={'100vh'}
        overflow={'scroll'}
      >
        {filters && Object.keys(filters)?.length > 0 ? (
          <>
            <Box
              display={'flex'}
              justifyContent="space-between"
              marginBottom={'10px'}
              padding={'10px'}
            >
              <Chip
                label="Filter"
                size="small"
                className={classes.chipFilter}
              />
              <Chip
                label="Clear All"
                size="small"
                clickable
                onClick={clearAllFilter}
                className={classes.chipClearAll}
                data-testid="clear-filters"
              />
            </Box>

            {filterComponents}

            <Paper className={`${classes.paper} height-100`} elevation={3}>
              <BottomNavigation showLabels value={0}>
                <Box className={classes.applyBox}>
                  <Button
                    variant="contained"
                    onClick={applyFilters}
                    className={`${classes.paperButton} ${
                      applyButtonDisabled && classes.paperButtonDisabled
                    }`}
                    disabled={applyButtonDisabled}
                    data-testid={'apply_filters'}
                  >
                    Apply
                  </Button>
                  {navbarComponentNameSelector ===
                    navbarComponentName.planAndMeet && (
                    <>
                      <Box className={classes.defaultBox}>
                        <ActionIconButton
                          title="Set Default Filter"
                          icon={<LockReset />}
                          disabled={disableDefaultFilters}
                          onClick={() => onDefaultActionButtonClick('set')}
                          testID="button-set-default-filter"
                        />
                        <ActionIconButton
                          title="Reset Default Filter"
                          icon={<Refresh />}
                          disabled={!isDefaultFilterApplied}
                          onClick={() => onDefaultActionButtonClick('reset')}
                          testID="button-reset-default-filter"
                        />
                        <ActionIconButton
                          title="Use Default Filter"
                          icon={<SettingsBackupRestore />}
                          disabled={!isDefaultFilterApplied}
                          onClick={onCLickUseDefaultFilter}
                          testID="button-use-default-filter"
                        />
                      </Box>
                      <DefaultFilterActionDialog
                        open={showDefaultActionDailog}
                        type={defaultActionType}
                        onClose={onDefaultActionDialogClose}
                      />
                    </>
                  )}
                </Box>
              </BottomNavigation>
            </Paper>
          </>
        ) : (
          <Box sx={{display: 'flex', justifyContent: 'center', mt: 2}}>
            <CircularProgress size={20} />
          </Box>
        )}
      </Box>
      {mockFilterData?.length ? (
        <FilterSearchDailog
          data={mockFilterData}
          parentId={parentRegionId}
          open={showFilterDailog}
          onClose={onFilterDialogClose}
          onApplyFilter={onApplyFilterClick}
        />
      ) : null}
    </>
  );
};

const ActionIconButton = ({
  title,
  icon,
  onClick,
  disabled,
  testID,
}: {
  title: string;
  icon: any;
  onClick: any;
  disabled: boolean;
  testID: string;
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Tooltip
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      arrow
      PopperProps={{disablePortal: true}}
      title={title}
    >
      <IconButton
        className={`${classes.actionButton} ${
          disabled && classes.disabledActionButton
        }`}
        size="small"
        disabled={disabled}
        onClick={onClick}
        data-testid={testID}
      >
        <Icon color={disabled ? 'disabled' : 'primary'}>{icon}</Icon>
      </IconButton>
    </Tooltip>
  );
};

const IndeterminateIcon = () => {
  const classes = useStyles();

  return (
    <>
      <CheckBoxOutlineBlank />
      <div className={classes.indeterminateBox} />
    </>
  );
};

export default PlanAndMeetFilter;
