import {PARTY_TYPE} from '@app/common/constants';
import {PartyFilterOptions} from '@app/screens/module-specific-utilities/pages/position-management/redux/slice';

export const filterDoctorParties = (parties: Array<any>) => {
  const updatedParties = parties.filter(
    (party: any) =>
      party?.partyTypes?.partyTypeGroup?.name === PARTY_TYPE.DOCTOR,
  );
  return updatedParties;
};

export const createPartyFilterOptions = (partiesList: any) => {
  let filters: PartyFilterOptions = {
    specialities: [],
    pools: [],
    stations: [],
    territories: [],
  };
  partiesList?.forEach((partyItem: any) => {
    if (partyItem?.specialities?.length) {
      const alreadyExists = filters?.specialities?.find(
        (speciality: any) => speciality?.id === partyItem?.specialities[0]?.id,
      );
      if (!alreadyExists) {
        filters?.specialities?.push(partyItem?.specialities?.[0]);
      }
    }

    if (partyItem?.areas?.length) {
      const alreadyExists = filters?.pools?.find(
        (pool: any) => pool?.poolId === partyItem?.areas?.[0]?.poolId,
      );
      if (!alreadyExists) {
        filters?.pools?.push(partyItem?.areas?.[0]);
      }
    }

    if (partyItem?.areas?.length) {
      const alreadyExists = filters?.stations?.find(
        (pool: any) => pool?.stationId === partyItem?.areas[0]?.stationId,
      );
      if (!alreadyExists) {
        filters?.stations?.push(partyItem?.areas?.[0]);
      }
    }

    if (partyItem?.areas?.length) {
      const alreadyExists = filters?.territories?.find(
        (pool: any) => pool?.id === partyItem?.areas[0]?.id,
      );
      if (!alreadyExists) {
        filters?.territories?.push(partyItem?.areas?.[0]);
      }
    }
  });

  return filters;
};

export const sortList = (array: any, sortBy: string) => {
  if (array?.length) {
    return [...array]?.sort((a: any, b: any) =>
      a?.[sortBy]?.toLowerCase()?.localeCompare(b?.[sortBy]?.toLowerCase()),
    );
  } else {
    return [];
  }
};
