import theme from '@app/themes';
import {makeStyles, DefaultTheme} from '@mui/styles';

interface CheckBoxObj {
  months: string[];
  checked: boolean;
}

export const useStyles = makeStyles<DefaultTheme>({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  searchBarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  arrowIcon: {
    alignItems: 'center',
    marginTop: theme.spacing(20),
    width: '15%',
  },
  radioBtns: {
    marginTop: theme.spacing(20),
  },
  searchRadioBtns: {
    marginTop: theme.spacing(5),
  },
  dropdown: {
    backgroundColor: '#f2f2f2',
    height: 40,
    width: 150,
  },
  bottomContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(20),
  },
  partiesContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  partiesTextContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  partyCount: {
    marginRight: theme.spacing(5),
    textTransform: 'capitalize',
  },
  selectedText: {
    textTransform: 'lowercase',
  },
  formLabel: {
    marginRight: 10,
    '& .MuiFormControlLabel-label': {
      fontSize: 13,
    },
  },
  optionRadio: {
    padding: 5,
  },
  searchBarContent: {
    flex: 1,
  },
});
