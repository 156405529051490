import {filterStateActions} from '@app/components/right-sidebar/redux';
import {routeStateActions} from '@app/router/redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import {useStyles} from '@app/screens/Styles/style';
import {Box, CircularProgress, Container} from '@mui/material';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AddExpenseType} from '../../components/add-expense-type-popup';
import {EditExpenseType} from '../../components/add-expense-type-popup/edit-expense-type-popup';
import {ExpenseTypeGrid} from '../../components/expenseTypeGrid';
import {
  ComponentManagementSelector,
  getComponentManagementStateSelector,
} from '../../components/redux/selectors';
import {
  componentManagementStateActions,
  fetchExpenseCategoryCreator,
  fetchExpenseTypeCreator,
  fetchExpenseTypeOptionsCreator,
} from '../../components/redux/slice';

export const ExpenseType = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const expenseTypes = useSelector(
    ComponentManagementSelector.getExpenseType(),
  );

  const addExpenseTypePopupVisible = useSelector(
    getComponentManagementStateSelector('addExpenseTypePopupVisible'),
  );
  const editExpenseTypePopupVisible = useSelector(
    ComponentManagementSelector.getEditExpenseTypePopupVisible(),
  );

  const Loading = useSelector(
    ComponentManagementSelector.getExpenseTypeLoading(),
  );

  useEffect(() => {
    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.ComponentManagement,
      ),
    );
    dispatch(
      routeStateActions.setNavbarComponentHeading(
        <div className={classes.navbarComponentHeading}>Expense Type</div>,
      ),
    );
    dispatch(filterStateActions.setFilterState({}));
    dispatch(fetchExpenseTypeCreator(null));
    dispatch(fetchExpenseCategoryCreator());
    dispatch(fetchExpenseTypeOptionsCreator());

    return () => {
      dispatch(routeStateActions.setNavbarComponentName(null));
      dispatch(filterStateActions.setFilterState({}));
      dispatch(componentManagementStateActions.setExpenseTypeInAutoSuggest([]));
      dispatch(
        componentManagementStateActions.setExpenseTypeInAutoSuggestFilter({}),
      );
      dispatch(componentManagementStateActions.setSearchText(''));
    };
  }, [classes.navbarComponentHeading, dispatch]);

  return (
    <div>
      {Loading ? (
        <Box className={classes.justifyContentcenterflex}>
          <CircularProgress />
        </Box>
      ) : (
        <Container maxWidth={false}>
          <ExpenseTypeGrid Data={expenseTypes} />
          {addExpenseTypePopupVisible && <AddExpenseType />}
          {editExpenseTypePopupVisible && <EditExpenseType />}
        </Container>
      )}
    </div>
  );
};
