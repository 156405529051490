const Colors = {
  background: '#F5F8F9',
  accent: '#ff7171',
  black: '#000',
  disabled: '#E4E4E4',
  grayishBlue: '#D5E2E7',
  lightGrayishBlue: '#F5F8F9',
  primary: '#322b7c',
  veryPaleRed: '#FFC0CB',
  white: '#fff',
  lightBgBlue: '#F5FCFF',
  orange: {
    100: '#FFA088',
    200: '#c43500',
    300: '#ff4500',
    400: '#FFF7F5',
    500: '#ff7650',
    600: '#ffb200',
    700: '#ffe8b3',
    800: '#d425f4',
    900: '#ce36ea',
    1000: '#f0971d',
  },
  borderColor: '#979797',
  blue: {
    100: '#2293cb',
    200: '#2394cc',
    300: '#e9f4fa',
    400: '#EAE9F2',
    500: '#36287f',
    600: '#1d183b',
    700: '#5340C1',
    800: '#79a8ff',
    900: '#ECF3F9',
    1000: '#DBE8F4',
    1100: '#36b0f0',
    1200: '#97ceeb',
    1300: '#5BDCFF',
    1400: '#1c1939',
    1500: '#000000',
    1600: '#1c1939',
    1700: '#00abff',
  },
  darkBlue: '#5920B7',
  lightBlue: '#0852C5',
  limeGreen: '#00B637',
  checkCircleBlue: '#0095d1',
  transparent: '#00000000',
  skyBlue: '#007fff',
  red: {
    100: '#E36565',
    200: '#f45b60',
    300: '#ff0000',
    400: '#830202',
    500: '#aa0808',
    600: '#ff0e02',
    700: '#e02020',
    800: '#fff6f5',
    900: '#ff0020',
    1000: '#c30000',
    1100: '#ffdfdc',
    1200: '#FFECE5',
  },
  blueBg: '#50478D',
  grey: {
    100: '#BEBEBF',
    200: '#1C1939',
    300: '#e3e3e3',
    400: '#e2e2e2',
    500: '#6D7278',
    600: '#464A53',
    700: '#c9ccd2',
    800: '#646464',
    900: '#524f67',
    1000: '#ededed',
    1100: '#494761',
    1200: '#1a1b1d',
    1300: '#322B7C1A',
    1400: '#524F670D',
    1500: '#FFE9E8',
    1600: '#747379',
    1700: '#f2f2f2',
    1800: '#D2D1D7',
    1900: '#e8e8e8',
    2000: '#fcfcfc',
    2100: '#3b3850',
    2200: '#BFBDD5',
    2300: '#C7C7C7',
    2400: '#00000D',
    2500: '#F4F4F4',
    2600: '#E5E5E5',
    2700: '#F8F9FA',
    2800: '#F0F3F5',
    2900: '#d0d0d0',
    3000: '#D6DCE5',
    3100: '#F5F5F5',
    3200: '#AEB5B5',
    3300: '#767676',
    3400: '#d3d3d3',
    3500: '#acacac',
  },
  green: {
    100: '#34b53a',
    200: '#01a685',
    300: '#c8e7eb',
    400: '#10606a',
    500: '#297f9d',
    600: '#F8FDFC',
    700: '#83C1AC',
    800: '#04a504',
    900: '#01a693',
    1000: '#6db2aa',
    1100: '#4b9b92',
    1200: '#C6E0B4',
  },
  blueShades: {100: '#32C5FF14', 200: '#32c5ff'},
  pink: {100: '#FF71E7', 200: '#F8ECEF'},
  yellow: {
    100: '#f5ad00',
    200: '#f5ad00',
    300: '#f8bf04',
    400: '#fc7e00',
    500: '#edbb0e',
    600: '#ffb400',
  },
  bluish: 'rgba(34, 178, 203, .05)',
  greenish: 'rgba(233,255,219,.25)',
  LIGHT_NAVY_BLUE: '#322b7c',
  textInputBorder: '#caccd1',
  DARK: '#1c1939',
  purple: '#800080',
  brown: '#B97A57',
};

export default Colors;
