import Typography from '@mui/material/Typography';
import {useSelector, shallowEqual} from 'react-redux';
import {Box} from '@mui/material';
import {appSelector} from '@app/store/selectors';
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
};
export const HomeNavBar = () => {
  const userInfo = useSelector(appSelector.getUserInfo(), shallowEqual);
  return (
    <Box sx={styles.container}>
      <Box sx={styles.flexSpaceBetween}>
        <Typography
          variant="h6"
          noWrap
          component="div"
          color={'#1c1939'}
          fontStyle={'normal'}
        >
          Home
        </Typography>
        {userInfo && Object.keys(userInfo).length > 0 && userInfo?.name && (
          <Typography>Welcome {userInfo?.name}</Typography>
        )}
      </Box>
    </Box>
  );
};
