import {createAction, createSlice} from '@reduxjs/toolkit';

interface initialStateProps {
  departmentManagementList: any;
}
const initialState: initialStateProps = {
  departmentManagementList: null,
};

export const departmentManagementListCreator = createAction<any>(
  'DIRECTORY/DEPARTMENT_MANAGEMENT_LIST',
);
export const departmentManagementTypeName =
  departmentManagementListCreator(null).type;

const departmentManagementSlice = createSlice({
  name: 'DEPARTMENT_MANAGEMENT',
  initialState: initialState,
  reducers: {
    fetchDepartmentList: (state, action) => {
      return {
        ...state,
        departmentManagementList: action.payload,
      };
    },
    fetchDepartmentNewList: (state, action) => {
      return {
        ...state,
        departmentManagementList: [
          ...state.departmentManagementList,
          ...action.payload,
        ],
      };
    },
  },
});

export const departmentManagementActions = departmentManagementSlice.actions;
export const departmentManagementReducer = departmentManagementSlice.reducer;
