import {
  DataGrid,
  DataGridProps,
  GridColDef,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import {Box} from '@mui/material';
import {saveExpenseTypeDateGridCreator} from '../redux/slice';
import {useDispatch, useSelector} from 'react-redux';
import {Gridstyle} from '@app/components/widgets/grid/grid';
import {appSelector} from '@app/store/selectors';
import {useCallback, useState} from 'react';

export const util: any = {
  processRowUpdate: null,
};

export const getAutoSubmissionDate = (setdate: any, date: any) => {
  return {
    field: 'autoSubmissionDate',
    headerName: 'Auto Submission Date',
    width: 300,
    editable: true,
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const amount = params.props.value;
      const hasError =
        amount > params?.otherFieldsProps?.managerApprovalDate?.value;
      return {...params.props, error: hasError};
    },
    valueParser: (value: any) => {
      let val = value?.replace(/\D/g, '');
      if (val <= 31) {
        setdate(val);
        return val;
      } else {
        return date;
      }
    },
  };
};
export const getManagerApprovalDate = (setMgrDate: any, mgrDate: any) => {
  return {
    field: 'managerApprovalDate',
    headerName: 'Manager Approval Date',
    width: 300,
    editable: true,
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const amount = params.props.value;
      const hasError =
        amount < params?.otherFieldsProps?.autoSubmissionDate?.value;
      return {...params.props, error: hasError};
    },
    valueParser: (value: any) => {
      let val = value?.replace(/\D/g, '');
      if (val <= 31) {
        setMgrDate(val);
        return val;
      } else {
        return mgrDate;
      }
    },
  };
};
export const ExpenseDatesGrid = ({expdata}: {expdata: any}) => {
  const dispatch = useDispatch();
  const [date, setdate] = useState(0);
  const [mgrDate, setMgrDate] = useState(0);
  const isWriteAccess = useSelector(appSelector.getUserInfo());
  const columns: GridColDef[] = [
    {
      field: 'divisionName',
      headerName: 'Division Name',
      width: 300,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <p style={{maxWidth: '100%', wordBreak: 'break-word'}}>
            {params.row.divisionName}
          </p>
        );
      },
    },
    getAutoSubmissionDate(setdate, date),
    getManagerApprovalDate(setMgrDate, mgrDate),
  ];
  const getRowIdHandler = useCallback(function (row: any) {
    return row?.divisionId;
  }, []);

  const processRowUpdate: DataGridProps['processRowUpdate'] = (newRow: any) => {
    if (newRow.autoSubmissionDate) {
      let send = {
        divisionId: newRow.divisionId,
        autoSubmissionDate: newRow.autoSubmissionDate,
        managerApprovalDate: newRow.managerApprovalDate,
      };
      dispatch(saveExpenseTypeDateGridCreator(send));
      return newRow;
    }
  };
  util.processRowUpdate = processRowUpdate;
  return (
    <Box sx={Gridstyle.gridcss}>
      <DataGrid
        columnBuffer={15}
        rows={expdata}
        getRowId={getRowIdHandler}
        editMode={isWriteAccess ? 'row' : undefined}
        columns={columns}
        experimentalFeatures={{newEditingApi: true}}
        processRowUpdate={processRowUpdate}
        pageSize={100}
        hideFooter={expdata?.length <= 100}
      />
    </Box>
  );
};
