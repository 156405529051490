import {createSelector} from '@reduxjs/toolkit';

export const inTransistAccessSelector = {
  getInTransistAccessType: () => {
    return createSelector(
      [(state: any) => state?.inTransistAccess?.inTransistAccessType],
      state => state,
    );
  },
  getInTransistAccessData: () => {
    return createSelector(
      [(state: any) => state?.inTransistAccess?.stateDivision],
      state => state,
    );
  },
  getInTransistAccessUserList: () => {
    return createSelector(
      [(state: any) => state?.inTransistAccess?.userList],
      state => state,
    );
  },
  getInTransistAccessFilterUserList: () => {
    return createSelector(
      [(state: any) => state?.inTransistAccess?.filteredUserList],
      state => state,
    );
  },
  getFilteredData: () => {
    return createSelector(
      [(state: any) => state?.inTransistAccess?.filteredData],
      state => state,
    );
  },
  getSearchValue: () => {
    return createSelector(
      [(state: any) => state?.inTransistAccess?.searchValue],
      state => state,
    );
  },
  getAdminFilterData: () => {
    return createSelector(
      [(state: any) => state?.inTransistAccess?.adminFilterData],
      state => state,
    );
  },
  getLoading: () => {
    return createSelector(
      [(state: any) => state?.inTransistAccess?.loading],
      state => state,
    );
  },
  getPageNumber: () => {
    return createSelector(
      [(state: any) => state?.inTransistAccess?.pageNumber],
      state => state,
    );
  },
  getRequest: () => {
    return createSelector(
      [(state: any) => state?.inTransistAccess?.request],
      state => state,
    );
  },
  getInTransitAccessFlag: () => {
    return createSelector(
      [(state: any) => state?.inTransistAccess?.inTransistAccessFlag],
      state => state,
    );
  },
};
