import {createSelector} from '@reduxjs/toolkit';

const getSocialMediaCategoryLists = (state: any) =>
  state.socialMediaCategoryState.socialMediaCategoryList;

const getSocialMediaCategoryList = createSelector(
  [getSocialMediaCategoryLists],
  _getSocialMediaCategoryLists => _getSocialMediaCategoryLists,
);

export const socialMediaCategorySelector = {
  getsocialmediacategorylist: () => getSocialMediaCategoryList,
};
