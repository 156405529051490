import {createSelector} from '@reduxjs/toolkit';

const hoUserCategories = (state: any) => state.hoUserState.hoUserCategory;
const hoUserDepartments = (state: any) => state.hoUserState.hoUserDepartment;

const getHoUserDivisions = (state: any) => state.hoUserState.hoUserDivision;

const getHoUserStates = (state: any) => state.hoUserState.hoUserState;
const getHoUserRegions = (state: any) => state.hoUserState.hoUserRegion;

const getHoUserLists = (state: any) => state.hoUserState.hoUserList;

const getHoUserCategory = createSelector(
  [hoUserCategories],
  (houser: any) => houser,
);

const getHoUserDepartments = createSelector(
  [hoUserDepartments],
  (department: any) => department,
);

const getHoUserDivision = createSelector(
  [getHoUserDivisions],
  (division: any) => division,
);

const getHoUserState = createSelector(
  [getHoUserStates],
  (hoUserState: any) => hoUserState,
);

const getHoUserRegion = createSelector(
  [getHoUserRegions],
  (hoUserRegion: any) => hoUserRegion,
);

const getHoUserList = createSelector(
  [getHoUserLists],
  _hoUserList => _hoUserList,
);

export const hoUserSelector = {
  getHoUserCategory: () => getHoUserCategory,
  getHoUserDepartments: () => getHoUserDepartments,
  getHoUserDivision: () => getHoUserDivision,
  getHoUserState: () => getHoUserState,
  getHoUserRegion: () => getHoUserRegion,
  getHoUserList: () => getHoUserList,
};
