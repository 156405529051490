import {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  TableRow,
  TableCell,
  Divider,
  IconButton,
  Grid,
  Button,
  InputLabel,
  TextField,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Stack,
} from '@mui/material';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {Add, Remove} from '@mui/icons-material';

import EditIcon from '@app/assets/svg/edit-icon';
import {routeStateActions} from '@app/router/redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import {
  directoryActions,
  actions,
  directorySelector,
  directoryNavActionHandlerCreator,
} from '../../redux';
import Popup from '../../components/Popup';
import {
  getSocialMediaCategoryCreator,
  socialMediaSelector,
  updateSocialMediaListCreator,
} from './redux';
import {
  fetchDivisionsCreator,
  tabManagemnetSelector,
} from '../tab-management/redux';
import NetworkService from '@app/services/network/NetworkService';
import {API_PATH, TOAST_MESSAGES} from '@app/common/constants';
import TableList from '../../components/TableList';
import {
  directoryPageLists,
  directoryUpdateCurrentPageCreator,
} from '../../redux/slice';
import {ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';

const columns = ['Division', 'Social media', 'Url', '', ''];

const initialSocialMedia = {
  id: 0,
  url: '',
  isActive: 1,
  divisionId: 0,
  divisionName: '',
  socialMediaCategoryId: 0,
  socialMediaCategoryName: '',
  name: '',
};

const SocialMedia = () => {
  const dispatch = useDispatch();

  const [addSocialMediaData, setAddSocialMediaData] = useState([
    initialSocialMedia,
  ]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [editSocialMediaData, setEditSocialMediaData] = useState([]);
  const [updatingError, setUpdatingError] = useState<null | string>(null);
  const isActive = useSelector(directorySelector.getStatus, shallowEqual);
  const search = useSelector(directorySelector.getNavSearch(), shallowEqual);

  const socialMediaList: any = useSelector(
    socialMediaSelector.getSocialMediaList(),
    shallowEqual,
  );
  const divisions = useSelector(
    tabManagemnetSelector.getDivisions(),
    shallowEqual,
  );
  const socialMediaCategory: any = useSelector(
    socialMediaSelector.getSocialMediaCategory(),
    shallowEqual,
  );

  const modal: any = useSelector(
    directorySelector.getDirectoryModal(),
    shallowEqual,
  );

  const showAddSocialMediaModal = useMemo(
    () => modal?.show && modal?.type === actions.addSocialMedia,
    [modal],
  );
  const loading: any = useSelector(
    directorySelector.getLoading(),
    shallowEqual,
  );

  const isTabsFetching = useMemo(
    () =>
      loading === API_PATH.directoryConfiguration.socialMediaList &&
      (!socialMediaList || socialMediaList.length === 0),
    [loading, socialMediaList],
  );

  const isUpdatingData = useMemo(
    () => loading === API_PATH.directoryConfiguration.socialMediaList,
    [loading],
  );

  const isEmpty = useMemo(
    () => !isTabsFetching && (!socialMediaList || socialMediaList.length === 0),
    [isTabsFetching, socialMediaList],
  );

  const cancelSocialMediaModal = useCallback(() => {
    setIsEditable(false);
    setAddSocialMediaData([initialSocialMedia]);
    dispatch(directoryNavActionHandlerCreator({show: false, type: null}));
  }, []);

  const socialMediaEditHandler = (socialMediaData: any) => {
    setIsEditable(true);
    setAddSocialMediaData([
      {
        ...initialSocialMedia,
        ...socialMediaData,
      },
    ]);
    dispatch(
      directoryNavActionHandlerCreator({
        show: true,
        type: actions.addSocialMedia,
      }),
    );
  };

  const onFormUpdateHandler = (index: number, data: any) => {
    if (updatingError) {
      setUpdatingError(null);
    }
    const updatedAddSocialMediaData = [...addSocialMediaData]?.map(
      (item, _index) => {
        if (index === _index) {
          return {...item, ...data};
        }
        return item;
      },
    );
    setAddSocialMediaData(updatedAddSocialMediaData);
  };

  const onDivisionUpdateHandler = useCallback(
    (index: any, val: any) => {
      const data = {
        divisionId: val.value,
        divisionName: val.label,
      };
      const updatedAddSocialMediaData = [...addSocialMediaData]?.map(item => ({
        ...item,
        ...data,
      }));
      setAddSocialMediaData(updatedAddSocialMediaData);
    },
    [addSocialMediaData],
  );

  const onAddRemoveHandler = useCallback(
    event => {
      const index = parseInt(event.currentTarget.dataset.current);
      if (index === 0) {
        return setAddSocialMediaData([
          ...addSocialMediaData,
          {
            ...initialSocialMedia,
            divisionId: addSocialMediaData[0].divisionId,
            divisionName: addSocialMediaData[0].divisionName,
          },
        ]);
      }
      const updatedAddSocialMediaData = [...addSocialMediaData];
      updatedAddSocialMediaData.splice(index, 1);
      return setAddSocialMediaData(updatedAddSocialMediaData);
    },
    [addSocialMediaData],
  );

  const renderSocialMediaCategoryDropdownInput = useCallback(
    params => <TextField {...params} placeholder="Select" required />,
    [],
  );

  const onChangeSocialMediaCategoryDropdown = useCallback(
    (event: any, val: any) => {
      const index = parseInt(
        event.currentTarget.parentNode.getAttribute('accessKey'),
      );
      onFormUpdateHandler(index, {
        socialMediaCategoryId: val.value,
        socialMediaCategoryName: val.label,
      });
    },
    [onFormUpdateHandler],
  );

  const onChangeUrlHandler = useCallback(
    event => {
      const index = parseInt(event.target.dataset.current);
      onFormUpdateHandler(index, {url: event.target.value});
    },
    [onFormUpdateHandler],
  );

  const onChangeSocialMediaIsActive = useCallback(
    event => {
      const index = parseInt(event.target.name);
      onFormUpdateHandler(index, {
        isActive: event.target.checked ? 1 : 0,
      });
    },
    [onFormUpdateHandler],
  );

  const onClickSocialMediaEditHandler = useCallback(event => {
    const socialMediaData = JSON.parse(event.currentTarget.dataset.current);
    socialMediaEditHandler(socialMediaData);
    setEditSocialMediaData(socialMediaData);
    setUpdatingError(null);
  }, []);

  const onResetHandler = useCallback(() => {
    setAddSocialMediaData([
      {
        ...initialSocialMedia,
        ...editSocialMediaData,
      },
    ]);
  }, [initialSocialMedia, editSocialMediaData]);

  const unsuccessToaster = () => {
    ToasterService.showToaster(
      isEditable
        ? TOAST_MESSAGES.UNSUCCESSFULL_UPDATE
        : TOAST_MESSAGES.UNSUCCESSFULL_ADD,
      ToastType.ERROR,
    );
  };

  const successToaster = () => {
    ToasterService.showToaster(
      isEditable
        ? TOAST_MESSAGES.SUCCESSFULLY_UPDATED
        : TOAST_MESSAGES.SUCCESSFULLY_ADD,
      ToastType.SUCCESS,
    );
  };

  const onAddOrUpdateSocialMediaCategoryHandler = useCallback(
    async (e: any) => {
      e.preventDefault();
      if (
        addSocialMediaData.every(
          _addSocialMediaData =>
            _addSocialMediaData.divisionId?.toString().trim() &&
            _addSocialMediaData.divisionName?.toString().trim() &&
            _addSocialMediaData.socialMediaCategoryId?.toString().trim() &&
            _addSocialMediaData.socialMediaCategoryName?.toString().trim() &&
            _addSocialMediaData.url?.toString().trim(),
        )
      ) {
        let url = addSocialMediaData[0].url?.toString().trim();
        if (
          !(
            url.startsWith('www.') ||
            url.startsWith('https:') ||
            (url.startsWith('http:') &&
              !url.endsWith('.') &&
              url.split('.').length >= 3 &&
              url.split('.')[1].length >= 2)
          )
        ) {
          setUpdatingError('Please enter valid URL');
          return;
        }
        setIsUpdating(true);
        try {
          const response = await NetworkService.post(
            API_PATH.directoryConfiguration.saveSocialMediaCategory,
            addSocialMediaData,
            {},
          );
          setIsUpdating(false);
          if (response.status !== 200) {
            unsuccessToaster();
            setUpdatingError(response.data.description);
          } else {
            successToaster();
            dispatch(
              updateSocialMediaListCreator({
                index: 1,
                isActive: isActive === 1,
                query: search?.query,
              }),
            );

            cancelSocialMediaModal();
          }
        } catch (error: any) {
          setIsUpdating(false);
          setUpdatingError(error.message);
        }
      } else {
        setUpdatingError('Please fill all required fields!!');
      }
    },
    [addSocialMediaData],
  );

  const renderDivisionDropdownInput = useCallback(
    params => <TextField {...params} placeholder="Select" required />,
    [],
  );

  const renderSocialMedia = useCallback(
    (item, index: number) => {
      return (
        <div>
          <Box sx={{mb: 3}}>
            <Stack
              sx={{mb: 1.25}}
              direction={'row'}
              justifyContent="space-between"
              alignItems={'center'}
            >
              <InputLabel sx={{mb: 0}} variant="standard" required>
                Social Media
              </InputLabel>
              {!addSocialMediaData[0].id && (
                <IconButton
                  sx={{pb: 0}}
                  data-current={index.toString()}
                  onClick={onAddRemoveHandler}
                >
                  {index === 0 ? (
                    <Add fontSize="small" />
                  ) : (
                    <Remove fontSize="small" />
                  )}
                </IconButton>
              )}
            </Stack>
            <Autocomplete
              options={socialMediaCategory ? socialMediaCategory : []}
              freeSolo
              disabled={
                !socialMediaCategory || socialMediaCategory.length === 0
              }
              renderInput={renderSocialMediaCategoryDropdownInput}
              onChange={onChangeSocialMediaCategoryDropdown}
              value={item.socialMediaCategoryName}
              ListboxProps={{accessKey: index.toString()}}
            />
          </Box>
          <Box sx={{mb: 3}}>
            <InputLabel variant="standard" required>
              Enter URL
            </InputLabel>
            <TextField
              fullWidth
              placeholder="www.example.com"
              value={item.url}
              required
              type="text"
              onChange={onChangeUrlHandler}
              inputProps={{
                'data-current': index.toString(),
              }}
            />
          </Box>
          <Box sx={{mb: 4}}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.isActive === 1}
                  onChange={onChangeSocialMediaIsActive}
                  name={index.toString()}
                />
              }
              label="Is Active"
              labelPlacement="top"
            />
          </Box>
        </div>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [socialMediaCategory, addSocialMediaData],
  );

  useEffect(() => {
    if (!divisions || divisions.length === 0) {
      /*** fetch divisions */
      dispatch(fetchDivisionsCreator({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divisions]);

  useEffect(() => {
    dispatch(
      updateSocialMediaListCreator({
        index: 1,
        isActive: isActive === 1,
        query: search?.query,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, search]);

  useEffect(() => {
    dispatch(getSocialMediaCategoryCreator({}));

    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.directoryConfiguration,
      ),
    );

    dispatch(
      directoryActions.updateNavData({
        title: 'Social Media',
        action: {title: 'Add New', type: actions.addSocialMedia},
        search: {placeholder: 'Search here', query: ''},
        status: {show: true, value: true},
      }),
    );

    dispatch(directoryUpdateCurrentPageCreator(directoryPageLists.socialMedia));

    return () => {
      dispatch(routeStateActions.setNavbarComponentName(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoadMoreHandler = useCallback(
    (index: number) => {
      dispatch(
        updateSocialMediaListCreator({
          index,
          isActive: isActive === 1,
          query: search?.query,
        }),
      );
    },
    [isActive, search],
  );

  return (
    <>
      <TableList
        columns={columns}
        isEmpty={isEmpty}
        onLoadMore={onLoadMoreHandler}
        isPaging={true}
        isLoading={isUpdatingData}
      >
        {socialMediaList && socialMediaList.length > 0
          ? socialMediaList?.map((socialMediaData: any, index: number) => (
              <>
                <TableRow key={socialMediaData.id.toString()}>
                  <TableCell>{socialMediaData.divisionName}</TableCell>
                  <TableCell>
                    {socialMediaData.socialMediaCategoryName}
                  </TableCell>
                  <TableCell>{socialMediaData.url}</TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right">
                    <IconButton
                      sx={{p: 0}}
                      data-current={JSON.stringify(socialMediaData)}
                      onClick={onClickSocialMediaEditHandler}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <Divider
                  component={TableRow}
                  variant="fullWidth"
                  sx={{height: 5, border: 0}}
                />
              </>
            ))
          : null}
      </TableList>

      <Popup
        open={!!showAddSocialMediaModal}
        isLoading={isUpdating}
        cancelPopup={cancelSocialMediaModal}
        onClose={cancelSocialMediaModal}
        error={updatingError}
        title={isEditable ? 'Edit Social Media' : 'Add Social Media'}
      >
        <Box
          sx={{
            overflowY: isEditable ? 'hidden' : 'scroll',
            maxHeight: '70vh',
            pb: 4,
          }}
        >
          <Box sx={{mb: 3}}>
            <InputLabel variant="standard" required>
              Division
            </InputLabel>
            <Autocomplete
              options={divisions}
              freeSolo
              disabled={!divisions || divisions.length === 0}
              renderInput={renderDivisionDropdownInput}
              onChange={onDivisionUpdateHandler}
              value={addSocialMediaData[0].divisionName}
            />
          </Box>
          {addSocialMediaData?.map(renderSocialMedia)}
        </Box>
        <Box position={'sticky'} sx={{backgroundColor: 'white', paddingTop: 2}}>
          <Grid container justifyContent={'center'} alignItems={'center'}>
            <Grid item mr={2}>
              <Button variant="outlined" onClick={onResetHandler}>
                Reset
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={onAddOrUpdateSocialMediaCategoryHandler}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Popup>
    </>
  );
};

export default SocialMedia;
