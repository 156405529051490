import {createAction, createSlice} from '@reduxjs/toolkit';

export const challanShortQtyApprovalInitialState = {
  challanShortQtyApprovalDataList: [],
  voucherDetailsList: [],
  loading: false,
  isRejected: false,
  isApproved: false,
  pageNumber: 0,
  autoSuggestionChallan: [],
  reqPayloadForChallanShortOtyList: {},
};

export const challanShortQtyApprovalDataListCreator = createAction<any>(
  'Challan_ShortQty_Approval_Data_List',
);
export const fetchChallanShortQtyApprovalDataList =
  challanShortQtyApprovalDataListCreator({}).type;
export const autoSuggestionChallanCreator = createAction<any>(
  'AutoSuggestion Challan',
);
export const FetchAutoSuggestionChallan = autoSuggestionChallanCreator({}).type;

export const voucherDetailsListCreator = createAction<any>(
  'Voucher_Details_Data_List',
);
export const fetchVoucherDetailsList = voucherDetailsListCreator({}).type;

export const challanQunantitySortApproveCreator = createAction<any>(
  'Challan_ShortQty_Approve',
);
export const ChallanQuantitySortApprove = challanQunantitySortApproveCreator(
  {},
).type;

export const challanQunantitySortRejectCreator = createAction<any>(
  'Challan_ShortQty_Reject',
);
export const ChallanQuantitySortReject = challanQunantitySortRejectCreator(
  {},
).type;

const challanShortQtyApprovalHandler = createSlice({
  name: 'CHALLAN_SHORT_QTY_APPROVAL_HANDLER',
  initialState: challanShortQtyApprovalInitialState,
  reducers: {
    setLoading: (prevState, {payload}) => {
      return {
        ...prevState,
        loading: payload,
      };
    },
    SetPageNumber: (prevState, {payload}) => {
      return {
        ...prevState,
        pageNumber: payload,
      };
    },
    setRequiredPayload: (prevState, {payload}) => {
      return {
        ...prevState,
        reqPayloadForChallanShortOtyList: payload,
      };
    },
    setChallanShortQtyApprovalDataList: (prevState, {payload}) => {
      return {
        ...prevState,
        challanShortQtyApprovalDataList: payload,
      };
    },
    setAutoSuggestionChallan: (prevState, {payload}) => {
      return {
        ...prevState,
        autoSuggestionChallan: payload,
      };
    },
    setVoucherDetailsList: (prevState, {payload}) => {
      return {
        ...prevState,
        voucherDetailsList: payload,
      };
    },
    setIsRejected: (prevState, {payload}) => {
      return {
        ...prevState,
        isRejected: payload,
      };
    },
    setIsApproved: (prevState, {payload}) => {
      return {
        ...prevState,
        isApproved: payload,
      };
    },
  },
});

export const challanShortQtyApprovalActions =
  challanShortQtyApprovalHandler.actions;
export const challanShortQtyApprovalReducer =
  challanShortQtyApprovalHandler.reducer;
