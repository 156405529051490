import {Search} from '@mui/icons-material';
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Autocomplete,
} from '@mui/material';
import React, {ChangeEvent} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {filterSelector} from '../right-sidebar/redux';
import {inTransistAccessSelector} from '@app/screens/inventory-management/pages/in-transit-access/redux/selectors';
import {
  inTransistAccessActions,
  inTransitAccessUserCreator,
} from '@app/screens/inventory-management/pages/in-transit-access/redux/slice';
import _ from 'lodash';

const styles = {
  formContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  formControl: {
    margin: '8px',
    backgroundColor: '#ffffff',
    borderWidth: 0.7,
    borderColor: '#322b7c',
  },
  suggestionsContainer: {
    left: '50%',
    backgroundColor: '#d5e2e7',
    width: '32%',
    padding: '20px',
    position: 'fixed',
  },
};

export enum SearchType {
  userName = 'UserName',
  staffCode = 'StaffCode',
}
const searchTypeLabel = {
  UserName: 'User Name',
  StaffCode: 'Staff Code',
};

const IntransitAccessUserNavSearch = () => {
  const dispatch = useDispatch();
  const [searchType, setSearchType] = React.useState<string>('');
  const [searchText, setSearchText] = React.useState<string>('');
  const [open, setOpen] = React.useState(false);

  const [autocompleteValue, setAutocompleteValue] = React.useState(null);
  const pageNUmber = useSelector(inTransistAccessSelector.getPageNumber());

  const handleDDChange = (e: SelectChangeEvent) => {
    setAutocompleteValue(null);
    setSearchText('' as string);
    setSearchType(e.target.value as SearchType);
  };

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText('');
    setSearchText(e.target.value);
  };
  const debouncedCallbackChange = _.debounce(handleTextChange, 1200);

  const adminFilterData = useSelector(
    inTransistAccessSelector.getAdminFilterData(),
  );
  const inTransitUser =
    useSelector(inTransistAccessSelector.getInTransistAccessUserList()) || [];
  const filters = useSelector(filterSelector.getFilterState(), shallowEqual);
  React.useEffect(() => {
    let stateId = adminFilterData?.stateData
      ?.filter((i: any) => filters?.States?.options[i?.stateName] === true)
      .map((e: any) => e.id);
    let divisionId = adminFilterData?.divisionData
      ?.filter((i: any) => filters?.Divisions?.options[i?.name] === true)
      .map((e: any) => e.id);
    let role = adminFilterData?.roleData
      ?.filter((i: any) => filters?.Role?.options[i?.name] === true)
      .map((e: any) => e.id);
    let temp = {
      searchType: searchType,
      searchValue: searchText,
      pageNumber: pageNUmber,
      offsetValue: 20,
      stateIds: {
        stateId: stateId || [],
      },
      divisionIds: {
        divisionId: divisionId || [],
      },
      roleIds: {
        role: role || [],
      },
    } as any;
    dispatch(inTransistAccessActions.setRequest(temp));
    dispatch(inTransitAccessUserCreator(temp));
  }, [searchType, searchText, filters, pageNUmber]);

  return (
    <Box sx={styles.formContainer}>
      <FormControl sx={styles.formControl} fullWidth>
        <InputLabel id="search-type-select-label">Search By</InputLabel>
        <Select
          inputProps={{
            'data-testid': 'dropdown-search-type',
          }}
          variant="outlined"
          labelId="search-type-select-label"
          label="Search By"
          sx={{width: '100%'}}
          value={searchType}
          onChange={handleDDChange}
        >
          <MenuItem value={SearchType.userName}>
            {searchTypeLabel[SearchType.userName]}
          </MenuItem>
          <MenuItem value={SearchType.staffCode}>
            {searchTypeLabel[SearchType.staffCode]}
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={styles.formControl} fullWidth>
        <Autocomplete
          options={inTransitUser?.adminGeoInTransitUserLockDtos || []}
          getOptionLabel={(option: any) => {
            if (searchType === SearchType.userName) {
              return option.userName;
            } else if (searchType === SearchType.staffCode) {
              return option.staffCode;
            }
            return '';
          }}
          onChange={(event, newValue) => {
            let searchTextValue = '';
            if (newValue) {
              searchTextValue =
                searchType === SearchType.userName
                  ? newValue.userName
                  : newValue.staffCode;
            }

            setAutocompleteValue(newValue);
            setSearchText(searchTextValue);
          }}
          value={autocompleteValue}
          open={open}
          onOpen={() => {
            if (searchType && searchText) {
              setOpen(true);
            }
          }}
          onClose={() => setOpen(false)}
          renderInput={params => (
            <TextField
              {...params}
              disabled={!searchType} // Disable when no search type or no text and dropdown closed
              data-testid="search-texfield"
              placeholder="Search By Name/Staff Code"
              onChange={debouncedCallbackChange}
              InputProps={{
                ...params.InputProps,

                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </FormControl>
    </Box>
  );
};

export default IntransitAccessUserNavSearch;
