import {createAction, createSlice} from '@reduxjs/toolkit';

interface InitialStateProps {
  tabs: {
    id: number;
    tabId: number;
    name: string;
    // partyTypeGroup: string;
    // division: string;
  }[];
  divisions: any;
  partyGroups: any;
}

export const initialState: InitialStateProps = {
  tabs: [],
  divisions: null,
  partyGroups: null,
};

export const updateTabsCreator = createAction<any>(
  'DIRECTORY/TAB_MANAGEMENT/UPDATE_TABS',
);
export const updateTabsCreatorTypeName = updateTabsCreator(null).type;

export const fetchDivisionsCreator = createAction<any>(
  'DIRECTORY/TAB_MANAGEMENT/FETCH_DIVISIONS',
);
export const fetchDivisionsCreatorTypeName = fetchDivisionsCreator(null).type;

export const fetchPartyGroupsCreator = createAction<any>(
  'DIRECTORY/TAB_MANAGEMENT/FETCH_PARTY_GROUPS',
);
export const fetchPartyGroupsCreatorTypeName =
  fetchPartyGroupsCreator(null).type;

const tabManagementSlice = createSlice({
  name: 'TAB_MANAGEMENT',
  initialState: initialState,
  reducers: {
    updateTabs: (state, action) => {
      return {
        ...state,
        tabs: action.payload,
      };
    },
    updateNewTabs: (state, action) => {
      return {
        ...state,
        tabs: [...state.tabs, ...action.payload],
      };
    },
    fetchDivisions: (state, action) => {
      return {
        ...state,
        divisions: action.payload,
      };
    },
    fetchPartyGroups: (state, action) => {
      return {
        ...state,
        partyGroups: action.payload,
      };
    },
  },
});

export const tabManagementActions = tabManagementSlice.actions;
export const tabManagementReducer = tabManagementSlice.reducer;
