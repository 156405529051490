import {Box, styled} from '@mui/material';

export const GoButton = styled(Box)(({theme}) => ({
  border: `1px solid ${
    theme.palette.mode === 'light' ? 'rgba(149, , 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
}));

export const Styles = {
  filterHeaderBody: {
    width: '95%',
    margin: '0 auto',
    backgroundColor: '#f5f5f5',
    height: 100,
    borderRadius: 10,
    padding: 20,
  },
  filterBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputCell: {
    margin: '0 auto',
    width: '100%',
    marginLeft: 10,
    marginRight: 10,
    // padding: 10,
  },
  goButton: {
    fontFamily: ['Poppins'].join(','),
    fontSize: 12.7,
    padding: '11px 54px',
    fontWeight: '600',
    cursor: 'pointer',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    backgroundColor: '#322b7c',
    color: 'white',
    // outline: "#322b7c",
    border: 0,
    // ':hover': {
    //   border: 0,
    // }
  },
};
