import { Box, Button, Checkbox, FormControl, Grid, ListItemText, MenuItem, Select, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import {
  fetchfromPoolCreator,
  fetchPoolCreator,
  fetchSLMCreator,
  fetchSalesTransferDataCreator,
  fetchYearlyQuartersCreator,
  inactiveNavbarDropdownDivisionCreator,
  incentiveActions,
  fetchSalesTransferGoBtnCreator,
  inactiveNavbaFilterIconCreator,
} from '../../../redux/slice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Dropdown from '@app/components/dropdown';
import { Styles } from './style';
import { appSelector } from '@app/store/selectors';
import { incentiveSelector } from '../../../redux/selectors';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 135,
    },
  },
};

const styles = {
  listItemText: {
    color: '#1e1c3b',
    fontWeight: 'normal',
    fontSize: '0.9rem',
    marginLeft: 1,
  },
};

let tempArr: any[] = [];

function FilterHeader() {
  const [division, setDivision] = useState<
    { label: string; value: number } | undefined
  >(undefined);
  const [quarter, setQuarter] = useState<
    { label: string; id: number } | undefined
  >(undefined);
  const [toPool, setToPool] = useState<any>([]);
  const [grpOfToPool, setGrpOfToPool] = useState<
    { label: string; value: number; isSelected: boolean } | undefined
  >(undefined);
  const [fromPool, setFromPool] = useState<any>([]);
  const [singleValueFromPool, setSingleValueFromPool] = useState<
    { label: string; value: number; isSelected: boolean } | undefined
  >(undefined);
  const [singleValueToPool, setSingleValueToPool] = useState<
    { label: string; value: number; isSelected: boolean } | undefined
  >(undefined);
  const [grpOfFromPool, setGrpOfFromPool] = useState<
    { label: string; value: number; isSelected: boolean } | undefined
  >(undefined);
  const [slm, setSLM] = useState<{ label: string; value: number } | undefined>(
    undefined,
  );
  const [togroupOfPoolListArr, setTogroupOfPoolListArr] = useState<any>([]);
  const [fromgroupOfPoolListArr, setFromgroupOfPoolListArr] = useState<any>([]);
  const [isGoBtnDisble, setIsGoBtnDisble] = useState(true);
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
  const [fromPoolListArr, setFromPoolListArr] = useState<any>([]);
  const [toPoolListArr, setToPoolListArr] = useState<any>([]);

  const divisionList = useSelector(incentiveSelector.getDivisionList());
  const quarterList = useSelector(incentiveSelector.getQuartetList());
  const slmList = useSelector(incentiveSelector.getSLMData());
  const toPoolList = useSelector(incentiveSelector.getPoolData());
  const poolList = useSelector(incentiveSelector.getToPoolData());
  const grpOfToPoolList = useSelector(incentiveSelector.getGrpOfPoolData());
  const userInfo = useSelector(appSelector.getUserInfo(), shallowEqual);
  const adminStaffPositionMasterId = userInfo.id;
  const search = useSelector(incentiveSelector.getNavSearch(), shallowEqual);
  const isFilterIconStatus = useSelector(
    incentiveSelector.getNavFilterIcon(),
    shallowEqual,
  );

  const fromPoolFilterData = useSelector(
    incentiveSelector.getFilterFromPoolData(),
    shallowEqual,
  );
  const toPoolFilterData = useSelector(
    incentiveSelector.getFilterToPoolData(),
    shallowEqual,
  );
  const toGroupOfPoolData = useSelector(
    incentiveSelector.getToGroupOfPoolList(),
  );
  const salesTransferData = useSelector(
    incentiveSelector.getSalesTransferData(),
    shallowEqual,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setTogroupOfPoolListArr(toGroupOfPoolData);
  }, [toGroupOfPoolData]);

  useEffect(() => {
    setToPoolListArr(toPoolList);
  }, [toPoolList]);

  useEffect(() => {
    setFromPoolListArr(poolList);
  }, [poolList]);

  useEffect(() => {
    setFromgroupOfPoolListArr(grpOfToPoolList);
  }, [grpOfToPoolList]);

  useEffect(() => {
    dispatch(
      inactiveNavbarDropdownDivisionCreator({
        adminStaffPositionMasterId: adminStaffPositionMasterId,
      }),
    );
    dispatch(fetchYearlyQuartersCreator());
  }, []);

  useEffect(() => {
    dispatch(fetchSalesTransferGoBtnCreator({ val: 0 }));
  }, []);


  useEffect(() => {
    if (
      (grpOfFromPool && grpOfToPool) ||
      (grpOfFromPool && singleValueToPool) ||
      (singleValueFromPool && grpOfToPool) ||
      (singleValueFromPool && singleValueToPool)
    ) {
      setIsGoBtnDisble(false);
    } else {
      setIsGoBtnDisble(true);
    }

    if(!division){
      setIsGoBtnDisble(true);
    }
  }, [
    toPool,
    fromPool,
    grpOfFromPool,
    grpOfToPool,
    singleValueToPool,
    singleValueFromPool,
  ]);

  useEffect(() => {
    if (!isFilterIconStatus?.value) {
      setIsFilterVisible(true);
    }
  }, [isFilterIconStatus]);

  const filterHandler = useCallback(
    (e: any) => {
      e.preventDefault();
      dispatch(
        inactiveNavbaFilterIconCreator({
          value: !isFilterIconStatus?.value,
          gobtnClick: true,
          color: 'blue',
        }),
      );
      setIsFilterVisible(false);

      const dataParam = {
        staffPositionId: slm?.value ? slm?.value : 0,
        quarterId: quarter?.id ? quarter?.id : 0,
        toPoolId: singleValueToPool?.value ? singleValueToPool?.value : 0,
        toGroupPoolId: grpOfToPool?.value ? grpOfToPool?.value : 0,
        fromPoolId: singleValueFromPool?.value ? singleValueFromPool?.value : 0,
        fromGroupPoolId: grpOfFromPool?.value ? grpOfFromPool?.value : 0,
        searchKey: search?.query || '',
      };
      dispatch(fetchSalesTransferGoBtnCreator({ val: 1 }));
      dispatch(incentiveActions.setSelectedQuarter(quarter));
      dispatch(fetchSalesTransferDataCreator(dataParam));
      dispatch(fetchYearlyQuartersCreator());
    },
    [
      fromPool,
      slm,
      quarter,
      toPool,
      grpOfToPool,
      fromPool,
      grpOfFromPool,
      search,
      singleValueToPool,
      singleValueFromPool,
      isGoBtnDisble,
    ],
  );

  useEffect(() => {
    try {
      if (
        (search?.query?.length > 2 || search?.query?.length === 0) &&
        salesTransferData
      ) {
        searchApiCall();
      }
    } catch (error) {
      console.log('-----sales transfer screen error-------', error);
    }
  }, [search]);

  const searchApiCall = () => {
    const dataParam = {
      staffPositionId: slm?.value ? slm?.value : 0,
      quarterId: quarter?.id ? quarter?.id : 0,
      toPoolId: singleValueToPool?.value ? singleValueToPool?.value : 0,
      toGroupPoolId: grpOfToPool?.value ? grpOfToPool?.value : 0,
      fromPoolId: singleValueFromPool?.value ? singleValueFromPool?.value : 0,
      fromGroupPoolId: grpOfFromPool?.value ? grpOfFromPool?.value : 0,
      searchKey: search?.query || '',
    };
    dispatch(fetchSalesTransferDataCreator(dataParam));
  };

  const handleRenderValue = useCallback(selected => {
    if (selected?.length === 0) {
      return <em style={Styles.placeHolder}>Select</em>;
    }

    return selected?.join(', ');
  }, []);

  useEffect(() => {
    let toPoolTempArr: any[] = [];
    if (toPoolFilterData && toPoolFilterData?.length > 0) {
      toPoolFilterData?.forEach((val: any) => {
        toPoolTempArr.push(val?.label);
      });
      setToPool(toPoolTempArr);
    }
  }, [toPoolFilterData, grpOfFromPool, slm]);

  const handleGroupfromPool = useCallback(
    (val: any) => {
      if (val?.value) {
        const updatedTemp = toGroupOfPoolData.filter(
          (item: any) => item?.value !== val?.value,
        );
        setTogroupOfPoolListArr(updatedTemp);
        setGrpOfToPool(undefined);
      } else {
        setTogroupOfPoolListArr(toGroupOfPoolData);
      }
      setGrpOfFromPool(val || undefined);
      setFromPool([]);
      dispatch(
        fetchPoolCreator({
          StaffPositionId: slm?.value,
          QuarterId: quarter?.id,
          grouppoolid: val?.value,
          divisionId: division?.value,
          from: 'fromGroup',
        }),
      );
    },
    [
      toPoolList,
      grpOfFromPool,
      slm,
      quarter,
      grpOfToPoolList,
      togroupOfPoolListArr,
    ],
  );

  const handleFromoPool = useCallback(
    (val: any) => {
      setSingleValueFromPool(val || undefined);

      if (val?.value) {
        const updatedTemp = toPoolList.filter(
          (item: any) => item?.value !== val?.value,
        );
        setToPoolListArr(updatedTemp);
      } else {
        setToPoolListArr(toPoolList);
      }
    },
    [singleValueFromPool, toPoolList, toPoolListArr],
  );

  const handleToPool = useCallback(
    (val: any) => {
      setSingleValueToPool(val || undefined);
      if (val?.value) {
        const updatedTemp = poolList.filter(
          (item: any) => item?.value !== val?.value,
        );
        setFromPoolListArr(updatedTemp);
      } else {
        setFromPoolListArr(poolList);
      }
    },
    [singleValueToPool, fromPoolListArr, poolList],
  );

  const handleToGroupOfPool = useCallback(
    (val: any) => {
      if (val?.value) {
        const updatedTemp = grpOfToPoolList?.filter(
          (item: any) => item.value !== val?.value,
        );
        setFromgroupOfPoolListArr(updatedTemp);
      } else {
        setFromgroupOfPoolListArr(grpOfToPoolList);
      }
      setGrpOfToPool(val || undefined);
      setToPool([]);
      dispatch(
        fetchPoolCreator({
          StaffPositionId: slm?.value,
          QuarterId: quarter?.id,
          grouppoolid: val?.value,
          divisionId: division?.value,
          from: 'toGroup',
        }),
      );
    },
    [
      grpOfFromPool,
      slm,
      quarter,
      grpOfToPoolList,
      fromgroupOfPoolListArr,
      dispatch,
    ],
  );

  useEffect(() => {
    if (fromPoolFilterData?.length > 0) {
      fromPoolFilterData.forEach((val: any) => {
        tempArr.push(val?.label);
      });
      setFromPool(tempArr);
    }
  }, [fromPoolFilterData, tempArr]);

  return (
    <>
      {isFilterIconStatus?.value && isFilterVisible ? (
        <div style={Styles.filterHeaderBody}>
          <div style={Styles.filterBox}>
            <Grid container justifyContent={'space-between'} spacing={1}>
              <Grid item xs={2}>
                <Box style={Styles.text}>DIVISION</Box>
                <Box style={Styles.inputCell}>
                  <Dropdown
                    onChange={(val: any) => {
                      dispatch(
                        fetchSLMCreator({
                          divisonId: val?.value,
                        }),
                      );
                      setDivision(val || undefined);
                      setSLM(undefined);
                      setQuarter(undefined);
                      setToPool(undefined);
                      setGrpOfToPool(undefined);
                      setFromPool(undefined);
                      setGrpOfFromPool(undefined);
                    }}
                    value={division}
                    disabled={false}
                    dropdownList={divisionList || []}
                    placeholder="Select Division"
                  />
                </Box>
              </Grid>

              <Grid item xs={2}>
                <Box style={Styles.text}>QUARTER</Box>
                <Box style={Styles.inputCell}>
                  <Dropdown
                    onChange={(val: any) => {
                      setQuarter(val || undefined);
                      setSLM(undefined);
                      setToPool(undefined);
                      setGrpOfToPool(undefined);
                      setFromPool(undefined);
                      setGrpOfFromPool(undefined);
                    }}
                    value={quarter}
                    dropdownList={quarterList || []}
                    placeholder="Quarter"
                    disabled={!division}
                  />
                </Box>
              </Grid>

              <Grid item xs={2}>
                <Box style={Styles.text}>SLM</Box>
                <Box style={Styles.inputCell}>
                  <Dropdown
                    onChange={(val: any) => {
                      setSLM(val || undefined);
                      setToPool(undefined);
                      setGrpOfToPool(undefined);
                      setFromPool(undefined);
                      setGrpOfFromPool(undefined);
                      setSingleValueFromPool(undefined);
                      setSingleValueToPool(undefined);
                      dispatch(incentiveActions.setToPoolData([]))
                      dispatch(incentiveActions.setPoolData([]))
                      dispatch(incentiveActions.setFromPoolData([]))
                      dispatch(incentiveActions.setToGroupOfpool([]))
                      if (val) {
                        dispatch(
                          fetchPoolCreator({
                            StaffPositionId: val?.value,
                            divisionId: division?.value,
                            QuarterId: quarter?.id,
                            grouppoolid: 0,
                          }),
                        );
                        dispatch(
                          fetchfromPoolCreator({
                            StaffPositionId: val?.value,
                            QuarterId: quarter?.id,
                          }),
                        );
                      }
                    }}
                    value={slm}
                    dropdownList={slmList || []}
                    placeholder="Select SLM"
                    disabled={!division || !quarter}
                  />
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box
                  style={{
                    fontSize: 12,
                    color: '#322b7c',
                    fontWeight: '600',
                    marginBottom: 10,
                  }}
                >
                  FROM GRP OF POOL
                </Box>
                <Box style={Styles.inputCell}>
                  <Dropdown
                    onChange={handleGroupfromPool}
                    value={grpOfFromPool}
                    dropdownList={fromgroupOfPoolListArr || []}
                    placeholder="Grp Of Pool"
                    disabled={
                      division === undefined ||
                      slm === undefined ||
                      singleValueFromPool !== undefined
                    }
                  />
                </Box>
              </Grid>

              <Grid item xs={2}>
                <Box style={{ ...Styles.text, maxWidth: '95%' }}>FROM POOL</Box>
                {grpOfFromPool === undefined ? (
                  <Box style={Styles.inputCell}>
                    <Dropdown
                      onChange={handleFromoPool}
                      value={singleValueFromPool}
                      dropdownList={fromPoolListArr || []}
                      placeholder="Grp Of Pool"
                      disabled={division === undefined || slm === undefined}
                    />
                  </Box>
                ) : (
                  <FormControl sx={{ maxWidth: '95%', minWidth: '95%' }}>
                    <Select
                      value={fromPool || []}
                      renderValue={handleRenderValue}
                      MenuProps={MenuProps}
                      displayEmpty
                      sx={{ backgroundColor: '#FFFFFF' }}
                      disabled={fromPool?.length == 0}
                    >

                      {fromPool?.length > 0 ? fromPool?.map((val: any) => (
                        <MenuItem key={val.value} value={val.label}>
                          <Checkbox checked={true}  disabled/>
                          <ListItemText
                            primary={
                              <Typography sx={styles.listItemText}>
                                {val}
                              </Typography>
                            }
                          />
                        </MenuItem>
                      )) : null}
                    </Select>
                  </FormControl>

                )}
              </Grid>
              <Grid item xs={2}>
                <Box style={Styles.text}>TO GRP OF POOL</Box>
                <Box style={Styles.inputCell}>
                  <Dropdown
                    onChange={handleToGroupOfPool}
                    value={grpOfToPool}
                    dropdownList={togroupOfPoolListArr || []}
                    placeholder="To group of pool"
                    disabled={
                      division === undefined ||
                      slm === undefined ||
                      singleValueToPool !== undefined
                    }
                  />
                </Box>
              </Grid>

              <Grid item xs={2}>
                <Box style={Styles.text}>TO POOL</Box>
                {grpOfToPool === undefined ? (
                  <Box style={Styles.inputCell}>
                    <Dropdown
                      onChange={handleToPool}
                      value={singleValueToPool}
                      dropdownList={toPoolListArr || []}
                      placeholder="Grp Of Pool"
                      disabled={division === undefined || slm === undefined}
                    />
                  </Box>
                ) : (
                  <FormControl sx={{ maxWidth: '95%', minWidth: '95%' }}>
                    <Select
                      value={toPool || []}
                      renderValue={handleRenderValue}
                      MenuProps={MenuProps}
                      displayEmpty
                      sx={{ backgroundColor: '#FFFFFF' }}
                      disabled={toPool?.length === 0 }
                    >

                      {toPool?.length > 0 ? toPool?.map((val: any) => (
                        <MenuItem key={val.value} value={val.label}>
                          <Checkbox checked={true} disabled/>
                          <ListItemText
                            primary={
                              <Typography sx={styles.listItemText}>
                                {val}
                              </Typography>
                            }
                          />
                        </MenuItem>
                      )) : null}
                    </Select>
                  </FormControl>
                )}
              </Grid>

              <Grid
                item
                xs={10}
                sx={{ display: 'flex', alignItems: 'center', marginTop: 3 }}
              >
                <Button
                  disabled={isGoBtnDisble}
                  data-testid="clickGo-icon"
                  variant="contained"
                  onClick={filterHandler}
                  size="small"
                  style={{
                    fontFamily: ['Poppins'].join(','),
                    fontSize: 12.7,
                    padding: '11px',
                    cursor: 'pointer',
                    letterSpacing: 0,
                    textAlign: 'center',
                    backgroundColor: isGoBtnDisble ? '#e0e0e0' : '#322b7c',
                    color: 'white',
                    border: 0,
                    borderRadius: 10,
                    minWidth: 50,
                  }}
                >
                  Go
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default React.memo(FilterHeader);
