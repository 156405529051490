import {createAction, createSlice} from '@reduxjs/toolkit';
export const generateReportInitialState = {
  generateReportData: [],
  generateReportLoading: false,
  isGenerateReport: false,
  generateReportSnaShot: [],
  generateReportPageNo: 0,
};
export const fetchGenerateReportCreator = createAction(
  'GENERATE_REPORT_HANDLER/FETCH_GENERATE_REPORT',
);
export const fetchGenerateReportCreatorTypeName =
  fetchGenerateReportCreator().type;
const generateReportStateHandler = createSlice({
  name: 'GENERATE_REPORT_HANDLER',
  initialState: generateReportInitialState,
  reducers: {
    setGenerateReport: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        generateReportData: payload,
      };
    },
    setGenerateReportPageNo: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        generateReportPageNo: payload,
      };
    },
    setGenerateReportSnaShot: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        generateReportSnaShot: payload,
      };
    },
    setIsGenerateReport: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        isGenerateReport: payload,
      };
    },
    setGenerateReportLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        generateReportLoading: payload,
      };
    },
  },
});

export const generateReportStateActions = generateReportStateHandler.actions;
export const generateReportStateReducer = generateReportStateHandler.reducer;
