const EditIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.166 5.333H5.333A1.667 1.667 0 0 0 3.666 7v11.667a1.667 1.667 0 0 0 1.667 1.666H17a1.667 1.667 0 0 0 1.666-1.666v-5.834"
        stroke="#322B7C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.416 4.083a1.768 1.768 0 0 1 2.5 2.5L12 14.5l-3.334.833L9.5 12l7.916-7.917z"
        stroke="#322B7C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
