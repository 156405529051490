import * as React from 'react';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import {userManagementStateSelector} from '@app/screens/access-management/pages/user-management/redux/selectors';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Autocomplete, Typography} from '@mui/material';
import {StatusMangerCard} from './StatusMangerCard';
import {statusManagerStateActions} from '../../pages/status-manager/redux';
import {statusManagerStateSelector} from '../../pages/status-manager/redux/selectors';
import {
  fetchGetExpenseStatusActionCreator,
  fetchStatusManagerAutoSuggestStaffCreator,
} from '../../pages/status-manager/redux/slice';
import {ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
import {appSelector} from '@app/store/selectors';
import {monthMock} from '@app/components/widgets/placeholder/multiselect-placeholder';
import {generateLastTenYears} from '../approve-expenses/utils';
import {useCallback, useEffect} from 'react';
import {
  MenuProp,
  renderInputStaffCodes,
  useStyles,
} from '@app/screens/Styles/style';
export const validate = (dropdownValues: any, staffPositionId: any) => {
  let validated = true;

  Object.entries(dropdownValues).forEach(([key, value]: any) => {
    let toastMsg = '';
    if (value.toString().length > 0) {
      return;
    }
    validated = false;
    if (key === 'divisionId') toastMsg = 'Division';
    else if (key === 'expenseMonth') toastMsg = 'Month';
    else if (key === 'expenseYear') toastMsg = 'Year';

    ToasterService.showToaster(`${toastMsg} is Mandatory`, ToastType.WARNING);
  });
  if (staffPositionId === '0') {
    validated = false;
    ToasterService.showToaster(
      `Staff Code/Name is Mandatory`,
      ToastType.WARNING,
    );
  }

  return validated;
};

export const StatusMangerHeader = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [division, setDivision] = React.useState('');
  const [month, setMonth] = React.useState('');
  const [staffPositionId, setStaffPositionId] = React.useState('0');
  const [staffUserId, setStaffUserId] = React.useState('0');
  const [staffCode, setStaffCode] = React.useState('0');
  const [staffName, setStaffName] = React.useState('');
  const [selectedValue, setSelectedValue] = React.useState({});
  const [year, setYear] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');
  const divisionDropDown = useSelector(
    userManagementStateSelector.getDivisionDropDown(),
  );

  const autoSuggestedStaffDetail = useSelector(
    statusManagerStateSelector.getStatusManagerAutoSuggestStaffDetail(),
  );
  const autoSuggestedStaffLoading = useSelector(
    statusManagerStateSelector.getStatusManagerAutoSuggestLoading(),
  );
  const {isWriteAccess = false} = useSelector(
    appSelector.getUserInfo(),
    shallowEqual,
  );

  const handleChangeDiv = (event: {target: {value: any}}) => {
    setDivision(event.target.value);
  };
  const handleStatusManagerStaffInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let search = {
      searchText: event?.target?.value ?? '',
      divisionId: division,
    };
    if (division !== '' && event?.target?.value?.length >= 3) {
      dispatch(fetchStatusManagerAutoSuggestStaffCreator(search));
    }
  };

  const lastTenYears: number[] = generateLastTenYears();
  const [open, setOpen] = React.useState(false);

  const handleClose = useCallback(
    (val: boolean) => {
      setOpen(val);
    },
    [open],
  );
  useEffect(() => {
    return () => {
      dispatch(
        statusManagerStateActions.setStatusManagerAutoSuggestStaffDetail([]),
      );
    };
  }, []);
  const handleOnClickGo = useCallback(() => {
    dispatch(statusManagerStateActions.setStatusManagerAction([]));

    const getActionValue = {
      divisionId: division,
      staffPositionId: staffPositionId,
      staffUserId: staffUserId,
      expenseMonth: month,
      expenseYear: year,
    };
    setSelectedValue(getActionValue);
    if (validate(getActionValue, staffPositionId)) {
      dispatch(fetchGetExpenseStatusActionCreator(getActionValue));
      setOpen(true);
    }
  }, [division, staffPositionId, month, year]);
  const onClose = useCallback(
    () =>
      dispatch(
        statusManagerStateActions.setStatusManagerAutoSuggestStaffDetail([]),
      ),
    [],
  );

  const handleChangeMonth = useCallback(
    (event: {target: {value: any}}) => {
      setMonth(event.target.value);
    },
    [month],
  );

  const handleChangeYear = useCallback(
    (event: {target: {value: any}}) => {
      setYear(event.target.value);
    },
    [year],
  );

  const getOptionLabelStaffCode = (option: any) => {
    return option?.employeeCode?.toString();
  };

  const onInputChangeStaffCode = (event: any) => {
    handleStatusManagerStaffInputChange(event);
  };

  const onInputChangeName = (event: any, newInputValue: any) => {
    setInputValue(newInputValue?.trimStart());
    handleStatusManagerStaffInputChange(event);
  };
  const keyDownHandle = useCallback(
    (e: any) => ['e', '-', 'E'].includes(e.key) && e.preventDefault(),
    [],
  );
  const onChangeStaffCode = (_event: any, value: any) => {
    setStaffPositionId(value?.staffPositionId || '0');
    setStaffUserId(value?.userId || '0');
    setStaffCode(value?.employeeCode || '0');
  };
  const onChangeStaffName = (_event: any, value: any) => {
    setStaffPositionId(value?.staffPositionId || '0');
    setStaffUserId(value?.userId || '0');
    setStaffName(value?.fullName || '');
  };

  const renderInputStaffCode = useCallback(function (params1: any) {
    return (
      <TextField
        type="number"
        placeholder="Enter Staff Code"
        {...params1}
        sx={renderInputStaffCodes}
      />
    );
  }, []);
  const renderInputStaffName = useCallback(function (params1: any) {
    return (
      <TextField
        placeholder="Enter Staff Name"
        {...params1}
        sx={renderInputStaffCodes}
      />
    );
  }, []);

  return (
    <>
      <Stack
        spacing={0.5}
        p={2}
        direction="row"
        alignItems="center"
        justifyContent="space-around"
        style={{
          backgroundColor: '#f4f4f4',
          height: 120,
          borderRadius: 6.7,
          margin: 5,
        }}
      >
        <Box>
          <Typography className={classes.label}>DIVISION</Typography>
          <FormControl size="small" fullWidth>
            <InputLabel
              sx={{marginTop: 2.5, fontSize: 12}}
              id="division-dropdown"
            >
              Select Division
            </InputLabel>

            <Select
              size="small"
              disabled={!isWriteAccess}
              value={division}
              placeholder="Division"
              MenuProps={MenuProp}
              inputProps={{
                'data-testid': 'dropdown-setDivision',
              }}
              onChange={handleChangeDiv}
              label={'Select Division'}
              sx={{
                width: 190.7,
                top: 18,
                backgroundColor: '#ffffff',
              }}
            >
              {divisionDropDown.map((divisionValue: any) => (
                <MenuItem key={divisionValue.value} value={divisionValue.value}>
                  {divisionValue.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <Typography className={classes.label}>EMPLOYEE CODE </Typography>

          <Autocomplete
            id="autocomplete-suggested-staff-code"
            size="small"
            options={autoSuggestedStaffDetail}
            className={classes.selectPlaceholder}
            loading={autoSuggestedStaffLoading}
            disabled={staffName !== '' || !isWriteAccess || division == ''}
            onClose={onClose}
            onKeyDown={keyDownHandle}
            getOptionLabel={getOptionLabelStaffCode}
            onInputChange={onInputChangeStaffCode}
            onChange={onChangeStaffCode}
            renderInput={renderInputStaffCode}
          />
        </Box>
        <Box>
          <Typography className={classes.label}>STAFF NAME</Typography>
          <Autocomplete
            id="autocomplete-suggested-staff"
            size="small"
            className={classes.selectPlaceholder}
            options={
              autoSuggestedStaffDetail && autoSuggestedStaffDetail.length > 0
                ? autoSuggestedStaffDetail
                : []
            }
            loading={autoSuggestedStaffLoading}
            disabled={staffCode !== '0' || !isWriteAccess || division == ''}
            onClose={onClose}
            getOptionLabel={(option: any) => {
              return option?.fullName;
            }}
            inputValue={inputValue}
            onKeyDown={event => {
              const reg = /^[a-zA-Z .]/;
              if (!reg.test(event?.key)) {
                event.preventDefault();
              }
            }}
            onInputChange={onInputChangeName}
            onChange={onChangeStaffName}
            renderInput={renderInputStaffName}
          />
        </Box>
        <Box>
          <Typography className={classes.label}>MONTH</Typography>

          <FormControl size="small" fullWidth>
            <InputLabel
              sx={{marginTop: 2.5, fontSize: 12}}
              id="division-dropdown"
            >
              Exp Month
            </InputLabel>
            <Select
              inputProps={{
                'data-testid': 'dropdown-setMonth',
              }}
              disabled={!isWriteAccess}
              value={month}
              size="small"
              label={'Exp Month'}
              onChange={handleChangeMonth}
              sx={{
                width: 190.7,
                top: 17,
                backgroundColor: '#ffffff',
              }}
            >
              {monthMock().map((monthVal: any) => (
                <MenuItem key={monthVal.key} value={monthVal.key}>
                  {monthVal.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{minWidth: 120}}>
          <Typography className={classes.label}>YEAR</Typography>

          <FormControl fullWidth>
            <InputLabel
              sx={{marginTop: 1.5, fontSize: 12}}
              id="division-dropdown"
            >
              Exp Year
            </InputLabel>
            <Select
              inputProps={{
                'data-testid': 'dropdown-setYear',
              }}
              size="small"
              disabled={!isWriteAccess}
              label={'Exp Year'}
              value={year}
              sx={{top: 17, backgroundColor: '#ffffff'}}
              onChange={handleChangeYear}
            >
              {lastTenYears.map((yearOption: any) => (
                <MenuItem key={yearOption} value={yearOption}>
                  {yearOption}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Button
          data-testid="button-handleOnClickGo"
          variant="contained"
          disabled={!isWriteAccess}
          size="large"
          sx={{top: 25, width: 53.3}}
          onClick={handleOnClickGo}
        >
          Go
        </Button>
        {open && (
          <StatusMangerCard
            open={open}
            selectedValue={selectedValue}
            onClose={handleClose}
          />
        )}
      </Stack>
    </>
  );
};
