import {TableCell, TableRow} from '@mui/material';

import DialogBox from '@app/components/dialogBox';
import React, {useCallback, useEffect} from 'react';
import {Box} from '@mui/system';
import {shallowEqual, useSelector} from 'react-redux';
import {incentiveSelector} from '@app/screens/incentive-configuration/redux/selectors';
import Dropdown from '@app/components/dropdown';

function ExpandedCell(
  props: Readonly<{
    row: any;
    onUnMount: any;
    unmount: any;
    handleOnChange: (data: any, from: string, index: number) => any;
    handleBlurFunction: () => any;
  }>,
) {
  const {row} = props;
  const [open, setOpen] = React.useState(false);
  const [header, setHeader] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [expandedCellData, setExpandedCellData] = React.useState<any>({});

  const salesDistributionDataList = useSelector(
    incentiveSelector.getSalesDistributionList(),
    shallowEqual,
  );
  const remarksList = useSelector(
    incentiveSelector.getRemaksDataList(),
    shallowEqual,
  );

  const closeModal = React.useCallback(() => {
    setOpen(false);
    setMessage('');
    setHeader('');
    props.onUnMount(!props.unmount);
  }, [props.unmount]);

  useEffect(() => {
    setExpandedCellData(row);
  }, [row]);

  const handleChange = (
    ele: any,
    index: number,
    month: any,
    obj: any,
    from: string,
  ) => {
    let temp = JSON.parse(JSON.stringify({...expandedCellData}));
    let rejex = /^\d+(?:\.\d{0,2})?$/;

    const updateAverageData = () => {
      temp.averageData.forEach((el: any, idx: number) => {
        if (el.segmentName === obj.segmentName) {
          temp.averageData[idx].incentiveAmount = ele.target.value;
        }
      });
    };

    const updateMonthData = () => {
      const findMatchingMonth = (val: any) => val.monthName === month.monthName;

      const findMatchingSegment = (_val: any, _index: number) =>
        _index === index;

      temp.monthData
        .find(findMatchingMonth)
        .segmentData.find(findMatchingSegment).incentiveAmount =
        ele.target.value;
    };

    if ((rejex.test(ele.target.value) || !ele.target.value) && ele.target.value <= 100) {
      try {
        if (from === 'avg') {
          updateAverageData();
        } else {
          updateMonthData();
        }
      } catch (error) {
        console.log('------ error -------', error);
      }
    }
    setExpandedCellData(temp);
    props.handleOnChange(temp, from, index);
  };

  const handleRemarksChange = useCallback(
    (val: any) => {
      let temp = JSON.parse(JSON.stringify({...expandedCellData}));

      temp.remarks = val?.label;
      setExpandedCellData(temp);
      props.handleOnChange(temp, 'remarks', -1);
    },
    [expandedCellData, props],
  );

  const renderSegmentData = (
    segmentData: any,
    month: any,
    from: string,
    idx: number,
  ) => {
    return segmentData?.map((_val: any, index: any) => (
      <>
        {salesDistributionDataList.isSubmit || salesDistributionDataList.isLocked  || from === 'avg' ? (
          <div
            key={_val?.incentiveAmount}
            style={{
              backgroundColor: '#cdcdcd',
              borderRadius: 5,
              height: 30,
              minWidth: 50,
              textAlign: 'right',
              padding: 5,
              display: 'flex',
              flexDirection: 'column',
              fontWeight: 500,
            }}
          >
            {_val?.incentiveAmount}
          </div>
        ) : (
          <input
            style={{
              backgroundColor: idx === 1 ? '#ffdacc' : '#F2F6F7',
              borderWidth: 0,
              color: '#322b7c',
              borderRadius: 5,
              height: 30,
              minWidth: 50,
              maxWidth: 50,
              textAlign: 'right',
              padding: 5,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              fontWeight: 500,
            }}
            value={_val?.incentiveAmount}
            onChange={ele => handleChange(ele, index, month, _val, from)}
            maxLength={8}
            onBlur={() => props.handleBlurFunction()}
          />
        )}
      </>
    ));
  };

  return (
    <>
      <DialogBox
        open={open}
        handleClose={closeModal}
        body={message}
        header={header}
      />
      <TableRow key={row.id} 
       sx={{marginBottom: 10, marginTop: 10,  margin: 20, padding: 20}}
      // style={{backgroundColor: 'red', marginBottom: 4, margin: 20, padding: 20}} 
      className='tableRow'
      >
        <TableCell
          style={{
            background: '#f2f9fc',
            width: 150,
            position: 'sticky',
            left: 0,
            top: 0,
            zIndex: 1,
          }}
        />
        <TableCell
          colSpan={2}
          style={{
            fontWeight: 700,
            paddingLeft: 12,
            position: 'sticky',
            left: 67.4,
            zIndex: 'auto',
            background: 'white',
            whiteSpace: 'nowrap',
            fontSize: 11
          }}
        >
          {'Company account bifurcation'}
        </TableCell>
        {expandedCellData?.monthData?.length > 0 &&
          expandedCellData?.monthData?.map((month: any, index: any) => {
            let idx = index;
            return (
              <TableCell key={idx}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}
                >
                  {renderSegmentData(month.segmentData, month, 'normal', index)}
                </Box>
              </TableCell>
            );
          })}
        <TableCell>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            {renderSegmentData(expandedCellData.averageData, {}, 'avg', 0)}
          </Box>
        </TableCell>
        <TableCell>
          <Dropdown
            size={'small'}
            onChange={(val: any) => {
              handleRemarksChange(val);
            }}
            value={{label: expandedCellData?.remarks}}
            dropdownList={remarksList || []}
            placeholder="Select Remarks"
          />
        </TableCell>
      </TableRow>
    </>
  );
}

export default ExpandedCell;
