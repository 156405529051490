import {createAction, createSlice} from '@reduxjs/toolkit';

interface InitialStateProps {
  columns: {
    id: number;
    tabId: number;
    name: string;
  }[];
  tabList: any;
  columnMappingFields: {}[];
  currentTabId?: null | number | string;
}
const initialState: InitialStateProps = {
  columns: [],
  tabList: null,
  columnMappingFields: [],
  currentTabId: null,
};

export const updateColumnsCreator = createAction<any>(
  'DIRECTORY/COLUMN_MANAGEMENT/UPDATE_COLUMNS',
);
export const updateColumnsCreatorTypeName = updateColumnsCreator(null).type;

export const fetchTabListCreator = createAction<any>(
  'DIRECTORY/COLUMN_MANAGEMENT/FETCH_TAB_LIST',
);
export const fetchTabListCreatorTypeName = fetchTabListCreator(null).type;

export const fetchMappingFieldsListCreator = createAction<any>(
  'DIRECTORY/COLUMN_MANAGEMENT/FETCH_MAPPING_FIELDS',
);
export const fetchMappingFieldsCreatorTypeName = fetchMappingFieldsListCreator(
  [],
).type;

const columnManagementSlice = createSlice({
  name: 'COLUMN_MANAGEMENT',
  initialState: initialState,
  reducers: {
    updateColumns: (state, action) => {
      return {
        ...state,
        columns: action.payload,
      };
    },
    updateNewColumns: (state, action) => {
      return {
        ...state,
        columns: [...state.columns, ...action.payload],
      };
    },
    fetchTabList: (state, action) => {
      return {
        ...state,
        tabList: action.payload,
      };
    },
    fetchMappingFields: (state, action) => {
      return {
        ...state,
        columnMappingFields: action.payload,
      };
    },
    updateCurrentTabId: (state, action) => {
      return {
        ...state,
        currentTabId: action.payload,
      };
    },
  },
});

export const columnManagementActions = columnManagementSlice.actions;
export const columnManagementReducer = columnManagementSlice.reducer;
