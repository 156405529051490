import {
  applyFilterWatcher,
  applySetDefaultFilterWatcher,
} from '@app/components/right-sidebar/redux/filterHandlerSaga';
import {fetchRoutesDataWatcher} from '@app/router/redux/routeHandlerSaga';
import {
  accessGroupMenuItemsWatcher,
  addAccessGroupWatcher,
  disableAccessGroupWatcher,
  editAccessGroupWatcher,
  fetchAccessGroupMasterWatcher,
  fetchAccessGroupsAutoSuggestDataWatcher,
} from '@app/screens/access-management/pages/access-group-master/redux/saga';
import {
  fetchInventoryCnfReturnWatcher,
  fetchCNFStatesAndDivisionsWatcher,
  fetchCNFHeadquartersWatcher,
  fetchCnfAutoSuggestionWatcher,
  fetchFilteredDataWatcher,
  fetchCnfReturnItemsFilterWatcher,
  fetchApprovedItemsWatcher,
  fetchRejectItemsWatcher,
} from '@app/screens/inventory-management/pages/cnf-return-items/redux/saga';
import {
  fetchInventoryIntransistChallansWatcher,
  fetchAutoSuggestionWatcher,
  fetchInTransitFilteredDataWatcher,
  postIntransitChallanOpenCloseWatcher,
  enableDisableIntransitChallansWatcher,
  fetchVoucherNumberDataWatcher,
  fetchRecievedChallansDataWatcher,
} from '@app/screens/inventory-management/pages/intransit-challan/redux/saga';

import {
  fetchInventoryApproveReturnWatcher,
  fetchApproveStatesAndDivisionsWatcher,
  fetchApproveHeadquartersWatcher,
  fetchApproveReturnItemsAutoSuggestionWatcher,
  fetchApproveFilteredDataWatcher,
  fetchApproveReturnItemsFilterWatcher,
  fetchApprovedReturnItemsWatcher,
  fetchApproveRejectItemsWatcher,
} from '@app/screens/inventory-management/pages/ApprovedReturnedItems/redux/saga';
import {
  editAdminUserWatcher,
  fetchAccessGroupDropDownWatcher,
  fetchAdminUsersDataWatcher,
  fetchDivisionDropdownWatcher,
  fetchStateDropdownWatcher,
  addAdminUserWatcher,
  disableAdminUserWatcher,
  fetchAdminUsersAutoSuggestDataWatcher,
  fetchAdminAddUsersAutoSuggestDataWatcher,
} from '@app/screens/access-management/pages/user-management/redux/saga';

import {
  fetchPlanAndMeetStatusListWatcher,
  updateMTPStatusWatcher,
  BulkGSPUpdateWatcher,
} from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux';

import {
  fetchDailyExpensePopupReadOnlyDataWatcher,
  fetchDailyExpensePopupTADADataWatcher,
  fetchDailyExpensePopupTADASaveDataWatcher,
  fetchMiscSaveWatcher,
  deductDaAmountWatcher,
  approveExpenseStatusWatcher,
} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/saga';
import {fetchExportFileCreatorWatcher} from '@app/screens/master-data-management/pages/expense-da-master/redux/saga';

import {all, call, spawn} from 'redux-saga/effects';
import {
  fetchCannotAccessModalUsersWatcher,
  fetchNotificationsWatcher,
  fetchUserInfoWatcher,
  markNotificationReadWatcher,
  fetchUserAppSettingsWatcher,
} from '@app/store/sagas/appSaga';
import {
  directoryNavFilterIconWatcher,
  fetchFromHQWatcher,
  fetchFromPoolWatcher,
  fetchPoolWatcher,
  fetchGroupPoolWatcher,
  fetchPoolSalesDistributionWatcher,
  fetchSLMSalesDistributionWatcher,
  fetchSLMWatcher,
  fetchSalesTransferWatcher,
  fetchToHQWatcher,
  fetchYearlyQuartersWatcher,
  incentiveSaveQueryWatcher,
  fetchSalesTransferGoBtnWatcher,
  fetchSalesDistributionListDataWatcher,
  fetchSalesDistributionRemarksDataWatcher,
  fetchDownloadExcelURLSalesTransferWatcher,
  fetchDownloadExcelURLSalesDistributionWatcher,
} from '@app/screens/incentive-configuration/redux/saga';
import {
  fetchHqsWatcher,
  fetchMTPRecommendationsWatcher,
  filterBasedDownloadExcelWatcher,
  fetchPreferredFiltersWatcher,
  fetchMTPUnlockReasonsWatcher,
  enableDCRWatcher,
  fetchPlanAndMeetHierarchyWatcher,
  HierarchyBasedDownloadExcelWatcher,
  webAccessUnlockWatcher,
  getWebAccessStatusWatcher,
} from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux/saga';
import {
  incentiveNavActionHandlerWatcher,
  incentiveNavDivisionListWatcher,
  incentiveNavSearchQueryWatcher,
  incentiveNavStateLisWatcher,
  incentiveNavToggleWatcher,
  incentiveUpdateCurrentPageWatcher,
} from '@app/screens/incentive-configuration/redux';
import {fetchUnlockRequestWatcher} from '@app/screens/incentive-configuration/pages/unlock-request/redux';
import {getUnlockRequestTypesWatcher} from '@app/screens/incentive-configuration/pages/unlock-request/redux/saga';
import {
  fetchDcrUnlockRequestListWatcher,
  fetchDCRUnlockReasonsWatcher,
  approveDCRUnlockRequestWatcher,
  fetchDCRHqsWatcher,
  dcrDownloadExcelWatcher,
} from '@app/screens/module-specific-utilities/pages/dcr-unlock-request/redux';
import {
  fetchPartiesBySpidWatcher,
  fetchPartyDetailBySpidWatcher,
  fetchCloningPeriodWatcher,
  transferPositionUpdateWatcher,
  fetchPositionCloningListWatcher,
  clonePositionUpdateWatcher,
  fetchPositionTransferListWatcher,
  validatePartyTransferWatcher,
} from '@app/screens/module-specific-utilities/pages/position-management/redux';
import {
  fetchApprovalWorkflowListWatcher,
  fetchMasterConfigDataWatcher,
  createApprovalResponseWatcher,
} from '@app/screens/configuration-management/pages/approval-workflow/redux';
import {updateMinApproverConfigWatcher} from '@app/screens/configuration-management/pages/approval-workflow/redux/saga';
import {modifyDCRRequestWatcher} from '@app/screens/module-specific-utilities/pages/dcr-unlock-request/redux/saga';
import {
  fetchInventoryConfiguratorCustomerTypeWatcher,
  fetchInventoryConfiguratorDivisionWatcher,
  fetchInventoryConfiguratorDoctorQualificationWatcher,
  fetchInventoryConfiguratorDoctorSpecialityWatcher,
  fetchInventoryConfiguratorRegionHeirarcyWatcher,
  fetchInventoryConfiguratorSpecCategoryWatcher,
  fetchInventoryConfiguratorClassCodeWatcher,
  fetchInventoryConfiguratorRolesWatcher,
  fetchInventoryConfiguratorItemCategoryWatcher,
  fetchInventoryConfiguratorItemCodeWatcher,
  fetchInventoryConfiguratorSetValueWatcher,
  fetchInventoryConfiguratorQualificationWatcher,
} from '@app/screens/inventory-management/pages/inventoryConfigurator/redux/saga';
import {
  fetchExpectedUtilizatonDateSkuAutoSuggestionWatcher,
  fetchExpectedUtilizatonDaysWatcher,
  fetchNewExpectedUtilizatonDaysWatcher,
} from '@app/screens/inventory-management/pages/Expected Utilizaton Date/redux/saga';
import {
  fetchItemCategoryWatcher,
  fetchInventoryDeleteRolesWatcher,
  fetchInventorySaveRolesWatcher,
  fetchInventoryDivisionWatcher,
  fetchInventoryRolesPage2Watcher,
  fetchInventorySaveRolesPage2Watcher,
  fetchInventoryDeleteRolesPage2Watcher,
  fetchInventoryPartyTypeWatcher,
  fetchItemCategoryUserWatcher,
  fetchInventoryRulesActiveInActiveWatcher,
  fetchInventoryRulesActiveWatcher,
  fetchInventoryRulesInActiveWatcher,
} from '@app/screens/inventory-management/pages/inventoryRules/redux/saga';

import {
  fetchInTransitAccessDivisionStateWatcher,
  fetchInTransitAccessUserWatcher,
  fetchInTransitAccessDivisionLockedWatcher,
  fetchInTransitAccessStateLockedWatcher,
  fetchInTransitAccessUserLockedWatcher,
  fetchInTransitAccessUserStateDivisionRoleWatcher,
} from '@app/screens/inventory-management/pages/in-transit-access/redux/saga';
import {fetchEmployeebydivisionforDropdownWatcher} from '@app/screens/module-specific-utilities/pages/bill-library/redux/saga';

import {
  directoryLoadingHandlerWatcher,
  directoryNavActionHandlerWatcher,
  directoryNavSearchQueryWatcher,
  directoryNavToggleWatcher,
  directoryUpdateCurrentPageWatcher,
  updateDirectoryNavWatcher,
} from '@app/screens/directory-configuration/redux';
import {
  fetchDivisionsWatcher,
  fetchPartyGroupsWatcher,
  updateTabsWatcher,
} from '@app/screens/directory-configuration/pages/tab-management/redux';
import {
  fetchHoUserCategoryWatcher,
  fetchHoUserDepartmentWatcher,
  fetchHoUserDivisionWatcher,
  fetchHoUserListWatcher,
  fetchHoUserRegionWatcher,
  fetchHoUserStateWatcher,
} from '@app/screens/directory-configuration/pages/ho-user/redux';
import {
  fetchMappingFieldsWatcher,
  fetchTabListWatcher,
  updateColumnsWatcher,
} from '@app/screens/directory-configuration/pages/column-management/redux';
import {
  getSocialMediaCategoryWatcher,
  updateSocialMediaListWatcher,
} from '@app/screens/directory-configuration/pages/social media/redux';
import {fetchDepartmentListWatcher} from '@app/screens/directory-configuration/pages/department-management/redux';
import {fetchHoContactCategoryWatcher} from '@app/screens/directory-configuration/pages/ho-contact-category/redux';
import {fetchSocialMediaCategoryListWatcher} from '@app/screens/directory-configuration/pages/social-media-category/redux/saga';
import {
  unmappedActivityListWatcher,
  mappedActivityListWatcher,
  saveActivityMappingWatcher,
  fetchDivisionMappingWatcher,
  fetchActivitiesWatcher,
  saveActivitiesWatcher,
} from '@app/screens/configuration-management/pages/activity-mapping/redux';
import {
  fetchCutOffDateConfigurationWatcher,
  fetchCutOffDatesWatcher,
} from '@app/screens/incentive-configuration/pages/cut-off/redux';
import {
  fetchCutOffDateConfigurationEditDataWatcher,
  fetchYearlyQuarterCutOffDatesWatcher,
} from '@app/screens/incentive-configuration/pages/cut-off/redux/saga';
import {
  fetchSalesTransferConfigurationWatcher,
  getSalesTransferConfigurationTypesWatcher,
} from '@app/screens/incentive-configuration/pages/sales-transfer-configuration/redux';
import {
  getSalesTransferConfigurationDivisionTypesWatcher,
  getSalesTransferConfigurationStateTypesWatcher,
  fetchSalesTransferConfigurationQuarterWatcher,
  fetchSalesTransferConfigurationConWatcher,
} from '@app/screens/incentive-configuration/pages/sales-transfer-configuration/redux/saga';
import {
  fetchChallanShortQtyApprovalDataListWatcher,
  fetchVoucherDetailsListWatcher,
  fetchChallanShortQtyApprovalWatcher,
  fetchChallanShortQtyRejectWatcher,
  FetchAutoSuggestionChallanWatcher,
} from '@app/screens/inventory-management/pages/ChallanShortQtyApproval/redux/saga';

export function* rootSaga() {
  /**
   *
   *  Register watchers
   *
   */
  const sagas: any = [
    fetchRoutesDataWatcher,
    fetchUserInfoWatcher,
    fetchAdminUsersDataWatcher,
    fetchAccessGroupMasterWatcher,
    fetchAccessGroupDropDownWatcher,
    fetchDivisionDropdownWatcher,
    fetchStateDropdownWatcher,
    editAdminUserWatcher,
    addAdminUserWatcher,
    disableAdminUserWatcher,
    fetchAdminUsersAutoSuggestDataWatcher,
    fetchAdminAddUsersAutoSuggestDataWatcher,
    fetchCannotAccessModalUsersWatcher,
    applyFilterWatcher,
    applySetDefaultFilterWatcher,
    fetchAccessGroupsAutoSuggestDataWatcher,
    editAccessGroupWatcher,
    disableAccessGroupWatcher,
    addAccessGroupWatcher,
    accessGroupMenuItemsWatcher,
    fetchPlanAndMeetStatusListWatcher,
    updateMTPStatusWatcher,
    fetchNotificationsWatcher,
    fetchMTPRecommendationsWatcher,
    fetchHqsWatcher,
    filterBasedDownloadExcelWatcher,
    fetchPreferredFiltersWatcher,
    fetchMTPUnlockReasonsWatcher,
    markNotificationReadWatcher,
    enableDCRWatcher,
    fetchDcrUnlockRequestListWatcher,
    fetchDCRUnlockReasonsWatcher,
    approveDCRUnlockRequestWatcher,
    fetchUserAppSettingsWatcher,
    fetchPlanAndMeetHierarchyWatcher,
    HierarchyBasedDownloadExcelWatcher,
    fetchDCRHqsWatcher,
    dcrDownloadExcelWatcher,
    fetchPartiesBySpidWatcher,
    fetchPartyDetailBySpidWatcher,
    fetchCloningPeriodWatcher,
    transferPositionUpdateWatcher,
    fetchPositionCloningListWatcher,
    clonePositionUpdateWatcher,
    fetchApprovalWorkflowListWatcher,
    fetchMasterConfigDataWatcher,
    createApprovalResponseWatcher,
    BulkGSPUpdateWatcher,
    updateMinApproverConfigWatcher,
    incentiveNavToggleWatcher,
    incentiveNavSearchQueryWatcher,
    incentiveNavActionHandlerWatcher,
    incentiveNavStateLisWatcher,
    incentiveNavDivisionListWatcher,
    incentiveUpdateCurrentPageWatcher,
    fetchUnlockRequestWatcher,
    getUnlockRequestTypesWatcher,
    fetchYearlyQuartersWatcher,
    fetchFromHQWatcher,
    fetchToHQWatcher,
    fetchSLMWatcher,
    fetchSalesTransferWatcher,
    fetchInventoryCnfReturnWatcher,
    fetchInventoryIntransistChallansWatcher,
    fetchAutoSuggestionWatcher,
    fetchInTransitFilteredDataWatcher,
    postIntransitChallanOpenCloseWatcher,
    enableDisableIntransitChallansWatcher,
    fetchVoucherNumberDataWatcher,
    fetchCNFStatesAndDivisionsWatcher,
    fetchCNFHeadquartersWatcher,
    fetchCnfAutoSuggestionWatcher,
    fetchFilteredDataWatcher,
    fetchCnfReturnItemsFilterWatcher,
    fetchApprovedItemsWatcher,
    fetchRecievedChallansDataWatcher,
    fetchRejectItemsWatcher,
    fetchApproveReturnItemsAutoSuggestionWatcher,
    fetchInventoryConfiguratorDivisionWatcher,
    fetchInventoryConfiguratorCustomerTypeWatcher,
    fetchInventoryConfiguratorRegionHeirarcyWatcher,
    fetchInventoryConfiguratorDoctorSpecialityWatcher,
    fetchInventoryConfiguratorDoctorQualificationWatcher,
    fetchInventoryConfiguratorSpecCategoryWatcher,
    fetchInventoryConfiguratorClassCodeWatcher,
    fetchInventoryConfiguratorRolesWatcher,
    fetchInventoryConfiguratorItemCategoryWatcher,
    fetchInventoryConfiguratorItemCodeWatcher,
    fetchInventoryConfiguratorSetValueWatcher,
    fetchInventoryConfiguratorQualificationWatcher,
    modifyDCRRequestWatcher,
    webAccessUnlockWatcher,
    getWebAccessStatusWatcher,
    fetchPositionTransferListWatcher,

    validatePartyTransferWatcher,
    fetchInventoryApproveReturnWatcher,
    fetchApproveStatesAndDivisionsWatcher,
    fetchApproveHeadquartersWatcher,
    fetchApproveFilteredDataWatcher,
    fetchApproveReturnItemsFilterWatcher,
    fetchApprovedReturnItemsWatcher,
    fetchApproveRejectItemsWatcher,
    fetchExpectedUtilizatonDateSkuAutoSuggestionWatcher,
    fetchExpectedUtilizatonDaysWatcher,
    fetchNewExpectedUtilizatonDaysWatcher,
    fetchItemCategoryWatcher,
    fetchInventoryDeleteRolesWatcher,
    fetchInventorySaveRolesWatcher,
    fetchInventoryDivisionWatcher,
    fetchInventoryRolesPage2Watcher,
    fetchInventorySaveRolesPage2Watcher,
    fetchInventoryDeleteRolesPage2Watcher,
    fetchInventoryPartyTypeWatcher,
    validatePartyTransferWatcher,
    fetchEmployeebydivisionforDropdownWatcher,
    fetchExportFileCreatorWatcher,
    fetchDailyExpensePopupReadOnlyDataWatcher,
    fetchDailyExpensePopupTADADataWatcher,
    fetchDailyExpensePopupTADASaveDataWatcher,
    fetchMiscSaveWatcher,
    deductDaAmountWatcher,

    updateDirectoryNavWatcher,
    fetchInTransitAccessDivisionStateWatcher,
    fetchInTransitAccessUserWatcher,
    fetchInTransitAccessDivisionLockedWatcher,
    fetchInTransitAccessStateLockedWatcher,
    fetchInTransitAccessUserLockedWatcher,
    fetchInTransitAccessUserStateDivisionRoleWatcher,
    fetchChallanShortQtyApprovalDataListWatcher,
    updateDirectoryNavWatcher,
    updateTabsWatcher,
    directoryNavActionHandlerWatcher,
    fetchDivisionsWatcher,
    fetchPartyGroupsWatcher,
    directoryLoadingHandlerWatcher,
    updateColumnsWatcher,
    fetchTabListWatcher,
    fetchMappingFieldsWatcher,
    fetchHoContactCategoryWatcher,
    directoryNavSearchQueryWatcher,
    fetchHoUserCategoryWatcher,
    fetchHoUserDepartmentWatcher,
    fetchHoUserDivisionWatcher,
    fetchHoUserStateWatcher,
    fetchHoUserRegionWatcher,
    fetchHoUserListWatcher,
    fetchDepartmentListWatcher,
    directoryNavToggleWatcher,
    updateSocialMediaListWatcher,
    getSocialMediaCategoryWatcher,
    fetchSocialMediaCategoryListWatcher,
    directoryUpdateCurrentPageWatcher,
    fetchPlanAndMeetStatusListWatcher,
    unmappedActivityListWatcher,
    mappedActivityListWatcher,
    saveActivityMappingWatcher,
    fetchActivitiesWatcher,
    fetchDivisionMappingWatcher,
    approveExpenseStatusWatcher,
    saveActivitiesWatcher,

    fetchCutOffDateConfigurationWatcher,
    fetchYearlyQuarterCutOffDatesWatcher,
    fetchCutOffDatesWatcher,
    getSalesTransferConfigurationTypesWatcher,
    fetchSalesTransferConfigurationWatcher,
    getSalesTransferConfigurationDivisionTypesWatcher,
    getSalesTransferConfigurationStateTypesWatcher,
    fetchSalesTransferConfigurationQuarterWatcher,
    fetchSalesTransferConfigurationConWatcher,
    fetchCutOffDateConfigurationEditDataWatcher,
    directoryNavFilterIconWatcher,
    fetchPoolWatcher,
    fetchFromPoolWatcher,
    fetchGroupPoolWatcher,
    fetchSLMSalesDistributionWatcher,
    fetchPoolSalesDistributionWatcher,
    incentiveSaveQueryWatcher,
    fetchSalesTransferGoBtnWatcher,
    fetchSalesDistributionListDataWatcher,
    fetchSalesDistributionRemarksDataWatcher,
    fetchDownloadExcelURLSalesTransferWatcher,
    fetchDownloadExcelURLSalesDistributionWatcher,
    saveActivitiesWatcher,
    fetchVoucherDetailsListWatcher,
    fetchChallanShortQtyApprovalWatcher,
    fetchChallanShortQtyRejectWatcher,
    FetchAutoSuggestionChallanWatcher,
    fetchItemCategoryUserWatcher,
    fetchInventoryRulesActiveInActiveWatcher,
    fetchInventoryRulesActiveWatcher,
    fetchInventoryRulesInActiveWatcher,
  ];

  /**
   * keep everything (e.g., child tasks) alive
   *
   **/
  yield all(
    sagas.map((saga: any) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {}
        }
      }),
    ),
  );
}
