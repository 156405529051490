import {Box, styled} from '@mui/material';

export const GoButton = styled(Box)(({theme}) => ({
  border: `1px solid ${
    theme.palette.mode === 'light' ? 'rgba(149, , 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
}));

export const Styles = {
  filterHeaderBody: {
    // width: '95%',
    // margin: '5',
    backgroundColor: '#f5f5f5',
    borderRadius: 10,
    padding: '15px 5px 25px 15px',
    // height: 150,
  },
  filterBox: {
    // display: 'flex',
    // justifyContent: 'space-between',
    // // flexGrow: 1
  },
  inputCell: {
    margin: '0 auto',
    width: '100%',
  },
  goButton: {
    fontFamily: ['Poppins'].join(','),
    fontSize: 12.7,
    padding: '11px 54px',
    fontWeight: '600',
    cursor: 'pointer',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    backgroundColor: '#322b7c',
    color: 'white',
    border: 0,
  },
  placeHolder: {
    color: '#1C19393B',
    fontStyle: 'normal',
  },
};
