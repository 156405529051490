// MultiSelectDropDown.js
import React, {useCallback, useEffect, useState} from 'react';
import {
  Checkbox,
  FormControl,
  InputAdornment,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import {Box} from '@mui/system';
import {Styles} from './style';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
let temp = [];
const MultiSelectDropDown = props => {
  const {
    isSelectedValue,
    dataList,
    titleName,
    isSelected,
    isSearchable,
    width,
    styles,
    placeHolder,
  } = props;

  const [divisionName, setDivisionName] = useState([]);
  const [selectDivisionAll, setSelectDivisionAll] = useState(false);
  const [searchtxt, setSearcTxt] = useState('');
  const [divisionListArr, setDivisionListArr] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState([]);

  const renderStateValue = useCallback(selected => {
    if (selected.length === 0) {
      return <em style={Styles.placeHolder}> {placeHolder ? placeHolder : 'Select'}</em>;
    }

    return selected.join(', ');
  }, []);

  const handleDivision = useCallback(
    event => {
      const {
        target: {value},
      } = event;

      let text = typeof value === 'string' ? value.split(',') : value


      if(text?.length === 1 && text[0] === undefined){
        setSelectDivisionAll(!selectedDivision)
      }else {
      setDivisionName(text);

      // setDivisionName(typeof value === 'string' ? value.split(',') : value);

      isSelectedValue(text);
      if (selectedDivision?.length !== event.target.value?.length) {
        setSelectDivisionAll(false);
        setSelectedDivision(event.target.value);
      }

      if (dataList?.length === event.target.value?.length) {
        setSelectDivisionAll(true);
        setSelectedDivision(event.target.value);
      }
    }
    },
    [selectedDivision, selectDivisionAll, divisionName, dataList],
  );

  const handleIsSelectedAllDivision = () => {
    if (selectDivisionAll) {
      setSelectDivisionAll(false);
    } else {
      setSelectDivisionAll(true);
    }
  };

  useEffect(() => {
    let temp = [];

    if (selectDivisionAll) {
      dataList?.forEach(element => {
        temp.push(element.label);
      });
      setSelectedDivision(temp);
      setDivisionName(temp);
      isSelectedValue(temp);
    }

    if (
      !selectDivisionAll &&
      selectedDivision?.length - 1 === dataList?.length
    ) {
      setDivisionName([]);
      isSelectedValue(temp);
    }
  }, [selectDivisionAll]);

  useState(() => {
    if (isSelected?.length > 0) {
      setDivisionName(isSelected);
      if (isSelected?.length === dataList?.length) {
        setSelectDivisionAll(true);
      }
    }
  }, [isSelected]);

  const handleTextFiled = useCallback(e => {
    let temp = [...dataList];
    setSearcTxt(e.target.value);
    let searchdata = temp?.filter(val => {
      return val?.label?.toLowerCase().includes(e.target.value?.toLowerCase());
    });

    setDivisionListArr(searchdata);
  }, []);

  const clearSearch = useCallback(() => {
    setSearcTxt('');
    setDivisionListArr(dataList);
  }, [divisionListArr]);

  useEffect(() => {
    temp = JSON.parse(JSON.stringify(dataList));
    setDivisionListArr(JSON.parse(JSON.stringify(dataList)));
  }, [dataList]);

  return (
    <FormControl sx={{width: width ? width : 200}}>
      <Select
        multiple
        value={divisionName}
        onChange={handleDivision}
        renderValue={renderStateValue}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: width ? width : 200,
            },
          },
        }}
        displayEmpty
        sx={{backgroundColor: '#FFFFFF'}}
      >
        {isSearchable ? (
          <ListSubheader>
            <TextField
              size="small"
              value={searchtxt}
              autoFocus
              placeholder="search..."
              fullWidth
              sx={{padding: 0}}
              InputProps={{
                style: {
                  height: 30,
                  fontSize: 11,
                  paddingLeft: 2,
                },
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{padding: 0, marginRight: -2}}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),

                endAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{cursor: 'pointer', padding: 0, margin: 0}}
                    onClick={clearSearch}
                  >
                    <ClearIcon sx={{fontSize: 15}} />
                  </InputAdornment>
                ),
              }}
              onChange={handleTextFiled}
              onKeyDown={e => {
                if (e.key !== 'Escape') {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
        ) : null}
        {divisionListArr?.length > 0 && (
          <MenuItem onChange={handleIsSelectedAllDivision}>
            <Checkbox
              value={'Select All'}
              checked={selectDivisionAll}
              inputProps={{'aria-label': 'Select All'}}
            />
            <ListItemText
              primary={
                <Typography sx={Styles.listItemText}>
                  {selectDivisionAll ? 'UnSelect All' : 'Select All'}
                </Typography>
              }
            />
          </MenuItem>
        )}
        {divisionListArr?.map(val => (
          <MenuItem key={val.value} value={val.label}>
            <Checkbox checked={divisionName.indexOf(val.label) > -1} />
            <ListItemText
              primary={
                <Typography sx={Styles.listItemText}>{val?.label}</Typography>
              }
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelectDropDown;
