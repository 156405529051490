import {Box, styled} from '@mui/material';

export const Styles = {
  text: {
    marginLeft: 10,
    fontSize: 12,
    color: '#322b7c',
    fontWeight: '600',
  },
  listItemText: {
    color: '#1e1c3b',
    fontWeight: 'normal',
    fontSize: '0.9rem',
    marginLeft: 1,
  },
  placeHolder: {
    color: '#1C19393B',
    fontStyle: 'normal',
  },
};
