import {filterStateActions} from '@app/components/right-sidebar/redux';
import {routeStateActions} from '@app/router/redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import {useStyles} from '@app/screens/Styles/style';
import {appSliceActions} from '@app/store/reducers/appSlice';
import {Container} from '@mui/system';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {GenerateReportGrid} from '../../components/generate-report/generate-report-grid';
import {generateReportStateSelector} from './redux/selectors';
import {
  fetchGenerateReportCreator,
  generateReportStateActions,
} from './redux/slice';

export const GenerateReport = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const generateReportGrid = useSelector(
    generateReportStateSelector.getGenerateReport(),
  );

  useEffect(() => {
    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.commonheader,
      ),
    );
    dispatch(
      routeStateActions.setNavbarComponentHeading(
        <div className={classes.navbarComponentHeadingEmpty}>
          Generate Report
        </div>,
      ),
    );
    dispatch(generateReportStateActions.setIsGenerateReport(true));
    dispatch(fetchGenerateReportCreator());
    return () => {
      dispatch(routeStateActions.setNavbarComponentName(null));
      dispatch(filterStateActions.setFilterState({}));
      dispatch(generateReportStateActions.setIsGenerateReport(false));
      dispatch(generateReportStateActions.setGenerateReportPageNo(0));
      dispatch(
        appSliceActions.setExcelData({
          data: [],
          headers: [],
        }),
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div>
      <Container maxWidth={false}>
        <GenerateReportGrid generateReportgridData={generateReportGrid} />
      </Container>
    </div>
  );
};
