import {API_PATH, ERROR, HTTP_OK} from '@app/common/constants';
import {mapActivityTypes} from '@app/helper/activity-mapping';
import NetworkService from '@app/services/network/NetworkService';
import {call, takeLatest, put} from '@redux-saga/core/effects';
import {
  mappedActivityListCreatorTypeName,
  activityMappingStateActions,
  saveResponseListCreatorTypeName,
  unmappedActivityListCreatorTypeName,
  divisionMappingCreatorTypeName,
  fetchActivitiesListCreatorTypeName,
  saveActivitiesCreatorTypeName,
} from './index';

/**
 * Fetch Mapped Activity List
 */
export function* mappedActivityListWatcher() {
  yield takeLatest(mappedActivityListCreatorTypeName, mappedActivityListWorker);
}

/**
 * Fetch Un-Mapped Activity List
 */
export function* unmappedActivityListWatcher() {
  yield takeLatest(
    unmappedActivityListCreatorTypeName,
    unmappedActivityListWorker,
  );
}

/**
 * Fetch Un-Mapped Activity List
 */
export function* saveActivityMappingWatcher() {
  yield takeLatest(saveResponseListCreatorTypeName, saveActivityMappingWorker);
}
/* Fetch Division Configuration Data
 */
export function* fetchDivisionMappingWatcher() {
  yield takeLatest(divisionMappingCreatorTypeName, fetchDivisionMappingWorker);
}

/**
 * Fetch Activities Data
 */
export function* fetchActivitiesWatcher() {
  yield takeLatest(fetchActivitiesListCreatorTypeName, fetchActivitiesWorker);
}

/**
 * Save Activities
 */
export function* saveActivitiesWatcher() {
  yield takeLatest(saveActivitiesCreatorTypeName, saveActivitiesWorker);
}

export function* mappedActivityListWorker(): any {
  try {
    const url = API_PATH.activityMapping.mappedActivityList;
    yield put(activityMappingStateActions.setMappedLoader(true));
    const response = yield call(NetworkService.get, url, {}, {});
    if (response?.status === HTTP_OK) {
      yield put(activityMappingStateActions.setWsfaActivityList(response.data));
    }
    yield put(activityMappingStateActions.setMappedLoader(false));
  } catch (error) {}
}

export function* unmappedActivityListWorker(): any {
  try {
    const url = API_PATH.activityMapping.unmappedActiviytList;
    yield put(activityMappingStateActions.setUnMappedLoader(true));

    const response = yield call(NetworkService.get, url, {}, {});
    if (response?.status === HTTP_OK) {
      const updatedData = response.data?.transferFailedActivities?.map(
        (obj: any, i: number) => {
          return {...obj, indexKey: i};
        },
      );
      yield put(
        activityMappingStateActions.setUnMappedActivitiesList({
          ...response.data,
          transferFailedActivities: updatedData,
        }),
      );
    }
    yield put(activityMappingStateActions.setUnMappedLoader(false));
  } catch (error) {}
}

export function* saveActivityMappingWorker(action: any): any {
  try {
    const url = API_PATH.activityMapping.saveActivityMapping;
    const response = yield call(NetworkService.post, url, action.payload);
    if (response?.status === HTTP_OK) {
      yield put(activityMappingStateActions.setSaveResponse(response.data));
    }
  } catch (error) {}
}

export function* fetchActivitiesWorker(): any {
  try {
    const url = API_PATH.activityMapping.activitiesList;
    const response = yield call(NetworkService.get, url, {}, {});
    if (response?.status === HTTP_OK) {
      const activitiesData = {
        ...response.data,
        activityList: mapActivityTypes(response.data),
      };
      yield put(activityMappingStateActions.setActivitiesList(activitiesData));
      yield put(
        activityMappingStateActions.setCloneActivitiesList(activitiesData),
      );
    }
  } catch (error) {}
}

export function* fetchDivisionMappingWorker(action: any): any {
  try {
    const {activityId, activityTypeId} = action.payload;
    const url = `${API_PATH.activityMapping.divisionMapping}/${activityId}/${activityTypeId}`;
    const response = yield call(NetworkService.get, url, {}, {});
    if (response?.status === HTTP_OK) {
      yield put(
        activityMappingStateActions.setDivisionMappingData(response.data),
      );
    }
  } catch (error) {}
}

export function* saveActivitiesWorker(action: any): any {
  try {
    const url = API_PATH.activityMapping.saveActivities;
    const response = yield call(NetworkService.post, url, action.payload);
    if (response?.status === HTTP_OK) {
      const data = {
        response: response.data,
        isNewActivity: action?.payload?.activityID ? false : true,
      };
      yield put(activityMappingStateActions.setCreateActivityResponse(data));
    } else if (response?.status === ERROR) {
      const data = {
        response: false,
        error: response?.data?.details?.[0]?.message || 'Something went wrong!',
      };
      yield put(activityMappingStateActions.setCreateActivityResponse(data));
    }
  } catch (error) {}
}
