import {routeStateActions} from '@app/router/redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {incentiveActions, incentiveUpdateCurrentPageCreator} from '../../redux';
import {
  actions,
  fetchSalesTransferGoBtnCreator,
  fetchdDownloadExcelURLSalesDistributionCreator,
  incentivePageLists,
} from '../../redux/slice';
import FilterHeader from './FilterHeader';
import SalesDistributionTable from './SalesDistributionTable';
import {incentiveSelector} from '../../redux/selectors';
import {Box, CircularProgress} from '@mui/material';

interface Filterdataobj {
  staffPositionId: number;
  divisionId: number;
  quarterId: number;
  groupPoolId: number;
  poolId: number;
}

interface Quater {
  label: string;
  value: number;
}

const initialFilterReq = {
  staffPositionId: 0,
  divisionId: 0,
  quarterId: 0,
  groupPoolId: 0,
  poolId: 0,
};

const initialQuarter = {
  label: '',
  value: 0,
};

function SalesDistribution() {
  const dispatch = useDispatch();
  const salesDistributionDataList = useSelector(
    incentiveSelector.getSalesDistributionList(),
    shallowEqual,
  );
  dispatch(fetchSalesTransferGoBtnCreator({isEdited: false}));
  const [filterReq, setFilterReq] = useState<Filterdataobj>(initialFilterReq);
  const [selectedQuater, setSelectedQuater] = useState<Quater>(initialQuarter);
  const loading = useSelector(incentiveSelector.getLoadingSalesTransferData());
  useEffect(() => {
    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.incentiveConfiguration,
      ),
    );

    dispatch(
      incentiveActions.updateNavData({
        title: 'Sales Distribution',
        download: {
          title: 'Download',
          type: actions.downloadSalesDistribution,
          filter: 'filter',
          save: 'Save',
          refresh: 'Refresh',
          submit: 'Submit',
        },
      }),
    );
    dispatch(
      incentiveUpdateCurrentPageCreator(incentivePageLists.salesDistribution),
    );

    return () => {
      dispatch(routeStateActions.setNavbarComponentName(null));
      dispatch(incentiveActions.clearSalesTransferState());
    };
  }, []);

  const getfilterData = useCallback(
    (val: Filterdataobj, quarter: any) => {
      setFilterReq(val);
      setSelectedQuater(quarter);
    },
    [filterReq],
  );

  useEffect(() => {
    const params = {
      staffPositionId: 0,
      quarterId: 0,
      groupPoolId: 0,
      poolId: 0,
      searchKey: '',
      divisionId: 0,
    };
    dispatch(fetchdDownloadExcelURLSalesDistributionCreator(params));
  }, []);

  return (
    <>
      <FilterHeader filterData={getfilterData} />

      {loading ? (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '60vh',
          }}
        >
          <CircularProgress
            sx={{color: 'primary.main', margin: '0 auto'}}
            disableShrink
          />
        </Box>
      ) : (
        salesDistributionDataList && (
          <SalesDistributionTable
            salesDistributionDataList={salesDistributionDataList}
            filterDataReq={filterReq}
            quarter={selectedQuater}
          />
        )
      )}
    </>
  );
}

export default SalesDistribution;
