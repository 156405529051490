import {API_PATH} from '@app/common/constants';
import {authTokenActions} from '@app/screens/generic/Login/redux';
import {ToasterService} from '@app/services';
import NetworkService from '@app/services/network/NetworkService';
import {ToastType} from '@app/services/toaster';
import {call, takeLatest, put} from '@redux-saga/core/effects';
import {
  appSliceActions,
  fetchCannotAccessModalUsersCreatorTypeName,
  fetchNotificationsCreatorTypeName,
  fetchUserInfoCreatorTypeName,
  markNotificationTypeName,
  fetchUserAppSettingsCreatorTypeName,
} from '../reducers/appSlice';

/**
 * Employee Details Watcher
 */
export function* fetchUserInfoWatcher() {
  yield takeLatest(fetchUserInfoCreatorTypeName, fetchUserInfoWatcherWorker);
}
export function* markNotificationReadWatcher() {
  yield takeLatest(markNotificationTypeName, markNotificationReadWorker);
}
export function* fetchUserAppSettingsWatcher() {
  yield takeLatest(
    fetchUserAppSettingsCreatorTypeName,
    fetchUserAppSettingsWatcherWorker,
  );
}
/**
 * Employee Details Worker
 */
export function* fetchUserInfoWatcherWorker(): any {
  try {
    yield put(appSliceActions.setAppLoading(true));
    const url = `${API_PATH.main.userInfo}`;
    const userInfo = yield call(NetworkService.post, url, {});
    if (userInfo.status === 200) {
      yield put(
        appSliceActions.setIsEditAccessAvailable(
          (userInfo?.data &&
            userInfo?.data[0] &&
            userInfo?.data[0].isWriteAccess) ||
            false,
        ),
      );

      yield put(
        appSliceActions.setUserInfo(
          (userInfo?.data && userInfo?.data[0]) || {},
        ),
      );
    } else {
      if (userInfo.status === 400 && userInfo.data === 'Unauthorized user') {
        yield put(authTokenActions.setIsAuthorized(false));
        ToasterService.showToaster('Unauthorized user!', ToastType.ERROR);
      }
    }
    yield put(appSliceActions.setAppLoading(false));
  } catch (error) {
    yield put(appSliceActions.setUserInfoError(true));
    yield put(appSliceActions.setUserInfoErrorMessage(error));
    setTimeout(function* () {
      yield put(appSliceActions.clearError());
    }, 5000);
  }
}

/**
 * Employee Details Watcher
 */
export function* fetchCannotAccessModalUsersWatcher() {
  yield takeLatest(
    fetchCannotAccessModalUsersCreatorTypeName,
    fetchCannotAccessModalUsersWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchCannotAccessModalUsersWorker(): any {
  try {
    yield put(appSliceActions.setShowCannotAccessModalLoading(true));
    const url = `${API_PATH.cannotAccess}`;
    const cannotAccessModalUsers = yield call(
      NetworkService.getOpen,
      url,
      {},
      {},
    );
    yield put(
      appSliceActions.setCannotAccessModalUsers(
        cannotAccessModalUsers?.data || [],
      ),
    );
    yield put(appSliceActions.setShowCannotAccessModalLoading(false));
  } catch (error) {
    yield put(appSliceActions.setShowCannotAccessModalLoading(false));
  }
}

/**
 * Notifications Worker
 */
export function* fetchNotificationsWatcher() {
  yield takeLatest(fetchNotificationsCreatorTypeName, fetchNotificationsWorker);
}

/**
 * Notifications Watcher
 */
export function* fetchNotificationsWorker(): any {
  try {
    const url = API_PATH.main.notifications;
    const response = yield call(NetworkService.get, url, {}, {}); // fix this

    yield put(appSliceActions.setNotifications(response.data));
  } catch (error) {
    yield put(appSliceActions.setNotifications([]));
  }
}

export function* markNotificationReadWorker(action: any): any {
  const notificationId = action.payload;
  let url = API_PATH.main.markAsRead;

  try {
    const response = yield call(NetworkService.put, url, [notificationId]);
    if (response?.status === 200) {
      yield put(appSliceActions.updateNotificationReadStatus(action.payload));
    }
  } catch (error) {}
}
export function* fetchUserAppSettingsWatcherWorker(): any {
  try {
    const url = API_PATH.main.appSettings;
    const response = yield call(NetworkService.get, url, {}, {});

    yield put(appSliceActions.setAppSettings(response.data));
  } catch (error) {
    yield put(appSliceActions.setAppSettings([]));
  }
}
