import {createSelector} from '@reduxjs/toolkit';

export const getConfigManagementStateSelector = (stateName: string) => {
  return createSelector(
    [(state: any) => state.ConfigurationManagement[stateName]],
    state => state,
  );
};
export const ConfigurationManagementSelector = {
  getconfigurationManagementPage: () => {
    return createSelector(
      [(state: any) => state.ConfigurationManagement.currentPageExpenseDate],
      currentPageExpenseDate => currentPageExpenseDate,
    );
  },

  getExpenseTypeSettingCreateValidation: () => {
    return createSelector(
      [
        (state: any) =>
          state.ConfigurationManagement.expenseTypeSettingCreateValidation,
      ],
      expenseTypeSettingCreateValidation => expenseTypeSettingCreateValidation,
    );
  },
  getEditExpenseTypeSetting: () => {
    return createSelector(
      [(state: any) => state.ConfigurationManagement.editExpenseTypeSetting],
      editExpenseTypeSetting => editExpenseTypeSetting,
    );
  },
  getExpenseTypeSettingSnaShot: () => {
    return createSelector(
      [(state: any) => state.ConfigurationManagement.expenseTypeSettingSnaShot],
      expenseTypeSettingSnaShot => expenseTypeSettingSnaShot,
    );
  },
  getIsActiveState: () => {
    return createSelector(
      [(state: any) => state.ConfigurationManagement.isActiveState],
      isActiveState => isActiveState,
    );
  },
  getExpenseType: () => {
    return createSelector(
      [(state: any) => state.ConfigurationManagement.expenseType],
      expenseType => expenseType,
    );
  },
  getExpenseTypeSetting: () => {
    return createSelector(
      [(state: any) => state.ConfigurationManagement.expenseTypeSetting],
      expenseTypeSetting => expenseTypeSetting,
    );
  },
  getExpenseTypeLoading: () => {
    return createSelector(
      [(state: any) => state.ConfigurationManagement.ExpenseTypeLoading],
      ExpenseTypeLoading => ExpenseTypeLoading,
    );
  },
  getExpenseCategory: () => {
    return createSelector(
      [(state: any) => state.ConfigurationManagement.ExpenseCategory],
      ExpenseCategory => ExpenseCategory,
    );
  },
  getExpenseTypeSettingHq: () => {
    return createSelector(
      [(state: any) => state.ConfigurationManagement.expenseTypeSettingHq],
      expenseTypeSettingHq => expenseTypeSettingHq,
    );
  },
  getaddExpenseConfigManagementPopupVisible: () => {
    return createSelector(
      [
        (state: any) =>
          state.ConfigurationManagement.addExpenseConfigManagementPopupVisible,
      ],
      addExpenseConfigManagementPopupVisible =>
        addExpenseConfigManagementPopupVisible,
    );
  },
  getEditExpenseConfigManagementPopupVisible: () => {
    return createSelector(
      [
        (state: any) =>
          state.ConfigurationManagement.editExpenseConfigManagementPopupVisible,
      ],
      editExpenseConfigManagementPopupVisible =>
        editExpenseConfigManagementPopupVisible,
    );
  },
  getExpenseDateLoading: () => {
    return createSelector(
      [(state: any) => state.ConfigurationManagement.ExpenseDateLoading],
      ExpenseDateLoading => ExpenseDateLoading,
    );
  },
  getExpenseTypeDate: () => {
    return createSelector(
      [(state: any) => state.ConfigurationManagement.expenseTypeDate],
      expenseTypeDate => expenseTypeDate,
    );
  },
  getPageNo: () => {
    return createSelector(
      [(state: any) => state.ConfigurationManagement.pageNo],
      pageNo => pageNo,
    );
  },
  getTotalCount: () => {
    return createSelector(
      [(state: any) => state.ConfigurationManagement.totalCount],
      totalCount => totalCount,
    );
  },
  getRowPerPage: () => {
    return createSelector(
      [(state: any) => state.ConfigurationManagement.rowPerPage],
      rowPerPage => rowPerPage,
    );
  },

  getExpenseDateError: () => {
    return createSelector(
      [(state: any) => state.configurationManagement.ExpenseDateError],
      ExpenseDateError => ExpenseDateError,
    );
  },
};
