import {API_PATH} from '@app/common/constants';
import {directoryActions} from '@app/screens/directory-configuration/redux';
import {directoryPageLists} from '@app/screens/directory-configuration/redux/slice';
import {ToasterService} from '@app/services';
import NetworkService from '@app/services/network/NetworkService';
import {ToastType} from '@app/services/toaster';
import {call, put, takeLatest} from 'redux-saga/effects';
import {
  columnManagementActions,
  fetchMappingFieldsCreatorTypeName,
  fetchTabListCreatorTypeName,
  updateColumnsCreatorTypeName,
} from './slice';

/**
 * Update columns watcher
 */
export function* updateColumnsWatcher() {
  yield takeLatest(updateColumnsCreatorTypeName, updateColumnsWorker);
}

/**
 * Update columns worker
 *
 * @param action
 */
function* updateColumnsWorker(action: any): any {
  const {index, isActive, query} = action.payload;
  yield put(
    directoryActions.updateLoading(
      API_PATH.directoryConfiguration.fetchColumns,
    ),
  );
  try {
    const response = yield call(
      NetworkService.get,
      API_PATH.directoryConfiguration.fetchColumns,
      {pageIndex: index, pageSize: 15, isActive, name: query || ''},
      {},
    );
    yield put(directoryActions.updateLoading(null));
    if (index === 1) {
      yield put(
        directoryActions.updateTotalRecords({
          listing: directoryPageLists.columnManagement,
          count: response?.data[0]?.totalRecords || 0,
          pageIndex: index,
        }),
      );
      yield put(columnManagementActions.updateColumns(response?.data));
    } else {
      yield put(
        directoryActions.updateTotalRecords({
          listing: directoryPageLists.columnManagement,
          pageIndex: index,
        }),
      );
      yield put(columnManagementActions.updateNewColumns(response?.data));
    }
  } catch (error) {
    yield put(directoryActions.updateLoading(null));
    ToasterService.showToaster(
      'Something went wrong while fetching admin data!',
      ToastType.ERROR,
    );
  }
}

/**
 * fetch tab list watcher
 */
export function* fetchTabListWatcher() {
  yield takeLatest(fetchTabListCreatorTypeName, fetchTabListWorker);
}

/**
 * fetch tab list worker
 *
 * @param action
 */
function* fetchTabListWorker(action: any): any {
  yield put(
    directoryActions.updateLoading(
      API_PATH.directoryConfiguration.fetchTabList,
    ),
  );
  try {
    const response = yield call(
      NetworkService.get,
      API_PATH.directoryConfiguration.fetchTabList,
      {},
      {},
    );
    yield put(directoryActions.updateLoading(null));
    yield put(columnManagementActions.fetchTabList(response?.data));
  } catch (error) {
    yield put(directoryActions.updateLoading(null));
    ToasterService.showToaster(
      'Something went wrong while fetching admin data!',
      ToastType.ERROR,
    );
  }
}

/**
 * fetch column maaping fields watcher
 */
export function* fetchMappingFieldsWatcher() {
  yield takeLatest(fetchMappingFieldsCreatorTypeName, fetchMappingFieldsWorker);
}

/**
 * fetch tab list worker
 *
 * @param action
 */
function* fetchMappingFieldsWorker(action: any): any {
  const {id} = action.payload;
  yield put(
    directoryActions.updateLoading(
      API_PATH.directoryConfiguration.fetchColumnMappingFields,
    ),
  );
  try {
    const response = yield call(
      NetworkService.get,
      API_PATH.directoryConfiguration.fetchColumnMappingFields,
      {Id: id},
      {},
    );
    yield put(directoryActions.updateLoading(null));
    yield put(columnManagementActions.fetchMappingFields(response?.data));
  } catch (error) {
    yield put(directoryActions.updateLoading(null));
    ToasterService.showToaster(
      'Something went wrong while fetching admin data!',
      ToastType.ERROR,
    );
  }
}
