import Colors from './colors';
import Typography, {fontFamilies} from './typography';

const theme = {
  spacing: (space: any) => {
    /**
     * manage size ratio using the spacing factor
     * scalingFactor helps scale UI elements up/down
     */
    const scalingFactor = 1;
    return space / scalingFactor;
  },
  sizing: {},
  typography: {
    ...Typography,
  },
  colors: {
    ...Colors,
  },
  fonts: {
    ...fontFamilies,
  },
  roundness: 10,
};

export default theme;
