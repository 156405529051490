import {createSelector} from '@reduxjs/toolkit';

const getPositionManagementState = (state: any) =>
  state.positionManagementMaster;

const getShowTransferPartiesModal = createSelector(
  [getPositionManagementState],
  data => {
    return data?.showTransferPartiesModal;
  },
);

const getPartiesList = createSelector([getPositionManagementState], data => {
  const sortedArray = [...data?.partiesList]?.sort((a: any, b: any) => {
    if (a?.name < b?.name) {
      return -1;
    }
    if (a?.name > b?.name) {
      return 1;
    }
    return 0;
  });
  return sortedArray;
});

const getPartyDetails = createSelector([getPositionManagementState], data => {
  return data?.partyDetails;
});

const getPartiesSelectedForTransfer = createSelector(
  [getPositionManagementState],
  data => {
    return data?.partiesSelectedForTransfer;
  },
);

const getCloningTimePeriod = createSelector(
  [getPositionManagementState],
  data => {
    return data?.cloningTimePeriod;
  },
);

const getPositionType = createSelector([getPositionManagementState], data => {
  return data?.positionType;
});

const getTransferPositionResponseType = createSelector(
  [getPositionManagementState],
  data => {
    return data?.transferPositionResponse;
  },
);

const getPositionCloneListGridOptions = createSelector(
  [getPositionManagementState],
  data => {
    return data?.positionCloneListGridOptions;
  },
);

const getPositionCloneListGridData = createSelector(
  [getPositionManagementState],
  data => {
    return data?.positionCloneListData;
  },
);

const getPositionCloneListRows = createSelector(
  [getPositionManagementState],
  data => {
    return data?.positionCloneListRows;
  },
);

const getClonePositionResponseType = createSelector(
  [getPositionManagementState],
  data => {
    return data?.clonePositionResponse;
  },
);

const getAllSelectedSelector = createSelector(
  [getPositionManagementState],
  data => {
    return data?.allSelected;
  },
);

const getPartiesFetchState = createSelector(
  [getPositionManagementState],
  data => {
    return data?.partiesFetched;
  },
);

const getCloningListResponse = createSelector(
  [getPositionManagementState],
  data => {
    return data?.cloningListResponse;
  },
);

const getPositionTransferListGridData = createSelector(
  [getPositionManagementState],
  data => {
    return data?.positionTransferListData;
  },
);

const getPositionTransferListRows = createSelector(
  [getPositionManagementState],
  data => {
    return data?.positionTransferListRows;
  },
);

const getPositionTransferListGridOptions = createSelector(
  [getPositionManagementState],
  data => {
    return data?.positionTransferListGridOptions;
  },
);

const getTransferListResponse = createSelector(
  [getPositionManagementState],
  data => {
    return data?.transferListResponse;
  },
);

const getValidateTransferResponse = createSelector(
  [getPositionManagementState],
  data => {
    return data?.validateTransferResponse;
  },
);

const getPartiesFilterOptions = createSelector(
  [getPositionManagementState],
  data => {
    return data?.partyFilterOptions;
  },
);

export const positionManagementSelector = {
  getShowTransferPartiesModal: () => getShowTransferPartiesModal,
  getPartiesList: () => getPartiesList,
  getPartyDetails: () => getPartyDetails,
  getPartiesSelectedForTransfer: () => getPartiesSelectedForTransfer,
  getCloningTimePeriod: () => getCloningTimePeriod,
  getPositionType: () => getPositionType,
  getTransferPositionResponse: () => getTransferPositionResponseType,
  getPositionCloneListGridOptions: () => getPositionCloneListGridOptions,
  getPositionCloneListGridData: () => getPositionCloneListGridData,
  getPositionCloneListRows: () => getPositionCloneListRows,
  getClonePositionResponse: () => getClonePositionResponseType,
  getAllSelected: () => getAllSelectedSelector,
  getPartiesFetchState: () => getPartiesFetchState,
  getCloningListResponse: () => getCloningListResponse,
  getPositionTransferListGridData: () => getPositionTransferListGridData,
  getPositionTransferListRows: () => getPositionTransferListRows,
  getPositionTransferListGridOptions: () => getPositionTransferListGridOptions,
  getTransferListResponse: () => getTransferListResponse,
  getValidateTransferResponse: () => getValidateTransferResponse,
  getPartiesFilterOptions: () => getPartiesFilterOptions,
};
