import Typography from '@mui/material/Typography';
import {Box} from '@mui/material';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
};

export const InventoryRoles = () => {
  return (
    <Box sx={styles.container}>
      <Typography
        variant="h6"
        noWrap
        component="div"
        color={'#1c1939'}
        fontStyle={'normal'}
      >
        Inventory Rules
      </Typography>
    </Box>
  );
};
