import {createAction, createSlice} from '@reduxjs/toolkit';
interface InitialStateProps {
  unlockRequestData: {
    id: number;
    tabId: number;
    name: string;
  }[];
}

const initialState: InitialStateProps = {
  unlockRequestData: [],
};

export const unlockRequestCreator = createAction<any>(
  'INCENTIVE/UNLOCK_REQUEST',
);
export const unlockRequestTypeName = unlockRequestCreator(null).type;

export const addUnlockRequestCreator = createAction<any>(
  'INCENTIVE/ADD_UNLOCK_REQUEST',
);
export const addUnlockRequestCreatorTypeName =
  addUnlockRequestCreator(null).type;

export const editUnlockRequestCreator = createAction<any>(
  'INCENTIVE/EDIT_UNLOCK_REQUEST',
);
export const editUnlockRequestCreatorTypeName =
  editUnlockRequestCreator(null).type;

export const getUnlockRequestTypesCreator = createAction<any>(
  'INCENTIVE/GET_UNLOCK_REQUEST_DATA',
);
export const getUnlockRequestTypesCreatorTypeName =
  getUnlockRequestTypesCreator(null).type;

const unlockRequestSlice = createSlice({
  name: 'UNLOCK_REQUEST',
  initialState: initialState,
  reducers: {
    fetchUnlockRequestData: (state, action) => {
      return {
        ...state,
        unlockRequestData: action.payload,
      };
    },
    fetchNewUnlockRequestData: (state, action) => {
      return {
        ...state,
        unlockRequestData: [...state.unlockRequestData, ...action.payload],
      };
    },
  },
});

export const unlockRequestActions = unlockRequestSlice.actions;
export const unlockRequestReducer = unlockRequestSlice.reducer;
