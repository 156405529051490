import {Popper, styled} from '@mui/material';

export const PopperStyledComponent = styled(Popper)(({theme}) => ({
  border: `1px solid ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
}));

export const Styles = {
  labelProps: {
    textField: {
      border: 'transparent',
      background: '#f2f2f2',
      borderRadius: '15px',
      width: '100%',
      padding: '0px',
      opacity: 0.6,
      fontWeight: 'normal',
      fontStyle: 'normal',
      textAlign: 'left',
      fontSize: 12,
    },
  },
};
