import {createSelector} from '@reduxjs/toolkit';
export const routeSelector = {
  getIsRoutesLoading: () => {
    return createSelector(
      [(state: any) => state.routeState.isRoutesLoading],
      (isRoutesLoading: any) => isRoutesLoading,
    );
  },
  getAppRoutes: () => {
    return createSelector(
      [(state: any) => state.routeState.appRoutes],
      (appRoutes: any) => appRoutes,
    );
  },
  getRoutesRenderingError: () => {
    return createSelector(
      [(state: any) => state.routeState.routesRenderingError],
      (routesRenderingError: any) => routesRenderingError,
    );
  },
  getRoutesenderingErrorMessage: () => {
    return createSelector(
      [(state: any) => state.routeState.routesenderingErrorMessage],
      (routesenderingErrorMessage: any) => routesenderingErrorMessage,
    );
  },
  getSideBarOpen: () => {
    return createSelector(
      [(state: any) => state.routeState.sideBarOpen],
      (sideBarOpen: any) => sideBarOpen,
    );
  },
  getNavbarComponentName: () => {
    return createSelector(
      [(state: any) => state.routeState.navbarComponentName],
      (navbarComponentName: any) => navbarComponentName,
    );
  },
  getNavbarComponentHeading: () => {
    return createSelector(
      [(state: any) => state.routeState.navbarComponentHeading],
      (navbarComponentHeading: any) => navbarComponentHeading,
    );
  },
};
