import {call, takeLatest, put} from '@redux-saga/core/effects';
import {API_PATH} from '@app/common/constants';
import NetworkService from '@app/services/network/NetworkService';
import {
  challanShortQtyApprovalActions,
  fetchChallanShortQtyApprovalDataList,
  fetchVoucherDetailsList,
  ChallanQuantitySortApprove,
  ChallanQuantitySortReject,
  FetchAutoSuggestionChallan,
} from './slice';

export function* fetchChallanShortQtyApprovalDataListWatcher() {
  yield takeLatest(
    fetchChallanShortQtyApprovalDataList,
    fetchChallanShortQtyApprovalDataListWorker,
  );
}

export function* fetchChallanShortQtyApprovalDataListWorker(action: any): any {
  try {
    yield put(challanShortQtyApprovalActions.setLoading(true));

    if (action.payload) {
      const url = `${API_PATH.inventoryModule.challanShortQtyApprovalListData}`;
      const response = yield call(
        NetworkService.post,
        url,
        action.payload,
        null,
      );
      if (response?.status === 200) {
        yield put(
          challanShortQtyApprovalActions.setChallanShortQtyApprovalDataList(
            response?.data,
          ),
        );

        yield put(challanShortQtyApprovalActions.setLoading(false));
      } else {
        yield put(challanShortQtyApprovalActions.setLoading(false));
      }
    }
    yield put(challanShortQtyApprovalActions.setLoading(false));
  } catch (e) {
    yield put(challanShortQtyApprovalActions.setLoading(false));
  }
}

export function* fetchVoucherDetailsListWatcher() {
  yield takeLatest(fetchVoucherDetailsList, fetchVoucherDetailsListWorker);
}

export function* fetchVoucherDetailsListWorker(action: any): any {
  try {
    yield put(challanShortQtyApprovalActions.setLoading(true));
    if (action.payload) {
      const {
        payload: {voucherNumber},
      } = action;
      const url = `${API_PATH.inventoryModule.voucherNumberData}/${voucherNumber}`;
      const response = yield call(NetworkService.get, url, {}, {});
      if (response?.status === 200) {
        yield put(
          challanShortQtyApprovalActions.setVoucherDetailsList(response?.data),
        );
        yield put(challanShortQtyApprovalActions.setLoading(false));
      } else {
        yield put(challanShortQtyApprovalActions.setLoading(false));
      }
    }
    yield put(challanShortQtyApprovalActions.setLoading(false));
  } catch (e) {
    yield put(challanShortQtyApprovalActions.setLoading(false));
  }
}

export function* fetchChallanShortQtyApprovalWatcher() {
  yield takeLatest(ChallanQuantitySortApprove, ChallanShortQtyApprovalWorker);
}

export function* ChallanShortQtyApprovalWorker(action: any): any {
  try {
    yield put(challanShortQtyApprovalActions.setLoading(true));

    if (action.payload) {
      const url = `${API_PATH.inventoryModule.challanShortQtyApprove}/${action.payload.userId}`;
      const response = yield call(
        NetworkService.post,
        url,
        action.payload.request,
        null,
      );
      if (response?.status === 200) {
        yield put(challanShortQtyApprovalActions.setIsApproved(response?.data));
        yield put(challanShortQtyApprovalActions.setLoading(false));
      } else {
        yield put(challanShortQtyApprovalActions.setLoading(false));
      }
    }
    yield put(challanShortQtyApprovalActions.setLoading(false));
  } catch (e) {
    yield put(challanShortQtyApprovalActions.setLoading(false));
  }
}

export function* fetchChallanShortQtyRejectWatcher() {
  yield takeLatest(ChallanQuantitySortReject, ChallanShortQtyRejectWorker);
}

export function* ChallanShortQtyRejectWorker(action: any): any {
  try {
    yield put(challanShortQtyApprovalActions.setLoading(true));
    if (action.payload) {
      const url = `${API_PATH.inventoryModule.challanShortQtyReject}/${action.payload.userId}`;
      const response = yield call(
        NetworkService.post,
        url,
        action.payload.request,
        null,
      );

      if (response?.status === 200) {
        yield put(challanShortQtyApprovalActions.setIsRejected(response?.data));
        yield put(challanShortQtyApprovalActions.setLoading(false));
      } else {
        yield put(challanShortQtyApprovalActions.setLoading(false));
      }
    }
  } catch (e) {
    yield put(challanShortQtyApprovalActions.setLoading(false));
  }
}

export function* FetchAutoSuggestionChallanWatcher() {
  yield takeLatest(FetchAutoSuggestionChallan, autoSuggestionChallanWorker);
}

export function* autoSuggestionChallanWorker(action: any): any {
  try {
    yield put(challanShortQtyApprovalActions.setLoading(true));
    if (action.payload) {
      const url = `${API_PATH.inventoryModule.autoSuggestChallan}`;
      const response = yield call(
        NetworkService.post,
        url,
        action.payload,
        null,
      );

      if (response?.status === 200) {
        yield put(
          challanShortQtyApprovalActions.setAutoSuggestionChallan(
            response?.data.searchValue,
          ),
        );
        yield put(challanShortQtyApprovalActions.setLoading(false));
      } else {
        yield put(challanShortQtyApprovalActions.setLoading(false));
      }
    }
  } catch (e) {
    yield put(challanShortQtyApprovalActions.setLoading(false));
  }
}
