import {createSelector} from '@reduxjs/toolkit';

const cutOffDateConfigurationData = (state: any) =>
  state.cutOffDateConfigurationState.cutOffDateConfigurationData;
const yearlyQuarter = (state: any) =>
  state.cutOffDateConfigurationState.yearlyQuarter;
const cutOffDates = (state: any) =>
  state.cutOffDateConfigurationState.cutOffDates;
const cutOffDateConfigurationEditData = (state: any) =>
  state.cutOffDateConfigurationState.cutOffDateConfigurationEditData;

const getCutOffDateConfigurationData = createSelector(
  [cutOffDateConfigurationData],
  _cutOffDateConfigurationData => _cutOffDateConfigurationData,
);
const getYearlyQuarter = createSelector(
  [yearlyQuarter],
  (quarter: any) => quarter,
);
const getCutOffDates = createSelector([cutOffDates], (date: any) => date);
const getCutOffDateConfigurationEditData = createSelector(
  [cutOffDateConfigurationEditData],
  _cutOffDateConfigurationEditData => _cutOffDateConfigurationEditData,
);

export const cutOffDateConfigurationSelector = {
  getCutOffDateConfigurationData: () => getCutOffDateConfigurationData,
  getYearlyQuarter: () => getYearlyQuarter,
  getCutOffDates: () => getCutOffDates,
  getCutOffDateConfigurationEditData: () => getCutOffDateConfigurationEditData,
};
