import {createAction, createSlice} from '@reduxjs/toolkit';
export const expenseTAMasterInitialState = {
  isActiveState: true,
  expenseTAMasterGrid: [],
  addexpenseTAMasterPopupVisible: false,
  expenseTAMasterLoading: false,
  adminUsersError: false,
  adminUsersSuccess: false,
  isExpenseTaMaster: false,
  divisionDropDown: [],
  stateDropDown: [],
  accessGroupDropDown: [],
  tIsActiveState: true,
  expenseTAMasterInAutoSuggest: {},
  searchText: '',
  expenseTAMasterInAutoSuggestFilter: {},
  editExpenseTAMasterPopupVisible: false,
  editExpenseTAMasterValue: [],
  expenseTAMasterSnaShot: [],
  resetNavbarVersion: 0,
  addPopupErrorMessage: [],
  expenseTAMasterNewDataValidation: [],
  page: 0,
  pageSize: 100,
  totalCount: 0,
};
export const fetchExpenseTAMasterGridCreator = createAction<any>(
  'EXPENSE_TA_MASTER_HANDLER/FETCH_EXPENSE_DA_MASTER_GRID',
);
export const fetchExpenseTAMasterGridCreatorTypeName =
  fetchExpenseTAMasterGridCreator(null).type;

export const fetchExpenseTAMasterInAutoSuggestCreator = createAction(
  'EXPENSE_TA_MASTER_HANDLER/FETCH_EXPENSE_DA_MASTER_AUTO_SUGGEST',
);
export const fetchExpenseTAMasterInAutoSuggestCreatorTypeName =
  fetchExpenseTAMasterInAutoSuggestCreator().type;

export const activeInactiveExpenseTAMasterCreator = createAction<any>(
  'USER_MANAGEMENT_HANDLER/ACTIVE_INACTIVE',
);

export const activeInactiveExpenseTAMasterCreatorCreatorTypeName =
  activeInactiveExpenseTAMasterCreator({}).type;

export const fetchAddExpenseTAMasterCreator = createAction<any>(
  'EXPENSE_TA_MASTER_HANDLER/ADD_EXPENSE_TA_MASTER',
);
export const fetchAddExpenseTAMasterCreatorTypeName =
  fetchAddExpenseTAMasterCreator({}).type;

export const editExpenseTAMasterCreator = createAction<any>(
  'EXPENSE_TA_MASTER_HANDLER/EDIT_EXPENSE_TA_MASTER',
);
export const editExpenseTAMasterCreatorTypeName = editExpenseTAMasterCreator(
  {},
).type;

export const AddExpenseValidationTAMasterCreator = createAction<any>(
  'EXPENSE_DA_MASTER_HANDLER/ADD_EXPENSE_VALIDATION_TA_MASTER',
);
export const AddExpenseValidationTAMasterCreatorTypeName =
  AddExpenseValidationTAMasterCreator({}).type;

const expenseTAMasterStateHandler = createSlice({
  name: 'EXPENSE_TA_MASTER_HANDLER',
  initialState: expenseTAMasterInitialState,
  reducers: {
    setExpenseTAMasterStateKeyValue: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        [payload.key]: payload.value,
      };
    },
    setaddExpenseTAMasterPopupVisible: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        addexpenseTAMasterPopupVisible: payload,
      };
    },
    setExpenseTAMasterGrid: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        expenseTAMasterGrid: payload,
      };
    },
    setIsExpenseTaMaster: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        isExpenseTaMaster: payload,
      };
    },
    setExpenseTAMasterLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        expenseTAMasterLoading: payload,
      };
    },
    setAddPopupErrorMessage: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        addPopupErrorMessage: payload,
      };
    },
    setExpenseTAMasterNewDataValidation: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        expenseTAMasterNewDataValidation: payload,
      };
    },
    setExpenseTAMasterInAutoSuggest: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        expenseTAMasterInAutoSuggest: payload,
      };
    },
    setExpenseTAMasterInAutoSuggestFilter: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        expenseTAMasterInAutoSuggestFilter: payload,
      };
    },
    setIsActiveState: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        isActiveState: payload,
      };
    },

    setPage: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        page: payload,
      };
    },
    setPageSize: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        pageSize: payload,
      };
    },
    setSearchText: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        searchText: payload,
      };
    },

    setEditExpenseTAMasterValue: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        editExpenseTAMasterValue: payload,
      };
    },
    setEditExpenseTAMasterPopupVisible: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        editExpenseTAMasterPopupVisible: payload,
      };
    },
    setResetNavbarVersion: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        resetNavbarVersion: payload,
      };
    },
    setExpenseTAMasterSnaShot: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        expenseTAMasterSnaShot: payload,
      };
    },
    setTotalCount: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        totalCount: payload,
      };
    },
  },
});
export const expenseTAMasterStateActions = expenseTAMasterStateHandler.actions;
export const expenseTAMasterStateReducer = expenseTAMasterStateHandler.reducer;
