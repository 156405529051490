import {API_PATH} from '@app/common/constants';
import {NetworkService, ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
import {takeLatest, put, call} from 'redux-saga/effects';
import {
  fetchDivisionCreatorTypeName,
  billLibraryStateActions,
  fetchDesignationByDivisionCreatorTypeName,
  fetchEmployeebydivisionCreatorTypeName,
  fetchHqByDivisionsandDesignationsCreatorTypeName,
  fetchPoolByDivisionsandDesignationsCreatorTypeName,
  fetchCategoryCreatorTypeName,
  fetchStatusCreatorTypeName,
  fetchBillDownloadCreatorTypeName,
  fetchViewHistoryCreatorTypeName,
  reviewSaveCreatorTypeName,
  fetchEmployeebydivisionforDropdownCreatorTypeName,
} from './slice';

/**
 * Employee Details Watcher
 */
export function* fetchDivisionWatcher() {
  yield takeLatest(fetchDivisionCreatorTypeName, fetchDivisionWatcherWorker);
}

/**
 * Employee Details Worker
 */
export function* fetchDivisionWatcherWorker(): any {
  try {
    const url = `${API_PATH.adminUsers.dropdowns.divisions}`;
    const division = yield call(NetworkService.get, url, {}, {});
    yield put(billLibraryStateActions.setDivision(division?.data));
  } catch (error) {}
}

/**
 * Employee Details Watcher
 */
export function* fetchDesignationByDivisionWatcher() {
  yield takeLatest(
    fetchDesignationByDivisionCreatorTypeName,
    fetchDesignationByDivisionWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchDesignationByDivisionWatcherWorker(action: any): any {
  try {
    let divisions = action.payload;
    yield put(billLibraryStateActions.setDesignation([]));
    if (divisions !== undefined) {
      const url = `${API_PATH.billLibrary.dropdown.designationbydivision}${divisions}&accountTypeCode=6`;
      const desigantion = yield call(NetworkService.get, url, {}, {});
      yield put(
        billLibraryStateActions.setDesignation(
          [{divisionId: 0, designationId: 0, designationName: 'ALL'}].concat(
            desigantion?.data,
          ),
        ),
      );
    }
  } catch (error) {}
}

export function* fetchEmployeebydivisionforDropdownWatcher() {
  yield takeLatest(
    fetchEmployeebydivisionforDropdownCreatorTypeName,
    fetchEmployeebydivisionforDropdownWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchEmployeebydivisionforDropdownWatcherWorker(
  action: any,
): any {
  try {
    yield put(billLibraryStateActions.setEmployee([]));
    let url;

    if (action.payload) {
      url = `${API_PATH.billLibrary.dropdown.employeebydivision}${action.payload}`;
    }

    const employee = yield call(NetworkService.get, url, {}, {});
    yield put(billLibraryStateActions.setEmployeeDropDown(employee?.data));
  } catch (error) {}
}

/**
 * Employee Details Watcher
 */
export function* fetchEmployeebydivisionWatcher() {
  yield takeLatest(
    fetchEmployeebydivisionCreatorTypeName,
    fetchEmployeebydivisionWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchEmployeebydivisionWatcherWorker(action: any): any {
  try {
    yield put(billLibraryStateActions.setEmployee([]));
    let url;
    if (
      action.payload.divisionIds !== undefined &&
      action.payload.designationIds !== undefined &&
      action.payload.hqIds !== undefined &&
      action.payload.poolIds !== undefined
    ) {
      url = `${API_PATH.billLibrary.dropdown.employeebydivision}${action.payload.divisionIds}&designations=${action.payload.designationIds}&hqIds=${action.payload.hqIds}&poolIds${action.payload.poolIds}`;
    } else if (
      action.payload.divisionIds !== undefined &&
      action.payload.designationIds !== undefined &&
      action.payload.hqIds !== undefined
    ) {
      url = `${API_PATH.billLibrary.dropdown.employeebydivision}${action.payload.divisionIds}&designations=${action.payload.designationIds}&hqIds=${action.payload.hqIds}`;
    } else if (
      action.payload.divisionIds !== undefined &&
      action.payload.designationIds !== undefined &&
      action.payload.poolIds !== undefined
    ) {
      url = `${API_PATH.billLibrary.dropdown.employeebydivision}${action.payload.divisionIds}&designations=${action.payload.designationIds}&poolIds=${action.payload.poolIds}`;
    } else if (
      action.payload.divisionIds !== undefined &&
      action.payload.hqIds !== undefined
    ) {
      url = `${API_PATH.billLibrary.dropdown.employeebydivision}${action.payload.divisionIds}&hqIds=${action.payload.hqIds}`;
    } else if (
      action.payload.divisionIds !== undefined &&
      action.payload.poolIds !== undefined
    ) {
      url = `${API_PATH.billLibrary.dropdown.employeebydivision}${action.payload.divisionIds}&poolIds=${action.payload.poolIds}`;
    } else if (action.payload.divisionIds !== undefined) {
      url = `${API_PATH.billLibrary.dropdown.employeebydivision}${action.payload.divisionIds}`;
    }

    const employee = yield call(NetworkService.get, url, {}, {});
    yield put(billLibraryStateActions.setEmployee(employee?.data));
  } catch (error) {}
}

/**
 * Employee Details Watcher
 */
export function* fetchHqByDivisionsandDesignationsWatcher() {
  yield takeLatest(
    fetchHqByDivisionsandDesignationsCreatorTypeName,
    fetchHqByDivisionsandDesignationsWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchHqByDivisionsandDesignationsWatcherWorker(
  action: any,
): any {
  try {
    let divisions = action.payload.divisionIds;
    let designationId = action.payload.designationIds;
    let url = '';
    yield put(billLibraryStateActions.setHQ([]));

    url = `${API_PATH.expenseDATAMaster.hqlistbydivisiondesignations}`;

    const hq = yield call(NetworkService.post, url, action.payload, {});

    yield put(
      billLibraryStateActions.setHQ(
        [{headQuaterId: 0, headQuaterName: 'ALL'}].concat(hq?.data),
      ),
    );
  } catch (error) {}
}

/**
 * Employee Details Watcher
 */
export function* fetchPoolByDivisionsandDesignationsWatcher() {
  yield takeLatest(
    fetchPoolByDivisionsandDesignationsCreatorTypeName,
    fetchPoolByDivisionsandDesignationsWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchPoolByDivisionsandDesignationsWatcherWorker(
  action: any,
): any {
  try {
    let divisions = action.payload.divisionIds;
    let designationId = action.payload.designationIds;
    let url = '';
    if (divisions !== undefined && designationId !== undefined) {
      url = `${API_PATH.billLibrary.dropdown.poolByDivisionsandDesignation}${divisions}&designationIds=${designationId}`;
    } else if (divisions !== undefined) {
      url = `${API_PATH.billLibrary.dropdown.poolByDivisionsandDesignation}${divisions}`;
    }
    const pool = yield call(NetworkService.get, url, {}, {});
    yield put(billLibraryStateActions.setPool(pool?.data));
  } catch (error) {}
}
/**
 * Employee Details Watcher
 */
export function* fetchCategoryWatcher() {
  yield takeLatest(fetchCategoryCreatorTypeName, fetchCategoryWatcherWorker);
}

/**
 * Employee Details Worker
 */
export function* fetchCategoryWatcherWorker(): any {
  try {
    let categoryType = 'ExpenseCategory';
    const url = `${API_PATH.billLibrary.dropdown.category}/${categoryType}`;
    const category = yield call(NetworkService.get, url, {}, {});
    yield put(billLibraryStateActions.setCategory(category?.data));
  } catch (error) {}
}

export function* fetchStatusWatcher() {
  yield takeLatest(fetchStatusCreatorTypeName, fetchStatusWatcherWorker);
}

/**
 * Employee Details Worker
 */
export function* fetchStatusWatcherWorker(): any {
  try {
    let Type = 'ExpenseBillLibraryStatus';
    const url = `${API_PATH.billLibrary.dropdown.category}/${Type}`;
    const status = yield call(NetworkService.get, url, {}, {});
    yield put(billLibraryStateActions.setStatus(status?.data));
  } catch (error) {}
}

export function* reviewSaveWatcher() {
  yield takeLatest(reviewSaveCreatorTypeName, reviewSaveWatcherWorker);
}

/**
 * Employee Details Worker
 */
export function* reviewSaveWatcherWorker(action: any): any {
  try {
    action.payload.fromDate = action.payload?.fromDate
      ? new Date(action.payload?.fromDate)?.toLocaleDateString('en-ca')
      : null;
    action.payload.toDate = action.payload?.toDate
      ? new Date(action.payload?.toDate)?.toLocaleDateString('en-ca')
      : null;
    action.payload.year = action.payload?.year
      ? new Date(action.payload?.year)?.getFullYear()
      : 0;
    action.payload.month = action.payload?.month
      ? new Date(action.payload?.month)?.getMonth() + 1
      : 0;
    const url = `${API_PATH.billLibrary.reviewSave}`;
    yield call(NetworkService.post, url, action.payload, {});
  } catch (error) {}
}

export function* fetchBillDownloadWatcher() {
  yield takeLatest(
    fetchBillDownloadCreatorTypeName,
    fetchBillDownloadWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchBillDownloadWatcherWorker(action: any): any {
  try {
    action.payload.fromDate = action.payload?.fromDate
      ? new Date(action.payload?.fromDate)?.toLocaleDateString('en-ca')
      : null;
    action.payload.toDate = action.payload?.toDate
      ? new Date(action.payload?.toDate)?.toLocaleDateString('en-ca')
      : null;
    action.payload.year = action.payload?.year
      ? new Date(action.payload?.year)?.getFullYear()
      : 0;
    action.payload.month = action.payload?.month
      ? new Date(action.payload?.month)?.getMonth() + 1
      : 0;
    let fileName = 'EmployeeDetails';
    // if(action.payload.employeeList.length === 1){
    //   fileName = action.payload.employeeList[0].employeeName+'_'+action.payload.employeeList[0].employeeId+'_'+action.payload.hq+'_'+action.payload.month;
    // }
    const url = `${API_PATH.billLibrary.downloadBill}`;
    const status = yield call(NetworkService.post, url, action.payload, {});
    if (!status.data.isRecordExists) {
      ToasterService.showToaster('No Record Found', ToastType.ERROR);
    } else {
      if (status.data.responseContent !== null) {
        const linkSource = `data:application/zip;base64,${status.data.responseContent}`;
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);
        downloadLink.href = linkSource;
        downloadLink.target = '_self';
        downloadLink.download = 'EmployeeDetails';
        downloadLink.click();
      }
    }
  } catch (error) {}
}

export function* fetchViewHistoryWatcher() {
  yield takeLatest(
    fetchViewHistoryCreatorTypeName,
    fetchViewHistoryWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchViewHistoryWatcherWorker(action: any): any {
  try {
    const url = `${API_PATH.billLibrary.viewHistory}${action.payload}`;
    const response = yield call(NetworkService.get, url, {}, {});
    yield put(billLibraryStateActions.setViewHistory(response?.data));
  } catch (error) {}
}
