import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import {
  fetchPoolCreator,
  fetchSLMCreator,
  fetchYearlyQuartersCreator,
  fetchdDownloadExcelURLSalesDistributionCreator,
  fetchfromPoolCreator,
  fetchsalesDistributionListDataCreator,
  inactiveNavbaFilterIconCreator,
  inactiveNavbarDropdownCreator,
  inactiveNavbarDropdownDivisionCreator,
  incentiveActions,
} from 'src/screens/incentive-configuration/redux/slice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Dropdown from '@app/components/dropdown';
import { Styles } from './style';
import { incentiveSelector } from 'src/screens/incentive-configuration/redux/selectors';
import { appSelector } from '@app/store/selectors';
import MultiSelectDropDown from '@app/components/multiSelectDropDown';

enum FilterType {
  division = 'division',
  state = 'state',
  quarter = 'quarter',
  pool = 'pool',
  group = 'group',
  slm = 'slm',
}

const styles = {
  listItemText: {
    color: '#1e1c3b',
    fontWeight: 'normal',
    fontSize: '0.9rem',
    marginLeft: 1,
  },
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
};

function FilterHeader(props: any) {
  const dispatch = useDispatch();
  const [division, setDivision] = useState<
    { label: string; value: number } | undefined
  >(undefined);
  const [quarter, setQuarter] = useState<
    { id: string; name: number } | undefined
  >(undefined);
  const [group, setGroup] = useState<
    { label: string; value: number } | undefined
  >(undefined);
  const [pool, setPool] = useState<any>({});
  const [slm, setSLM] = useState<{ label: string; value: number } | undefined>(
    undefined,
  );
  const [selectedState, setSelectedState] = useState<any>([]);

  const [selectedFilter, setSelectedFilter] = useState({
    stateIdList: null,
    divisionId: null,
    quarter: null,
    configuration: null,
  });

  const divisionList = useSelector(
    incentiveSelector.getDivisionList(),
    shallowEqual,
  );

  const quarterList = useSelector(
    incentiveSelector.getQuartetList(),
    shallowEqual,
  );

  const [filterPoolDataArr, setFilterPoolDataArr] = useState<any>([]);
  const [isDisbleGoBtn, setIsDisbleGoBtn] = useState<any>(true);
  const stateList = useSelector(incentiveSelector.getStateList(), shallowEqual);
  const isFilterIconStatus = useSelector(incentiveSelector.getNavFilterIcon());
  const poolList = useSelector(incentiveSelector.getPoolData());
  const slmList = useSelector(incentiveSelector.getSLMData());
  const groupPoolList = useSelector(incentiveSelector.getGrpOfPoolData());
  const filterPoolData = useSelector(
    incentiveSelector.getFilterPoolList(),
    shallowEqual,
  );

  const userInfo = useSelector(appSelector.getUserInfo(), shallowEqual);
  const adminStaffPositionMasterId = userInfo.id;

  const clearFilterData = useSelector(incentiveSelector.clearFilterData());

  useEffect(() => {
    dispatch(
      inactiveNavbaFilterIconCreator({
        value: false,
        color: 'gray',
        gobtnClick: false,
      }),
    );
    dispatch(fetchYearlyQuartersCreator());
    dispatch(inactiveNavbarDropdownCreator({}));
    dispatch(
      inactiveNavbarDropdownDivisionCreator({
        adminStaffPositionMasterId: adminStaffPositionMasterId,
      }),
    );
  }, []);

  useEffect(() => {
    if (clearFilterData) {
      resetData();
    }
  }, [clearFilterData]);

  const resetData = () => {
    setDivision(undefined);
    setQuarter(undefined);
    setPool(undefined);
    setGroup(undefined);
    setSLM(undefined);
    dispatch(incentiveActions.toggleClearFilter(false));
  };

  const selectfilterHandler = useCallback(
    (val: any, typeOfFilter: FilterType) => {
      let value: any;
      switch (typeOfFilter) {
        case FilterType.division:
          value = {
            ...selectedFilter,
            divisionId: val?.value,
          };
          break;
        case FilterType.state:
          value = {
            ...selectedFilter,
            stateIdList: val?.value,
          };
          break;
        case FilterType.quarter:
          value = {
            ...selectedFilter,
            quarter: val?.label,
          };
          break;
        case FilterType.slm:
          value = {
            ...selectedFilter,
            slm: val?.label,
          };
          break;
      }
      setSelectedFilter(value);
    },
    [selectedFilter],
  );

  useEffect(() => {
    let temp: any[] = [];
    if (filterPoolData?.length > 0) {
      filterPoolData?.forEach((ele: any) => {
        temp.push(ele.label);
      });
    }
    setFilterPoolDataArr(temp);
  }, [filterPoolData]);

  const filterHandler = useCallback(
    (e: any) => {
      e.preventDefault();
      dispatch(
        inactiveNavbaFilterIconCreator({
          value: !isFilterIconStatus?.value,
          gobtnClick: true,
          color: 'blue',
        }),
      );
      const requestParams: any = {
        staffPositionId: slm?.value,
        divisionId: division?.value,
        quarterId: quarter?.id,
        groupPoolId: group?.value,
        poolId: pool?.value,
      };

      props.filterData(requestParams, quarter);
      dispatch(fetchsalesDistributionListDataCreator(requestParams));
      dispatch(fetchdDownloadExcelURLSalesDistributionCreator(requestParams));
    },
    [division, slm, quarter, pool, dispatch, group],
  );

  const handleRenderValue = useCallback(selected => {
    if (selected?.length === 0) {
      return <em style={Styles.placeHolder}>Select Pool</em>;
    }
    return selected?.join(', ');
  }, []);

  useEffect(() => {
    if (pool || group) {
      setIsDisbleGoBtn(false);
    } else {
      setIsDisbleGoBtn(true);
    }

    if(!division){
      setIsDisbleGoBtn(true);
    }
  }, [pool, group]);

  return (
    <>
      {isFilterIconStatus?.value ? (
        <div style={{ margin: 5 }}>
          <div style={Styles.filterHeaderBody}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 1 }}
            >
              <Grid item xs={2.2}>
                <Box style={Styles.inputCell}>
                  <InputLabel variant="standard">Division</InputLabel>
                  <Dropdown
                    onChange={(val: any) => {
                      selectfilterHandler(val, FilterType.division);
                      setDivision(val || undefined);
                      dispatch(
                        fetchSLMCreator({
                          divisonId: val?.value,
                        }),
                      );
                      setQuarter(undefined);
                      setSLM(undefined);
                      setGroup(undefined);
                      setPool(undefined);
                      setFilterPoolDataArr([]);
                    }}
                    value={division}
                    dropdownList={divisionList || []}
                    placeholder="Select Division"
                  />
                </Box>
              </Grid>
              <Grid item xs={2.2}>
                <Box style={Styles.inputCell}>
                  <InputLabel variant="standard">Quarter</InputLabel>
                  <Dropdown
                    onChange={(val: any) => {
                      selectfilterHandler(val, FilterType.quarter);
                      setQuarter(val);
                      setGroup(undefined);
                      setPool(undefined);
                      setFilterPoolDataArr([]);
                      setSLM(undefined)
                    }}
                    value={quarter}
                    dropdownList={quarterList}
                    placeholder="Select Quarter"
                    disabled={!division}
                  />
                </Box>
              </Grid>

              <Grid item xs={2.2}>
                <Box style={Styles.inputCell}>
                  <InputLabel variant="standard">SLM</InputLabel>
                  <Dropdown
                    onChange={(val: any) => {
                      selectfilterHandler(val, FilterType.slm);
                      setSLM(val || undefined);
                      setGroup(undefined);
                      setPool(undefined);
                      setFilterPoolDataArr([]);
                      dispatch(incentiveActions.setFromPoolData([]));
                      dispatch(incentiveActions.setPoolData([]));
                      if (val) {
                        dispatch(
                          fetchPoolCreator({
                            StaffPositionId: val?.value,
                            divisionId: division?.value,
                            QuarterId: quarter?.id,
                            grouppoolid: 0,
                          }),
                        );
                        dispatch(
                          fetchfromPoolCreator({
                            StaffPositionId: val?.value,
                            QuarterId: quarter?.id,
                          }),
                        );
                      }
                    }}
                    value={slm}
                    dropdownList={slmList || []}
                    placeholder="Select SLM"
                    disabled={!slmList || !quarter}
                  />
                </Box>
              </Grid>

              <Grid item xs={2.2}>
                <Box style={Styles.inputCell}>
                  <InputLabel variant="standard">GRP OF POOL</InputLabel>
                  <Dropdown
                    onChange={(val: any) => {
                      selectfilterHandler(val, FilterType.group);
                      setGroup(val || undefined);
                      if (val) {
                        dispatch(
                          fetchPoolCreator({
                            StaffPositionId: slm?.value,
                            divisionId: division?.value,
                            QuarterId: quarter?.id,
                            grouppoolid: val?.value,
                            from: 'fromGrpOfPool',
                          }),
                        );
                      } else {
                        dispatch(incentiveActions.setFilterPoolData([]));
                      }
                    }}
                    value={group}
                    dropdownList={groupPoolList || []}
                    placeholder="Select Group"
                    disabled={!quarter || !slm || !division || pool}
                  />
                </Box>
              </Grid>
              <Grid item xs={2.2} >
                <InputLabel variant="standard">Pool</InputLabel>
                {group ? (
                  <FormControl sx={{ maxWidth: '98%', minWidth: '98%' }}>
                    <Select
                      value={filterPoolDataArr || []}
                      renderValue={handleRenderValue}
                      MenuProps={MenuProps}
                      displayEmpty
                      sx={{ backgroundColor: '#FFFFFF' }}
                      disabled={filterPoolDataArr?.length === 0}
                    >

                      {filterPoolDataArr?.length > 0 ? filterPoolDataArr?.map((val: any) => (
                        <MenuItem key={val.value} value={val.label}>
                          <Checkbox checked={true} disabled/>
                          <ListItemText
                            primary={
                              <Typography sx={styles.listItemText}>
                                {val}
                              </Typography>
                            }
                          />
                        </MenuItem>
                      )) : null}
                    </Select>
                  </FormControl>
                ) : (
                  <Box style={Styles.inputCell}>
                    <Dropdown
                      onChange={(val: any) => {
                        selectfilterHandler(val, FilterType.pool);
                        setPool(val || undefined);
                      }}
                      value={pool}
                      dropdownList={poolList || []}
                      placeholder="Select Pool"
                      disabled={group || !quarter || !slm}
                    />
                  </Box>
                )}
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                }}
              >
                <Button
                  data-testid="clickGo-icon"
                  variant="contained"
                  onClick={filterHandler}
                  size="small"
                  disabled={isDisbleGoBtn}
                  style={{
                    fontFamily: ['Poppins'].join(','),
                    fontSize: 12.7,
                    padding: '11px',
                    cursor: 'pointer',
                    letterSpacing: 0,
                    textAlign: 'center',
                    backgroundColor: isDisbleGoBtn ? '#e0e0e0' : '#322b7c',
                    color: 'white',
                    border: 0,
                    borderRadius: 10,
                    minWidth: 50,
                  }}
                >
                  Go
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default React.memo(FilterHeader);
