import {shallowEqual, useSelector} from 'react-redux';
import {routeSelector} from '@app/router/redux';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

const IncentiveConfiguration = () => {
  const navigate = useNavigate();
  const incentiveRoute = useSelector(
    routeSelector.getAppRoutes(),
    shallowEqual,
  );
  useEffect(() => {
    const selectedRoute = getSelectedRoute(
      incentiveRoute,
      '/incentive-configuration',
    );
    if (selectedRoute) {
      const firstRoute = getFirstLevelRoute(selectedRoute);
      if (firstRoute) {
        navigate(firstRoute.webPath);
      }
    }
  }, [incentiveRoute]);

  return <div></div>;
};

function getSelectedRoute(routes: any[], webPath: string) {
  let selectedRoute: any = null;
  routes.forEach((route: any) => {
    if (route.webPath === webPath) {
      selectedRoute = route;
    }
  });
  return selectedRoute;
}

function getFirstLevelRoute(route: any) {
  if (route.firstLevel && route.firstLevel.length > 0) {
    return route.firstLevel[0];
  }
  return null;
}

export default IncentiveConfiguration;
