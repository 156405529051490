import {createSelector} from '@reduxjs/toolkit';

export const billLibraryStateSelector = {
  getGridVeiw: () => {
    return createSelector(
      [(state: any) => state.billLibrary.gridView],
      gridView => gridView,
    );
  },
  getContentGridView: () => {
    return createSelector(
      [(state: any) => state.billLibrary.contentGridView],
      contentGridView => contentGridView,
    );
  },
  getDivision: () => {
    return createSelector(
      [(state: any) => state.billLibrary.divisionArray],
      divisionArray => divisionArray,
    );
  },
  getDesignation: () => {
    return createSelector(
      [(state: any) => state.billLibrary.designationArray],
      designationArray => designationArray,
    );
  },
  geDownloadRequest: () => {
    return createSelector(
      [(state: any) => state.billLibrary.downloadRequest],
      downloadRequest => downloadRequest,
    );
  },
  getEmployee: () => {
    return createSelector(
      [(state: any) => state.billLibrary.employeeArray],
      employeeArray => employeeArray,
    );
  },
  getHQ: () => {
    return createSelector(
      [(state: any) => state.billLibrary.hqArray],
      hqArray => hqArray,
    );
  },
  getPool: () => {
    return createSelector(
      [(state: any) => state.billLibrary.poolArray],
      poolArray => poolArray,
    );
  },
  getCategory: () => {
    return createSelector(
      [(state: any) => state.billLibrary.categoryArr],
      categoryArr => categoryArr,
    );
  },
  getStatus: () => {
    return createSelector(
      [(state: any) => state.billLibrary.statusArr],
      statusArr => statusArr,
    );
  },
  getViewHistory: () => {
    return createSelector(
      [(state: any) => state.billLibrary.viewHistory],
      viewHistory => viewHistory,
    );
  },
  getEmployeeDropDown: () => {
    return createSelector(
      [(state: any) => state.billLibrary.employeeDropDown],
      employeeDropDown => employeeDropDown,
    );
  },
};
