import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ComponentManagementSelector} from '../redux/selectors';
import {componentManagementStateActions} from '../redux';
import Stack from '@mui/material/Stack';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import {editExpenseTypeCreator} from '../redux/slice';
import {DropdownStyle} from '@app/components/widgets/TextFieldStyle';
import {AntSwitch} from '@app/components/widgets/AntSwitch/AntSwitch';
import {popUpStyle} from '@app/components/widgets/popupCss/popUpStyles';

export const EditExpenseType = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(false);
  const [expenseTypes, setExpenseTypes] = useState('');
  const [expenseTypeNames, setExpenseTypeNames] = useState('');
  const [expenseCategorys, setExpenseCategorys] = useState('');
  const [editBillRequired, setEditBillRequired] = useState(false);
  const [editExpenseTypeValidate, setEditExpenseTypeValidate] = useState(false);

  const handleChangeExpenseType = useCallback(
    (event: any) => {
      setExpenseTypes(event.target.value);
    },
    [expenseTypes],
  );
  const handleChangeExpenseCategory = useCallback(
    (event: any) => {
      setExpenseCategorys(event.target.value);
    },
    [expenseCategorys],
  );
  const editExpenseTypePopupVisible = useSelector(
    ComponentManagementSelector.getEditExpenseTypePopupVisible(),
  );

  const editExpenseTypeValue = useSelector(
    ComponentManagementSelector.getEditExpenseType(),
  );

  const editExpenseCat = useSelector(
    ComponentManagementSelector.getExpenseCategory(),
  );
  const editExpenseTypeOption = useSelector(
    ComponentManagementSelector.getExpenseTypeOption(),
  );

  useEffect(() => {
    if (Object.keys(editExpenseTypeValue).length > 0) {
      setExpenseTypes(editExpenseTypeValue?.expenseType);
      setExpenseTypeNames(editExpenseTypeValue?.expenseTypeName);
      setExpenseCategorys(editExpenseTypeValue?.expenseCategory);
      setEditBillRequired(editExpenseTypeValue?.isBillRequired);
      setStatus(editExpenseTypeValue?.isActive);
    }
  }, [editExpenseTypeValue]);
  const onResetClickExpenseType = useCallback(() => {
    setExpenseTypes(editExpenseTypeValue?.expenseType);
    setExpenseCategorys(editExpenseTypeValue?.expenseCategory);
    setStatus(editExpenseTypeValue?.isActive);
    setEditBillRequired(editExpenseTypeValue?.isBillRequired);
  }, []);
  useEffect(() => {
    editvalidationExpenseType();
  }, [expenseTypeNames, expenseTypes, expenseCategorys]);
  const editvalidationExpenseType = () => {
    if (expenseTypeNames && expenseTypes && expenseCategorys) {
      setEditExpenseTypeValidate(false);
    } else {
      setEditExpenseTypeValidate(true);
    }
  };
  const onSaveClick = useCallback(() => {
    const expenseTypeDate = {
      expenseTypeID: editExpenseTypeValue?.expenseTypeID,
      expenseTypeName: expenseTypeNames,
      expenseType: expenseTypes,
      expenseCategory: expenseCategorys,
      startDate: null,
      endDate: null,
      isActive: status,
      isBillRequired: editBillRequired,
    };
    dispatch(editExpenseTypeCreator(expenseTypeDate));
  }, [
    expenseTypeNames,
    expenseTypes,
    expenseCategorys,
    editBillRequired,
    status,
  ]);

  useEffect(() => {
    return () => {
      dispatch(componentManagementStateActions.setAutoSuggestHq([]));
    };
  }, [dispatch]);

  const TriggerEvent = useCallback(() => {
    return (
      <button
        data-testid="triggeringButton"
        className={'hiddenButton'}
        onClick={() => {
          handleChangeExpenseType({target: {value: 'true'}});
          handleChangeExpenseCategory({target: {value: 'true'}});
          editvalidationExpenseType();
          editvalidationExpenseType();
          onSaveClick();
          onResetClickExpenseType();
        }}
      />
    );
  }, []);

  const close = useCallback((reason: string) => {
    if (reason && reason === 'backdropClick') return;
    dispatch(
      componentManagementStateActions.setEditExpenseTypePopupVisible(false),
    );
  }, []);
  const Onclose = useCallback(() => {
    dispatch(
      componentManagementStateActions.setEditExpenseTypePopupVisible(false),
    );
  }, []);
  const onStatus = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setStatus(event.target.checked),
    [status],
  );
  const billRequired = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setEditBillRequired(event.target.checked),
    [editBillRequired],
  );
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={editExpenseTypePopupVisible}
        onClose={close}
      >
        <TriggerEvent />
        <DialogTitle>
          <Box display={'flex'} justifyContent="space-between">
            <Box>
              <Typography sx={popUpStyle.addcss}>Edit</Typography>
              <Typography sx={popUpStyle.headingcss}>Expense Type</Typography>
            </Box>
            <Box>
              <Button
                data-testid="setEditExpenseTypePopupVisible"
                onClick={Onclose}
              >
                <ClearOutlinedIcon sx={popUpStyle.closeIcon} />
              </Button>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <div style={popUpStyle.dialogContent}>
            <FormControl fullWidth sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>
                {'Expense Name'}
              </Typography>

              <TextField
                disabled
                sx={DropdownStyle.textroot}
                id="fullWidth"
                value={expenseTypeNames}
                size="small"
              />
            </FormControl>
            <FormControl fullWidth sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>
                {'Expense Type'}
              </Typography>
              <Select
                value={expenseTypes}
                onChange={handleChangeExpenseType}
                sx={DropdownStyle.root}
                size="small"
              >
                {editExpenseTypeOption.length > 0 &&
                  editExpenseTypeOption.map((element1: any) => (
                    <MenuItem key={element1.id} value={element1.displayName}>
                      {element1.displayName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>
                {'Expense Category'}
              </Typography>
              <Select
                value={expenseCategorys}
                displayEmpty
                onChange={handleChangeExpenseCategory}
                inputProps={{'aria-label': 'Without label'}}
                sx={DropdownStyle.root}
                size="small"
              >
                {editExpenseCat.length > 0 &&
                  editExpenseCat.map((element2: any) => (
                    <MenuItem key={element2.id} value={element2.displayName}>
                      {element2.displayName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <div style={popUpStyle.twocolumnlayout}>
              <FormGroup>
                <Typography sx={popUpStyle.labelSizing}>{'Status'}</Typography>
                <Stack
                  direction="row"
                  paddingLeft={1}
                  spacing={2}
                  alignItems="center"
                >
                  <Typography
                    fontFamily={'Poppins'}
                    fontSize={'12px'}
                    color={'#322b7c'}
                    fontWeight={'800'}
                  >
                    In-Active
                  </Typography>
                  <AntSwitch
                    checked={status}
                    onChange={onStatus}
                    inputProps={{'aria-label': 'ant design'}}
                  />
                  <Typography
                    fontFamily={'Poppins'}
                    fontSize={'12px'}
                    color={'#322b7c'}
                    fontWeight={'800'}
                  >
                    Active
                  </Typography>
                </Stack>
              </FormGroup>
              <FormGroup sx={popUpStyle.marginBottom15}>
                <Typography sx={popUpStyle.labelSizing}>
                  {'Bill Required'}
                </Typography>
                <Stack
                  spacing={2}
                  alignItems="center"
                  direction="row"
                  paddingLeft={1}
                >
                  <Typography
                    fontFamily={'Poppins'}
                    fontSize={'12px'}
                    color={'#322b7c'}
                    fontWeight={'800'}
                  >
                    No
                  </Typography>
                  <AntSwitch
                    checked={editBillRequired}
                    onChange={billRequired}
                    inputProps={{'aria-label': 'ant design'}}
                  />
                  <Typography
                    color={'#322b7c'}
                    fontWeight={'800'}
                    fontFamily={'Poppins'}
                    fontSize={'12px'}
                  >
                    Yes
                  </Typography>
                </Stack>
              </FormGroup>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Box display={'flex'} justifyContent="center" width={'100%'}>
            <Button
              variant="contained"
              sx={popUpStyle.resetButton}
              onClick={onResetClickExpenseType}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              sx={popUpStyle.saveButton}
              onClick={onSaveClick}
              disabled={editExpenseTypeValidate}
            >
              Update
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};
