import Typography from '@mui/material/Typography';
import {Box, Button, Stack} from '@mui/material';
import {inventoryManagementConfiguratorStateActions} from '@app/screens/inventory-management/pages/inventoryConfigurator/redux';
import {useCallback, useRef, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ToogleSeperator} from '../../widgets/toogleSeperator';
import {InventoryManagementConfiguratorSelector} from '@app/screens/inventory-management/pages/inventoryConfigurator/redux/selectors';
import {
  inventoryConfiguratorCustomerTypeCreator,
  inventoryConfiguratorDivisionCreator,
  inventoryConfiguratorRegionHeirarchyCreator,
  inventoryConfiguratorClassCodeCreator,
  inventoryConfiguratorItemCodeCreator,
  inventoryConfiguratorItemCategoryCreator,
  inventoryConfiguratorRolesCreator,
  inventoryConfiguratorQualificationCreator,
} from '@app/screens/inventory-management/pages/inventoryConfigurator/redux/slice';
import {inventoryModuleCnfActions} from '@app/screens/inventory-management/pages/cnf-return-items/redux/slice';

type DefineType = 'DEFINE' | 'REFINE';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  antSwitch: {
    marginTop: '4px !important',
  },
  switchStyle: {display: 'flex', flexDirection: 'row'},
};
type toogleProp = {
  handleTypeChange?: (value: DefineType) => void;
};
export const InventoryConfiguratorNavBar: React.FC<toogleProp> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(inventoryModuleCnfActions.setSelectedCnfState(''));
    dispatch(inventoryModuleCnfActions.setSelectedCnfDivison(''));
    dispatch(inventoryModuleCnfActions.setSelectedCnfHeadquarter(''));
    dispatch(inventoryModuleCnfActions.setSelectedCnfReturnItems([]));
    dispatch(inventoryConfiguratorCustomerTypeCreator());
    dispatch(inventoryConfiguratorDivisionCreator());
    dispatch(inventoryConfiguratorRegionHeirarchyCreator());
    dispatch(inventoryConfiguratorClassCodeCreator());
    dispatch(inventoryConfiguratorItemCodeCreator());
    dispatch(inventoryConfiguratorItemCategoryCreator());
    dispatch(inventoryConfiguratorRolesCreator());
    dispatch(inventoryConfiguratorQualificationCreator());
  }, []);

  const currentPageSwitch = useSelector(
    InventoryManagementConfiguratorSelector.getInventoryConfiguratorManagementPage(),
  );

  const handleTypeChange = useCallback(function (value: DefineType) {
    dispatch(
      inventoryManagementConfiguratorStateActions.setInventoryManagementConfiguratorPage(
        value,
      ),
    );
  }, []);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.flexSpaceBetween}>
        <Typography
          variant="h6"
          noWrap
          component="div"
          color={'#1c1939'}
          fontStyle={'normal'}
        >
          Inventory Configurator
        </Typography>

        <Stack direction="row" spacing={2} alignContent="center">
          {currentPageSwitch === 'DEFINE' ? (
            <Button
              data-testid="save-config-button"
              variant="contained"
              sx={{
                width: 165.3,
                height: 42.7,
                borderRadius: 0.67,
                backgroundColor: '#322b7c',
              }}
            >
              Save
            </Button>
          ) : (
            <Box sx={{height: 42.7}}></Box>
          )}
        </Stack>
      </Box>

      <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
        <ToogleSeperator
          name="group-1"
          callback={handleTypeChange}
          controlRef={useRef()}
          segments={[
            {
              label: 'DEFINE',
              value: 'DEFINE',
              ref: useRef(),
            },
            {
              label: 'REFINE',
              value: 'REFINE',
              ref: useRef(),
            },
          ]}
        />
      </Box>
    </Box>
  );
};
