import {createSelector} from '@reduxjs/toolkit';

export const getComponentManagementStateSelector = (stateName: string) => {
  return createSelector(
    [(state: any) => state.ComponentManagement[stateName]],
    state => state,
  );
};
export const ComponentManagementSelector = {
  getExpenseType: () => {
    return createSelector(
      [(state: any) => state.ComponentManagement.expenseType],
      expenseType => expenseType,
    );
  },
  getExpenseTypeSnaShot: () => {
    return createSelector(
      [(state: any) => state.ComponentManagement.expenseTypeSnaShot],
      expenseTypeSnaShot => expenseTypeSnaShot,
    );
  },
  getAutoSuggestHq: () => {
    return createSelector(
      [(state: any) => state.ComponentManagement.autoSuggestHq],
      autoSuggestHq => autoSuggestHq,
    );
  },
  getExpenseTypeInAutoSuggestFilter: () => {
    return createSelector(
      [
        (state: any) =>
          state.ComponentManagement.expenseTypeInAutoSuggestFilter,
      ],
      expenseTypeInAutoSuggestFilter => expenseTypeInAutoSuggestFilter,
    );
  },
  getIsActiveState: () => {
    return createSelector(
      [(state: any) => state.ComponentManagement.isActiveState],
      isActiveState => isActiveState,
    );
  },
  getEditExpenseTypePopupVisible: () => {
    return createSelector(
      [(state: any) => state.ComponentManagement.editExpenseTypePopupVisible],
      editExpenseTypePopupVisible => editExpenseTypePopupVisible,
    );
  },
  getEditExpenseType: () => {
    return createSelector(
      [(state: any) => state.ComponentManagement.editExpenseType],
      editExpenseType => editExpenseType,
    );
  },
  getExpenseTypeLoading: () => {
    return createSelector(
      [(state: any) => state.ComponentManagement.ExpenseTypeLoading],
      ExpenseTypeLoading => ExpenseTypeLoading,
    );
  },
  getSearchText: () => {
    return createSelector(
      [(state: any) => state.ComponentManagement.searchText],
      searchText => searchText,
    );
  },
  getExpenseTypeInAutoSuggest: () => {
    return createSelector(
      [(state: any) => state.ComponentManagement.expenseTypeInAutoSuggest],
      expenseTypeInAutoSuggest => expenseTypeInAutoSuggest,
    );
  },
  getExpenseTypeOption: () => {
    return createSelector(
      [(state: any) => state.ComponentManagement.expenseTypeOption],
      expenseTypeOption => expenseTypeOption,
    );
  },
  getExpenseCategory: () => {
    return createSelector(
      [(state: any) => state.ComponentManagement.expenseCategory],
      expenseCategory => expenseCategory,
    );
  },
  getaddExpenseTypePopupVisible: () => {
    return createSelector(
      [(state: any) => state.ComponentManagement.addExpenseTypePopupVisible],
      addExpenseTypePopupVisible => addExpenseTypePopupVisible,
    );
  },
  getResetNavbarVersion: () => {
    return createSelector(
      [(state: any) => state.ComponentManagement.resetNavbarVersion],
      resetNavbarVersion => resetNavbarVersion,
    );
  },
};
