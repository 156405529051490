import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {routeStateActions} from '@app/router/redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import {makeStyles} from '@mui/styles';

import {StatusManagerContent} from '../../components/status-manager';
import {fetchDivisionDropdownCreator} from '@app/screens/access-management/pages/user-management/redux/slice';
const useStyles = makeStyles({
  navbarComponentHeading: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontStyle: 'normal',
    textAlign: 'left',
    color: '#1c1939',
    width: 194,
    height: 70.7,
    fontSize: 18,
  },
});

export const ReverseExpense = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.statusManager,
      ),
    );
    dispatch(
      routeStateActions.setNavbarComponentHeading(
        <div className={classes.navbarComponentHeading}>Reverse Expense</div>,
      ),
    );

    dispatch(fetchDivisionDropdownCreator());
    return () => {
      dispatch(routeStateActions.setNavbarComponentName(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StatusManagerContent />
    </>
  );
};
