import Typography from '@mui/material/Typography';
import {useSelector, useDispatch} from 'react-redux';
import {Box} from '@mui/material';
import {SearchBarInventoryModule} from '../../search-bar-inventory-module';
import {intransistChallanActions} from '@app/screens/inventory-management/pages/intransit-challan/redux';
import {intransistChallanSelector} from '@app/screens/inventory-management/pages/intransit-challan/redux/selectors';
import {
  fetchIntransistChallanCreator,
  inTransitFilteredData,
} from '@app/screens/inventory-management/pages/intransit-challan/redux/slice';
import {inventoryModuleCnfActions} from '@app/screens/inventory-management/pages/cnf-return-items/redux';
import {useEffect, useRef} from 'react';
import {ToogleSeperator} from '../../widgets/toogleSeperator';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  antSwitch: {
    marginTop: '4px !important',
  },
};

export const IntransitChallanNavBar = () => {
  const isOpenChallans = useSelector(
    intransistChallanSelector.getIsOpenChallans(),
  );
  const autoSuggestionData = useSelector(
    intransistChallanSelector.getAutoSuggestionData(),
  );
  const searchType = useSelector(intransistChallanSelector.getSearchType());
  const searchValue = useSelector(intransistChallanSelector.getSearchValue());

  const searchTypeLabel = {
    divisionname: 'Division Name',
    staffname: 'Name',
    challanNumber: 'Challan Number',
    staffCode: 'Staff Code',
    state: 'State Name',
  };

  const Search = () => {
    let searchdata = '';
    if (searchType === searchTypeLabel.staffname) {
      searchdata = 'staffname';
    } else if (searchType === searchTypeLabel.staffCode) {
      searchdata = 'staffCode';
    } else if (searchType === searchTypeLabel.challanNumber) {
      searchdata = 'challanNumber';
    } else if (searchType === searchTypeLabel.state) {
      searchdata = 'state';
    }
    return searchdata;
  };
  const dispatch = useDispatch();
  const handleToggleChange = () => {
    const data = Search();
    dispatch(
      inTransitFilteredData({searchType: data, searchValue: searchValue}),
    );
    dispatch(intransistChallanActions.setIsOpenChallans());
    dispatch(fetchIntransistChallanCreator({isOpenClose: !isOpenChallans}));
  };
  useEffect(() => {
    if (!isOpenChallans) {
      dispatch(intransistChallanActions.setIsOpenChallans());
    }
    dispatch(inventoryModuleCnfActions.setSelectedCnfState(''));
    dispatch(inventoryModuleCnfActions.setSelectedCnfDivison(''));
    dispatch(inventoryModuleCnfActions.setSelectedCnfHeadquarter(''));
    dispatch(inventoryModuleCnfActions.setSelectedCnfReturnItems([]));
  }, []);
  return (
    <Box sx={styles.container}>
      <Box sx={styles.flexSpaceBetween}>
        <Typography
          variant="h6"
          noWrap
          component="div"
          color={'#1c1939'}
          fontStyle={'normal'}
        >
          Challan Closing and Receiving
        </Typography>
        <Box sx={{padding: 0}}>
          <ToogleSeperator
            containerStyle={{width: '262px'}}
            name="group-1"
            callback={handleToggleChange}
            controlRef={useRef()}
            segments={[
              {
                label: 'Open Challans',
                value: 'Open Challans',
                ref: useRef(),
              },
              {
                label: 'Closed Challans',
                value: 'Closed Challans',
                ref: useRef(),
              },
            ]}
          />
        </Box>
      </Box>

      <SearchBarInventoryModule
        autoSuggestionData={autoSuggestionData?.searchValue}
      />
    </Box>
  );
};
