import {createSelector} from '@reduxjs/toolkit';

export const intransistChallanSelector = {
  getIsOpenChallans: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.isOpenChallans],
      state => state,
    );
  },
  getIntransitChallanloading: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.intransitChallanloading],
      state => state,
    );
  },
  getIntransistChallanData: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.challanData],
      state => state,
    );
  },
  getAutoSuggestionData: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.autoSuggestionData],
      state => state,
    );
  },
  getFilteredData: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.filteredData],
      state => state,
    );
  },
  getSelectedIntransitChallas: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.selectedChallans],
      state => state,
    );
  },
  getVoucherNumberData: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.voucherNumber],
      state => state,
    );
  },
  getRecievedChallanData: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.receivedChallans],
      state => state,
    );
  },
  getSearchType: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.searchType],
      state => state,
    );
  },
  getSearchValue: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.searchValue],
      state => state,
    );
  },
  getTotalIntransitChallans: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.totalIntransitChallans],
      state => state,
    );
  },
};
