import {userManagementStateActions} from '@app/screens/access-management/pages/user-management/redux';
import {userManagementStateSelector} from '@app/screens/access-management/pages/user-management/redux/selectors';
import {fetchAdminUsersCreatorTypeName} from '@app/screens/access-management/pages/user-management/redux/slice';
import {API_PATH} from '@app/common/constants';
import {
  attachMTPObjectToFilters,
  createFilterObject,
} from '@app/helper/plan-and-meet';
import {select} from 'redux-saga/effects';
import {NetworkService} from '@app/services';
import {takeLatest, put, call} from '@redux-saga/core/effects';

import {
  applyFilterCreatorTypeName,
  filterStateActions,
  resetDefaultFilterCreatorTypeName,
  setDefaultFilterCreatorTypeName,
} from './filterHandler';
import {filterSelector} from './filterHandlerSelector';
import {
  planAndMeetSelector,
  planAndMeetStatusStateActions,
} from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux';
import {routeSelector} from '@app/router/redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';

const fetchAllUsersFlag = (states: any, divisions: any, accessGroups: any) => {
  let fetchAllUsers = true;
  if (!accessGroups.completeApplied) {
    Object.keys(accessGroups.options).forEach(key => {
      if (accessGroups.options[key]) {
        fetchAllUsers = false;
      }
    });
  }
  if (!divisions.completeApplied) {
    Object.keys(divisions.options).forEach(key => {
      if (divisions.options[key]) {
        fetchAllUsers = false;
      }
    });
  }
  if (!states.completeApplied) {
    Object.keys(states.options).forEach(key => {
      if (states.options[key]) {
        fetchAllUsers = false;
      }
    });
  }
  if (
    states.completeApplied ||
    divisions.completeApplied ||
    accessGroups.completeApplied
  ) {
    fetchAllUsers = false;
  }

  return fetchAllUsers;
};
const fetchAllFlag = (division: any, designation: any, hq: any) => {
  let fetchAll = true;
  if (!division.completeApplied) {
    Object.keys(division.options).forEach(key => {
      if (division.options[key]) {
        fetchAll = false;
      }
    });
  }
  if (!designation.completeApplied) {
    Object.keys(designation.options).forEach(key => {
      if (designation.options[key]) {
        fetchAll = false;
      }
    });
  }
  if (!hq.completeApplied) {
    Object.keys(hq.options).forEach(key => {
      if (hq.options[key]) {
        fetchAll = false;
      }
    });
  }
  if (
    designation.completeApplied ||
    division.completeApplied ||
    hq.completeApplied
  ) {
    fetchAll = false;
  }

  return fetchAll;
};

const isValidAppliedExpenseCategory = (
  appliedExpenseCategory: any,
  Data: any,
) => {
  let isValid1 = true;
  if (appliedExpenseCategory.length > 0) {
    isValid1 = false;
    appliedExpenseCategory?.forEach((expenseCategory: any) => {
      if (Data?.expenseCategory === expenseCategory) {
        isValid1 = true;
      }
    });
  }
  return isValid1;
};
const isValidAppliedAccessGroups = (appliedAccessGroups: any, user: any) => {
  let isValid1 = true;
  if (appliedAccessGroups.length > 0) {
    isValid1 = false;
    appliedAccessGroups?.forEach((accessGroup: any) => {
      user?.accessGroup?.forEach((accessGroupInternal: any) => {
        if (accessGroupInternal.accessGroupName === accessGroup) {
          isValid1 = true;
        }
      });
    });
  }
  return isValid1;
};
const isValidAppliedStates = (appliedStates: any, user: any) => {
  let isValid3 = true;
  if (appliedStates.length > 0) {
    isValid3 = false;
    appliedStates?.forEach((state: any) => {
      user?.state?.forEach((stateInternal: any) => {
        if (stateInternal.name === state) {
          isValid3 = true;
        }
      });
    });
  }
  return isValid3;
};
const isValidAppliedDivisions = (appliedDivisions: any, user: any) => {
  let isValid2 = true;
  if (appliedDivisions.length > 0) {
    isValid2 = false;
    appliedDivisions?.forEach((division: any) => {
      user?.division?.forEach((divisionInternal: any) => {
        if (divisionInternal.name === division) {
          isValid2 = true;
        }
      });
    });
  }
  return isValid2;
};
const isValidAppliedDivision = (appliedDivision: any, expenseMAster: any) => {
  let isValid2 = true;
  if (appliedDivision.length > 0) {
    isValid2 = false;
    appliedDivision?.forEach((division: any) => {
      if (
        expenseMAster?.division !== undefined &&
        expenseMAster?.division === division
      ) {
        isValid2 = true;
      } else if (expenseMAster?.divisionName === division) {
        isValid2 = true;
      }
    });
  }
  return isValid2;
};
const isValidAppliedDesignation = (
  appliedDesignation: any,
  expenseMAster: any,
) => {
  let isValid1 = true;
  if (appliedDesignation.length > 0) {
    isValid1 = false;
    appliedDesignation?.forEach((designation: any) => {
      if (
        expenseMAster?.designation !== undefined &&
        expenseMAster?.designation === designation
      ) {
        isValid1 = true;
      } else if (expenseMAster?.designationName === designation) {
        isValid1 = true;
      } else if (expenseMAster?.staffDesignationName === designation) {
        isValid1 = true;
      }
    });
  }
  return isValid1;
};
const isValidAppliedhq = (appliedhq: any, expenseMaster: any) => {
  let isValid3 = true;
  if (appliedhq.length > 0) {
    isValid3 = false;
    appliedhq?.forEach((hq: any) => {
      if (expenseMaster?.hq !== undefined && expenseMaster?.hq === hq) {
        isValid3 = true;
      } else if (expenseMaster?.hqName === hq) {
        isValid3 = true;
      }
    });
  }
  return isValid3;
};

const isValidExpenseType = ({
  expenseType,
  appliedExpenseCategory,
}: {
  expenseType: any;
  appliedExpenseCategory: any;
}) => {
  const isValid1 = isValidAppliedExpenseCategory(
    appliedExpenseCategory,
    expenseType,
  );
  return isValid1;
};
const isValidUser = ({
  user,
  appliedAccessGroups,
  appliedDivisions,
  appliedStates,
}: {
  user: any;
  appliedAccessGroups: any;
  appliedDivisions: any;
  appliedStates: any;
}) => {
  const isValid1 = isValidAppliedAccessGroups(appliedAccessGroups, user);
  const isValid2 = isValidAppliedDivisions(appliedDivisions, user);
  const isValid3 = isValidAppliedStates(appliedStates, user);
  return isValid1 && isValid2 && isValid3;
};
const isValidMaster = ({
  Master,
  appliedDivision,
  appliedDesignation,
  appliedhq,
}: {
  Master: any;
  appliedDivision: any;
  appliedDesignation: any;
  appliedhq: any;
}) => {
  const isValid1 = isValidAppliedDivision(appliedDivision, Master);
  const isValid2 = isValidAppliedDesignation(appliedDesignation, Master);
  const isValid3 = isValidAppliedhq(appliedhq, Master);
  return isValid1 && isValid2 && isValid3;
};
const getfilteredUsers = ({
  adminUsers,
  isUserActive,
  appliedAccessGroups,
  appliedDivisions,
  appliedStates,
}: {
  adminUsers: any;
  isUserActive: any;
  appliedAccessGroups: any;
  appliedDivisions: any;
  appliedStates: any;
}) => {
  return adminUsers
    .filter((user: any) => {
      if (isUserActive && !user.isActive) {
        return false;
      }
      if (!isUserActive && user.isActive) {
        return false;
      }
      return true;
    })
    .filter((user: any) => {
      return isValidUser({
        user,
        appliedAccessGroups,
        appliedDivisions,
        appliedStates,
      });
    });
};

const getfilteredExpenseType = ({
  expenseType,
  isUserActive,
  appliedExpenseCategory,
}: {
  expenseType: any;
  isUserActive: any;
  appliedExpenseCategory: any;
}) => {
  return expenseType
    .filter((expenseType: any) => {
      if (isUserActive && !expenseType.isActive) {
        return false;
      }
      if (!isUserActive && expenseType.isActive) {
        return false;
      }
      return true;
    })
    .filter((expenseType: any) => {
      return isValidExpenseType({
        expenseType,
        appliedExpenseCategory,
      });
    });
};
const getfilteredExpense = ({
  gridData,
  isActiveStatus,
  appliedDivision,
  appliedDesignation,
  appliedhq,
}: {
  gridData: any;
  isActiveStatus: any;
  appliedDesignation: any;
  appliedDivision: any;
  appliedhq: any;
}) => {
  return gridData
    .filter((Master: any) => {
      if (isActiveStatus && !Master.isActive) {
        return false;
      }
      if (!isActiveStatus && Master.isActive) {
        return false;
      }
      return true;
    })
    .filter((Master: any) => {
      return isValidMaster({
        Master,
        appliedDivision,
        appliedDesignation,
        appliedhq,
      });
    });
};
const getfilteredGenerateReport = ({
  gridData,
  appliedDivision,
  appliedDesignation,
  appliedhq,
}: {
  gridData: any;
  appliedDesignation: any;
  appliedDivision: any;
  appliedhq: any;
}) => {
  return gridData.filter((Master: any) => {
    return isValidMaster({
      Master,
      appliedDivision,
      appliedDesignation,
      appliedhq,
    });
  });
};
/**
 * Employee Details Watcher
 */
export function* applyFilterWatcher() {
  yield takeLatest(applyFilterCreatorTypeName, applyFilterWatcherWorker);
}
export function* applySetDefaultFilterWatcher() {
  yield takeLatest(
    setDefaultFilterCreatorTypeName,
    setDefaultFilterWatcherWorker,
  );
  yield takeLatest(
    resetDefaultFilterCreatorTypeName,
    resetDefaultFilterWatcherWorker,
  );
}
/**
 * Employee Details Worker
 */
export function* applyFilterWatcherWorker(): any {
  const filterState = yield select(filterSelector.getFilterState());
  const componentName = yield select(routeSelector.getNavbarComponentName());
  const division = filterState?.Division;
  const designation = filterState?.Designation;
  const divisionsDropDown = yield select(
    userManagementStateSelector.getDivisionDropDown(),
  );
  const request = yield select(filterSelector.getFilterOptions());

  if (
    filterState.States &&
    filterState.Divisions &&
    filterState['Access Groups']
  ) {
    yield put(userManagementStateActions.setAdminUsersLoading(true));
    const states = filterState.States;
    const divisions = filterState.Divisions;
    const accessGroups = filterState['Access Groups'];
    const fetchAllUsers = fetchAllUsersFlag(states, divisions, accessGroups);
    if (fetchAllUsers) {
      yield put({type: fetchAdminUsersCreatorTypeName, payload: {}});
    } else {
      const adminUsers = yield select(
        userManagementStateSelector.getAdminUsersSnaShot(),
      );
      const isUserActive = yield select(
        userManagementStateSelector.getIsActiveState(),
      );
      const appliedAccessGroups = Object.keys(accessGroups.options).filter(
        key => accessGroups.options[key],
      );
      const appliedDivisions = Object.keys(divisions.options).filter(
        key => divisions.options[key],
      );
      const appliedStates = Object.keys(states.options).filter(
        key => states.options[key],
      );

      const filteredUsers = getfilteredUsers({
        adminUsers,
        isUserActive,
        appliedAccessGroups,
        appliedDivisions,
        appliedStates,
      });
      yield put(userManagementStateActions.setAdminUsers(filteredUsers));
    }

    yield put(userManagementStateActions.setAdminUsersLoading(false));
  }
}
/**
 * set default filters
 */
export function* setDefaultFilterWatcherWorker(action: any): any {
  try {
    const obj = yield select(planAndMeetSelector.getPlanAndMeetState());
    const filters = yield select(filterSelector.getFilterState());
    const request = createFilterObject(filters, obj?.filters);
    const updatedRequest = attachMTPObjectToFilters(request, filters);
    const url = API_PATH.planAndMeetStatus.defaultfilters;
    yield put(filterStateActions.setFilterLoader(true));
    const response = yield call(NetworkService.post, url, updatedRequest);
    yield put(
      planAndMeetStatusStateActions.setPreferredFilters(updatedRequest),
    );
    yield put(
      filterStateActions.setDefaultFilterResponse({
        ...response,
        actionType: action.payload,
      }),
    );
    yield put(filterStateActions.setFilterLoader(false));
  } catch (error) {
    yield put(filterStateActions.setFilterLoader(false));
  }
}
/**
 * reset default filters
 */
export function* resetDefaultFilterWatcherWorker(action: any): any {
  try {
    const url = API_PATH.planAndMeetStatus.resetFilters;
    yield put(filterStateActions.setFilterLoader(true));
    const response = yield call(NetworkService.put, url);
    yield put(
      filterStateActions.setDefaultFilterResponse({
        ...response,
        actionType: action?.payload,
      }),
    );
    yield put(planAndMeetStatusStateActions.setPreferredFilters({}));
    yield put(filterStateActions.setFilterLoader(false));
  } catch (error) {
    yield put(filterStateActions.setFilterLoader(false));
  }
}
export const getFilter = (itemA: any, itemB: any[], label: any, value: any) => {
  let Users = itemA?.filter((item1: any) => {
    return itemB?.find(item2 => {
      return item1?.['' + label + ''] === item2;
    });
  });
  return Users?.map((values: any) => {
    return values?.['' + value + ''];
  });
};
