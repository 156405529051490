import {createAction, createSlice} from '@reduxjs/toolkit';

interface InitialStateProps {
  salesTransferConfigurationData: {
    id: number;
    tabId: number;
    name: string;
  }[];
  salesTransferConfigurationDivisionData: {
    value: number;
    label: string;
  }[];
  salesTransferConfigurationQuarterData: {
    value: number;
    label: string;
  }[];
  salesTransferConfigurationConData: {
    value: number;
    label: string;
  }[];
}

export const initialState: InitialStateProps = {
  salesTransferConfigurationData: [],
  salesTransferConfigurationDivisionData: [],
  salesTransferConfigurationQuarterData: [],
  salesTransferConfigurationConData: [],
};

export const salesTransferConfigurationCreator = createAction<any>(
  'INCENTIVE/SALES_TRANSFER_CONFIGURATION',
);
export const salesTransferConfigurationTypeName =
  salesTransferConfigurationCreator(null).type;

export const addSalesTransferConfigurationCreator = createAction<any>(
  'INCENTIVE/ADD_SALES_TRANSFER_CONFIGURATION',
);
export const addsalesTransferConfigurationTypeName =
  addSalesTransferConfigurationCreator(null).type;

export const editSalesTransferConfigurationCreator = createAction<any>(
  'INCENTIVE/EDIT_SALES_TRANSFER_CONFIGURATION',
);
export const editSalesTransferConfigurationTypeName =
  editSalesTransferConfigurationCreator(null).type;

export const getSalesTransferConfigurationCreator = createAction<any>(
  'INCENTIVE/GET_SALES_TRANSFER_CONFIGURATION',
);
export const getSalesTransferConfigurationTypeName =
  getSalesTransferConfigurationCreator(null).type;

export const getSalesTransferConfigurationDivisionCreator = createAction<any>(
  'INCENTIVE/SALES_TRANSFER_CONFIGURATION_DIVISION',
);
export const getSalesTransferConfigurationDivisionTypeName =
  getSalesTransferConfigurationDivisionCreator(null).type;

export const getSalesTransferConfigurationStateCreator = createAction<any>(
  'INCENTIVE/SALES_TRANSFER_CONFIGURATION_STATE',
);
export const getSalesTransferConfigurationStateTypeName =
  getSalesTransferConfigurationStateCreator(null).type;

// For Quarter
export const getSalesTransferConfigurationQuarterCreator = createAction<any>(
  'INCENTIVE/SALES_TRANSFER_CONFIGURATION_QUARTER',
);
export const getSalesTransferConfigurationQuarterTypeName =
  getSalesTransferConfigurationQuarterCreator(null).type;

// For Configuaration
export const getSalesTransferConfigurationConCreator = createAction<any>(
  'INCENTIVE/SALES_TRANSFER_CONFIGURATION_CON',
);
export const getSalesTransferConfigurationConTypeName =
  getSalesTransferConfigurationConCreator(null).type;

const salesTransferConfigurationSlice = createSlice({
  name: 'SALES_TRANSFER_CONFIGURATION',
  initialState: initialState,
  reducers: {
    fetchSalesTransferConfigurationData: (state, action) => {
      return {
        ...state,
        salesTransferConfigurationData: action.payload,
      };
    },
    fetchNewSalesTransferConfigurationData: (state, action) => {
      return {
        ...state,
        salesTransferConfigurationData: [
          ...state.salesTransferConfigurationData,
          ...action.payload,
        ],
      };
    },
    fetchSalesTransferConfigurationDivisionData: (state, action) => {
      return {
        ...state,
        salesTransferConfigurationDivisionData: action.payload,
      };
    },
    fetchSalesTransferConfigurationStateData: (state, action) => {
      return {
        ...state,
        salesTransferConfigurationStateData: action.payload,
      };
    },
    fetchSalesTransferConfigurationQuarterData: (state, action) => {
      return {
        ...state,
        salesTransferConfigurationQuarterData: action.payload,
      };
    },
    fetchSalesTransferConfigurationConData: (state, action) => {
      return {
        ...state,
        salesTransferConfigurationConData: action.payload,
      };
    },
  },
});

export const salestransferConfigurationAction =
  salesTransferConfigurationSlice.actions;
export const salestransferConfigurationReducer =
  salesTransferConfigurationSlice.reducer;
