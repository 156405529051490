export const Gridstyle = {
  gridcss: {
    height: '70vh',
    width: '100%',
    marginTop: '2%',
    '& .MuiDataGrid-root': {
      backgroundColor: '#fff',
    },
    '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
    '& .text-align-center': {
      textAlign: 'center',
    },
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none !important',
    },
    '& .MuiTableCell-root': {
      borderBottom: '0px solid #fff !important',
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 'unset !important',
      maxHeight: 'none !important',
      whiteSpace: 'normal !important',
      wordBreak: 'break-all !important',
      color: '#1c1939',
      fontWeight: 'normal',
      fontStyle: 'normal',
      textAlign: 'left',
      fontSize: 12,
    },
    '& .MuiDataGrid-row': {
      maxHeight: 'none !important',
      wordBreak: 'break-all !important',
    },
    '& .boldHeader': {
      width: 46.7,
      height: 15.3,
      fontSize: 10.7,
      fontWeight: '600',
      fontStyle: 'normal',
      lineHeight: 21.3,
      letterSpacing: 0,
      textAlign: 'left',
      color: '#747379',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      lineHeight: 'normal !important',
      whiteSpace: 'normal !important',
      wordBreak: 'break-all !important',
      color: '#747379',
      textTransform: 'uppercase',
      letterSpacing: 0,
      textAlign: 'left',
      fontSize: 10.7,
      fontWeight: '600',
      fontStyle: 'normal',
    },
    '& .Mui-error': {
      bgcolor: (theme: {palette: {mode: string}}) =>
        `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
      color: '#f44336',
      width: '100%',
      height: '50px',
    },
  },
  IconButton: {
    padding: '0px',
    minWidth: '40px',
  },
  pointer: {
    cursor: 'pointer !important',
  },
};
