import {
  filterSelector,
  filterStateActions,
} from '@app/components/right-sidebar/redux';
import {userManagementStateSelector} from '@app/screens/access-management/pages/user-management/redux/selectors';
import {expenseDAMasterStateSelector} from '@app/screens/master-data-management/pages/expense-da-master/redux/selectors';
import {useEffect} from 'react';
import {useSelector, shallowEqual, useDispatch} from 'react-redux';

export const Filter = () => {
  const dispatch = useDispatch();
  const HQsDropdown = useSelector(expenseDAMasterStateSelector.getHQDropdown());
  const divisionsDropDown = useSelector(
    userManagementStateSelector.getDivisionDropDown(),
  );
  const designationsDropdown = useSelector(
    expenseDAMasterStateSelector.getDesignationsDropdown(),
  );
  const filters = useSelector(filterSelector.getFilterState(), shallowEqual);
  useEffect(() => {
    if (designationsDropdown?.length > 0) {
      const options: any = {};
      designationsDropdown?.map((item: any) => {
        options[item.label] = false;
        return null;
      });
      dispatch(
        filterStateActions.setFilterState({
          ...filters,
          Designation: {
            completeApplied: false,
            collapsed: true,
            options,
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [designationsDropdown]);
  useEffect(() => {
    if (divisionsDropDown?.length > 0) {
      const options: any = {};
      divisionsDropDown?.map((item: any) => {
        options[item.label] = false;
        return null;
      });
      dispatch(
        filterStateActions.setFilterState({
          ...filters,
          Division: {
            completeApplied: false,
            collapsed: true,
            options,
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divisionsDropDown]);
  useEffect(() => {
    if (HQsDropdown?.length > 0) {
      const options: any = {};
      HQsDropdown?.map((item: any) => {
        if (item.headQuaterId !== 0) options[item.headQuaterName] = false;
        return null;
      });
      dispatch(
        filterStateActions.setFilterState({
          ...filters,
          HQ: {completeApplied: false, collapsed: true, options},
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HQsDropdown]);
  useEffect(() => {
    dispatch(
      filterStateActions.setFilterOptions({
        isActive: true,
        searchText: '',
        pageNumber: 0,
        pageLimit: 100,
        divisionIds: [],
        designationIds: [],
        hqIds: [],
      }),
    );
    return () => {
      dispatch(filterStateActions.setFilterState({}));
    };
  }, [dispatch]);
  return <></>;
};
