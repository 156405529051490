import React from 'react';

interface Props {
  condition: any;
  children: any;
}

const If = (props: Props) => {
  if (props.condition && props.children) return props.children;
  return null;
};

export default React.memo(If);
