import {createSelector} from '@reduxjs/toolkit';

const tabs = (state: any) => state.tabManagementState.tabs;
const divisions = (state: any) => state.tabManagementState.divisions;
const partyGroups = (state: any) => state.tabManagementState.partyGroups;

const getTabs = createSelector([tabs], _tabs => _tabs);

const getDivisions = createSelector([divisions], division => division);

const getPartyGroups = createSelector([partyGroups], partyGroup => partyGroup);

export const tabManagemnetSelector = {
  getTabs: () => getTabs,
  getDivisions: () => getDivisions,
  getPartyGroups: () => getPartyGroups,
};
