import EditIcon from '@app/assets/svg/edit-icon';
import {API_PATH, TOAST_MESSAGES} from '@app/common/constants';
import {routeStateActions} from '@app/router/redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import NetworkService from '@app/services/network/NetworkService';
import {
  Box,
  TableRow,
  TableCell,
  FormControlLabel,
  Autocomplete,
  TextField,
  InputLabel,
  Grid,
  Button,
  Divider,
  CircularProgress,
  IconButton,
  AutocompleteCloseReason,
  ClickAwayListener,
  styled,
  Popper,
  Tooltip,
} from '@mui/material';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import Checkbox from '@mui/material/Checkbox';

import {
  directoryActions,
  directorySelector,
  actions,
  directoryNavActionHandlerCreator,
  directoryUpdateCurrentPageCreator,
} from '../../redux';
import Popup from '../../components/Popup';
import {
  fetchDivisionsCreator,
  fetchPartyGroupsCreator,
  tabManagemnetSelector,
  updateTabsCreator,
} from './redux';
import {directoryPageLists} from '../../redux/slice';
import TableList from '../../components/TableList';
import {ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';

const columns = ['Tab Name', 'Divison', 'Order no.', '', ''];

const initialAddTabData = {
  id: 0,
  division: '',
  divisionId: 0,
  isActive: 1,
  name: '',
  orderNo: 0,
  partyTypeGroup: '',
  partyTypeGroupId: 0,
  divisionIdList: [{}],
};

const PopperStyledComponent = styled(Popper)(({theme}) => ({
  border: `1px solid ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
}));

const newData: any[] = [];
const TabManagement = () => {
  const [updatedInitialAddTabData, setUpdatedInitialAddTabData] =
    useState(initialAddTabData);
  const [addTabData, setAddTabData] = useState<any>(updatedInitialAddTabData);
  const [isUpdatingTab, setIsUpdatingTab] = useState(false);
  const [updatingError, setUpdatingError] = useState<null | string>(null);
  const [value, setValue] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [open, setOpen] = useState(false);
  const [isActiveData, setIsActiveData] = useState<any>([]);
  const [valDivision, setValDivision] = useState([]);

  const checkAllChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCheckAll(event.target.checked);
      if (event.target.checked) {
        const tempData: any[] = [];
        let isActive: any[] = [];
        for (let row of divisions) {
          let selectAllData: any = {};
          let obj = isActiveData.divisionIdList.some(
            (o: {divisionId: any}) => o.divisionId === row.value,
          );
          if (obj) {
            isActive.push({
              isActive: 1,
            });
            isActiveData.divisionIdList.map((val: any): any => {
              if (val.divisionId === row.value) {
                selectAllData['divisionId'] = row.value;
                selectAllData['divisionName'] = row.label;
                selectAllData['isActive'] = isActive[0].isActive;
                selectAllData['tabName'] = val.tabName;
              }
            });
          } else {
            selectAllData['divisionId'] = row.value;
            selectAllData['divisionName'] = row.label;
            selectAllData['isActive'] = isActive[0].isActive;
            selectAllData['tabName'] = '';
          }
          tempData.push(selectAllData);
          onFormUpdateHandler({
            divisionIdListID: row.value,
            divisionIdList: tempData,
          });
        }
        setValue(divisions);
      } else {
        setValue([]);
      }
    },
    [],
  );

  const removeFromFormData = (newValue: any) => {
    let tempData: any[] = [];
    newValue?.forEach((ele: any, index: number) => {
      let removeOptionData: any = {};
      let obj = isActiveData.divisionIdList.some(
        (o: {divisionId: any}) => o.divisionId === ele.value,
      );
      if (obj) {
        isActiveData.divisionIdList.map((val: any): any => {
          if (val.divisionId === ele.value) {
            removeOptionData['divisionId'] = ele.value;
            removeOptionData['divisionName'] = ele.label;
            removeOptionData['isActive'] = val.isActive;
            removeOptionData['tabName'] = val.tabName;
          }
        });
      } else {
        removeOptionData['divisionId'] = ele.value;
        removeOptionData['divisionName'] = ele.label;
        removeOptionData['isActive'] = 1;
        removeOptionData['tabName'] = '';
      }
      tempData.push(removeOptionData);
      onFormUpdateHandler({
        divisionIdListID: ele.value,
        divisionIdList: tempData,
      });
    });
  };

  const updateFormData = (reason: any, newValue: any, isActive: any[]) => {
    if (reason === 'selectOption') {
      setValue(newValue);
      let tempData: any[] = [];
      newValue?.forEach((ele: any, index: number) => {
        let selectOptionData: any = {};
        isActiveData.divisionIdList?.forEach((e: any, index: number) => {
          if (e.divisionId === ele.value) {
            selectOptionData['divisionId'] = ele.value;
            selectOptionData['divisionName'] = ele.label;
            selectOptionData['isActive'] = isActive[0].isActive;
            selectOptionData['tabName'] = e.tabName;
          } else {
            selectOptionData['divisionId'] = ele.value;
            selectOptionData['divisionName'] = ele.label;
            selectOptionData['isActive'] = isActive[0].isActive;
          }
        });
        tempData.push(selectOptionData);
        onFormUpdateHandler({
          divisionIdListID: ele.value,
          divisionIdList: tempData,
        });
      });
    } else if (reason === 'removeOption') {
      setCheckAll(false);
      setValue(newValue);
      removeFromFormData(newValue);
    } else if (reason === 'clear') {
      setValue([]);
      setCheckAll(false);
      onFormUpdateHandler({
        divisionIdList: [],
      });
    }
  };

  const handleClickAway = useCallback((e: any) => {
    setOpen(false);
  }, []);

  const dispatch = useDispatch();

  const isActive = useSelector(directorySelector.getStatus, shallowEqual);
  const tabs = useSelector(tabManagemnetSelector.getTabs(), shallowEqual);
  const search = useSelector(directorySelector.getNavSearch(), shallowEqual);
  const modal = useSelector(
    directorySelector.getDirectoryModal(),
    shallowEqual,
  );
  const divisions = useSelector(
    tabManagemnetSelector.getDivisions(),
    shallowEqual,
  );
  const partyGroups = useSelector(
    tabManagemnetSelector.getPartyGroups(),
    shallowEqual,
  );
  const loading = useSelector(directorySelector.getLoading(), shallowEqual);

  const showAddTabModal = useMemo(
    () => modal?.show && modal?.type === actions.addTab,
    [modal],
  );

  const isTabsFetching = useMemo(
    () =>
      loading === API_PATH.directoryConfiguration.fetchTabs &&
      (!tabs || tabs?.length === 0),
    [loading, tabs],
  );

  const isUpdatingData = useMemo(
    () => loading === API_PATH.directoryConfiguration.fetchTabs,
    [loading],
  );

  const isEmpty = useMemo(
    () => !isTabsFetching && (!tabs || tabs.length === 0),
    [isTabsFetching, tabs],
  );

  const cancelAddTabModal = useCallback(() => {
    dispatch(directoryNavActionHandlerCreator({show: false, type: null}));
    setUpdatedInitialAddTabData(initialAddTabData);
    setCheckAll(false);
    setValue([]);
    setUpdatingError(null);
  }, [initialAddTabData]);

  const onAddOrUpdateTabHandler = async (e: any) => {
    e.preventDefault();
    if (addTabData.name?.length < 2) {
      setUpdatingError(
        'Name should be alphanumeric - 2-30 char long. No special character allowed.!!',
      );
    } else if (!/^\d+$/.test(addTabData.orderNo)) {
      setUpdatingError('Enter the positive number only!!!');
    } else if (
      addTabData.orderNo &&
      addTabData.name?.toString().trim() &&
      addTabData.divisionId?.toString().trim() &&
      addTabData.divisionIdList?.length !== 0
    ) {
      setIsUpdatingTab(true);
      const updatedAddTabData = {...addTabData};
      if (!updatedAddTabData.partyTypeGroup) {
        delete updatedAddTabData.partyTypeGroup;
        delete updatedAddTabData.partyTypeGroupId;
      }
      try {
        const response = await NetworkService.post(
          API_PATH.directoryConfiguration.updateTab,
          updatedAddTabData,
          {},
        );
        setIsUpdatingTab(false);
        if (response.status !== 200) {
          ToasterService.showToaster(
            TOAST_MESSAGES.UNSUCCESSFULL_UPDATE,
            ToastType.ERROR,
          );
          setUpdatingError(response.data.description);
        } else {
          ToasterService.showToaster(
            TOAST_MESSAGES.SUCCESSFULLY_UPDATED,
            ToastType.SUCCESS,
          );
          /**** fetch tabs list */
          dispatch(
            updateTabsCreator({
              index: 1,
              isActive: isActive === 1,
              query: search?.query,
            }),
          );

          cancelAddTabModal();
        }
      } catch (error: any) {
        setIsUpdatingTab(false);
        setUpdatingError(error.message);
      }
    } else {
      setUpdatingError('Please fill all required fields!!');
    }
  };

  const onFormUpdateHandler = (data: any) => {
    if (updatingError) {
      setUpdatingError(null);
    }
    setAddTabData({
      ...addTabData,
      ...data,
    });
  };

  const onFormChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      onFormUpdateHandler({[event.target.name]: event.target.value}),
    [onFormUpdateHandler],
  );

  const handleDivisionChange = useCallback(
    (event: any, newValue: any, reason: any) => {
      if (newValue.length === 0) {
        onFormUpdateHandler({
          divisionIdList: [],
        });
      }
      let isActive: any[] = [];
      for (let row of newValue) {
        let divisionData: any = {};
        let obj = isActiveData.divisionIdList.some(
          (o: {divisionId: any}) => o.divisionId === row.value,
        );
        if (obj) {
          isActive.push({
            isActive: 1,
          });
        }
        isActiveData.divisionIdList?.forEach((e: any, index: number) => {
          if (e.divisionId === row.value) {
            divisionData['divisionId'] = row.value;
            divisionData['divisionName'] = row.label;
            divisionData['isActive'] = isActive[0].isActive;
            divisionData['tabName'] = e.tabName;
          } else {
            divisionData['divisionId'] = row.value;
            divisionData['divisionName'] = row.label;
            divisionData['isActive'] = isActive[0].isActive;
          }
        });
        newData.push(divisionData);
        onFormUpdateHandler({
          divisionIdListID: row.value,
          divisionIdList: newData,
        });
      }

      updateFormData(reason, newValue, isActive);

      if (newValue?.length === divisions?.length) {
        setCheckAll(true);
        setValue(divisions ? divisions : []);
      }
    },
    [isActiveData, onFormUpdateHandler],
  );

  const tabEditHandler = (tab: any, tabs__: any) => {
    let tempData: any[] = [];
    for (let row of divisions) {
      let selectAllData: any = {};
      let obj = tab.divisionIdList.some(
        (o: {divisionId: any}) => o.divisionId === row.value,
      );
      if (obj) {
        tab.divisionIdList.map((val: any): any => {
          if (val.divisionId === row.value) {
            selectAllData['divisionId'] = row.value;
            selectAllData['divisionName'] = row.label;
            selectAllData['isActive'] = val.isActive;
            selectAllData['tabName'] = val.tabName;
          }
        });
      } else {
        selectAllData['divisionId'] = row.value;
        selectAllData['divisionName'] = row.label;
        selectAllData['tabName'] = tab.name;
      }
      tempData.push(selectAllData);
    }
    setValue(divisions);

    setUpdatedInitialAddTabData({
      id: tab.id,
      division: '',
      divisionId: 0,
      isActive: tab.isActive,
      name: tab.name,
      orderNo: tabs__.orderNo,
      partyTypeGroup: tabs__.partyTypeGroup,
      partyTypeGroupId: tab.partyTypeGroupId,
      divisionIdList: tempData,
    });
    dispatch(
      directoryNavActionHandlerCreator({show: true, type: actions.addTab}),
    );
  };

  const onLoadMoreHandler = useCallback(
    (index: number) => {
      dispatch(
        updateTabsCreator({
          index,
          isActive: isActive === 1,
          query: search?.query,
        }),
      );
    },
    [search, isActive],
  );

  const onResetHandler = useCallback(() => {
    setAddTabData(updatedInitialAddTabData);
    setValue(valDivision);
  }, [updatedInitialAddTabData, valDivision]);

  const onDivisionDropdownClose = useCallback(
    (e: any, reason: AutocompleteCloseReason) => {
      if (reason === 'escape') {
        setOpen(false);
      }
    },
    [],
  );

  const onDivisionDropdownOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const renderDivisionDropdownOption = useCallback(
    (props, option: any, {selected}) => (
      <li {...props}>
        <Checkbox style={{marginRight: 8}} checked={selected || checkAll} />
        {option?.label}
      </li>
    ),
    [],
  );

  const renderDivisionDropdownInput = useCallback(
    params => <TextField {...params} label="select" placeholder="select" />,
    [],
  );

  const onPartyGroupDropdownChange = useCallback(
    (_, val: any) =>
      onFormUpdateHandler({
        partyTypeGroupId: val.value,
        partyTypeGroup: val.label,
      }),
    [],
  );

  const renderPartyGroupDropdownInput = useCallback(
    params => <TextField {...params} placeholder="Select" />,
    [],
  );

  const onPreventDefaultHandler = useCallback(e => e.preventDefault(), []);

  const getDivisionDropdownOptionLabel = useCallback(
    (option: any) => option?.label,
    [],
  );
  const onChangeIsTabActiveHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      onFormUpdateHandler({
        isActive: event.target.checked ? 1 : 0,
      }),
    [onFormUpdateHandler],
  );

  const onTabNameTextChangeHandler = useCallback(
    (event: any) => {
      const ele = JSON.parse(event.target.dataset.current);
      const temp = {
        ...ele,
        tabName: event.target.value,
      };
      let updateMap = addTabData?.divisionIdList?.map((value: any) => {
        if (value.divisionId === ele.divisionId) {
          return {
            ...temp,
          };
        } else {
          return {
            ...value,
          };
        }
      });
      onFormUpdateHandler({
        divisionIdList: updateMap,
      });
    },
    [addTabData, onFormUpdateHandler],
  );

  const onTabNameActiveChangeHandler = useCallback(
    event => {
      const ele = JSON.parse(event.target.name);
      const temp = {
        ...ele,
        isActive: event.target.checked ? 1 : 0,
      };

      let updateMap = addTabData?.divisionIdList?.map((value: any) => {
        if (value.divisionId === ele.divisionId) {
          return {
            ...temp,
          };
        } else {
          return {
            ...value,
          };
        }
      });

      onFormUpdateHandler({
        divisionIdList: updateMap,
      });
    },
    [addTabData, onFormUpdateHandler],
  );

  const configAPI = useCallback(
    async event => {
      const tab = JSON.parse(event.currentTarget.dataset.current);
      setValue([]);

      const response = await NetworkService.get(
        API_PATH.directoryConfiguration.getdirectoryconfigtabbytabid,
        {tabId: tab.id},
        {},
      );

      setIsActiveData(response.data);
      const defaultCheck = divisions?.filter(function (div: any) {
        return response.data.divisionIdList?.some(function (_div: any) {
          return div.value === _div.divisionId;
        });
      });
      setValue(defaultCheck);
      setValDivision(defaultCheck);
      if (defaultCheck?.length === divisions.length) {
        setCheckAll(true);
      }
      tabEditHandler(response.data, tab);
      setUpdatingError(null);
    },
    [divisions, tabEditHandler],
  );

  const renderPopperComponent = useCallback(
    param => (
      <PopperStyledComponent {...param}>
        <Box {...param} />
        <Divider />
        <Box
          sx={{
            backgroundColor: '#fff',
            height: '45px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            paddingLeft: 2,
            paddingTop: 1,
          }}
        >
          <Checkbox
            checked={checkAll}
            onChange={checkAllChange}
            id="check-all"
            sx={{marginRight: '8px'}}
            onMouseDown={onPreventDefaultHandler}
          />
          Select All
        </Box>
      </PopperStyledComponent>
    ),
    [checkAllChange, checkAll],
  );

  useEffect(() => {
    setAddTabData(updatedInitialAddTabData);
  }, [updatedInitialAddTabData]);

  useEffect(() => {
    /**** fetch tabs list */
    dispatch(
      updateTabsCreator({
        index: 1,
        isActive: isActive === 1,
        query: search?.query,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, search]);

  useEffect(() => {
    /***** fetch divisions */
    dispatch(fetchDivisionsCreator({}));

    /***** fetch party groups */
    dispatch(fetchPartyGroupsCreator({}));

    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.directoryConfiguration,
      ),
    );
    dispatch(
      directoryActions.updateNavData({
        title: 'Tab Management',
        search: {placeholder: 'Search here', query: ''},
        status: {show: true, value: true},
      }),
    );
    dispatch(
      directoryUpdateCurrentPageCreator(directoryPageLists.tabManagement),
    );
    return () => {
      dispatch(routeStateActions.setNavbarComponentName(null));
      cancelAddTabModal();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isTabsFetching) {
    return (
      <Box justifyContent={'center'} display="flex" sx={{mt: 2}}>
        <CircularProgress sx={{color: 'primary.main'}} disableShrink />
      </Box>
    );
  }

  const divName = (__tab: any) => {
    if (__tab?.division.length > 15) {
      return __tab?.division?.slice(0, 18) + '...';
    } else {
      return __tab?.division;
    }
  };
  return (
    <>
      <TableList
        columns={columns}
        isEmpty={isEmpty}
        onLoadMore={onLoadMoreHandler}
        isPaging={true}
        isLoading={isUpdatingData}
      >
        {tabs && tabs?.length > 0
          ? tabs?.map((tab: any, index: number) => (
              <>
                <TableRow key={tab.id.toString()}>
                  <TableCell>{tab.name}</TableCell>
                  <TableCell>
                    <Tooltip title={tab.division}>
                      <div>{tab?.division ? divName(tab) : ' -- '}</div>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="right" sx={{width: '10%'}}>
                    {tab.orderNo}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right">
                    <IconButton
                      sx={{p: 0}}
                      data-current={JSON.stringify(tab)}
                      onClick={configAPI}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <Divider
                  component={TableRow}
                  variant="fullWidth"
                  sx={{height: 5, border: 0}}
                />
              </>
            ))
          : null}
      </TableList>
      <Popup
        open={!!showAddTabModal}
        isLoading={isUpdatingTab}
        cancelPopup={cancelAddTabModal}
        error={updatingError}
        onClose={cancelAddTabModal}
        title=" Edit Tab"
      >
        <form
          onSubmit={onAddOrUpdateTabHandler}
          style={{maxHeight: 400, overflow: 'auto'}}
        >
          <ClickAwayListener onClickAway={handleClickAway}>
            <Box sx={{mb: 3}}>
              <InputLabel variant="standard" required>
                Division
              </InputLabel>
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box>
                  <Autocomplete
                    freeSolo
                    multiple
                    disabled
                    disableCloseOnSelect
                    limitTags={3}
                    size="medium"
                    options={divisions ? divisions : []}
                    value={value}
                    open={open}
                    onChange={handleDivisionChange}
                    onClose={onDivisionDropdownClose}
                    onOpen={onDivisionDropdownOpen}
                    PopperComponent={renderPopperComponent}
                    getOptionLabel={getDivisionDropdownOptionLabel}
                    renderOption={renderDivisionDropdownOption}
                    style={{width: '100%'}}
                    renderInput={renderDivisionDropdownInput}
                  />
                </Box>
              </ClickAwayListener>
            </Box>
          </ClickAwayListener>
          <Box sx={{mb: 3}}>
            <InputLabel variant="standard">Party Type</InputLabel>
            <Autocomplete
              options={partyGroups ? partyGroups : []}
              freeSolo
              disabled={!partyGroups || partyGroups?.length === 0}
              renderInput={renderPartyGroupDropdownInput}
              onChange={onPartyGroupDropdownChange}
              value={addTabData.partyTypeGroup}
            />
          </Box>
          <Box sx={{mb: 3}}>
            <InputLabel variant="standard" required>
              Name
            </InputLabel>
            <TextField
              style={{
                backgroundColor: '#EBECF0',
              }}
              fullWidth
              placeholder="Enter Text"
              value={addTabData.name}
              required
              disabled
              type="text"
              name="name"
              onChange={onFormChangeHandler}
              inputProps={{
                maxlength: 30,
              }}
            />
          </Box>
          <Box sx={{mb: 3}}>
            <InputLabel variant="standard" required>
              Order Number
            </InputLabel>
            <TextField
              style={{
                backgroundColor:
                  addTabData.orderNo === 1 ? '#EBECF0' : '#FFFFFF',
              }}
              fullWidth
              placeholder="Enter"
              value={addTabData.orderNo}
              required
              type={'number'}
              name={'orderNo'}
              disabled={addTabData.orderNo === 1 ? true : false}
              onChange={onFormChangeHandler}
            />
          </Box>
          <Box
            sx={{
              mb: 3,
            }}
          >
            <Box
              sx={{
                flexDirection: 'column',
              }}
            >
              {addTabData?.divisionIdList?.length > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <InputLabel
                    variant="standard"
                    required
                    sx={{mb: 2.5, width: '39%'}}
                  >
                    Divison
                  </InputLabel>
                  <InputLabel
                    variant="standard"
                    sx={{mb: 2.5, width: '45%'}}
                    required
                  >
                    Name
                  </InputLabel>
                  <InputLabel variant="standard" required>
                    Is Active
                  </InputLabel>
                </Box>
              )}

              {addTabData?.divisionIdList?.map((ele: any, index: number) => {
                return (
                  <Box
                    key={ele.divisionName}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      mb: 2.5,
                      alignItems: '',
                    }}
                  >
                    <InputLabel variant="standard" sx={{width: 180}}>
                      {ele?.divisionName}
                    </InputLabel>
                    <Box
                      sx={{
                        display: 'flex',
                        mb: 2,
                      }}
                    >
                      <TextField
                        fullWidth
                        placeholder="Enter Text"
                        value={ele?.tabName}
                        // key={ele?.tabName ? ele?.tabName : addTabData.name}
                        required
                        type="text"
                        onChange={onTabNameTextChangeHandler}
                        inputProps={{
                          maxlength: 30,
                          'data-current': JSON.stringify(ele),
                        }}
                      />
                      <Box sx={{ml: 2}}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={ele?.isActive === 1}
                              onChange={onTabNameActiveChangeHandler}
                              name={JSON.stringify(ele)}
                            />
                          }
                          label=""
                        />
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box sx={{mb: 2}}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={addTabData.isActive === 1}
                  onChange={onChangeIsTabActiveHandler}
                />
              }
              label="Is Active"
              labelPlacement="top"
            />
          </Box>
          <Grid container justifyContent={'center'} alignItems={'center'}>
            <Grid item mr={2}>
              <Button variant="outlined" onClick={onResetHandler}>
                Reset
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" type="submit">
                Save
              </Button>
            </Grid>
          </Grid>
          <div style={{height: 5}}></div>
        </form>
      </Popup>
    </>
  );
};

export default TabManagement;
