import React, {useEffect} from 'react';
import {Alert, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {GridCloseIcon} from '@mui/x-data-grid';
import {keyframes} from '@emotion/react';

const slideDown = keyframes`
0% {
    transform: translateY(-90%);
  }
  100% {
    transform: translateY(0);
  }
`;

const ErrorPopup = (props: any) => {
  useEffect(() => {
    if (props.isVisible) {
      const timerId = setTimeout(() => {
        props.close();
      }, 5000);

      return () => clearTimeout(timerId);
    }
  }, [props.isVisible]);

  return (
    <Box>
      {props.isVisible && (
        <Alert
          id="animatedAlert"
          icon={false}
          sx={{
            position: 'absolute',
            top: 20,
            zIndex: 999999,
            width: 'auto',
            left: '37%',
            backgroundColor: 'red',
            display: 'flex',
            alignItems: 'center',
            visibility: 'visible',
            opacity: 1,
            animation: `${slideDown} 0.3s ease-out`,
          }}
          severity="warning"
        >
          <Box sx={{display: 'flex', flexDirection: 'row'}}>
            <Typography sx={{color: '#fff', fontSize: 12}}>
              {props?.massage}
            </Typography>
            <Box onClick={() => props.close()}>
              <GridCloseIcon
                style={{
                  color: 'black',
                  position: 'absolute',
                  top: '-12px',
                  right: '-9px',
                  backgroundColor: '#fff',
                  borderRadius: '50%',
                }}
              />
            </Box>
          </Box>
        </Alert>
      )}
    </Box>
  );
};

export default React.memo(ErrorPopup);
