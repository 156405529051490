import {API_PATH} from '@app/common/constants';
import {NetworkService, ToasterService} from '@app/services';
import {
  salestransferConfigurationAction,
  salesTransferConfigurationTypeName,
  getSalesTransferConfigurationTypeName,
  getSalesTransferConfigurationDivisionTypeName,
  getSalesTransferConfigurationStateTypeName,
  getSalesTransferConfigurationQuarterTypeName,
  getSalesTransferConfigurationConTypeName,
} from './slice';
import {call, put, takeLatest} from 'redux-saga/effects';

import {ToastType} from '@app/services/toaster';
import {incentiveActions} from '@app/screens/incentive-configuration/redux';
import {incentivePageLists} from '@app/screens/incentive-configuration/redux/slice';

/**
 * Fetch salesTransferConfiguration List Watcher
 */

export function* fetchSalesTransferConfigurationWatcher() {
  yield takeLatest(
    salesTransferConfigurationTypeName,
    fetchSalesTransferConfigurationWorker,
  );
}

export function* fetchSalesTransferConfigurationWorker(action: any): any {
  const {
    isActive,
    quarterId,
    quarter,
    configurationId,
    divisionIdList,
    pageIndex,
    stateIdList,
  } = action.payload;

  try {
    yield put(
      incentiveActions.updateLoading(
        API_PATH.incentiveConfiguration.fetchSalesTransferConfiguration,
      ),
    );
    yield put(incentiveActions.toggleFetchingData(true));
    const response = yield call(
      NetworkService.post,
      API_PATH.incentiveConfiguration.fetchSalesTransferConfiguration,
      {
        pageIndex: pageIndex,
        isActive: isActive,
        pageSize: 15,
        quarterId: quarterId,
        quarter: quarter,
        configurationId: configurationId,
        divisionIdList: divisionIdList,
        stateIdList: stateIdList,
      },
      {},
    );
    if (response?.data) {
      if (pageIndex === 1) {
        yield put(
          incentiveActions.updateTotalRecords({
            listing: incentivePageLists.salesTransferConfiguration,
            count: response?.data?.totalRecords || 0,
            pageIndex: pageIndex,
          }),
        );
        yield put(
          salestransferConfigurationAction.fetchSalesTransferConfigurationData(
            response.data.salesTransferConfigList,
          ),
        );
      } else {
        yield put(
          incentiveActions.updateTotalRecords({
            listing: incentivePageLists.salesTransferConfiguration,
            pageIndex: pageIndex,
          }),
        );
        yield put(
          salestransferConfigurationAction.fetchNewSalesTransferConfigurationData(
            response.data.salesTransferConfigList,
          ),
        );
      }
    }
    yield put(incentiveActions.updateLoading(null));
  } catch (error) {
    yield put(incentiveActions.updateLoading(null));
    ToasterService.showToaster(
      'Something went wrong while fetching admin data!',
      ToastType.ERROR,
    );
  } finally {
    yield put(incentiveActions.toggleFetchingData(false));
  }
}

/**
 * Fetch Division List Watcher
 */

export function* getSalesTransferConfigurationDivisionTypesWatcher() {
  yield takeLatest(
    getSalesTransferConfigurationDivisionTypeName,
    getSalesTransferConfigurationDivisionTypesWorker,
  );
}

export function* getSalesTransferConfigurationDivisionTypesWorker(): any {
  try {
    const url = API_PATH.incentiveConfiguration.division;
    const response = yield call(NetworkService.get, url, {}, {});

    if (response.status === 200) {
      yield put(
        salestransferConfigurationAction.fetchSalesTransferConfigurationDivisionData(
          response.data,
        ),
      );
    }
  } catch (error) {}
}
// /**
//  * Fetch state List Watcher
//  */

export function* getSalesTransferConfigurationStateTypesWatcher() {
  yield takeLatest(
    getSalesTransferConfigurationStateTypeName,
    getSalesTransferConfigurationStateTypesWorker,
  );
}

export function* getSalesTransferConfigurationStateTypesWorker(): any {
  try {
    const url = API_PATH.incentiveConfiguration.state;
    const response = yield call(NetworkService.get, url, {}, {});

    if (response.status === 200) {
      yield put(
        salestransferConfigurationAction.fetchSalesTransferConfigurationStateData(
          response.data,
        ),
      );
    }
  } catch (error) {}
}

export function* getSalesTransferConfigurationTypesWatcher() {
  yield takeLatest(
    getSalesTransferConfigurationTypeName,
    getSalesTransferConfigurationTypesWorker,
  );
}

export function* getSalesTransferConfigurationTypesWorker(): any {
  try {
    const url = API_PATH.incentiveConfiguration.getRequestTypes;
    const response = yield call(NetworkService.get, url, {}, {});

    if (response.status === 200) {
      yield put(incentiveActions.setRequestForListData(response.data));
    }
  } catch (error) {}
}

// /**
//  * Fetch Quarter  Watcher
//  */

export function* fetchSalesTransferConfigurationQuarterWatcher() {
  yield takeLatest(
    getSalesTransferConfigurationQuarterTypeName,
    fetchSalesTransferConfigurationQuarterWorker,
  );
}

export function* fetchSalesTransferConfigurationQuarterWorker(): any {
  try {
    const url = API_PATH.cutOffDateConfiguration.fetchYearlyQuarter;
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === 200) {
      yield put(
        salestransferConfigurationAction.fetchSalesTransferConfigurationQuarterData(
          response.data,
        ),
      );
    }
  } catch (error) {}
}

// Fetch Configuaration Watcher

export function* fetchSalesTransferConfigurationConWatcher() {
  yield takeLatest(
    getSalesTransferConfigurationConTypeName,
    fetchSalesTransferConfigurationConWorker,
  );
}

export function* fetchSalesTransferConfigurationConWorker(): any {
  try {
    const url = API_PATH.incentiveConfiguration.configuration;
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status === 200) {
      yield put(
        salestransferConfigurationAction.fetchSalesTransferConfigurationConData(
          response.data,
        ),
      );
    }
  } catch (error) {}
}
