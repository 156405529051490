import {createAction, createSlice} from '@reduxjs/toolkit';

interface initialStateProps {
  socialMediaCategoryList: any;
}

const initialState: initialStateProps = {
  socialMediaCategoryList: null,
};

export const socialMediaCategoryListCreator = createAction<any>(
  'DIRECTORY/SOCIAL_MEDIA_CATEGORY_LIST',
);
export const socialMediaCategoryTypeName =
  socialMediaCategoryListCreator(null).type;

const socialMediaCategorySlice = createSlice({
  name: 'SOCIAL_MEDIA_CATEGORY',
  initialState: initialState,
  reducers: {
    fetchSocialMediaCategoryList: (state, action) => {
      return {
        ...state,
        socialMediaCategoryList: action.payload,
      };
    },
    fetchSocialMediaCategoryNewList: (state, action) => {
      return {
        ...state,
        socialMediaCategoryList: [
          ...state.socialMediaCategoryList,
          ...action.payload,
        ],
      };
    },
  },
});

export const socialMediaCategoryActions = socialMediaCategorySlice.actions;
export const socialMediaCategorytReducer = socialMediaCategorySlice.reducer;
