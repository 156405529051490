export const searchObj = (isActive: number, search: any) => {
  let searchList = {
    index: 1,
    isActive: isActive === 1,
    query: search?.query,
  };
  return searchList;
};

export const tabTitleObj = (title: string, actions: any) => {
  let tabTitleData = {
    title: title,
    action: {title: 'Add New', type: actions.addTab},
    search: {placeholder: 'Search here', query: ''},
    status: {show: true, value: true},
  };
  return tabTitleData;
};

export const headerButton = (title: string, actions: any) => {
  let tabButtonData = {
    title: title,
    action: {title: 'Matrix Properties', type: actions.addTab},
    search: {placeholder: 'Search here', query: ''},
    status: {show: true, value: true},
  };
  return tabButtonData;
};
