import {createSelector} from '@reduxjs/toolkit';

const hoContactCategories = (state: any) =>
  state.hoContactCategoryState.hoContactCategory;

const getHoContactCategory = createSelector(
  [hoContactCategories],
  _hoContactCategories => _hoContactCategories,
);

export const hoContactCategorySelector = {
  gethocontactcategorydetails: () => getHoContactCategory,
};
