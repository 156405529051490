import {call, put, takeLatest} from 'redux-saga/effects';
import {
  fetchFromHQTypeName,
  fetchSLMTypename,
  fetchGroupPoolDataTypename,
  fetchSalesTransferDataTypename,
  fetchToHQTypeName,
  fetchYearlyQuartersTypeName,
  inactiveNavbarDropdownCreatorTypeName,
  inactiveNavbarDropdownDivisionCreatorTypeName,
  incentiveActions,
  incentiveNavActionHandlerCreatorTypeName,
  incentiveNavSearchQueryCreatorTypeName,
  incentiveNavToggleCreatorTypeName,
  incentiveUpdateCurrentPageCreatorTypeName,
  inactiveNavbarFilterIconCreatorTypeName,
  fetchfromPoolTypename,
  fetchPoolTypename,
  fetchSLMSalesDistributionDataTypename,
  fetchPoolSalesDistributionDataTypename,
  incentiveSaveCreatorTypeName,
  fetchSalesTransferGoBtnTypename,
  fetchsalesDistributionListDataTypename,
  fetchsalesDistributionRemarksDataTypename,
  fetchDownloadExcelURLSalesTransferTypeName,
  fetchDownloadExcelURLSalesDistributionTypeName,
  fetchdDownloadExcelURLSalesDistributionCreator,
  fetchdDownloadExcelURLSalesTransferCreator,
  incentivePageLists,
} from './slice';

import {API_PATH} from '@app/common/constants';
import {NetworkService} from '@app/services';

export function* incentiveNavSearchQueryWatcher(action: any) {
  yield takeLatest(
    incentiveNavSearchQueryCreatorTypeName,
    incentiveNavSearchQueryWorker,
  );
}

function* incentiveNavSearchQueryWorker(action: any) {
  yield put(incentiveActions.updateNavSearchQuery(action.payload));
}

export function* incentiveSaveQueryWatcher(action: any) {
  yield takeLatest(incentiveSaveCreatorTypeName, incentiveSaveQueryWorker);
}

function* incentiveSaveQueryWorker(action: any) {
  yield put(incentiveActions.updateSaveQuery(action.payload));
}

export function* incentiveNavToggleWatcher(action: any) {
  yield takeLatest(incentiveNavToggleCreatorTypeName, incentiveNavToggleWorker);
}

function* incentiveNavToggleWorker(action: any) {
  yield put(incentiveActions.updateNavToggle(action.payload));
}

export function* incentiveNavActionHandlerWatcher(action: any) {
  yield takeLatest(
    incentiveNavActionHandlerCreatorTypeName,
    incentiveNavActionHandlerWorker,
  );
}

function* incentiveNavActionHandlerWorker(action: any) {
  yield put(incentiveActions.updateModalHandler(action.payload));
}

function* incentiveNavStateListWorker(): any {
  try {
    const url = API_PATH.incentiveConfiguration.state;
    const res = yield call(NetworkService.get, url, {}, {});
    yield put(incentiveActions.updateStatesList(res.data));
  } catch (error) {}
}

export function* incentiveNavStateLisWatcher() {
  yield takeLatest(
    inactiveNavbarDropdownCreatorTypeName,
    incentiveNavStateListWorker,
  );
}

export function* incentiveNavDivisionListWatcher() {
  yield takeLatest(
    inactiveNavbarDropdownDivisionCreatorTypeName,
    incentiveNavDivisionListWorker,
  );
}

function* incentiveNavDivisionListWorker(action: any): any {
  try {
    const url = API_PATH.incentiveConfiguration.incentiveDivision;
    const res = yield call(NetworkService.get, url, {}, {});
    if (res?.data) yield put(incentiveActions.updateDivisionList(res.data));
    yield put(incentiveActions.updateLoading(null));
  } catch (error) {
    console.log('error ===>', error);
  }
}

export function* incentiveUpdateCurrentPageWatcher(action: any) {
  yield takeLatest(
    incentiveUpdateCurrentPageCreatorTypeName,
    incentiveUpdateCurrentPageWorker,
  );
}

function* incentiveUpdateCurrentPageWorker(action: any) {
  yield put(incentiveActions.updateCurrentPage(action.payload));
}

// incentive distribution Saga
export function* fetchYearlyQuartersWatcher() {
  yield takeLatest(fetchYearlyQuartersTypeName, fetchYearlyQuartersWorker);
}

function* fetchYearlyQuartersWorker(action: any): any {
  try {
    const url = API_PATH.incentiveConfiguration.getQuarter;
    const response = yield call(NetworkService.get, url, {}, {});
    if (response?.data) {
      yield put(incentiveActions.setQuarterData(response?.data || []));
    }
    yield put(incentiveActions.updateLoading(null));
  } catch (error) {
    console.log('error ==>', error);
  }
}

export function* fetchFromHQWatcher() {
  yield takeLatest(fetchFromHQTypeName, fetchFromHQWorker);
}

function* fetchFromHQWorker(action: any): any {
  try {
    const url = API_PATH.incentiveConfiguration.getHQ;
    const response = yield call(NetworkService.get, url, action.payload, {});
    if (response?.data) {
      yield put(incentiveActions.setFromHQData(response?.data || []));
    }
  } catch (error) {
    console.log('error ==>', error);
  }
}

export function* fetchPoolWatcher() {
  yield takeLatest(fetchPoolTypename, fetchPoolWorker);
}

function* fetchPoolWorker(action: any): any {
  const {from, StaffPositionId, QuarterId, grouppoolid, divisionId} =
    action.payload;

  try {
    const url = API_PATH.incentiveConfiguration.getPoolSalesDistribution;
    const response = yield call(
      NetworkService.get,
      url,
      {
        StaffPositionId: StaffPositionId,
        QuarterId: QuarterId,
        grouppoolid: grouppoolid,
        divisionId: divisionId,
      },
      {},
    );
    if (response?.data) {
      if (from === 'fromGroup') {
        yield put(incentiveActions.setFromPoolFilter(response?.data || []));
      } else if (from === 'toGroup') {
        yield put(incentiveActions.setToPoolFilterData(response?.data || []));
      } else if (from === 'fromGrpOfPool') {
        yield put(incentiveActions.setFilterPoolData(response?.data));
      } else {
        yield put(incentiveActions.setPoolData(response?.data || []));
        yield put(incentiveActions.setToPoolData(response?.data || []));
      }
    }
  } catch (error) {
    console.log('error ==>', error);
  }
}

export function* fetchFromPoolWatcher() {
  yield takeLatest(fetchfromPoolTypename, fetchFromPoolWorker);
}

function* fetchFromPoolWorker(action: any): any {
  const {StaffPositionId, QuarterId} = action.payload;
  try {
    const url = API_PATH.incentiveConfiguration.fetchGroupPool;
    const response = yield call(
      NetworkService.get,
      url,
      {
        StaffPositionId: StaffPositionId,
        QuarterId: QuarterId,
      },
      {},
    );

    if (response?.data) {
      yield put(
        incentiveActions.setFromPoolData(response?.data.groupPoolList || []),
      );
      yield put(
        incentiveActions.setToGroupOfpool(response?.data.groupPoolList || []),
      );
    }
  } catch (error) {
    console.log('error ==>', error);
  }
}

export function* fetchToHQWatcher() {
  yield takeLatest(fetchToHQTypeName, fetchToHQWorker);
}

function* fetchToHQWorker(action: any): any {
  try {
    const url = API_PATH.incentiveConfiguration.getHQ;
    const response = yield call(NetworkService.get, url, action.payload, {});
    if (response?.data) {
      yield put(incentiveActions.setToHQData(response?.data || []));
    }
  } catch (error) {
    console.log('error ==>', error);
  }
}

export function* fetchSLMWatcher() {
  yield takeLatest(fetchSLMTypename, fetchSLMWorker);
}

function* fetchSLMWorker(action: any): any {
  try {
    const url = API_PATH.incentiveConfiguration.getSLM;
    const response = yield call(NetworkService.get, url, action.payload, {});
    if (response?.data) {
      yield put(incentiveActions.setSLMData(response?.data));
    }
  } catch (error) {
    console.log('error ==>', error);
  }
}

export function* fetchSalesTransferGoBtnWatcher() {
  yield takeLatest(
    fetchSalesTransferGoBtnTypename,
    fetchSalesTransferGoBtnWorker,
  );
}

function* fetchSalesTransferGoBtnWorker(action: any): any {
  try {
    yield put(incentiveActions.setClickGoValue(action.payload));
  } catch (error) {
    yield put(incentiveActions.setClickGoValue({val: 0, isEdited: false}));
    console.log('error ==>', error);
  }
}

export function* fetchSalesTransferWatcher() {
  yield takeLatest(fetchSalesTransferDataTypename, fetchSalesTransferWorker);
}

function* fetchSalesTransferWorker(action: any): any {
  try {
    yield put(incentiveActions.setSalesTransferLoading(true));
    const url = API_PATH.incentiveConfiguration.getSalesTransferData;
    const response = yield call(NetworkService.post, url, action.payload, {});
    // if (response?.data) {
    //   const updatedData = {
    //     ...response.data,
    //     segmentList: addTotalInSegmentList(response?.data.segmentList),
    //   }
    yield put(incentiveActions.setSalesTransferData(response?.data));
    yield put(incentiveActions.setSalesTransferBackupData(response?.data));
    yield put(
      incentiveActions.updateTotalRecords({
        listing: incentivePageLists.salesTransfer,
      }),
    );
    yield put(fetchdDownloadExcelURLSalesTransferCreator(action.payload));
  } catch (error) {
    console.log('error ==>', error);
  } finally {
    yield put(incentiveActions.setSalesTransferLoading(false));
  }
}

export function* fetchGroupPoolWatcher() {
  yield takeLatest(fetchGroupPoolDataTypename, fetchGroupPoolWorker);
}

function* fetchGroupPoolWorker(action: any): any {
  try {
    const url = API_PATH.incentiveConfiguration.fetchGroupPool;
    const response = yield call(NetworkService.get, url, action.payload, {});
    if (response?.data) {
      yield put(
        incentiveActions.setGroupPoolData(response?.data?.groupPoolList),
      );
    }
  } catch (error) {
    console.log('error ==>', error);
  }
}
export function* directoryNavFilterIconWatcher(action: any) {
  yield takeLatest(
    inactiveNavbarFilterIconCreatorTypeName,
    directoryNavfilterIconWorker,
  );
}
function* directoryNavfilterIconWorker(action: any) {
  yield put(incentiveActions.updateFilterIcon(action.payload));
}

export function* fetchSLMSalesDistributionWatcher() {
  yield takeLatest(
    fetchSLMSalesDistributionDataTypename,
    fetchSLMSalesDistributionWorker,
  );
}

function* fetchSLMSalesDistributionWorker(action: any): any {
  try {
    const url = API_PATH.incentiveConfiguration.getSLMSalesDistribution;
    const response = yield call(NetworkService.get, url, action.payload, {});
    if (response?.data) {
      yield put(incentiveActions.setSLMSalesDistributionData(response?.data));
    }
  } catch (error) {
    console.log('error ==>', error);
  }
}

export function* fetchPoolSalesDistributionWatcher() {
  yield takeLatest(
    fetchPoolSalesDistributionDataTypename,
    fetchPoolSalesDistributionWorker,
  );
}

function* fetchPoolSalesDistributionWorker(action: any): any {
  try {
    const url = API_PATH.incentiveConfiguration.getPoolSalesDistribution;
    const response = yield call(NetworkService.get, url, action.payload, {});
    if (response?.data) {
      yield put(incentiveActions.setPoolSalesDistributionData(response?.data));
    }
  } catch (error) {
    console.log('error ==>', error);
  }
}

/**
 * Sales distribution screen
 */

export function* fetchSalesDistributionListDataWatcher() {
  yield takeLatest(
    fetchsalesDistributionListDataTypename,
    fetchSalesDistributionListDataWorker,
  );
}

function* fetchSalesDistributionListDataWorker(action: any): any {
  try {
    yield put(incentiveActions.setSalesTransferLoading(true));
    const url = API_PATH.incentiveConfiguration.getSalesDistributionSearch;
    const response = yield call(NetworkService.get, url, action.payload, {});
    if (response?.data) {
      yield put(incentiveActions.setSalesDistributionListData(response?.data));
      yield put(incentiveActions.setSalesDistributionBackupListData(response?.data));
      yield put(incentiveActions.setSaleDistributionDiscription(''));
      yield put(fetchdDownloadExcelURLSalesDistributionCreator(action.payload));
    }

    if (response.status !== 200 && response?.data?.description) {
      yield put(
        incentiveActions.setSaleDistributionDiscription(
          response?.data?.description,
        ),
      );
    }
    yield put(incentiveActions.setSalesTransferLoading(false));
  } catch (error) {
    yield put(incentiveActions.setSalesTransferLoading(false));
    console.log('error ==>', error);
  }
}

export function* fetchSalesDistributionRemarksDataWatcher() {
  yield takeLatest(
    fetchsalesDistributionRemarksDataTypename,
    fetchSalesDistributionRemaksDataWorker,
  );
}

function* fetchSalesDistributionRemaksDataWorker(action: any): any {
  try {
    const url = API_PATH.incentiveConfiguration.getRemarks;
    const response = yield call(NetworkService.get, url, {}, {});
    if (response?.data) {
      yield put(incentiveActions.setRemarksData(response?.data));
    }
  } catch (error) {
    console.log('error ==>', error);
  }
}

/**
 * Fetch Download Excel SalesTransfer watcher
 */

export function* fetchDownloadExcelURLSalesTransferWatcher() {
  yield takeLatest(
    fetchDownloadExcelURLSalesTransferTypeName,
    fetchDownloadExcelURLSalesTransferWorker,
  );
}

/**
 * Fetch Download Excel SalesTransfer Worker
 */

function* fetchDownloadExcelURLSalesTransferWorker(action: any): any {
  yield put(
    incentiveActions.updateLoading(
      API_PATH.incentiveConfiguration.fetchDownloadExcelSalesTransferURL,
    ),
  );
  try {
    const response = yield call(
      NetworkService.post,
      API_PATH.incentiveConfiguration.fetchDownloadExcelSalesTransferURL,
      action.payload,
      {},
    );
    yield put(incentiveActions.setDownloadExcelSalesTransferURL(response.data));
    yield put(incentiveActions.updateLoading(null));
  } catch (error) {
    console.log('error ==>', error);
  }
}

/**
 * Fetch Download Excel SalesDistribution watcher
 */

export function* fetchDownloadExcelURLSalesDistributionWatcher() {
  yield takeLatest(
    fetchDownloadExcelURLSalesDistributionTypeName,
    fetchDownloadExcelURLSalesDistributionWorker,
  );
}

/**
 * Fetch Download Excel SalesDistribution Worker
 */

function* fetchDownloadExcelURLSalesDistributionWorker(action: any): any {
  yield put(
    incentiveActions.updateLoading(
      API_PATH.incentiveConfiguration.fetchDownloadExcelSalesDistributionURL,
    ),
  );
  try {
    const response = yield call(
      NetworkService.get,
      API_PATH.incentiveConfiguration.fetchDownloadExcelSalesDistributionURL,
      action.payload,
      {},
    );
    yield put(
      incentiveActions.setDownloadExcelSalesDistributionURL(response.data),
    );
    yield put(incentiveActions.updateLoading(null));
  } catch (error) {
    console.log('error ==>', error);
  }
}
