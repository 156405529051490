import {Autocomplete, Box, Divider, TextField} from '@mui/material';
import {PopperStyledComponent, Styles} from './styles';

import React from 'react';
import {makeStyles} from '@mui/styles';

interface DropDownProps {
  readonly value: any;
  readonly onChange: Function;
  readonly dropdownList: any;
  readonly placeholder: string;
  readonly disabled?: boolean;
  readonly size?: any;
}

const PopperDropDown = (param: any) => {
  return (
    <PopperStyledComponent {...param}>
      <Box {...param} />
      <Divider />
    </PopperStyledComponent>
  );
};

function DropDown(props: DropDownProps) {
  const {dropdownList, placeholder} = props;

  const useStyles = makeStyles(() => ({
    popupIndicator: {
      color: '#322b7c',
    },
    customTextField: {
      '& input::placeholder': {
        fontSize: 12,
        color: '#B7B7B7',
        fontFamily: 'Poppins',
        fontWeight: 500,
      },
    },
  }));
  const classes = useStyles();

  return (
    <Autocomplete
      sx={{width: 450}}
      options={dropdownList || []}
      value={props.value || {}}
      loading={false}
      disabled={props.disabled}
      onChange={(e, event) => {
        props.onChange(event);
      }}
      renderInput={params => (
        <TextField
          {...params}
          // classes={{ root: classes.customTextField }}
          sx={Styles.labelProps}
          placeholder={placeholder}
          value={props.value}
          required
          style={{backgroundColor: 'white', width: '100%', borderRadius: 10}}
        />
      )}
      classes={{
        popupIndicator: classes.popupIndicator,
      }}
      PopperComponent={PopperDropDown}
      getOptionLabel={(option: any) =>
        option?.label ? option.label : option.name || ''
      }
      style={{width: '100%'}}
      size={props.size}
    />
  );
}

export default React.memo(DropDown);
