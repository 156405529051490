import {routeStateActions} from '@app/router/redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import {useStyles} from '@app/screens/Styles/style';
import {Container} from '@mui/material';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AddExpenseConfigManagement} from '../../components/expense-type-popup';
import {ExpenseTypeSetting} from '../../components/expense-type-setting';
import {getConfigManagementStateSelector} from '../../components/redux/seletors';
import {configurationManagementStateActions} from '../../components/redux/slice';

export const Expenses = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const addExpenseConfigManagementPopupVisible = useSelector(
    getConfigManagementStateSelector('addExpenseConfigManagementPopupVisible'),
  );

  useEffect(() => {
    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.ConfigurationManagement,
      ),
    );
    dispatch(
      routeStateActions.setNavbarComponentHeading(
        <div className={classes.navbarComponentHeading}>
          Expense Type Settings
        </div>,
      ),
    );
    dispatch(
      configurationManagementStateActions.setconfigurationManagementPage(false),
    );
    return () => {
      dispatch(routeStateActions.setNavbarComponentName(null));
      dispatch(
        configurationManagementStateActions.setconfigurationManagementPage(
          true,
        ),
      );
    };
  }, [classes.navbarComponentHeading, dispatch]);
  return (
    <div>
      <Container maxWidth={false}>
        <ExpenseTypeSetting />
        {addExpenseConfigManagementPopupVisible && (
          <AddExpenseConfigManagement />
        )}
      </Container>
    </div>
  );
};
