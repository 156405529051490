import {Search} from '@mui/icons-material';
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Autocomplete,
  Typography,
} from '@mui/material';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {challanShortQtyApprovalSelector} from '@app/screens/inventory-management/pages/ChallanShortQtyApproval/redux/selectors';
import {
  autoSuggestionChallanCreator,
  challanShortQtyApprovalActions,
  challanShortQtyApprovalDataListCreator,
} from '@app/screens/inventory-management/pages/ChallanShortQtyApproval/redux/slice';
import _ from 'lodash';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: '5px',
  },
  antSwitch: {
    marginTop: '4px !important',
  },
  QtyformContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  QtyformControl: {
    margin: '12px',
    backgroundColor: '#ffffff',
    borderWidth: 0.7,
    borderColor: '#322b7c',
  },
};
export enum SearchType {
  userName = 'UserName',
  staffCode = 'StaffCode',
  voucherNumber = 'voucherNumber',
}
const searchTypeLabel = {
  voucherNumber: 'Challan Number',
  UserName: 'Employee Name',
  StaffCode: 'SAP Code',
};

export const ChallonShortQtyApprovalNavBar = () => {
  const dispatch = useDispatch();
  const [searchType, setSearchType] = React.useState<string>('');
  const [searchText, setSearchText] = React.useState<string>('');
  const [autocompleteValue, setAutocompleteValue] = React.useState<any>(null);
  const [open, setOpen] = React.useState(false);
  const pageNUmber = useSelector(
    challanShortQtyApprovalSelector.getPageNumber(),
  );
  const autosuggestChallan = useSelector(
    challanShortQtyApprovalSelector.getAutoSuggestionChallan(),
  );
  const handleDDChange = (e: SelectChangeEvent) => {
    setAutocompleteValue(null);
    setSearchText('' as string);
    setSearchType(e.target.value as SearchType);
  };
  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value;
    setSearchText(newSearchTerm);
    dispatch(challanShortQtyApprovalActions.SetPageNumber(0));
  };

  const debouncedSearch = _.debounce(() => {
    const autosuggest = {
      pageNumber: pageNUmber + 1,
      offsetValue: 20,
      filterValue: searchText,
      searchType: searchType,
    };
    dispatch(autoSuggestionChallanCreator(autosuggest));
    dispatch(challanShortQtyApprovalDataListCreator(autosuggest));
    dispatch(challanShortQtyApprovalActions.setRequiredPayload(autosuggest));
  }, 1000);

  useEffect(() => {
    if (searchText !== '' && searchType !== '') {
      debouncedSearch();
    } else {
      dispatch(
        challanShortQtyApprovalActions.setChallanShortQtyApprovalDataList({}),
      );
      dispatch(challanShortQtyApprovalDataListCreator({}));
    }
    return () => debouncedSearch.cancel();
  }, [pageNUmber, searchType, searchText]);

  const filterOptions = (
    autosuggestChallan: any[],
    state: {inputValue: string},
  ) => {
    if (state.inputValue.length < 3) {
      return [];
    }
    return autosuggestChallan.filter((autosuggestChallan: any) =>
      autosuggestChallan.includes(state.inputValue.toUpperCase()),
    );
  };
  return (
    <Box sx={styles.container}>
      <Box sx={styles.flexSpaceBetween}>
        <Typography
          variant="h6"
          noWrap
          component="div"
          color={'#1c1939'}
          fontStyle={'normal'}
        >
          Challan Short Qty Approval
        </Typography>
      </Box>
      <Box sx={styles.QtyformContainer}>
        <FormControl sx={styles.QtyformControl} fullWidth>
          <InputLabel id="search-type-select-label">Search By</InputLabel>
          <Select
            inputProps={{
              'data-testid': 'dropdown-search-type',
            }}
            variant="outlined"
            labelId="search-type-select-label"
            label="Search By"
            sx={{width: '100%'}}
            value={searchType}
            onChange={handleDDChange}
          >
            <MenuItem value={SearchType.voucherNumber}>
              {searchTypeLabel[SearchType.voucherNumber]}
            </MenuItem>
            <MenuItem value={SearchType.staffCode}>
              {searchTypeLabel[SearchType.staffCode]}
            </MenuItem>
            <MenuItem value={SearchType.userName}>
              {searchTypeLabel[SearchType.userName]}
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={styles.QtyformControl} fullWidth>
          <Autocomplete
            options={autosuggestChallan}
            getOptionLabel={(option: any) => {
              if (
                searchType === SearchType.voucherNumber ||
                SearchType.staffCode ||
                SearchType.userName
              ) {
                return option;
              }
              return '';
            }}
            disabled={!searchType}
            filterOptions={filterOptions}
            onInputChange={(event, newValue) => {
              let searchTextValue = '';
              if (newValue) {
                if (
                  searchType === SearchType.voucherNumber ||
                  SearchType.staffCode ||
                  SearchType.userName
                ) {
                  searchTextValue = newValue;
                }
              }
              setAutocompleteValue(newValue);
              setSearchText(searchTextValue);
            }}
            value={autocompleteValue}
            open={open}
            onOpen={() => {
              if (searchType && searchText) {
                setOpen(true);
              }
            }}
            onClose={() => setOpen(false)}
            renderInput={params => (
              <TextField
                {...params}
                data-testid="search-texfield"
                placeholder="Search By Challan Number/SAP Code/Employee Name"
                onChange={handleTextChange}
                InputProps={{
                  ...params.InputProps,

                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </FormControl>
      </Box>
    </Box>
  );
};
