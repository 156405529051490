import {DataGrid, GridCellParams, GridRenderCellParams} from '@mui/x-data-grid';
import {
  TableCell,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';

import {EVENT_NAME_USER_MANAGEMENT_DEACTIVATED_BUTTON} from '@app/utils/analytics/constants';
import {trackEvent} from '@app/utils/analytics';
import {
  componentManagementStateActions,
  fetchExpenseTypeActiveInactiveCreator,
} from './redux/slice';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {ComponentManagementSelector} from './redux/selectors';
import {filterStateActions} from '@app/components/right-sidebar/redux';
import {useCallback, useEffect, useState} from 'react';
import {Gridstyle} from '@app/components/widgets/grid/grid';
import CheckIcon from '@mui/icons-material/Check';
import {appSelector} from '@app/store/selectors';
import {popUpStyle} from '@app/components/widgets/popupCss/popUpStyles';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import {EditIcon, useStyles} from '@app/screens/Styles/style';

export const util: any = {
  clearsearch: null,
  getIconColor: null,
  renderBillRequiredExpenseTypeCell: null,
};

export const ExpenseTypeGrid = ({Data}: {Data: any}) => {
  let row: any;
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const resetNavbarVersionExpenseType = useSelector(
    ComponentManagementSelector.getResetNavbarVersion(),
    shallowEqual,
  );
  const getIsActiveState = useSelector(
    ComponentManagementSelector.getIsActiveState(),
    shallowEqual,
  );
  const {isWriteAccess = false} = useSelector(
    appSelector.getUserInfo(),
    shallowEqual,
  );

  const dialogCloseHandler = () => {
    setDialogIsOpen(false);
  };
  const clearsearch = () => {
    dispatch(componentManagementStateActions.setExpenseTypeInAutoSuggest([]));
    dispatch(
      componentManagementStateActions.setResetNavbarVersion(
        resetNavbarVersionExpenseType + 1,
      ),
    );
    dispatch(
      componentManagementStateActions.setExpenseTypeInAutoSuggestFilter({}),
    );
    dispatch(componentManagementStateActions.setSearchText(''));
  };
  const getIconColor = () => {
    if (!isWriteAccess) {
      return 'grey';
    } else if (getIsActiveState) {
      return 'green';
    } else {
      return 'red';
    }
  };
  const renderBillRequiredExpenseTypeCell = (params: GridRenderCellParams) => {
    return (
      <TableCell>
        {' '}
        <Box>{params?.row?.isBillRequired ? 'Yes' : 'No'}</Box>
      </TableCell>
    );
  };
  const Onedit = useCallback((e: any) => {
    const row = JSON.parse(e.currentTarget.dataset.rowdata || '') || {};
    dispatch(componentManagementStateActions.setEditExpenseType(row));
    dispatch(
      componentManagementStateActions.setEditExpenseTypePopupVisible(true),
    );
    clearsearch();
  }, []);
  const inactiveHandler = useCallback((e: any) => {
    row = JSON.parse(e.currentTarget.dataset.rowdata || '') || {};
    setDialogIsOpen(true);
  }, []);
  const OnDelete = useCallback(() => {
    trackEvent(EVENT_NAME_USER_MANAGEMENT_DEACTIVATED_BUTTON, {});
    dispatch(fetchExpenseTypeActiveInactiveCreator(row));
    clearsearch();
  }, []);
  const getRowIdHandler = useCallback(function (row: any) {
    return row?.expenseTypeID;
  }, []);
  useEffect(() => {
    const options = {MISC: false, TA: false, DA: false};
    dispatch(
      filterStateActions.setFilterState({
        ExpenseCategory: {
          completeApplied: false,
          collapsed: true,
          options,
        },
      }),
    );
    return () => {
      dispatch(filterStateActions.setFilterState({}));
    };
  }, [dispatch]);
  const columns = [
    {
      field: 'expenseTypeName',
      headerName: 'Expense Name',
      width: 230,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return <p>{params.row.expenseTypeName}</p>;
      },
    },
    {
      field: 'expenseCategory',
      headerName: 'Expense Category',
      width: 180,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return <p>{params.row.expenseCategory}</p>;
      },
    },
    {
      field: 'expenseType',
      headerName: 'Expense Type',
      width: 180,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return <p>{params.row.expenseType}</p>;
      },
    },

    {
      field: 'isBillRequired',
      headerName: 'Bill Required',
      width: 180,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return renderBillRequiredExpenseTypeCell(params);
      },
    },

    {
      field: 'Action',
      headerName: 'Action',
      width: 180,
      sortable: false,

      renderCell: ({row}: GridCellParams<any>) => (
        <TableCell>
          <Box className={classes.justifyContentbtflex}>
            <Button
              data-testid={`IconButton1${row.expenseTypeID}`}
              sx={Gridstyle.IconButton}
              disabled={!isWriteAccess}
              onClick={Onedit}
              data-rowData={JSON.stringify({...row})}
            >
              <EditIcon />
            </Button>
            <Button
              data-testid={`IconButton2${row.expenseTypeID}`}
              sx={Gridstyle.IconButton}
              disabled={!isWriteAccess}
              onClick={inactiveHandler}
              data-rowData={JSON.stringify({...row})}
            >
              <CheckIcon
                fontSize="small"
                style={{
                  color: getIconColor(),
                }}
                sx={{marginLeft: -5}}
              />
            </Button>
          </Box>
        </TableCell>
      ),
    },
  ];

  util.clearsearch = clearsearch;
  util.getIconColor = getIconColor;
  util.renderBillRequiredExpenseTypeCell = renderBillRequiredExpenseTypeCell;

  return (
    <>
      <Dialog
        open={dialogIsOpen}
        onClose={dialogCloseHandler}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle>
          <Box display={'flex'} justifyContent="space-between">
            <Typography sx={popUpStyle.headingcss}>Confirm</Typography>
            <Box>
              <Button onClick={dialogCloseHandler} data-testid="closeButton">
                <ClearOutlinedIcon sx={popUpStyle.closeIcon} />
              </Button>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <div style={popUpStyle.dialogContent}>
            <Typography sx={popUpStyle.labelSizing}>
              Are you sure you want to continue?
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Box className={classes.justifyContentcenterflex}>
            <Button
              variant="contained"
              sx={popUpStyle.resetButton}
              onClick={OnDelete}
            >
              Ok
            </Button>
            <Button
              variant="contained"
              sx={popUpStyle.saveButton}
              onClick={dialogCloseHandler}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Box sx={Gridstyle.gridcss}>
        <DataGrid
          columnBuffer={8}
          rows={Data ?? []}
          getRowId={getRowIdHandler}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
        />
      </Box>
    </>
  );
};
