import {createSelector} from '@reduxjs/toolkit';

const salesTransferConfigurationData = (state: any) =>
  state.salesTransferConfigurationState.salesTransferConfigurationData;
const salestransferconfigurationDivisionData = (state: any) =>
  state.salesTransferConfigurationState.salesTransferConfigurationDivisionData;
const salestransferconfigurationStateData = (state: any) =>
  state.salesTransferConfigurationState.salesTransferConfigurationStateData;
const salesTransferConfigurationQuarterData = (state: any) =>
  state.salesTransferConfigurationState.salesTransferConfigurationQuarterData;
const salesTransferConfigurationConData = (state: any) =>
  state.salesTransferConfigurationState.salesTransferConfigurationConData;

const getStatus = createSelector([salesTransferConfigurationData], nav =>
  nav?.status?.value ? 1 : 0,
);

const getSalesTransferConfigurationData = createSelector(
  [salesTransferConfigurationData],
  _salesTransferConfigurationData => _salesTransferConfigurationData,
);
const getSalesTransferConfigurationDivisionData = createSelector(
  [salestransferconfigurationDivisionData],
  _salesTransferConfigurationData => _salesTransferConfigurationData,
);
const getSalesTransferConfigurationStateData = createSelector(
  [salestransferconfigurationStateData],
  _salesTransferConfigurationData => _salesTransferConfigurationData,
);

const getSalesTransferConfigurationQuarterData = createSelector(
  [salesTransferConfigurationQuarterData],
  _salesTransferConfigurationData => _salesTransferConfigurationData,
);

const getSalesTransferConfigurationConData = createSelector(
  [salesTransferConfigurationConData],
  _salesTransferConfigurationData => _salesTransferConfigurationData,
);

export const salesTransferConfigurationSelector = {
  getSalesTransferConfigurationData: () => getSalesTransferConfigurationData,
  getStatus,
  getSalesTransferConfigurationDivisionData: () =>
    getSalesTransferConfigurationDivisionData,
  getSalesTransferConfigurationStateData: () =>
    getSalesTransferConfigurationStateData,
  getSalesTransferConfigurationQuarterData: () =>
    getSalesTransferConfigurationQuarterData,
  getSalesTransferConfigurationConData: () =>
    getSalesTransferConfigurationConData,
};
