import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import React, {FC} from 'react';

import Close from '@mui/icons-material/Close';
import If from '../If';
import {Styles} from './style';

interface PopupProps {
  isLoading?: boolean;
  cancelPopup?: () => void;
  open: boolean;
  onClose?: () => void;
  error?: null | string;
  title?: string;
  children?: React.ReactNode;
  showErrorMessage?: boolean;
}

const Popup: FC<PopupProps> = (props: PopupProps) => {
  const {
    isLoading,
    cancelPopup,
    open,
    onClose,
    children,
    title,
    showErrorMessage,
  } = props;

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={Styles.ModalBody}>
        <If condition={showErrorMessage}>
          <Typography style={{fontSize: 12}} data-testid={'show-error-message'}>
            {props.error}
          </Typography>
        </If>
        <If condition={isLoading}>
          <Backdrop sx={{zIndex: 9999}} open>
            <CircularProgress sx={{color: 'primary.main'}} disableShrink />
          </Backdrop>
        </If>
        <Stack
          justifyContent={'space-between'}
          alignItems="center"
          direction={'row'}
          // sx={{ pb: 2.5 }}
        >
          <If condition={Boolean(title?.length)}>
            <Typography variant="popupHeading" style={{color: '#322b7c'}}>
              {title}
            </Typography>
          </If>
          <Box sx={Styles.iconContainer}>
            <IconButton onClick={cancelPopup}>
              <Close sx={{color: 'primary.dark'}} />
            </IconButton>
          </Box>
        </Stack>
        {children}
      </Box>
    </Modal>
  );
};

export default Popup;
