import Colors from '@app/themes/colors';
import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles({
  navbarHeading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  backIcon: {
    padding: 0,
    marginRight: 10,
  },
  navbarComponentHeading: {
    height: 24.7,
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    color: '#1c1939',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  button: {
    borderRadius: '5px',
    width: 280,
    fontStyle: 'normal',
    textAlign: 'center',
    fontWeight: 'normal',
    fontSize: 12.7,
  },
});
