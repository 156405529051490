import {createAction, createSlice} from '@reduxjs/toolkit';

export const billLibraryInitialState = {
  gridView: false,
  contentGridView: false,
  divisionArray: [],
  designationArray: [],
  employeeArray: [],
  hqArray: [],
  poolArray: [],
  categoryArr: [],
  statusArr: [],
  viewHistory: [],
  downloadRequest: [],
  employeeDropDown: [],
};
export const fetchDivisionCreator = createAction(
  'BILLLIBRARY_HANDLER/FETCH_DIVISION_DROPDOWN',
);
export const fetchDivisionCreatorTypeName = fetchDivisionCreator().type;

export const fetchDesignationByDivisionCreator = createAction<any>(
  'BILLLIBRARY_HANDLER/FETCH_DESIGNATION_DROPDOWN',
);
export const fetchDesignationByDivisionCreatorTypeName =
  fetchDesignationByDivisionCreator(null).type;

export const fetchEmployeebydivisionCreator = createAction<any>(
  'BILLLIBRARY_HANDLER/FETCH_EMPLOYEE_DROPDOWN',
);
export const fetchEmployeebydivisionCreatorTypeName =
  fetchEmployeebydivisionCreator(null).type;

export const fetchEmployeebydivisionforDropdownCreator = createAction<any>(
  'BILLLIBRARY_HANDLER/FETCH_EMPLOYEE_DROPDOWN_VALUE',
);
export const fetchEmployeebydivisionforDropdownCreatorTypeName =
  fetchEmployeebydivisionforDropdownCreator(null).type;

export const fetchHqByDivisionsandDesignationsCreator = createAction<any>(
  'BILLLIBRARY_HANDLER/FETCH_HQ_DROPDOWN',
);
export const fetchHqByDivisionsandDesignationsCreatorTypeName =
  fetchHqByDivisionsandDesignationsCreator(null).type;

export const fetchPoolByDivisionsandDesignationsCreator = createAction<any>(
  'BILLLIBRARY_HANDLER/FETCH_POOL_DROPDOWN',
);
export const fetchPoolByDivisionsandDesignationsCreatorTypeName =
  fetchPoolByDivisionsandDesignationsCreator(null).type;

export const fetchCategoryCreator = createAction(
  'BILLLIBRARY_HANDLER/FETCH_CATEGORY_DROPDOWN',
);
export const fetchCategoryCreatorTypeName = fetchCategoryCreator().type;

export const fetchStatusCreator = createAction(
  'BILLLIBRARY_HANDLER/FETCH_STATUS_DROPDOWN',
);
export const fetchStatusCreatorTypeName = fetchStatusCreator().type;

export const fetchBillDownloadCreator = createAction<any>(
  'BILLLIBRARY_HANDLER/BILL_DOWNLOAD',
);
export const fetchBillDownloadCreatorTypeName =
  fetchBillDownloadCreator(null).type;

export const reviewSaveCreator = createAction<any>(
  'BILLLIBRARY_HANDLER/BILL_REVIEW_SAVE',
);
export const reviewSaveCreatorTypeName = reviewSaveCreator(null).type;

export const fetchViewHistoryCreator = createAction<any>(
  'BILLLIBRARY_HANDLER/VIEW_hISTORY',
);
export const fetchViewHistoryCreatorTypeName =
  fetchViewHistoryCreator(null).type;

const billLibraryStateHandler = createSlice({
  name: 'BILLLIBRARY_HANDLER',
  initialState: billLibraryInitialState,
  reducers: {
    setGridVeiw: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        gridView: payload,
      };
    },
    setContentGridView: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        contentGridView: payload,
      };
    },
    setDivision: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        divisionArray: payload,
      };
    },
    setDesignation: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        designationArray: payload,
      };
    },
    setDownloadRequest: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        downloadRequest: payload,
      };
    },
    setEmployee: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        employeeArray: payload,
      };
    },
    setHQ: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        hqArray: payload,
      };
    },
    setPool: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        poolArray: payload,
      };
    },

    setViewHistory: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        viewHistory: payload,
      };
    },

    setCategory: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        categoryArr: payload,
      };
    },
    setStatus: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        statusArr: payload,
      };
    },
    setEmployeeDropDown: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        employeeDropDown: payload,
      };
    },
  },
});

export const billLibraryStateActions = billLibraryStateHandler.actions;
export const billLibraryStateReducer = billLibraryStateHandler.reducer;
