import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, {ChangeEvent, useEffect, useState} from 'react';
import {
  incentiveNavActionHandlerCreator,
  incentiveNavSearchQueryCreator,
  incentiveNavToggleCreator,
} from '@app/screens/incentive-configuration/redux';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';

import {Search} from '@mui/icons-material';
import {incentiveSelector} from '@app/screens/incentive-configuration/redux/selectors';
import {makeStyles} from '@mui/styles';
import './style.css';
import downLoad from '@app/assets/images/svg/download.svg';
import download_gray_icon from '@app/assets/images/download_gray_icon.png';
import {
  inactiveNavbaFilterIconCreator,
  incentivePageLists,
  incentiveSaveCreator,
} from '@app/screens/incentive-configuration/redux/slice';

const useStyles = makeStyles((theme: any) => ({
  search: {
    '& input': {
      padding: '12px 30px 12px 0',
      // color: theme.palette.primary.main,
      // '::placeholder': theme.palette.primary.main,
    },
  },
}));

const IncentiveNavBar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchPlaceHolder, setSearchPlaceHolder] = useState<any>('');
  const title = useSelector(incentiveSelector.getNavTitle());
  const action = useSelector(incentiveSelector.getNavAction());
  const search = useSelector(incentiveSelector.getNavSearch());
  const status = useSelector(incentiveSelector.getStatus, shallowEqual);
  const totalCount = useSelector(incentiveSelector.totalRecords, shallowEqual);
  const salesTransferData = useSelector(
    incentiveSelector.getSalesTransferData(),
  );
  const salesDistributionDataList = useSelector(
    incentiveSelector.getSalesDistributionList(),
    shallowEqual,
  );
  const currentPage = useSelector(
    incentiveSelector.getCurrentPage,
    shallowEqual,
  );
  const [isSearchable, setIsSearchable] = useState(true);
  const showToggle = useSelector(
    incentiveSelector.getShowNavToggle(),
    shallowEqual,
  );
  const statusOfEditable = useSelector(
    incentiveSelector.clickOnGoBtnValue(),
    shallowEqual,
  );
  const isFilterIconStatus = useSelector(incentiveSelector.getNavFilterIcon());

  const download = useSelector(incentiveSelector.getNavDownload());

  const onSearchHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    dispatch(incentiveNavSearchQueryCreator(event.target.value));
  };

  const onToggleHandler = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(incentiveNavToggleCreator(event.target.checked));
  };

  const onActionHandler = () => {
    dispatch(incentiveNavActionHandlerCreator({show: true, type: action.type}));
  };

  useEffect(() => {
    if (download?.search?.placeholder) {
      setSearchPlaceHolder(download?.search?.placeholder);
    }
  }, [download]);

  const onActionFilterIconHandler = () => {
    if (isFilterIconStatus?.gobtnClick) {
      dispatch(
        inactiveNavbaFilterIconCreator({
          value: !isFilterIconStatus?.value,
          color: 'blue',
          gobtnClick: true,
        }),
      );
    } else {
      dispatch(
        inactiveNavbaFilterIconCreator({
          value: !isFilterIconStatus?.value,
          color: isFilterIconStatus?.color === 'blue' ? 'gray' : 'blue',
        }),
      );
    }
  };

  const onSaveHandler = () => {
    dispatch(incentiveSaveCreator({value: 'save'}));
  };

  const onRefreshHandler = () => {
    dispatch(incentiveSaveCreator({value: 'refresh'}));
  };

  const onsubmitHandle = () => {
    dispatch(incentiveSaveCreator({value: 'submit'}));
  };

  const salesTransferURL = useSelector(
    incentiveSelector.getDownloadExcelSalesTransferURL(),
    shallowEqual,
  );

  const salesDistributionURL = useSelector(
    incentiveSelector.getDownloadExcelSalesDistributionURL(),
    shallowEqual,
  );

  const url = () => {
    switch (currentPage) {
      case incentivePageLists.salesTransfer:
        return salesTransferURL;
      case incentivePageLists.salesDistribution:
        return salesDistributionURL;

      default:
        return '';
    }
  };
  const handleDownloadExcel = () => {
    if (url()) {
      window.open(String(url()), '_blank');
    }
  };

  useEffect(() => {
    if (
      salesTransferData?.segmentList &&
      salesTransferData?.segmentList?.length > 0 &&
      !search?.quary
    ) {
      setIsSearchable(false);
    }

    if (
      salesDistributionDataList?.mrDetails &&
      salesDistributionDataList?.mrDetails?.length > 0 &&
      !search?.quary
    ) {
      setIsSearchable(false);
    }
  }, [
    search?.query,
    salesTransferData,
    isSearchable,
    salesDistributionDataList,
  ]);

  return (
    <Grid container justifyContent={'space-between'} sx={{height: 100}}>
      <Grid item xs={download?.save ? 2 : 8}>
        <Box
          sx={{
            mt: 1,
            mb: 4,
            minWidth: 200,
          }}
        >
          <Typography gridTemplateAreas="title" color={'primary.main'}>
            {title} {Boolean(totalCount) && `( ${totalCount} )`}
          </Typography>
        </Box>
        {search && !download?.search ? (
          <TextField
            // disabled={isSearchable}
            variant="outlined"
            placeholder={search?.placeholder}
            className={classes.search}
            type="search"
            fullWidth
            value={search?.query}
            InputProps={{
              className: 'input-addPage',
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={onSearchHandler}
          />
        ) : null}
      </Grid>
      <Grid
        item
        display={'flex'}
        direction="column"
        justifyContent={'space-between'}
        alignContent={'flex-end'}
      >
        <Grid
          item
          display={'flex'}
          direction="row"
          justifyContent={'space-between'}
        >
          <Grid container justifyContent={'center'} alignItems={'center'}>
            {action ? (
              <Grid item>
                <Button variant="contained" onClick={onActionHandler}>
                  {action.title}
                </Button>
              </Grid>
            ) : null}
            {download?.refresh && (
              <Grid item sx={{marginRight: 2}}>
                <Button
                  className="refresh_button"
                  variant="outlined"
                  onClick={onRefreshHandler}
                  disabled={!statusOfEditable?.isRefreshEditable}
                >
                  {download?.refresh}
                </Button>
              </Grid>
            )}

            {download?.save ? (
              <Grid item sx={{marginRight: 2}}>
                <Button
                  className="save_button"
                  variant="contained"
                  onClick={onSaveHandler}
                  disabled={!statusOfEditable?.isEdited}
                >
                  {download?.save}
                </Button>
              </Grid>
            ) : null}

            {download?.submit ? (
              <Grid item sx={{marginRight: 2}}>
                <Button
                  className="refresh_button"
                  variant="outlined"
                  onClick={onsubmitHandle}
                  disabled={!salesDistributionDataList?.showSubmitButton || salesDistributionDataList?.isSubmit}
                >
                  {download?.submit}
                </Button>
              </Grid>
            ) : null}

            {download ? (
              <Grid item>
                <Button
                  className="downLoad_button"
                  variant="contained"
                  onClick={handleDownloadExcel}
                >
                  {download?.title}
                </Button>
              </Grid>
            ) : null}
            {download?.filter === 'filter' ? (
              <button
                type="button"
                onClick={onActionFilterIconHandler}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    onActionFilterIconHandler();
                  }
                }}
                style={{
                  cursor: 'pointer',
                  padding: '6px',
                  height: 40,
                  border: 'none',
                  background: 'none',
                }}
                aria-label={
                  isFilterIconStatus?.color
                    ? 'Download Icon'
                    : 'Gray Download Icon'
                }
              >
                <img
                  src={
                    isFilterIconStatus?.color == 'blue'
                      ? downLoad
                      : download_gray_icon
                  }
                  alt={
                    isFilterIconStatus?.color
                      ? 'Download Icon'
                      : 'Gray Download Icon'
                  }
                  style={{width: '100%', height: '100%'}}
                />
              </button>
            ) : null}
          </Grid>
        </Grid>

        {showToggle ? (
          <Stack
            direction="row"
            spacing={1}
            sx={{
              mt: 3,
            }}
            alignItems="center"
            alignSelf={'flex-end'}
          >
            <Typography variant="switch">InActive</Typography>
            <Switch
              sx={{
                '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked': {
                  transform: 'translateX(16px)',
                },
                '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                  {
                    opacity: 1,
                  },
              }}
              defaultChecked
              inputProps={{'aria-label': 'ant design'}}
              checked={status == 1}
              onChange={onToggleHandler}
            />
            <Typography variant="switch">Active</Typography>
          </Stack>
        ) : null}
      </Grid>
      {download?.search && (
        <TextField
          disabled={isSearchable}
          variant="outlined"
          placeholder={searchPlaceHolder || ''}
          className={classes.search}
          type="search"
          fullWidth
          value={search.query}
          InputProps={{
            className: 'input-addPage',
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={onSearchHandler}
        />
      )}
    </Grid>
  );
};

export default React.memo(IncentiveNavBar);
