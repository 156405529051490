import {API_PATH} from '@app/common/constants';
import {NetworkService, ToasterService} from '@app/services';
import {unlockRequestActions, unlockRequestTypeName} from '.';
import {call, put, takeLatest} from 'redux-saga/effects';
import {getUnlockRequestTypesCreatorTypeName} from './slice';

import {ToastType} from '@app/services/toaster';
import {incentiveActions} from '@app/screens/incentive-configuration/redux';
import {incentivePageLists} from '@app/screens/incentive-configuration/redux/slice';

/**
 * Fetch Unlock List Watcher
 */

export function* fetchUnlockRequestWatcher() {
  yield takeLatest(unlockRequestTypeName, fetchUnlockRequestWorker);
}

export function* fetchUnlockRequestWorker(action: any): any {
  const {
    isActive,
    divisionIdList,
    stateIdList,
    requestedFor,
    pageIndex,
    unlockTillDate,
  } = action.payload;
  yield put(
    incentiveActions.updateLoading(
      API_PATH.incentiveConfiguration.fetchUnlockRequest,
    ),
  );
  try {
    yield put(incentiveActions.toggleFetchingData(true));
    const response = yield call(
      NetworkService.post,
      API_PATH.incentiveConfiguration.fetchUnlockRequest,
      {
        pageIndex: pageIndex,
        pageSize: 20,
        isActive,
        divisionIdList: divisionIdList,
        stateIdList: stateIdList,
        requestedFor: requestedFor,
        unlockTillDate: unlockTillDate,
      },
      {},
    );
    yield put(incentiveActions.updateLoading(null));
    if (response?.data) {
      if (pageIndex === 1) {
        yield put(
          incentiveActions.updateTotalRecords({
            listing: incentivePageLists.unlockRequest,
            count: response?.data[0]?.totalRecords || 0,
            pageIndex: pageIndex,
          }),
        );
        yield put(unlockRequestActions.fetchUnlockRequestData(response.data));
      } else {
        yield put(
          incentiveActions.updateTotalRecords({
            listing: incentivePageLists.unlockRequest,
            pageIndex: pageIndex,
          }),
        );
        yield put(
          unlockRequestActions.fetchNewUnlockRequestData(response.data),
        );
      }
    }
  } catch (error) {
    yield put(incentiveActions.updateLoading(null));
    ToasterService.showToaster(
      'Something went wrong while fetching admin data!',
      ToastType.ERROR,
    );
  } finally {
    yield put(incentiveActions.toggleFetchingData(false));
  }
}

export function* getUnlockRequestTypesWatcher() {
  yield takeLatest(
    getUnlockRequestTypesCreatorTypeName,
    getUnlockRequestTypesWorker,
  );
}

export function* getUnlockRequestTypesWorker(): any {
  try {
    const url = API_PATH.incentiveConfiguration.getRequestTypes;
    const response = yield call(NetworkService.get, url, {}, {});

    if (response.status === 200) {
      yield put(incentiveActions.setRequestForListData(response.data));
    }
  } catch (error) {}
}
