import {createAction, createSlice} from '@reduxjs/toolkit';

interface initialStateProps {
  loading: boolean;
  incentiveData: any;
}

const initialState: initialStateProps = {
  loading: false,
  incentiveData: undefined,
};

export const getIncentiveDataCreator = createAction<any>(
  'SALES_TRANSFER/GET_INCENTIVE_DATA',
);
export const getIncentiveDataTypeName = getIncentiveDataCreator(null).type;

export const inactiveNavbarDropdownDivisionCreator = createAction<any>(
  'INCENTIVE_CONFIGURATION/INCENTIVE_NAV_GET_DIVISION_DATA',
);
export const inactiveNavbarDropdownDivisionCreatorTypeName =
  inactiveNavbarDropdownDivisionCreator(null).type;

const salesTransferSlice = createSlice({
  name: 'SALES-TRANSFER',
  initialState: initialState,
  reducers: {
    setLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setIncentiveData: (state, action) => {
      return {
        ...state,
        incentiveData: action.payload,
      };
    },
  },
});

export const salesTransferAction = salesTransferSlice.actions;
export const salesTransferReducer = salesTransferSlice.reducer;
