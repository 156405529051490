import {
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Backdrop,
} from '@mui/material';
import {FC, useCallback, useMemo} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import {directorySelector} from '../../redux';

interface TableListProps {
  columns: string[];
  isEmpty?: boolean;
  pagination?: {
    pageSize: number;
    pageIndex: number;
  };
  isPaging?: boolean;
  onLoadMore?: (index: number) => void;
  isLoading?: boolean;
  maxHeight?: number;
}

const TableListDefaultProps: TableListProps = {
  columns: [],
  isEmpty: false,
  pagination: {
    pageSize: 15,
    pageIndex: 1,
  },
  isPaging: false,
  isLoading: true,
  maxHeight: 470,
};

const TableList: FC<TableListProps> = ({
  columns,
  children,
  isEmpty,
  pagination,
  isPaging,
  onLoadMore,
  isLoading,
  maxHeight,
}) => {
  const pageIndex = useSelector(directorySelector.pageIndex, shallowEqual);
  const totalCount = useSelector(directorySelector.totalRecords, shallowEqual);

  const isMoreLoad = useMemo(
    () =>
      isPaging && pagination && totalCount > pageIndex * pagination.pageSize,
    [totalCount, pagination, isPaging, pageIndex],
  );

  const handleScroll = useCallback(
    (e: any) => {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom && onLoadMore) {
        onLoadMore(pageIndex + 1);
      }
    },
    [pageIndex],
  );

  const notHandleScroll = () => {
    return null;
  };
  return (
    <TableContainer
      sx={{pl: 5, pr: 5, maxHeight}}
      onScroll={isMoreLoad ? handleScroll : notHandleScroll}
    >
      {isLoading && !isMoreLoad ? (
        <Backdrop sx={{zIndex: 9999}} open>
          <CircularProgress sx={{color: 'primary.main'}} disableShrink />
        </Backdrop>
      ) : null}
      <Table
        stickyHeader
        aria-label="sticky table"
        sx={{borderCollapse: 'collapse'}}
      >
        <TableHead>
          <TableRow>
            {columns?.map((column, index) => (
              <TableCell
                key={column}
                align={columns?.length - 1 === index ? 'right' : 'left'}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!isEmpty ? children : <TableCell>No Record Found</TableCell>}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={columns.length}>
              {isMoreLoad && isLoading ? (
                <span style={{marginLeft: '50%'}}>Loading...</span>
              ) : (
                <span style={{visibility: 'hidden'}}>h</span>
              )}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

TableList.defaultProps = TableListDefaultProps;

export default TableList;
