export const styles = {
  tableHeader: {
    width: 90,
    // fontSize: 10,
    // color: '#322b7c',
  },
  groupHeder: {
    // fontSize: 10,
    // color: '#322b7c',
    width: 120,
    minWidth: 120,
  },
  totalHeader: {
    // fontSize: 10,
    // color: '#322b7c',
    lineHeight: 1.2,
    width: 150,
  },
  remarkHeader: {
    // fontSize: 10,
    // color: '#322b7c',
  },
  inputCell: {
    backgroundColor: '#e9e9e9',
    borderRadius: 5,
    height: 26,
    textAlign: 'right',
    padding: 5,
    display: 'flex',
  },
};
