import {filterStateActions} from '@app/components/right-sidebar/redux';
import {fetchDivisionDropdownCreator} from '@app/screens/access-management/pages/user-management/redux/slice';
import {
  expenseDAMasterStateActions,
  fetchDesignationsDropdownCreator,
  fetchHQDropdownCreator,
} from '@app/screens/master-data-management/pages/expense-da-master/redux/slice';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ExpenseTypeSettingGrid} from '../expense-type-setting-grid';
import {ConfigurationManagementSelector} from '../redux/seletors';
import {configurationManagementStateActions} from '../redux/slice';
import {Filter} from '@app/components/widgets/filters';

export const ExpenseTypeSetting = () => {
  const dispatch = useDispatch();
  const expenseTypeSetting = useSelector(
    ConfigurationManagementSelector.getExpenseTypeSetting(),
  );

  useEffect(() => {
    dispatch(filterStateActions.setFilterState({}));
    dispatch(
      filterStateActions.setFilterOptions({
        isActive: true,
        searchText: '',
        pageNumber: 0,
        pageLimit: 100,
        divisionIds: [],
        designationIds: [],
        hqIds: [],
      }),
    );
    dispatch(fetchDivisionDropdownCreator());
    dispatch(fetchDesignationsDropdownCreator());
    dispatch(fetchHQDropdownCreator());
    return () => {
      dispatch(filterStateActions.setFilterState({}));
      dispatch(filterStateActions.setFilterApplied(false));
      dispatch(expenseDAMasterStateActions.setDesignationArr([]));
      dispatch(
        configurationManagementStateActions.setExpenseTypeSettingCreateValidation(
          [],
        ),
      );
      dispatch(
        filterStateActions.setFilterOptions({
          isActive: true,
          searchText: '',
          pageNumber: 0,
          pageLimit: 100,
          divisionIds: [],
          designationIds: [],
          hqIds: [],
        }),
      );
      dispatch(configurationManagementStateActions.setPageNo(0));
      dispatch(configurationManagementStateActions.setRowPerPage(100));
    };
  }, [dispatch]);
  return (
    <div>
      <Filter />
      <ExpenseTypeSettingGrid expenseTypeSetting={expenseTypeSetting} />
    </div>
  );
};
