import {API_PATH} from '@app/common/constants';
import {NetworkService, ToasterService} from '@app/services';
import {
  cutOffDateConfigurationActions,
  cutOffDateConfigurationTypeName,
  editCutOffDateConfigurationCreatorTypeName,
  getCutOffDatesTypeName,
  getYearlyQuarterTypeName,
} from './slice';
import {call, put, takeLatest} from 'redux-saga/effects';

import {ToastType} from '@app/services/toaster';
import {incentiveActions} from '@app/screens/incentive-configuration/redux';
import {incentivePageLists} from '@app/screens/incentive-configuration/redux/slice';

/**
 * Fetch Cut Off Date Configuration List Watcher
 */

export function* fetchCutOffDateConfigurationWatcher() {
  yield takeLatest(
    cutOffDateConfigurationTypeName,
    fetchCutOffDateConfigurationWorker,
  );
}

export function* fetchCutOffDateConfigurationWorker(action: any): any {
  const {isActive, pageIndex, query} = action.payload;

  yield put(
    incentiveActions.updateLoading(
      API_PATH.cutOffDateConfiguration.fetchCutOffDateList,
    ),
  );
  try {
    yield put(incentiveActions.toggleFetchingData(true));
    const response = yield call(
      NetworkService.get,
      API_PATH.cutOffDateConfiguration.fetchCutOffDateList,
      {
        pageIndex: pageIndex,
        pageSize: 15,
        isActive: isActive,
        QuarterName: query,
      },
      {},
    );
    yield put(incentiveActions.updateLoading(null));
    if (response?.data) {
      if (pageIndex === 1) {
        yield put(
          incentiveActions.updateTotalRecords({
            listing: incentivePageLists.cutOffDateConfiguration,
            count: response?.data?.totalRecords || 0,
            pageIndex: pageIndex,
          }),
        );
        yield put(
          cutOffDateConfigurationActions.fetchCutOffDateConfigurationData(
            response?.data?.masterList,
          ),
        );
      } else {
        yield put(
          incentiveActions.updateTotalRecords({
            listing: incentivePageLists.cutOffDateConfiguration,
            pageIndex: pageIndex,
          }),
        );
        yield put(
          cutOffDateConfigurationActions.fetchNewcutOffDateConfigurationData(
            response.data?.masterList,
          ),
        );
      }
    }
  } catch (error) {
    yield put(incentiveActions.updateLoading(null));
    ToasterService.showToaster(
      'Something went wrong while fetching admin data!',
      ToastType.ERROR,
    );
  } finally {
    yield put(incentiveActions.toggleFetchingData(false));
  }
}

/**
 * Fetch Cut Off Dates watcher
 */

export function* fetchCutOffDatesWatcher() {
  yield takeLatest(getCutOffDatesTypeName, fetchCutOffDatesWorker);
}

/**
 * Fetch Cut Off Dates Worker
 */

function* fetchCutOffDatesWorker(): any {
  yield put(
    incentiveActions.updateLoading(
      API_PATH.cutOffDateConfiguration.fetchCutOffDates,
    ),
  );
  try {
    const response = yield call(
      NetworkService.get,
      API_PATH.cutOffDateConfiguration.fetchCutOffDates,
      {},
      {},
    );
    yield put(incentiveActions.updateLoading(null));
    yield put(cutOffDateConfigurationActions.fetchCutOffDates(response.data));
  } catch (error) {
    yield put(incentiveActions.updateLoading(null));
    ToasterService.showToaster(
      'Something went wrong while fetching admin data!',
      ToastType.ERROR,
    );
  }
}

/**
 * Fetch Yearly Quarter watcher
 */

export function* fetchYearlyQuarterCutOffDatesWatcher() {
  yield takeLatest(getYearlyQuarterTypeName, fetchYearlyQuarterWorker);
}

/**
 * Fetch Yearly Quarter Worker
 */

function* fetchYearlyQuarterWorker(): any {
  yield put(
    incentiveActions.updateLoading(
      API_PATH.cutOffDateConfiguration.fetchYearlyQuarter,
    ),
  );
  try {
    const response = yield call(
      NetworkService.get,
      API_PATH.cutOffDateConfiguration.fetchYearlyQuarter,
      {},
      {},
    );
    yield put(incentiveActions.updateLoading(null));
    yield put(cutOffDateConfigurationActions.fetchYearlyQuarter(response.data));
  } catch (error) {
    yield put(incentiveActions.updateLoading(null));
    ToasterService.showToaster(
      'Something went wrong while fetching admin data!',
      ToastType.ERROR,
    );
  }
}

/**
 * Fetch Cut Off Date Configuration Edit Data Watcher
 */

export function* fetchCutOffDateConfigurationEditDataWatcher() {
  yield takeLatest(
    editCutOffDateConfigurationCreatorTypeName,
    fetchCutOffDateConfigurationEditDataWorker,
  );
}

export function* fetchCutOffDateConfigurationEditDataWorker(action: any): any {
  const {id} = action.payload;

  yield put(
    incentiveActions.updateLoading(
      API_PATH.cutOffDateConfiguration.cutOffDateMasterById,
    ),
  );
  try {
    yield put(incentiveActions.toggleFetchingData(true));
    const response = yield call(
      NetworkService.get,
      API_PATH.cutOffDateConfiguration.cutOffDateMasterById,
      {
        Id: id,
      },
      {},
    );
    yield put(incentiveActions.updateLoading(null));
    if (response?.data) {
      const extractedData = {
        id: response.data.id,
        quarterId: response.data.quarterId,
        quarterName: response.data.quarterName,
        date: response.data.date,
        isActive: response.data.isActive,
      };
      yield put(
        cutOffDateConfigurationActions.fetchCutOffDateConfigurationEditData(
          extractedData,
        ),
      );
    }
  } catch (error) {
    console.log('err', error);

    yield put(incentiveActions.updateLoading(null));
    ToasterService.showToaster(
      'Something went wrong while fetching admin datddda!',
      ToastType.ERROR,
    );
  } finally {
    yield put(incentiveActions.toggleFetchingData(false));
  }
}
