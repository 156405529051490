import {DataGrid, GridRenderCellParams} from '@mui/x-data-grid';
import {Box, Stack} from '@mui/material';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {expenseDAMasterStateActions} from '@app/screens/master-data-management/pages/expense-da-master/redux';
import {expenseDAMasterStateSelector} from '@app/screens/master-data-management/pages/expense-da-master/redux/selectors';
import {filterSelector} from '@app/components/right-sidebar/redux/filterHandlerSelector';
import {fetchExpenseDAMasterGridCreator} from '@app/screens/master-data-management/pages/expense-da-master/redux/slice';
import {useState, useEffect, useCallback} from 'react';
import {Gridstyle} from '@app/components/widgets/grid/grid';
import {renderDateCell} from '@app/screens/configuration-management/components/expense-type-setting-grid';
import {filterStateActions} from '@app/components/right-sidebar/redux';

export const ExpenseDAGrid = ({DAgridData}: {DAgridData: any}) => {
  const dispatch = useDispatch();
  const pageNumber = useSelector(
    expenseDAMasterStateSelector.getPageNo(),
    shallowEqual,
  );
  const pageRow = useSelector(
    expenseDAMasterStateSelector.getRowPerPage(),
    shallowEqual,
  );
  const total = useSelector(
    expenseDAMasterStateSelector.getExpenseDAMasterTotalCount(),
    shallowEqual,
  );

  const [page, setPage] = useState(pageNumber);
  const [pageSize, setPageSize] = useState(pageRow);
  const loading = useSelector(
    expenseDAMasterStateSelector.getExpenseDAMasterLoading(),
    shallowEqual,
  );
  const request = useSelector(filterSelector.getFilterOptions());

  const getRowIdHandlerExpDAMaster = useCallback(function (row: any) {
    return row?.expenseDAMasterId;
  }, []);

  const PageSizeChangeExpDAMaster = useCallback(
    function (newPageSize: any) {
      return setPageSize(newPageSize);
    },
    [pageSize],
  );

  const PageChangeExpDAMaster = useCallback(
    function (newPage: any) {
      return setPage(newPage);
    },
    [page],
  );

  const noRowsOverlayComponent = useCallback(
    () => (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No Records Found
      </Stack>
    ),
    [],
  );
  useEffect(() => {
    dispatch(expenseDAMasterStateActions.setPageNo(page));
    dispatch(expenseDAMasterStateActions.setRowPerPage(pageSize));
    if (Number(page) * Number(pageSize) > total) {
      dispatch(expenseDAMasterStateActions.setPageNo(0));
      dispatch(
        filterStateActions.setFilterOptions({
          ...request,
          pageNumber: 0,
          pageLimit: pageSize,
        }),
      );
    } else {
      dispatch(
        filterStateActions.setFilterOptions({
          ...request,
          pageNumber: page,
          pageLimit: pageSize,
        }),
      );
    }

    dispatch(fetchExpenseDAMasterGridCreator(null));
    console.log(total);
    console.log(Number(page) * Number(pageSize), 'pageSize');
  }, [page, pageSize]);

  useEffect(() => {
    setPage(pageNumber);
    setPageSize(pageRow);
  }, [pageNumber, pageRow]);

  const columnsDAMaster = [
    {
      field: 'divisionName',
      headerName: 'Division',
      width: 130,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return <p>{params.row.divisionName}</p>;
      },
    },
    {
      field: 'designationName',
      headerName: 'Designation',
      width: 150,
      editable: false,

      renderCell: (params: GridRenderCellParams<any>) => {
        return <p>{params.row.designationName}</p>;
      },
    },

    {
      field: 'hqName',
      headerName: 'HQ',
      width: 150,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return <p>{params.row.hqName}</p>;
      },
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 120,
      editable: false,
    },
    {
      field: 'hqTypeName',
      headerName: 'HQ Type',
      width: 110,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return <p>{params.row.hqTypeName}</p>;
      },
    },
    {
      field: 'startDate',
      headerName: 'Effective From',
      width: 150,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return renderDateCell(params?.row?.startDate);
      },
    },
    {
      field: 'endDate',
      headerName: 'Valid Upto',
      width: 120,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return renderDateCell(params?.row?.endDate);
      },
    },
  ];
  return (
    <Box sx={Gridstyle.gridcss}>
      <DataGrid
        columnBuffer={8}
        page={page}
        loading={loading}
        onPageChange={PageChangeExpDAMaster}
        pagination
        paginationMode="server"
        pageSize={pageSize}
        rows={DAgridData ?? []}
        rowCount={
          Number(page) * Number(pageSize) > total
            ? Number(page) * Number(pageSize)
            : total
        }
        columns={columnsDAMaster}
        onPageSizeChange={PageSizeChangeExpDAMaster}
        getRowId={getRowIdHandlerExpDAMaster}
        rowsPerPageOptions={[10, 20, 50, 100]}
        components={{
          NoRowsOverlay: noRowsOverlayComponent,
        }}
      />
    </Box>
  );
};
