import {DataGrid, GridRenderCellParams} from '@mui/x-data-grid';
import {Box, Stack} from '@mui/material';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {expenseTAMasterStateSelector} from '../../../pages/expense-ta-master/redux/selectors';
import {Gridstyle} from '@app/components/widgets/grid/grid';
import {useCallback, useEffect, useState} from 'react';
import {renderDateCell} from '@app/screens/configuration-management/components/expense-type-setting-grid';
import {expenseTAMasterStateActions} from '@app/screens/master-data-management/pages/expense-ta-master/redux';
import {
  filterSelector,
  filterStateActions,
} from '@app/components/right-sidebar/redux';
import {fetchExpenseTAMasterGridCreator} from '@app/screens/master-data-management/pages/expense-ta-master/redux/slice';

export const ExpenseTAGrid = ({gridData}: {gridData: any}) => {
  const dispatch = useDispatch();
  const expenseTAMasterLoading = useSelector(
    expenseTAMasterStateSelector.getExpenseTAMasterLoading(),
  );

  const expenseTAMasterPageno = useSelector(
    expenseTAMasterStateSelector.getPage(),
  );
  const expenseTAMasterPagesize = useSelector(
    expenseTAMasterStateSelector.getPageSize(),
  );
  const totalCount = useSelector(
    expenseTAMasterStateSelector.getTotalCount(),
    shallowEqual,
  );
  const request = useSelector(filterSelector.getFilterOptions());
  const [pageSize, setPageSize] = useState(expenseTAMasterPagesize);
  const [pageNo, setPageNo] = useState(expenseTAMasterPageno);

  const noRowsOverlayComponent = useCallback(
    () => (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No Records Found
      </Stack>
    ),
    [],
  );
  const getRowIdHandlerExpTAMaster = useCallback(function (row: any) {
    return row?.expenseTAMasterId;
  }, []);
  const PageSizeChangeExpTAMaster = useCallback(
    function (newPageSize: any) {
      return setPageSize(newPageSize);
    },
    [pageSize],
  );

  const PageChangeExpTAMaster = useCallback(
    function (newPageNo: any) {
      return setPageNo(newPageNo);
    },
    [pageNo],
  );
  useEffect(() => {
    dispatch(expenseTAMasterStateActions.setPage(pageNo));
    dispatch(expenseTAMasterStateActions.setPageSize(pageSize));
    if (Number(pageNo) * Number(pageSize) > totalCount) {
      dispatch(expenseTAMasterStateActions.setPage(0));
      dispatch(
        filterStateActions.setFilterOptions({
          ...request,
          pageNumber: 0,
          pageLimit: pageSize,
        }),
      );
    } else {
      dispatch(
        filterStateActions.setFilterOptions({
          ...request,
          pageNumber: pageNo,
          pageLimit: pageSize,
        }),
      );
    }

    dispatch(fetchExpenseTAMasterGridCreator(null));
  }, [pageNo, pageSize]);

  useEffect(() => {
    setPageNo(expenseTAMasterPageno);
    setPageSize(expenseTAMasterPagesize);
  }, [expenseTAMasterPageno, expenseTAMasterPagesize]);

  const columns = [
    {field: 'expenseTAMasterId', hide: true},
    {
      field: 'division',
      headerName: 'Division',
      width: 120,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return <p>{params?.row?.division}</p>;
      },
    },
    {
      field: 'designation',
      headerName: 'Designation',
      width: 200,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return <p>{params?.row?.designation}</p>;
      },
    },

    {
      field: 'hq',
      headerName: 'HQ',
      width: 200,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return <p>{params.row.hq}</p>;
      },
    },
    {
      field: 'taPerKM',
      headerName: 'TA per km',
      width: 120,
      editable: false,
    },
    {
      field: 'startDate',
      editable: false,
      headerName: 'Effective From',
      width: 150,
      renderCell: (params: GridRenderCellParams<any>) => {
        return renderDateCell(params?.row?.startDate);
      },
    },
    {
      field: 'endDate',
      width: 120,
      headerName: 'Valid Upto',
      renderCell: (params: GridRenderCellParams<any>) => {
        return renderDateCell(params?.row?.endDate);
      },
      editable: false,
    },
  ];
  return (
    <Box sx={Gridstyle.gridcss}>
      <DataGrid
        pagination
        paginationMode="server"
        rows={gridData ?? []}
        page={pageNo}
        rowCount={
          Number(pageNo) * Number(pageSize) > totalCount
            ? Number(pageNo) * Number(pageSize)
            : totalCount
        }
        loading={expenseTAMasterLoading}
        getRowId={getRowIdHandlerExpTAMaster}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 20, 50, 100]}
        onPageSizeChange={PageSizeChangeExpTAMaster}
        onPageChange={PageChangeExpTAMaster}
        components={{
          NoRowsOverlay: noRowsOverlayComponent,
        }}
      />
    </Box>
  );
};
