import {createSelector} from '@reduxjs/toolkit';

const getActivityMappingState = (state: any) => state.activityMappingMaster;

const getShowCreateActivityModal = createSelector(
  [getActivityMappingState],
  data => data?.showCreateActivityModal,
);

const getShowCreateActivityScreen = createSelector(
  [getActivityMappingState],
  data => data?.showCreateActivityScreen,
);

const getWSFAActivityList = createSelector(
  [getActivityMappingState],
  data => data?.wsfaActivityList,
);

const getTempWSFAActivityList = createSelector(
  [getActivityMappingState],
  data => data?.tempWsfaActivityList,
);

const getCopiedMappedItem = createSelector(
  [getActivityMappingState],
  data => data?.copiedMappedItem,
);

const getUnMappedActivitiesList = createSelector(
  [getActivityMappingState],
  data => data?.unMappedActivities,
);

const getTempUnMappedActivitiesList = createSelector(
  [getActivityMappingState],
  data => data?.tempUnMappedActivities,
);

const getMappingRemoved = createSelector(
  [getActivityMappingState],
  data => data?.mappingRemoved,
);

const getSaveResponse = createSelector(
  [getActivityMappingState],
  data => data?.savedResponse,
);

const getNewMappingCreated = createSelector(
  [getActivityMappingState],
  data => data?.newMappingCreated,
);

const getMappedLoader = createSelector(
  [getActivityMappingState],
  data => data?.mappedLoader,
);

const getUnMappedLoader = createSelector(
  [getActivityMappingState],
  data => data?.unMappedLoader,
);

const getActivitiesList = createSelector(
  [getActivityMappingState],
  data => data?.activitiesList,
);

const getCloneActivitiesList = createSelector(
  [getActivityMappingState],
  data => data?.cloneActivitiesList,
);

const getActivitiesListGridOptions = createSelector(
  [getActivityMappingState],
  data => data?.activitiesListGridOptions,
);

const getDivisionMapping = createSelector(
  [getActivityMappingState],
  data => data?.divisionMapping,
);

const getCreateActivityResponse = createSelector(
  [getActivityMappingState],
  data => data?.createActivityResponse,
);

const getCreateActivitySearchText = createSelector(
  [getActivityMappingState],
  data => data?.createActivitySearchText,
);

const getSearchFlag = createSelector(
  [getActivityMappingState],
  data => data?.searchFlag,
);

const getSearchedList = createSelector(
  [getActivityMappingState],
  data => data?.searchedList,
);

export const activityMappingSelector = {
  getShowCreateActivityModal: () => getShowCreateActivityModal,
  getShowCreateActivityScreen: () => getShowCreateActivityScreen,
  getWSFAActivityList: () => getWSFAActivityList,
  getTempWSFAActivityList: () => getTempWSFAActivityList,
  getCopiedMappedItem: () => getCopiedMappedItem,
  getUnMappedActivitiesList: () => getUnMappedActivitiesList,
  getTempUnMappedActivitiesList: () => getTempUnMappedActivitiesList,
  getMappingRemoved: () => getMappingRemoved,
  getSaveResponse: () => getSaveResponse,
  getNewMappingCreated: () => getNewMappingCreated,
  getMappedLoader: () => getMappedLoader,
  getUnMappedLoader: () => getUnMappedLoader,
  getActivitiesList: () => getActivitiesList,
  getCloneActivitiesList: () => getCloneActivitiesList,
  getActivitiesListGridOptions: () => getActivitiesListGridOptions,
  getDivisionMapping: () => getDivisionMapping,
  getCreateActivityResponse: () => getCreateActivityResponse,
  getCreateActivitySearchText: () => getCreateActivitySearchText,
  getSearchedList: () => getSearchedList,
  getSearchFlag: () => getSearchFlag,
};
