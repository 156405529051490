import {createAction, createSlice} from '@reduxjs/toolkit';
interface InitialStateProps {
  cutOffDateConfigurationData: {
    id: number;
    quarterId: number;
    quarterName: '';
    date: number;
    isActive: boolean;
  }[];
  yearlyQuarter: any;
  cutOffDates: any;
  cutOffDateConfigurationEditData: {
    id: number;
    quarterId: number;
    quarterName: string;
    date: number;
    isActive: boolean;
  };
}

const initialState: InitialStateProps = {
  cutOffDateConfigurationData: [],
  yearlyQuarter: null,
  cutOffDates: null,
  cutOffDateConfigurationEditData: {
    id: 0,
    quarterId: 0,
    quarterName: '',
    date: 0,
    isActive: true,
  },
};

export const cutOffDateConfigurationCreator = createAction<any>(
  'INCENTIVE/CUT_OFF_DATE_CONFIGURATION',
);
export const cutOffDateConfigurationTypeName =
  cutOffDateConfigurationCreator(null).type;

export const editCutOffDateConfigurationCreator = createAction<any>(
  'INCENTIVE/EDIT_CUT_OFF_DATE_CONFIGURATION',
);
export const editCutOffDateConfigurationCreatorTypeName =
  editCutOffDateConfigurationCreator(null).type;

export const getYearlyQuarterCreator = createAction<any>(
  'INCENTIVE/GET_YEARLY_QUARTER',
);
export const getYearlyQuarterTypeName = getYearlyQuarterCreator(null).type;

export const getCutOffDatesCreator = createAction<any>('INCENTIVE/GET_DATE');
export const getCutOffDatesTypeName = getCutOffDatesCreator(null).type;

const cutOffDateConfigurationSlice = createSlice({
  name: 'CUT_OFF_DATE_CONFIGURATION',
  initialState: initialState,
  reducers: {
    fetchCutOffDateConfigurationData: (state, action) => {
      return {
        ...state,
        cutOffDateConfigurationData: action.payload,
      };
    },
    fetchNewcutOffDateConfigurationData: (state, action) => {
      return {
        ...state,
        cutOffDateConfigurationData: [
          ...state.cutOffDateConfigurationData,
          ...action.payload,
        ],
      };
    },
    fetchYearlyQuarter: (state, action) => {
      return {
        ...state,
        yearlyQuarter: action.payload,
      };
    },
    fetchCutOffDates: (state, action) => {
      return {
        ...state,
        cutOffDates: action.payload,
      };
    },
    fetchCutOffDateConfigurationEditData: (state, action) => {
      return {
        ...state,
        cutOffDateConfigurationEditData: action.payload,
      };
    },
  },
});

export const cutOffDateConfigurationActions =
  cutOffDateConfigurationSlice.actions;
export const cutOffDateConfigurationReducer =
  cutOffDateConfigurationSlice.reducer;
